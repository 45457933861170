import { InputFileType, TaskType } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { getSecondsFrom } from '../../../utils/helpers';

export type EasyPrintTaskFormFields = Omit<API.TaskEasyPrint, 'maxDurationInSeconds' | 'validationErrors'> & {
    timeToWait: number;
};

export const convertFormToEasyPrintTask = (form: EasyPrintTaskFormFields): API.TaskEasyPrint => {
    const { timeToWait, resources, ...rest } = form;
    let { attachment, htmlTemplate } = form;

    if (!resources.envelope.logo.filename) {
        resources.envelope.logo = undefined;
    }

    if (!resources.envelope.supplierExample.filename) {
        resources.envelope.supplierExample = undefined;
    }

    if (!attachment.filename) {
        attachment = undefined;
    }

    if (!htmlTemplate.filename) {
        htmlTemplate = undefined;
    }

    return {
        ...rest,
        maxDurationInSeconds: getSecondsFrom(timeToWait),
        taskType: TaskType.EASY_PRINT,
        resources,
        attachment,
        htmlTemplate,
        validationErrors: [],
    };
};
