import { ParcelType } from '@eservices/shared/constant';
import { RecipientWithParcelInfo } from '@eservices/shared/type';

export type RecipientsActions =
    | { type: 'insert'; payload: RecipientWithParcelInfo }
    | { type: 'insertAtIndex'; payload: { index: number; recipient: RecipientWithParcelInfo } }
    | { type: 'insertList'; payload: RecipientWithParcelInfo[] }
    | { type: 'update'; payload: { index: number; recipient: RecipientWithParcelInfo } }
    | { type: 'delete'; payload: number }
    | {
          type: 'empty';
      };

interface RecipientsState {
    recipients?: RecipientWithParcelInfo[];
}

const defaultState: RecipientsState = {
    recipients: [],
};

const validateRecipient = (recipient: RecipientWithParcelInfo): RecipientWithParcelInfo => {
    let hasError = false;

    if (recipient.type === ParcelType.PARCEL_WITH_CUSTOMS && recipient.customsDetails.contents.length === 0)
        hasError = true;

    return { ...recipient, hasError: hasError && recipient.hasError };
};

export function recipientsReducer(state: RecipientsState = defaultState, action: RecipientsActions): RecipientsState {
    switch (action.type) {
        case 'insert': {
            const recipient = action.payload;
            const validatedRecipient = validateRecipient(recipient);
            return { recipients: [validatedRecipient, ...state.recipients] };
        }
        case 'insertAtIndex': {
            const index = action.payload.index;
            const recipient = action.payload.recipient;
            const validatedRecipient = validateRecipient(recipient);
            const before = state.recipients.slice(0, index);
            const after = state.recipients.slice(index);
            return { recipients: [...before, validatedRecipient, ...after] };
        }
        case 'insertList': {
            const recipients = action.payload;
            const validatedRecipients = recipients.map(validateRecipient);
            return { recipients: [...validatedRecipients, ...state.recipients] };
        }
        case 'update': {
            const updatedRecipients = [...state.recipients];
            const recipient = action.payload.recipient;
            const validatedRecipient = validateRecipient(recipient);
            updatedRecipients[action.payload.index] = validatedRecipient;
            return { recipients: updatedRecipients };
        }
        case 'delete': {
            const { recipients } = state;
            const recipientToBeRemoved = action.payload;
            return {
                recipients: [
                    ...recipients.slice(0, recipientToBeRemoved),
                    ...recipients.slice(recipientToBeRemoved + 1),
                ],
            };
        }
        case 'empty': {
            return defaultState;
        }
        default:
            throw new Error();
    }
}
