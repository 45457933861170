import { API } from '@eservices/shared/type';
import { FormInstance } from 'antd/lib/form/Form';
import { useEffect } from 'react';
import { UseQueryResult } from 'react-query';
import DepartmentDetail from '../../components/department/DepartmentDetail';
import ResourceDetailLayout from '../../layouts/ResourceDetailLayout';

interface DepartmentDetailFetchingProps {
    isDetailsReadOnly: boolean;
    isUsersReadonly: boolean;
    companyId: string;
    query: UseQueryResult<API.Department, Error>;
    form: FormInstance;
    actions?: React.ReactNode;
    users: API.User[];
    setUsers: (users: API.User[]) => void;
    errors?: string[];
    onBack?: () => void;
}

const DepartmentDetailFetching: React.FC<DepartmentDetailFetchingProps> = ({
    companyId,
    isDetailsReadOnly,
    isUsersReadonly,
    actions,
    query,
    form,
    users,
    setUsers,
    errors,
    onBack: onBackClick,
}) => {
    useEffect(() => {
        if (query.data) {
            const {
                name,
                headAddress: { countryCode, city, street, postalCode, streetNr },
                phoneNumber,
                emailAddress,
                erpId,
                hasSeparateInvoicing,
                identificationNumber,
                isEligibleForRPRegisteredMail,
            } = query.data;
            form.setFieldsValue({
                name,
                countryCode,
                city,
                street,
                postalCode,
                streetNr,
                phoneNumber,
                emailAddress,
                erpId,
                identificationNumber,
                hasSeparateInvoicing,
                isEligibleForRPRegisteredMail,
            });
            setUsers(query.data.users);
        }
    }, [query.data]);

    const detail = (
        <DepartmentDetail
            isDetailsReadOnly={isDetailsReadOnly}
            isUsersReadOnly={isUsersReadonly}
            companyId={companyId}
            form={form}
            users={users}
            setUsers={setUsers}
        />
    );

    return (
        <ResourceDetailLayout
            isLoading={query.isLoading}
            isError={query.isError}
            title={query.data?.name}
            createdAt={query.data?.createdAt}
            lastModifiedAt={query.data?.lastModifiedAt}
            lastModifiedBy={query.data?.lastModifiedBy}
            errors={errors}
            detail={detail}
            actions={actions}
            onBack={onBackClick}
        />
    );
};

export default DepartmentDetailFetching;
