import { Card as AntCard } from 'antd';
import styled from 'styled-components';
import { Fonts } from '../constants';

export default styled(AntCard)`
    .ant-card-head-title {
        text-transform: capitalize;
        font-family: ${Fonts.HURME4_LIGHT};
        font-size: 16px;
    }
`;
