import { LoadingOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { Space, Timeline } from 'antd';
import { useTranslation } from '../../../hooks/translationHook';
import { toReadableDateTime } from '../../../utils/helpers';

interface QerdsProgressTaskProps {
    task: API.QERDSTaskDetails;
}

const QerdsTaskProgress: React.FC<QerdsProgressTaskProps> = ({ task }) => {
    const { t, tCommon } = useTranslation('qerds_task');

    const [
        received,
        signed,
        revoked,
        sent,
        recipientIdentityMatched,
        recipientIdentityVerified,
        recipientDecisionTaken,
        processed,
        expired,
    ] = task.statusHistory;

    const getColor = (endDate: string): string => {
        if (endDate) return 'green';
        else return 'gray';
    };

    const showLoadingIcon = (endDate: string, previousTaskEndDate: string): boolean => {
        if (endDate || task.endDate) return false;
        if (previousTaskEndDate) return true;
    };

    return (
        <Timeline>
            <Timeline.Item color={getColor(received.date)}>
                <Space>
                    {t(received.value.toLocaleLowerCase())} {received.date && toReadableDateTime(received.date)}
                </Space>
            </Timeline.Item>
            <Timeline.Item
                color={getColor(signed.date)}
                dot={showLoadingIcon(signed.date, sent.date) && <LoadingOutlined spin />}
            >
                <Space>
                    {t(signed.value.toLocaleLowerCase())} {signed.date && toReadableDateTime(signed.date)}
                </Space>
            </Timeline.Item>
            <Timeline.Item
                color={getColor(sent.date)}
                dot={showLoadingIcon(sent.date, recipientIdentityMatched.date) && <LoadingOutlined spin />}
            >
                <Space>
                    {t(sent.value.toLocaleLowerCase())} {sent.date && toReadableDateTime(sent.date)}
                </Space>
            </Timeline.Item>

            {revoked.date && (
                <Timeline.Item color={'red'}>
                    <Space>
                        {t(revoked.value.toLocaleLowerCase())} {revoked.date && toReadableDateTime(revoked.date)}
                    </Space>
                </Timeline.Item>
            )}

            {expired.date && (
                <Timeline.Item color={'red'}>
                    <Space>
                        {t(expired.value.toLocaleLowerCase())} {expired.date && toReadableDateTime(expired.date)}
                    </Space>
                </Timeline.Item>
            )}

            {!revoked.date && !expired.date && (
                <>
                    <Timeline.Item
                        color={getColor(recipientIdentityMatched.date)}
                        dot={
                            showLoadingIcon(recipientIdentityMatched.date, recipientIdentityVerified.date) && (
                                <LoadingOutlined spin />
                            )
                        }
                    >
                        <Space>
                            {t(recipientIdentityMatched.value.toLocaleLowerCase())}{' '}
                            {recipientIdentityMatched.date && toReadableDateTime(recipientIdentityMatched.date)}
                        </Space>
                    </Timeline.Item>
                    <Timeline.Item
                        color={getColor(recipientIdentityVerified.date)}
                        dot={
                            showLoadingIcon(recipientIdentityVerified.date, recipientDecisionTaken.date) && (
                                <LoadingOutlined spin />
                            )
                        }
                    >
                        <Space>
                            {t(recipientIdentityVerified.value.toLocaleLowerCase())}{' '}
                            {recipientIdentityVerified.date && toReadableDateTime(recipientIdentityVerified.date)}
                        </Space>
                    </Timeline.Item>
                    <Timeline.Item
                        color={getColor(recipientDecisionTaken.date)}
                        dot={
                            showLoadingIcon(recipientDecisionTaken.date, recipientDecisionTaken.date) && (
                                <LoadingOutlined spin />
                            )
                        }
                    >
                        <Space>
                            {t(recipientDecisionTaken.value.toLocaleLowerCase())}{' '}
                            {recipientDecisionTaken.date && toReadableDateTime(recipientDecisionTaken.date)}
                        </Space>
                    </Timeline.Item>
                </>
            )}

            <Timeline.Item color={task.endDate && processed.date ? 'green' : task.endDate ? 'red' : 'gray'}>
                <Space>
                    {tCommon('end')} {task.endDate && toReadableDateTime(task.endDate)}
                </Space>
            </Timeline.Item>
        </Timeline>
    );
};

export default QerdsTaskProgress;
