import { API } from '@eservices/shared/type';
import React from 'react';
import { assignableRolesPerDepartment } from '../../constants';
import styled from 'styled-components';
import DepartmentUsersPerRoleEditor from './DepartmentUsersPerRoleEditor';
import { useTranslation } from '../../hooks/translationHook';
import { Header3 } from '../Headers';
import Card from '../Card';

const Container = styled(Card)`
    min-width: 600px;
`;

const Users = styled.div`
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
`;

interface DepartmentUsersEditorProps {
    isReadOnly: boolean;
    companyId: string;
    users: API.User[];
    setUsers?: (users: API.User[]) => void;
}

const DepartmentUsersEditor: React.FC<DepartmentUsersEditorProps> = ({ companyId, users, setUsers, isReadOnly }) => {
    const { tCommon } = useTranslation('department');

    const handleAddUsers = (newlyAddedUsers: API.User[]) => {
        setUsers(users.concat(newlyAddedUsers));
    };

    const handleRemoveUser = (id: string) => {
        setUsers(users.filter((user) => user.id !== id));
    };

    return (
        <Container title={tCommon('users')}>
            <Users>
                {assignableRolesPerDepartment.map((role) => (
                    <DepartmentUsersPerRoleEditor
                        isReadOnly={isReadOnly}
                        key={role}
                        role={role}
                        companyId={companyId}
                        users={users.filter((user) => user.role === role)}
                        onAddUsers={handleAddUsers}
                        onRemoveUser={handleRemoveUser}
                    />
                ))}
            </Users>
        </Container>
    );
};

export default DepartmentUsersEditor;
