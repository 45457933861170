import WarningFilled from '@ant-design/icons/lib/icons/WarningFilled';
import { Colors } from '@eservices/ui-constants/colors';
import { Spin } from 'antd';
import QueryString from 'query-string';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Fonts } from '../../../constants';

const Tile = styled.div`
    border-radius: 15px;
    cursor: pointer;
    padding-top: 35px;
    padding-bottom: 35px;
    height: 180px;
    border: 1px solid ${Colors.GRAY_QUATERNARY};
    background-color: ${Colors.GRAY_TERTIARY};
    text-align: center;
    text-transform: capitalize;
    display: block;
    transition: 0.3s ease-in-out;
    position: relative;

    &:hover {
        border: 1px solid ${Colors.PRIMARY};
        background-color: ${Colors.GRAY_QUATERNARY};
    }
`;

const Title = styled.div`
    font-size: 26px;
    color: ${Colors.GRAY_PRIMARY};
    font-family: ${Fonts.HURME4};
`;

const StyledSpin = styled(Spin)`
    width: 100%;
    margin-top: 30px;
    .ant-spin-dot-item {
        background-color: ${Colors.GRAY_PRIMARY};
    }
`;

const Amount = styled.div`
    font-size: 45px;
    color: ${Colors.GRAY_PRIMARY};
    font-family: ${Fonts.HURME4};
    font-weight: 600;
`;

const TotalWarnings = styled.div`
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    height: 30px;
    width: 120px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    color: ${Colors.WHITE};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${Colors.WARNING};
    font-family: ${Fonts.HURME4};

    transition: 0.3s ease-in-out;
    &:hover {
        background-color: #ffa25d;
    }
`;

const StyledWarningFilled = styled(WarningFilled)`
    margin-right: 10px;
    margin-bottom: 2px;
    svg {
        color: ${Colors.WHITE};
    }
`;

interface TotalInfoTileProps {
    titleSection?: string;
    amount?: number;
    baseRoute: string;
    rangeDate: string;
    departmentId: string;
    isDetailsLoading: boolean;
    totalWarning?: number;
}

const TotalInfoTile: React.FC<TotalInfoTileProps> = ({
    titleSection,
    amount,
    baseRoute,
    isDetailsLoading,
    rangeDate,
    departmentId,
    totalWarning,
}) => {
    const history = useHistory();

    const redirectToFullDetails = () => {
        history.push({
            pathname: baseRoute,
            search: QueryString.stringify({
                rangeDate,
                departmentId,
            }),
        });
    };
    const redirectToFullDetailsWithWarnings = (e) => {
        e.stopPropagation();
        history.push({
            pathname: baseRoute,
            search: QueryString.stringify({
                rangeDate,
                departmentId,
                containWarnings: true,
            }),
        });
    };

    return (
        <Tile onClick={() => redirectToFullDetails()}>
            <Title>{titleSection}</Title>
            {isDetailsLoading ? <StyledSpin /> : <Amount>{amount}</Amount>}
            {totalWarning > 0 && (
                <TotalWarnings onClick={redirectToFullDetailsWithWarnings}>
                    <StyledWarningFilled /> {totalWarning}
                </TotalWarnings>
            )}
        </Tile>
    );
};

export default TotalInfoTile;
