import FilePdfOutlined from '@ant-design/icons/lib/icons/FilePdfOutlined';
import TableOutlined from '@ant-design/icons/lib/icons/TableOutlined';
import { WorkflowInputSourceType } from '@eservices/shared/constant';
import { FormInstance } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../../hooks/translationHook';
import { FormItem } from '../../FormItem';
import { Header2 } from '../../Headers';
import { Paragraph } from '../../Paragraph';
import WizardChoiceWithIcon from '../../WizardChoiceWithIcon';

const Choices = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 30px;
    margin: 5% 0 0 0;
`;

interface InputSourceTypeQuestionProps {
    form: FormInstance;
}

const InputSourceTypeQuestion: React.FC<InputSourceTypeQuestionProps> = ({ form }) => {
    const { t, tValidation } = useTranslation('workflow');
    const [inputSourceType, setInputSourceType] = useState(form.getFieldValue(['inputSource', 'type']));

    const inputSourceTypes = [
        {
            type: WorkflowInputSourceType.PDF,
            title: t('pdf_option_title'),
            description: 'pdf_option_description',
            cover: <FilePdfOutlined />,
            enabled: true,
        },
        {
            type: WorkflowInputSourceType.CSV,
            title: t('csv_option_title'),
            description: 'csv_option_description',
            cover: <TableOutlined />,
            enabled: false,
        },
    ];

    return (
        <>
            <Header2>{t('input_source_title')}</Header2>
            <Paragraph>{t('input_source_description')}</Paragraph>
            <FormItem
                name={['inputSource', 'type']}
                rules={[
                    {
                        required: true,
                        message: tValidation('required.input_source'),
                    },
                ]}
                hidden
            />
            <Choices>
                {inputSourceTypes.map(({ type, enabled, cover, title, description }) => (
                    <WizardChoiceWithIcon
                        size={inputSourceTypes.length > 2 ? 'small' : 'default'}
                        key={type}
                        onClick={() => {
                            setInputSourceType(type);
                            form.setFieldsValue({
                                inputSource: { type },
                            });
                        }}
                        enabled={enabled}
                        selected={inputSourceType === type}
                        icon={cover}
                        title={title}
                        description={description}
                    />
                ))}
            </Choices>
        </>
    );
};

export default InputSourceTypeQuestion;
