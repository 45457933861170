import {
    CheckCircleFilled,
    ClockCircleFilled,
    CloseOutlined,
    DownloadOutlined,
    FireFilled,
    Loading3QuartersOutlined,
    SendOutlined,
    StopOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import { ApprovalStatus, JobStatus } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Colors } from '@eservices/ui-constants/colors';
import { Popconfirm, Progress, Space, Tooltip } from 'antd';
import Countdown from 'antd/lib/statistic/Countdown';
import { ColumnsType } from 'antd/lib/table';
import QueryString from 'query-string';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Routes } from '../../constants';
import { useTranslation } from '../../hooks/translationHook';
import { getPaginationDetails, toReadableDateTime } from '../../utils/helpers';
import { IconButton } from '../Buttons';
import DownloadLink from '../DownloadLink';
import Table from '../Table';
import { Tag } from '../Tag';
import ApprovalModal from './ApprovalModal';

const tooltipDelay = 1;

const SendingAmount = styled.span`
    width: 40px;
`;

const percentagePerStatus = {
    [JobStatus.WAITING]: 0,
    [JobStatus.OK]: 100,
    [JobStatus.PROCESSING]: 50,
    [JobStatus.ACTION_REQUIRED]: 80,
    [JobStatus.ERROR]: 100,
};

const colorPerApproval_status = {
    [ApprovalStatus.APPROVED]: 'green',
    [ApprovalStatus.REJECTED]: 'red',
};

interface JobsTableProps {
    companyId: string;
    jobs: API.JobListItem[];
    total: number;
    isLoading: boolean;
    onPaginationChange: (page: number, pageSize?: number) => void;
    departments: API.DepartmentListItem[];
    workflows: API.WorkflowConfigurationListItem[];
    canCancelJobs: boolean;
    canApproveJobs: boolean;
    pagination: { page: number; pageSize: number };
    onCancelJob: (jobId: string, departmentId: string) => void;
    onApproveJob: (jobId: string, departmentId: string) => void;
    onRejectJob: (jobId: string, departmentId: string) => void;
}

const JobsTable: React.FC<JobsTableProps> = ({
    companyId,
    jobs = [],
    total,
    isLoading,
    onPaginationChange,
    departments = [],
    workflows = [],
    canCancelJobs,
    canApproveJobs,
    pagination,
    onCancelJob,
    onApproveJob,
    onRejectJob,
}) => {
    const { t, tCommon } = useTranslation('jobs_overview');

    const paginationConfig = getPaginationDetails(total, {
        current: pagination.page,
        pageSize: pagination.pageSize,
        onChange: onPaginationChange,
        showSizeChanger: true,
    });

    const columns: ColumnsType<API.JobListItem> = [
        {
            title: tCommon('status'),
            width: 10,
            align: 'left',
            render: ({ status, executionDate }: API.JobListItem) => (
                <Space>
                    <Progress
                        strokeColor={Colors[status]}
                        steps={5}
                        percent={percentagePerStatus[status]}
                        showInfo={false}
                    />
                    {status === JobStatus.WAITING && (
                        <Space direction="vertical">
                            <Tooltip
                                title={
                                    <Space>
                                        <Countdown
                                            valueStyle={{
                                                color: Colors.WHITE,
                                                fontSize: '12px',
                                                textAlign: 'center',
                                            }}
                                            format="HH:mm:ss"
                                            value={new Date(executionDate).getTime()}
                                        />
                                    </Space>
                                }
                            >
                                <ClockCircleFilled style={{ color: Colors[status] }} />
                            </Tooltip>
                        </Space>
                    )}
                    <Tooltip title={tCommon(status.toLocaleLowerCase())} mouseEnterDelay={tooltipDelay}>
                        {status === JobStatus.PROCESSING && (
                            <Loading3QuartersOutlined style={{ color: Colors[status] }} />
                        )}
                        {status === JobStatus.ERROR && <FireFilled style={{ color: Colors[status] }} />}
                        {status === JobStatus.OK && <CheckCircleFilled style={{ color: Colors[status] }} />}
                        {status === JobStatus.CANCELLED && <CloseOutlined style={{ color: Colors[status] }} />}
                    </Tooltip>
                </Space>
            ),
        },
        {
            title: tCommon('name'),
            dataIndex: 'name',
        },
        {
            title: tCommon('author'),
            dataIndex: 'author',
        },
        {
            title: tCommon('department'),
            render: (record: API.JobListItem) =>
                departments.find((department) => department.id === record.departmentId)?.name,
        },
        {
            title: tCommon('workflow'),
            render: (record: API.JobListItem) => workflows.find((workflow) => workflow.id === record.workflowId)?.name,
        },
        {
            title: t('amount_sendings'),
            align: 'right',
            render: ({ sendingEvents, id, startDate }: API.JobListItem) => {
                return (
                    <Space>
                        <Link
                            to={`${Routes.SENDING_EVENTS}?${QueryString.stringify({
                                jobId: id,
                                startDate,
                                containWarnings: true,
                            })}`}
                        >
                            <Tag icon={<WarningOutlined />}>
                                <SendingAmount>{sendingEvents.warning}</SendingAmount>
                            </Tag>
                        </Link>
                        <Link
                            to={`${Routes.SENDING_EVENTS}?${QueryString.stringify({
                                jobId: id,
                                startDate,
                            })}`}
                        >
                            <Tag icon={<SendOutlined />}>
                                <SendingAmount>{sendingEvents.ok}</SendingAmount>
                            </Tag>
                        </Link>
                    </Space>
                );
            },
        },
        {
            title: tCommon('approval_status'),
            align: 'center',
            render: (job: API.JobListItem) =>
                job.approval &&
                (job.approval.status === ApprovalStatus.AWAITING_APPROVAL ? (
                    <ApprovalModal
                        isReadOnly={!canApproveJobs}
                        companyId={companyId}
                        job={job}
                        onApproveJob={onApproveJob}
                        onRejectJob={onRejectJob}
                    />
                ) : (
                    <Tag color={colorPerApproval_status[job.approval.status]}>
                        {tCommon(job.approval.status.toLowerCase())}
                    </Tag>
                )),
        },
        {
            title: tCommon('start_date'),
            align: 'right',
            render: ({ startDate }: API.JobListItem) => toReadableDateTime(startDate),
        },
        {
            width: 10,
            align: 'right',
            render: ({ status, originalFilename, location, id, departmentId }: API.JobListItem) => (
                <Space>
                    <DownloadLink
                        companyId={companyId}
                        source={API.FileSource.JOB}
                        location={location}
                        content={
                            <Tooltip title={originalFilename}>
                                <IconButton icon={<DownloadOutlined />} />
                            </Tooltip>
                        }
                    />
                    <Popconfirm
                        title={t('cancel_job_confirm')}
                        onConfirm={() => onCancelJob(id, departmentId)}
                        onCancel={() => ({})}
                        okText={tCommon('yes')}
                        cancelText={tCommon('no')}
                        placement="left"
                        disabled={!canCancelJobs || status !== JobStatus.WAITING}
                    >
                        <Tooltip title={t('cancel_job')} mouseEnterDelay={tooltipDelay}>
                            <IconButton
                                danger
                                icon={<StopOutlined />}
                                disabled={!canCancelJobs || status !== JobStatus.WAITING}
                            />
                        </Tooltip>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return <Table loading={isLoading} rowKey="id" columns={columns} dataSource={jobs} pagination={paginationConfig} />;
};

export default JobsTable;
