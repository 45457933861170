import { CloseOutlined, SettingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '../../hooks/translationHook';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { Modal } from '../Modal';

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

interface CustomModalProps {
    children: React.ReactNode;
}

const CustomModal: React.FC<CustomModalProps> = ({ children }) => {
    const { t, tCommon } = useTranslation('subscription');
    const history = useHistory();

    const onCancel = () => {
        history.goBack();
    };

    return (
        <Modal
            visible={true}
            title={t('subscription_modal_title')}
            onCancel={onCancel}
            footer={
                <ButtonsContainer>
                    <SecondaryButton aria-label="back" icon={<CloseOutlined />} onClick={onCancel}>
                        {tCommon('back')}
                    </SecondaryButton>
                    <PrimaryButton aria-label="manage subscription" icon={<SettingOutlined />}>
                        {t('manage_subscription')}
                    </PrimaryButton>
                </ButtonsContainer>
            }
        >
            {children}
        </Modal>
    );
};

export default CustomModal;
