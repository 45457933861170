import { PhysicalAddress } from '@eservices/shared/type';
import { TablePaginationConfig } from 'antd';
import endOfDay from 'date-fns/endOfDay';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import { StringMap, TOptions } from 'i18next';
import { TABLE_PAGE_SIZE } from '../constants';
import { useTranslation } from '../hooks/translationHook';

//TODO replace by shared interface
interface FormFieldError {
    name: string;
    errors: Array<string>;
}

export const translateFieldErrors = (
    tError: (key: string, options?: string | TOptions<StringMap>) => string,
    fieldErrors: FormFieldError[],
): FormFieldError[] => {
    return fieldErrors.map(({ name, errors }) => ({
        name,
        errors: errors.map((e) => tError(e)),
    }));
};

export function arrayMove<T>(arr: Array<T>, old_index: number, new_index: number): Array<T> {
    const element1 = arr[old_index];
    const element2 = arr[new_index];

    const newArr = [...arr];
    newArr[new_index] = element1;
    newArr[old_index] = element2;
    return newArr;
}

export const getDaysFrom = (seconds: number): number => {
    return seconds / 24 / 60 / 60;
};

export const getSecondsFrom = (days: number): number => {
    return days * 24 * 60 * 60;
};

export const getPaginationDetails = (total: number, props: TablePaginationConfig = {}): TablePaginationConfig => {
    const { tCommon } = useTranslation();

    return {
        defaultPageSize: TABLE_PAGE_SIZE,
        pageSizeOptions: ['5', '10', '20', '50', '100'],
        total: total,
        size: 'small',
        showTotal: (count) => <>{count >= 10000 ? tCommon('amount_items_10k') : tCommon('amount_items', { count })}</>,
        position: ['bottomCenter'],
        ...props,
    };
};

export const headAddressToString = (headAddress: PhysicalAddress): string =>
    `${headAddress.countryCode}, ${headAddress.city}, ${headAddress.postalCode}, ${headAddress.street} ${headAddress.streetNr}`;

export const toReadableDateTime = (date: string) => date && format(new Date(date), 'dd/MM/yyyy HH:mm');

export const toReadableDate = (date: string) => date && format(new Date(date), 'dd/MM/yyyy');

export const generateInitials = (name: string) =>
    name
        .split(' ')
        .map((n: string) => n[0])
        .join('');

export const addWildcards = (search: string) => (search.trim() === '' ? '*' : `*${search.split(/[\s-]+/).join('* ')}*`);

export const formatSearchTerm = (withWildCards: boolean, search?: string) => {
    if (search && search.trim() !== '') {
        return withWildCards ? addWildcards(search) : search;
    }
    return withWildCards ? '*' : undefined;
};

export const noop = () => undefined;

export const getRangeDates = (range: string) => {
    const ranges = {
        today: { startDate: startOfDay(new Date()), endDate: new Date() },
        yesterday: { startDate: startOfDay(subDays(new Date(), 1)), endDate: endOfDay(subDays(new Date(), 1)) },
        last_week: { startDate: startOfDay(subDays(new Date(), 7)), endDate: new Date() },
        last_month: { startDate: startOfDay(subMonths(new Date(), 1)), endDate: new Date() },
    };
    return ranges[range];
};
