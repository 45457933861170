import { ChannelProperties, TaskType } from '@eservices/shared/constant';
import { API, PDFChannelIdentifier } from '@eservices/shared/type';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as TaskTypeDetailsIcon } from '../../../../../assets/images/task_type_details.svg';
import { useTranslation } from '../../../../../hooks/translationHook';
import TabContent from '../Common/TabContent';
import { WorkflowPDFInputSourceActions, WorkflowPDFInputSourceDrawingActions } from '../workflowPDFInputSourceReducer';
import QerdsTaskTypeEditor from './QerdsTaskTypeEditor';
import TaskTypeEditor from './TaskTypeEditor';

const Channels = styled.div`
    display: flex;
    gap: 30px;
    flex-direction: column;
`;

interface TaskTypePropertiesProps {
    isReadOnly: boolean;
    tasks: Array<API.TaskConfiguration>;
    channelIdentifiers: PDFChannelIdentifier[];
    workflowPDFInputSourceDispatch: React.Dispatch<WorkflowPDFInputSourceActions>;
    setAction: React.Dispatch<React.SetStateAction<WorkflowPDFInputSourceDrawingActions>>;
}

const TaskTypeProperties: React.FC<TaskTypePropertiesProps> = ({
    isReadOnly,
    tasks,
    channelIdentifiers,
    setAction,
    workflowPDFInputSourceDispatch,
}) => {
    const { t } = useTranslation('workflow');

    const taskTypesUsed = new Set(tasks.map(({ taskType }) => taskType));

    const onChannelPropertyClick = (type: ChannelProperties) => {
        setAction({
            type: 'insertChannelIdentifier',
            payload: {
                kind: type,
                regionOfInterest: {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 0,
                },
            },
        });
    };

    const onClearClick = (type: ChannelProperties) => {
        workflowPDFInputSourceDispatch({
            type: 'removeChannelIdentifier',
            payload: type,
        });
    };

    return (
        <TabContent
            title={t('task_type_properties_title')}
            description={t('markdown_task_type_properties_description')}
            icon={TaskTypeDetailsIcon}
        >
            <Channels>
                {taskTypesUsed.has(TaskType.EASY_PRINT) && (
                    <TaskTypeEditor
                        isReadOnly={isReadOnly}
                        taskType={TaskType.EASY_PRINT}
                        channelProperties={[
                            {
                                type: ChannelProperties.ADDRESS,
                            },
                        ]}
                        channelIdentifiers={channelIdentifiers}
                        onChannelPropertyClick={onChannelPropertyClick}
                        onClearClick={onClearClick}
                    />
                )}
                {taskTypesUsed.has(TaskType.EMAIL) && (
                    <TaskTypeEditor
                        isReadOnly={isReadOnly}
                        taskType={TaskType.EMAIL}
                        channelProperties={[{ type: ChannelProperties.EMAIL_ADDRESS }]}
                        channelIdentifiers={channelIdentifiers}
                        onChannelPropertyClick={onChannelPropertyClick}
                        onClearClick={onClearClick}
                    />
                )}
                {taskTypesUsed.has(TaskType.ERDS) && (
                    <TaskTypeEditor
                        isReadOnly={isReadOnly}
                        taskType={TaskType.ERDS}
                        channelProperties={[{ type: ChannelProperties.EMAIL_ADDRESS }]}
                        channelIdentifiers={channelIdentifiers}
                        onChannelPropertyClick={onChannelPropertyClick}
                        onClearClick={onClearClick}
                    />
                )}
                {taskTypesUsed.has(TaskType.QERDS) && (
                    <QerdsTaskTypeEditor
                        isReadOnly={isReadOnly}
                        taskType={TaskType.QERDS}
                        channelIdentifiers={channelIdentifiers}
                        onChannelPropertyClick={onChannelPropertyClick}
                        onClearClick={onClearClick}
                    />
                )}
            </Channels>
        </TabContent>
    );
};

export default TaskTypeProperties;
