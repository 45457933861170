import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../../hooks/translationHook';
import { FormItem } from '../../FormItem';
import { Input, InputNumberWithLeftAddon, TextArea } from '../../Input';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-flow: row wrap;
    justify-content: flex-start;
    gap: 4% 10%;
`;

const Box = styled.div`
    width: 35%;
    max-width: 500px;
    min-width: 250px;
`;
interface SendingConfigurationProps {
    isReadOnly: boolean;
}

const SendingConfiguration: React.FC<SendingConfigurationProps> = ({ isReadOnly }) => {
    const { t, tCommon, tValidation } = useTranslation('qerds_task');

    return (
        <Container>
            <Box>
                <FormItem
                    label={t('name')}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.name'),
                        },
                    ]}
                >
                    <Input disabled={isReadOnly} type="text" aria-label="name input" />
                </FormItem>
                <FormItem label={tCommon('description')} name="description">
                    <TextArea disabled={isReadOnly} rows={5} aria-label="description input" />
                </FormItem>
            </Box>
            <Box>
                <FormItem
                    label={t('time_to_wait')}
                    name="timeToWait"
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.time_to_wait'),
                        },
                        {
                            type: 'number',
                            min: 1,
                            message: tValidation('min.time_to_wait'),
                        },
                        {
                            type: 'number',
                            max: 30,
                            message: tValidation('max.time_to_wait'),
                        },
                    ]}
                >
                    <InputNumberWithLeftAddon
                        disabled={isReadOnly}
                        addonBefore={t('days')}
                        min={1}
                        max={30}
                        aria-label="time to wait input"
                    />
                </FormItem>
            </Box>
        </Container>
    );
};

export default SendingConfiguration;
