import { FormInstance } from 'antd';
import styled from 'styled-components';
import { Header3 } from '../../components/Headers';
import { Paragraph } from '../../components/Paragraph';
import { useTranslation } from '../../hooks/translationHook';
import Card from '../Card';
import CompanyForm from './CompanyForm';

const Container = styled(Card)`
    max-width: 600px;
`;
interface CompanyDetailProps {
    isReadOnly: boolean;
    form: FormInstance;
}

const CompanyDetail: React.FC<CompanyDetailProps> = ({ isReadOnly, form }) => {
    const { t } = useTranslation('company');

    return (
        <Container>
            <Header3>{t('configuration')}</Header3>
            <Paragraph>{t('configuration_description')}</Paragraph>
            <CompanyForm isReadOnly={isReadOnly} form={form} />
        </Container>
    );
};

export default CompanyDetail;
