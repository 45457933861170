import { CountryCode, ProductCode, ProductsCatalog } from '@eservices/shared/constant';
import { API, OrderItem } from '@eservices/shared/type';
import { message } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useCreateOrder, useGetDepartment } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import Loading from '../../screens/utils/Loading';
import Card from '../Card';
import Cart from './Cart';
import Catalogue from './Catalogue';

const loadingMessageKey = 'loadingMessageKey';

const Container = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
`;

const CatalogueCard = styled(Card)`
    max-width: 55%;
    min-width: 400px;
`;

const ShoppingCart = styled(Card)`
    height: 100%;
    width: 30%;
    min-width: 500px;
`;

interface StorePanelProps {
    form: FormInstance;
    companyId: string;
    departmentId: string;
    onAfterSuccessfullOrder: () => void;
}

const StorePanel: React.FC<StorePanelProps> = ({ form, companyId, departmentId, onAfterSuccessfullOrder }) => {
    const { t } = useTranslation('order');
    const { isLoading, data: department } = useGetDepartment(companyId, departmentId);
    const mutation = useCreateOrder(companyId, departmentId);

    const [orderItems, setOrderItems] = useState([]);

    useEffect(() => {
        if (mutation.isLoading) {
            message.loading({ key: loadingMessageKey, content: t('creating_new_order_loading') }, 0);
        } else {
            message.destroy(loadingMessageKey);
        }
    }, [mutation.isLoading]);

    useEffect(() => {
        if (mutation.isSuccess) {
            message.success(t('creating_new_order_success', { id: mutation.data.id }), 3);
            onAfterSuccessfullOrder();
        }
    }, [mutation.isSuccess]);

    useEffect(() => {
        if (mutation.isError) {
            message.error(t('creating_new_order_error'), 3);
        }
    }, [mutation.isError]);

    const updateCartItems = (orderItems: OrderItem[]) => {
        // Remove items if amount is 0
        const newOrderItems = orderItems.filter((orderItem) => orderItem.amount !== 0);
        setOrderItems(newOrderItems);
    };

    const handleAddProduct = async (productCode: ProductCode) => {
        const fields = await form.validateFields();
        addToCart(productCode, fields[productCode]);
    };

    const addToCart = (productCode: ProductCode, amount: number) => {
        if (amount) {
            const product = ProductsCatalog[productCode];
            const orderItem = orderItems.find((orderItem: OrderItem) => orderItem.code === productCode);
            const currentAmount = orderItem ? orderItem.amount : 0;
            if (currentAmount + amount <= product.maxAmount) {
                if (orderItem) {
                    orderItem.amount = currentAmount + amount;
                    setOrderItems([...orderItems]);
                } else {
                    const newOrderItem: OrderItem = {
                        code: productCode,
                        amount: currentAmount + amount,
                    };
                    setOrderItems([newOrderItem, ...orderItems]);
                }
            } else {
                message.error(t('too_many_items', { num: product.maxAmount }), 3);
            }
        }
    };

    const handlePurchase = async () => {
        const fields = await form.validateFields();
        // Extract items from form
        const productsInCart = Object.keys(fields).filter((key) => key.includes('cart_input'));
        const items: OrderItem[] = productsInCart
            .map((key) => ({ code: key.replace('cart_input_', '') as ProductCode, amount: fields[key] }))
            .filter((item) => item.amount !== 0);
        if (items) {
            // Create order
            const newOrder: API.Order = {
                companyId,
                departmentId,
                items,
            };
            mutation.mutate(newOrder);
        }
    };

    return (
        <Container>
            <CatalogueCard loading={isLoading} type="inner" title={t('stock_title')}>
                <Catalogue
                    countryCode={CountryCode[department?.headAddress.countryCode]}
                    addProductToCart={handleAddProduct}
                />
            </CatalogueCard>
            <ShoppingCart type="inner" title={t('purchase_title')}>
                <Cart form={form} items={orderItems} setItems={updateCartItems} purchaseItems={handlePurchase}></Cart>
            </ShoppingCart>
        </Container>
    );
};

export default StorePanel;
