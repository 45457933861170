import { DownloadOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { Col, Row } from 'antd';
import { PrimaryButton } from '../../../components/Buttons';
import DescriptionItem from '../../../components/DescriptionItem';
import DownloadLink from '../../../components/DownloadLink';
import { useTranslation } from '../../../hooks/translationHook';
import styled from 'styled-components';

const StyledPrimaryButton = styled(PrimaryButton)`
    margin-top: 40px;
`;

const DownloadContainer = styled.div`
    text-align: center;
`;

interface ErdsTaskDetailProps {
    companyId: string;
    task: API.ERDSTaskDetails;
    taskConfiguration: API.TaskERDS;
}

const ErdsTaskDetail: React.FC<ErdsTaskDetailProps> = ({ companyId, task, taskConfiguration }) => {
    const { t, tCommon } = useTranslation('erds_task');

    const NA = <>{tCommon('na')}</>;

    const submissionReport = task?.submissionReportLocation ? (
        <DownloadLink
            companyId={companyId}
            source={API.FileSource.SENDING_EVENT}
            location={task.submissionReportLocation}
            content={<a download>{t('submission_report')}</a>}
        />
    ) : (
        NA
    );

    const deliverReport = task?.deliveryReportLocation ? (
        <DownloadLink
            companyId={companyId}
            source={API.FileSource.SENDING_EVENT}
            location={task.deliveryReportLocation}
            content={<a download>{t('delivery_report')}</a>}
        />
    ) : (
        NA
    );

    return (
        <>
            <Row>
                <Col span={24}>
                    <DescriptionItem title={t('recipient')} content={task.recipient} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem title={t('sender')} content={taskConfiguration.sender.emailAddress} />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem title={t('display_name')} content={taskConfiguration.sender.displayName} />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem title={t('subject')} content={taskConfiguration.subject} />
                </Col>
                <Col span={12}>
                    <DescriptionItem title={t('default_language')} content={taskConfiguration.defaultLanguage} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem title={t('submission_report')} content={submissionReport} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem title={t('delivery_report')} content={deliverReport} />
                </Col>
            </Row>
            <Row>
                <Col span={12} offset={6}>
                    <DownloadContainer>
                        <DownloadLink
                            companyId={companyId}
                            source={API.FileSource.SENDING_EVENT}
                            location={task.location}
                            content={
                                <StyledPrimaryButton icon={<DownloadOutlined />}>
                                    {tCommon('download_document')}
                                </StyledPrimaryButton>
                            }
                        />
                    </DownloadContainer>
                </Col>
            </Row>
        </>
    );
};

export default ErdsTaskDetail;
