import { EditOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { ColumnsType } from 'antd/es/table';
import Table from '../../components/Table';
import { Routes } from '../../constants';
import { getPaginationDetails } from '../../utils/helpers';
import { useTranslation } from '../../hooks/translationHook';
import { IconButton } from '../Buttons';

interface UsersTableProps {
    users: API.User[];
    isLoading: boolean;
}

const UsersTable: React.FC<UsersTableProps> = ({ users = [], isLoading }) => {
    const { t, tCommon } = useTranslation('user_overview');

    const paginationConfig = getPaginationDetails(users.length, {});

    const columns: ColumnsType<API.User> = [
        {
            title: tCommon('username'),
            dataIndex: 'username',
        },
        {
            title: tCommon('email'),
            dataIndex: 'emailAddress',
        },
        {
            title: tCommon('firstname'),
            dataIndex: 'firstName',
        },
        {
            title: tCommon('lastname'),
            dataIndex: 'lastName',
        },
        {
            width: '10%',
            align: 'right',
            render: ({ id }: API.User) => (
                <IconButton icon={<EditOutlined />} href={Routes.EDIT_USER.replace(':id', id)} />
            ),
        },
    ];

    return <Table loading={isLoading} rowKey="id" columns={columns} dataSource={users} pagination={paginationConfig} />;
};

export default UsersTable;
