import { ApplicationModule, Language, rolesRequiringDeparmentsAccess, UserRole } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Col, Form, FormInstance, Row, Space, Switch, Transfer } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { TransferItem } from 'antd/lib/transfer';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormItem } from '../../components/FormItem';
import { Input, Select } from '../../components/Input';
import { useTranslation } from '../../hooks/translationHook';
import Card from '../Card';
import { Checkbox } from '../Checkbox';
import CheckSquareOutlined from '../CheckSquareOutlined';
import { Header3 } from '../Headers';

const Container = styled.div`
    width: 100%;
`;

const UserFields = styled(Card)`
    width: 60%;
`;

const Details = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
`;

const MultiSelect = styled(Transfer)`
    /* Workaround to avoid bug on MenuItem missing key */
    span.ant-transfer-list-header-dropdown {
        display: none;
    }
`;

const allowedRolesToSelect: Record<UserRole, UserRole[]> = {
    SUPER_USER: Object.values(UserRole),
    CLIENT_ADMIN: [
        UserRole.CLIENT_ADMIN,
        UserRole.CLIENT_DEPARTMENT_ADMIN,
        UserRole.CLIENT_OPERATOR,
        UserRole.CLIENT_VIEWER,
    ],
    CLIENT_DEPARTMENT_ADMIN: [UserRole.CLIENT_DEPARTMENT_ADMIN, UserRole.CLIENT_OPERATOR, UserRole.CLIENT_VIEWER],
    CLIENT_OPERATOR: [],
    CLIENT_VIEWER: [],
};

interface UserDetailsFormProps {
    authorRole: UserRole;
    departments: API.DepartmentListItem[];
    isUpdate: boolean;
    form: FormInstance;
}

const UserForm: FC<UserDetailsFormProps> = ({ departments, authorRole, isUpdate, form }) => {
    const { t, tCommon, tValidation } = useTranslation('user');

    const [targetKeys, setTargetKeys] = useState(form.getFieldValue('departments') ?? []);
    const [selectedRole, setSelectedRole] = useState(form.getFieldValue('role') ?? UserRole.CLIENT_VIEWER);
    const [departmentRequired, setDepartmentRequired] = useState(false);

    const modulesOptions = [
        { label: t('module_ep_connect'), value: ApplicationModule.EASYPOST_CONNECT },
        { label: t('module_ep_classic'), value: ApplicationModule.EASYPOST_PORTAL },
    ];

    const dataSource: TransferItem[] = departments.map((department: API.DepartmentListItem) => ({
        title: department.name,
        key: department.id,
    }));

    const onChange = (nextTargetKeys: string[]) => {
        setTargetKeys(nextTargetKeys);
    };

    const onSelectRoleChange = (value: SelectValue) => {
        setSelectedRole(UserRole[value.toString()]);
    };

    useEffect(() => {
        setDepartmentRequired(rolesRequiringDeparmentsAccess.includes(selectedRole));
    }, [selectedRole]);

    return (
        <Container>
            <Form form={form} layout="vertical" name="user" requiredMark={false}>
                <Details>
                    <FormItem name="lastModifiedAt" hidden />
                    <UserFields>
                        {isUpdate && (
                            <Row>
                                <Col span={11}>
                                    <FormItem name="enabled" valuePropName="checked">
                                        <Switch
                                            checkedChildren={tCommon('enabled')}
                                            unCheckedChildren={tCommon('disabled')}
                                        />
                                    </FormItem>
                                </Col>
                                <Col offset={2} span={11}>
                                    {form.getFieldValue('hasMFALoginEnabled') && (
                                        <FormItem name="hasMFALoginEnabled">
                                            <Space>
                                                <CheckSquareOutlined />
                                                <span>{t('has_mfa_login_enabled')} </span>
                                            </Space>
                                        </FormItem>
                                    )}
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col span={11}>
                                <FormItem
                                    label={tCommon('username')}
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: tValidation('required.admin_username'),
                                        },
                                        {
                                            pattern: new RegExp(/^\w{3,20}$/i),
                                            message: tValidation('format.username'),
                                        },
                                    ]}
                                >
                                    <Input disabled={isUpdate} type="text" aria-label="user username input" />
                                </FormItem>
                            </Col>
                            <Col offset={2} span={11}>
                                <FormItem
                                    label={tCommon('email')}
                                    name="emailAddress"
                                    rules={[
                                        {
                                            required: true,
                                            type: 'email',
                                            message: tValidation('valid.email'),
                                        },
                                    ]}
                                >
                                    <Input disabled={isUpdate} type="email" aria-label="user email input" />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={11}>
                                <FormItem
                                    label={tCommon('firstname')}
                                    name="firstName"
                                    rules={[
                                        {
                                            required: true,
                                            message: tValidation('required.firstname'),
                                        },
                                        {
                                            pattern: new RegExp(/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s,.'-\pL]+$/),
                                            message: tValidation('format.firstname'),
                                        },
                                    ]}
                                >
                                    <Input type="text" aria-label="user firstname input" />
                                </FormItem>
                            </Col>
                            <Col offset={2} span={11}>
                                <FormItem
                                    label={tCommon('lastname')}
                                    name="lastName"
                                    rules={[
                                        {
                                            required: true,
                                            message: tValidation('required.lastname'),
                                        },
                                        {
                                            pattern: new RegExp(/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s,.'-\pL]+$/),
                                            message: tValidation('format.lastname'),
                                        },
                                    ]}
                                >
                                    <Input type="text" aria-label="user lastname input" />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={11}>
                                <FormItem
                                    label={tCommon('preferred_language')}
                                    name="preferredLanguage"
                                    initialValue={Language.EN}
                                >
                                    <Select
                                        aria-label="user language select"
                                        options={Object.keys(Language).map((key: string) => ({
                                            key: key,
                                            value: Language[key],
                                        }))}
                                    />
                                </FormItem>
                            </Col>
                            <Col offset={2} span={11}>
                                <FormItem label={tCommon('role')} name="role" initialValue={UserRole.CLIENT_VIEWER}>
                                    <Select
                                        aria-label="user role select"
                                        options={allowedRolesToSelect[authorRole].map((key: string) => ({
                                            key: key,
                                            value: UserRole[key],
                                        }))}
                                        onChange={onSelectRoleChange}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormItem
                                    label={tCommon('modules_access')}
                                    name="modulesAccess"
                                    rules={[
                                        {
                                            required: true,
                                            message: tValidation('required.modulesAccess'),
                                        },
                                    ]}
                                    initialValue={[ApplicationModule.EASYPOST_CONNECT]}
                                >
                                    <Checkbox.Group options={modulesOptions} />
                                </FormItem>
                            </Col>
                        </Row>
                        <Header3>{t('extra_capabilities')}</Header3>
                        <Row>
                            <Col>
                                <FormItem name="hasQERDSMandate" valuePropName="checked" initialValue={false}>
                                    <Checkbox>{t('has_qerds_mandate')}</Checkbox>
                                </FormItem>
                            </Col>
                        </Row>
                    </UserFields>
                    <Card>
                        <Row>
                            <Col>
                                <FormItem
                                    label={tCommon('departments')}
                                    name="departments"
                                    valuePropName="targetKeys"
                                    initialValue={targetKeys}
                                    rules={[
                                        {
                                            required: departmentRequired,
                                            message: tValidation('required.department'),
                                        },
                                    ]}
                                >
                                    <MultiSelect
                                        key="department"
                                        aria-label="user department select"
                                        dataSource={dataSource}
                                        locale={{
                                            itemUnit: tCommon('department'),
                                            itemsUnit: tCommon('departments'),
                                        }}
                                        targetKeys={targetKeys}
                                        onChange={onChange}
                                        render={(item) => item.title}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </Card>
                </Details>
            </Form>
        </Container>
    );
};

export default UserForm;
