import ProfileFetching from './ProfileFetching';

interface ProfileEditProps {
    companyId: string;
}

const ProfileEdit: React.FC<ProfileEditProps> = ({ companyId }) => {
    return <ProfileFetching companyId={companyId} />;
};

export default ProfileEdit;
