import { API } from '@eservices/shared/type';
import { SelectProps } from 'antd';
import styled from 'styled-components';
import { useGetDepartments } from '../hooks/apiHooks';
import { useTranslation } from '../hooks/translationHook';
import { noop } from '../utils/helpers';
import { Select } from './Input';

const StyledSelect = styled(Select)`
    min-width: 254px !important;
`;

interface DepartmentSelectorProps extends SelectProps<string, unknown> {
    companyId: string;
    renderLabel?: (department: API.DepartmentListItem) => string;
    onDepartmentSelected?: (item: API.DepartmentListItem) => void;
}

const DepartmentSelector: React.FC<DepartmentSelectorProps> = ({
    companyId,
    renderLabel = (department: API.DepartmentListItem) => department.name,
    onDepartmentSelected = noop,
    ...restProps
}) => {
    const { t, tCommon } = useTranslation('department');
    const { isError, isLoading, data: departments = [] } = useGetDepartments(companyId);

    if (isError) return <StyledSelect disabled={true} />;

    const departmentOptions = departments.map((department) => ({
        key: department.id,
        label: department.isActive
            ? renderLabel(department)
            : `${renderLabel(department)} - ${t('department_not_active')}`,
        value: department.id,
        disabled: !department.isActive,
    }));

    return (
        <StyledSelect
            aria-placeholder={tCommon('department')}
            {...restProps}
            id="department-selector"
            loading={isLoading}
            showSearch
            placeholder={t('search_department_placeholder')}
            optionFilterProp="label"
            filterOption
            options={departmentOptions}
        />
    );
};

export default DepartmentSelector;
