import { CacheDataType } from '../constant';

export interface RegisteredMailCacheEntry {
    taskId: string;
    companyId: string;
    jobId: string;
    sendingEventId: string;
    barcode: string;
    ttl: number;
    dataType: CacheDataType.REGISTERED_MAIL;
}

export interface EasyprintClientCacheEntry {
    clientId: number;
    companyId: string;
    dataType: CacheDataType.EASYPRINT_CLIENT;
}

export interface EasyprintRealmCacheEntry {
    clientId: number;
    realmId: number;
    companyId: string;
    departmentId: string;
    dataType: CacheDataType.EASYPRINT_REALM;
}

export interface EasyprintConfigCacheEntry {
    clientId: number;
    realmId: number;
    configId: number;
    companyId: string;
    workflowConfigurationId: string;
    taskId: string;
    taskValidationToken: string;
    dataType: CacheDataType.EASYPRINT_CONFIG;
}

export interface EasyprintJobCacheEntry {
    easyprintJobId: number;
    companyId: string;
    jobId: string;
    sendingEventId: string;
    taskId: string;
    orchestrationToken: string;
    dataType: CacheDataType.EASYPRINT_JOB;
    ttl: number;
}

export type CacheEntry =
    | RegisteredMailCacheEntry
    | EasyprintClientCacheEntry
    | EasyprintRealmCacheEntry
    | EasyprintConfigCacheEntry
    | EasyprintJobCacheEntry;
