import DailyAnnouncementsOverviewFetching from './DailyAnnouncementsOverviewFetching';

interface DailyAnnouncementsOverviewViewProps {
    companyId: string;
    canCreateAnnouncement?: boolean;
}

const DailyAnnouncementsOverviewView: React.FC<DailyAnnouncementsOverviewViewProps> = ({
    companyId,
    canCreateAnnouncement,
}) => {
    return <DailyAnnouncementsOverviewFetching companyId={companyId} canCreateAnnouncement={canCreateAnnouncement} />;
};

export default DailyAnnouncementsOverviewView;
