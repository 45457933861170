import { TaskType } from '@eservices/shared/constant';
import React from 'react';
import { iconsPerTask } from '../constants';
import styled from 'styled-components';

const IconWrapper = styled.div`
    img {
        width: 20px;
    }
`;

interface ChannelIndicatorProps {
    type: TaskType;
}

const ChannelIndicator: React.FC<ChannelIndicatorProps> = ({ type }) => <IconWrapper>{iconsPerTask[type]}</IconWrapper>;

export default ChannelIndicator;
