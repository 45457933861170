import { useEffect, useState } from 'react';
import DepartmentsTable from '../../components/department/DepartmentsTable';
import { SearchInput } from '../../components/Input';
import SubscriptionCounter from '../../components/subscription/SubscriptionCounter';
import { SubscriptionContext } from '../../constants';
import { useGetDepartments } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import ResourcesTableLayout from '../../layouts/ResourcesTableLayout';
import styled from 'styled-components';

const TableHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const FilterContainer = styled.div`
    width: 250px;
`;

interface DepartmentsOverviewFetchedProps {
    companyId: string;
    isReadOnly: boolean;
    actions?: React.ReactNode;
}

const DepartmentsOverviewFetching: React.FC<DepartmentsOverviewFetchedProps> = ({ companyId, isReadOnly, actions }) => {
    const [filteredDepartments, setFilteredDepartments] = useState([]);
    const { isLoading, isError, data: departments = [] } = useGetDepartments(companyId);
    const { t } = useTranslation('department_overview');

    useEffect(() => {
        if (departments) {
            setFilteredDepartments([...departments]);
        }
    }, [JSON.stringify(departments)]);

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFilteredDepartments(
            departments.filter(
                (department) =>
                    department.name.toLowerCase().includes(value.toLowerCase()) ||
                    department.erpId.toLowerCase().includes(value.toLowerCase()),
            ),
        );
    };

    const filter = (
        <TableHeader>
            <FilterContainer>
                <SearchInput placeholder={t('search_placeholder')} onChange={onSearch} />
            </FilterContainer>
            <SubscriptionCounter companyId={companyId} context={SubscriptionContext.DEPARTMENT} />
        </TableHeader>
    );

    const detail = <DepartmentsTable isLoading={isLoading} departments={filteredDepartments} isReadOnly={isReadOnly} />;

    return (
        <ResourcesTableLayout isError={isError} title={t('title')} filter={filter} detail={detail} actions={actions} />
    );
};

export default DepartmentsOverviewFetching;
