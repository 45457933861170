import { API } from '@eservices/shared/type';
import { Input, Space } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../../../../hooks/translationHook';
import DeleteButton from '../Common/DeleteButton';
import RegionButton from '../Common/RegionButton';

const StyledInput = styled(Input)`
    width: 200px;
    margin: 0 5px;
`;

interface CollectedDataOverviewProps {
    isReadOnly: boolean;
    order: number;
    identifier: API.PDFExtraInfoIdentifier;
    onDeleteExtraInfoIdentifier: (order: number) => void;
}

const DataCollector: React.FC<CollectedDataOverviewProps> = ({
    order,
    onDeleteExtraInfoIdentifier,
    identifier,
    isReadOnly,
}) => {
    const { tValidation, tCommon } = useTranslation('workflow');

    return (
        <Space align="baseline">
            <FormItem
                name={[order, 'name']}
                rules={[
                    {
                        required: true,
                        message: tValidation('required.name'),
                    },
                ]}
                noStyle
            >
                <StyledInput disabled={isReadOnly} placeholder={tCommon('name')} />
            </FormItem>
            <RegionButton regionOfInterest={identifier.regionOfInterest} />
            <DeleteButton disabled={isReadOnly} onClick={() => onDeleteExtraInfoIdentifier(order)} />
        </Space>
    );
};

export default DataCollector;
