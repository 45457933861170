import { DropboxOutlined, EditOutlined } from '@ant-design/icons';
import { ParcelCustomsShipmentType, ParcelType } from '@eservices/shared/constant';
import {
    API,
    RecipientWithParcelInfo,
    RecipientWithParcelInfoBase,
    RecipientWithParcelInfoWithoutCustoms,
} from '@eservices/shared/type';
import { Avatar, Checkbox, Form, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import { Fonts } from '../../constants';
import { useBpostCountries } from '../../hooks/bpostCountriesHook';
import { useTranslation } from '../../hooks/translationHook';
import styled from 'styled-components';
import { IconButton } from '../Buttons';
import { FormItem } from '../FormItem';
import { Input } from '../Input';
import { Modal } from '../Modal';
import EditRecipientCustomsPart from './EditRecipientCustomsPart';
import { Colors } from '@eservices/ui-constants/colors';

const Header = styled.div`
    margin: 0 0 7% 0;
`;

const Name = styled.div`
    font-family: ${Fonts.ARIAL_BOLD} !important;
    font-size: 15px;
    color: ${Colors.GRAY_PRIMARY};
`;

const Address = styled.div`
    color: ${Colors.GRAY_SEPTENARY};
`;

export interface RecipientWithParcelInfoWithCustomsForm extends RecipientWithParcelInfoBase {
    type: ParcelType.PARCEL_WITH_CUSTOMS;
    customsDetails: {
        shipmentType: ParcelCustomsShipmentType;
        contentDescription: string;
        contents: Partial<API.ParcelCustomsContentDetail>[];
    };
}

type RecipientWithParcelInfoWithoutCustomsForm = RecipientWithParcelInfoWithoutCustoms;
type RecipientWithParcelInfoForm = RecipientWithParcelInfoWithCustomsForm | RecipientWithParcelInfoWithoutCustomsForm;

interface EditRecipientProps {
    recipient: RecipientWithParcelInfo;
    onSave: (recipient: RecipientWithParcelInfo) => void;
}

const EditRecipient: React.FC<EditRecipientProps> = ({ recipient, onSave }) => {
    const { t, tCommon, tValidation } = useTranslation('create_parcels');
    const [form] = useForm<RecipientWithParcelInfoForm>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { address, companyName, name, parcelName } = recipient;
    const { findCountryName } = useBpostCountries();
    let defaultFormValues: RecipientWithParcelInfoForm = { ...recipient };

    if (recipient.type === ParcelType.PARCEL_WITH_CUSTOMS && recipient.customsDetails.contents.length === 0) {
        defaultFormValues = {
            ...recipient,
            customsDetails: {
                ...recipient.customsDetails,
                contents: [{}],
            },
        };
    }

    const showModal = () => {
        form.resetFields();
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        const formFields = await form.validateFields();
        const recipientFormValues = formFields as RecipientWithParcelInfo;
        onSave({ ...recipient, ...recipientFormValues });
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <IconButton icon={<EditOutlined />} onClick={showModal} />
            <Modal
                title={t('edit_parcel')}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={tCommon('save')}
                centered
                maskClosable={false}
                destroyOnClose
            >
                <Header>
                    <Space size="large">
                        <Avatar shape="square" size={50} icon={<DropboxOutlined />} />
                        <div>
                            <Name>{parcelName}</Name>
                            {companyName || name}
                            <br />
                            {companyName && name}
                            <Address>
                                {address.street} {address.streetNr} {address.box}, {address.postalCode} {address.city},{' '}
                                {findCountryName(address.countryCode)}
                            </Address>
                        </div>
                    </Space>
                </Header>
                <Form
                    initialValues={defaultFormValues}
                    layout="vertical"
                    form={form}
                    name="recipient"
                    requiredMark={false}
                >
                    <FormItem
                        label={tCommon('name')}
                        name="parcelName"
                        rules={[{ min: 3, message: tValidation('custom.min_3') }]}
                    >
                        <Input type="text" aria-label="name input" placeholder={`Parcel for ${companyName || name}`} />
                    </FormItem>
                    <Space size="large">
                        <FormItem name={['bpostOptions', 'hasInsurance']} valuePropName="checked">
                            <Checkbox>{tCommon('insurance')}</Checkbox>
                        </FormItem>
                        <FormItem name={['bpostOptions', 'hasSignature']} valuePropName="checked">
                            <Checkbox>{tCommon('signature')}</Checkbox>
                        </FormItem>
                        <FormItem name={['bpostOptions', 'needsMailAnnouncement']} valuePropName="checked">
                            <Checkbox>{t('needsMailAnnouncement')}</Checkbox>
                        </FormItem>
                    </Space>
                    {recipient.type === ParcelType.PARCEL_WITH_CUSTOMS && <EditRecipientCustomsPart />}
                </Form>
            </Modal>
        </>
    );
};

export default EditRecipient;
