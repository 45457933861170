import { Colors } from '@eservices/ui-constants/colors';
import { Button as AntButton } from 'antd';
import styled from 'styled-components';
import { Fonts } from '../constants';

const Button = styled(AntButton)`
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;

    span {
        font-family: ${Fonts.HURME1};
    }
`;

export const PrimaryButton = styled(Button)`
    border: 2px solid ${Colors.PRIMARY};
    background-color: ${Colors.PRIMARY};
    color: ${Colors.DARK_PRIMARY};

    &:hover {
        border: 2px solid ${Colors.PRIMARY};
        background-color: ${Colors.WHITE};
        color: ${Colors.GRAY_PRIMARY} !important;
    }

    &:active,
    &:focus {
        border: 2px solid ${Colors.PRIMARY};
        color: ${Colors.GRAY_PRIMARY};
    }

    a {
        color: ${Colors.GRAY_PRIMARY} !important;
    }
`;
PrimaryButton.defaultProps = {
    shape: 'round',
    type: 'primary',
};

export const SecondaryButton = AntButton;
SecondaryButton.defaultProps = {
    shape: 'round',
    type: 'default',
};

export const IconButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: space-around;

    color: ${(props) => (props.color ? props.color : Colors.DARK_SECONDARY)} !important;
    border: 1px solid ${Colors.GRAY_QUATERNARY} !important;

    &:hover,
    &:focus {
        color: ${(props) => (props.color ? props.color : props.danger ? Colors.ALERT : Colors.PRIMARY)} !important;
        border-color: ${(props) =>
            props.color ? props.color : props.danger ? Colors.ALERT : Colors.PRIMARY} !important;
    }
`;
IconButton.defaultProps = {
    shape: 'circle',
};

export const TertiaryButton = styled(Button)`
    color: ${Colors.SECONDARY};
    background-color: ${Colors.WHITE};
    border: none;
`;

export const AlertButton = styled(Button)`
    background-color: ${Colors.WHITE};
    border: 2px solid ${Colors.ALERT};

    &:hover,
    &:active,
    &:focus {
        color: ${Colors.WHITE} !important;
        border: 2px solid ${Colors.ALERT};
        background-color: ${Colors.ALERT};
    }
`;

export const RemoveButton = styled(AntButton)`
    background-color: ${Colors.GRAY_OCTENARY};
    color: ${Colors.DARK_SECONDARY};
`;
