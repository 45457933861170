import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../components/Buttons';
import { Routes } from '../../constants';
import { useTranslation } from '../../hooks/translationHook';
import DepartmentsOverviewFetching from './DepartmentsOverviewFetching';

interface DepartmentsOverviewEditProps {
    companyId: string;
    canCreateDepartment: boolean;
}

const DepartmentsOverviewEdit: React.FC<DepartmentsOverviewEditProps> = ({ companyId, canCreateDepartment }) => {
    const { t } = useTranslation('department_overview');
    const history = useHistory();

    const actions = canCreateDepartment && (
        <PrimaryButton
            icon={<PlusOutlined />}
            aria-label="create department link"
            onClick={() => history.push(Routes.CREATE_DEPARTMENT)}
        >
            {t('action_add')}
        </PrimaryButton>
    );

    return <DepartmentsOverviewFetching isReadOnly={false} companyId={companyId} actions={actions} />;
};

export default DepartmentsOverviewEdit;
