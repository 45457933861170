import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { environment } from './environments/environment';
import Locize from 'i18next-locize-backend';
import LastUsed from 'locize-lastused';
import { locizePlugin } from 'locize';

const locizeOptions = {
    projectId: 'ede9b877-770b-4723-aa06-5093d370fed3',
    referenceLng: 'en',
    private: false,
    allowedAddOrUpdateHosts: [environment.host],
    debounceSubmit: 90000,
    apiKey: environment.locize.enableAPI && process.env.NX_LOCIZE_APIKEY,
    version: environment.locize.version,
};

if (environment.pillar === 'LOCAL') {
    // locize-lastused
    // sets a timestamp of last access on every translation segment on locize
    // -> safely remove the ones not being touched for weeks/months
    // https://github.com/locize/locize-lastused
    i18n.use(LastUsed);
}

i18n.use(Locize)
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(locizePlugin)
    .init({
        ns: ['glossary', 'common', 'error', 'validation'],
        debug: !environment.production,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: locizeOptions,
        locizeLastUsed: locizeOptions,
        saveMissing: environment.locize.enableAPI,
        supportedLngs: ['en', 'nl', 'fr'],
    });

export default i18n;
