import { Language } from '@eservices/shared/constant';
import { useGetBpostCountries } from './apiHooks';
import { useTranslation } from './translationHook';

export function useBpostCountries() {
    const { i18n } = useTranslation();
    const { data: countries = [], isLoading: isLoadingCountries } = useGetBpostCountries(i18n.language as Language);

    const findPostalCodeFormat = (countryCode: string) =>
        countries.find(({ code }) => countryCode === code)?.postalCodeFormat;

    const findCountryName = (countryCode: string) => countries.find(({ code }) => countryCode === code)?.name;

    const requiresCustoms = (countryCode: string) =>
        countries.find(({ code }) => countryCode === code)?.zone === 'WORLD';

    return {
        activeCountries: countries.filter((country) => country.active),
        isLoadingCountries,
        findPostalCodeFormat,
        findCountryName,
        requiresCustoms,
    };
}
