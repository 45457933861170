import { Form, FormInstance, Tabs } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../hooks/translationHook';
import { PrimaryButton } from '../Buttons';
import Card from '../Card';
import { FormItem } from '../FormItem';
import DetailsQuestion from './wizard/DetailsQuestion';
import EnablingQuestion from './wizard/EnablingQuestion';
import InputSourceTypeQuestion from './wizard/InputSourceTypeQuestion';
import SftpQuestion from './wizard/SftpQuestion';
import TriggerTypeQuestion from './wizard/TriggerTypeQuestion';
import WorkflowTypeQuestion from './wizard/WorkflowTypeQuestion';

const { TabPane } = Tabs;

const NavigationButtons = styled.div`
    margin: 2% 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
`;

const TabContent = styled.div`
    max-width: 850px;
    margin: 0 0 0 5%;
`;

const Content = styled(Card)`
    height: 400px;
`;

interface WorkflowDetailNewProps {
    companyId: string;
    form: FormInstance;
    onDone: () => void;
}

const WorkflowDetailNew: React.FC<WorkflowDetailNewProps> = ({ form, companyId, onDone }) => {
    const [activeStep, setActiveStep] = useState(0);
    const { tCommon } = useTranslation();

    const onNext = () => {
        setActiveStep(activeStep + 1);
    };

    const steps = [
        {
            title: 'General',
            content: <DetailsQuestion companyId={companyId} />,
        },
        {
            title: 'Flow',
            content: <WorkflowTypeQuestion form={form} />,
        },
        {
            title: 'SFTP',
            content: <SftpQuestion />,
        },
        {
            title: 'Trigger',
            content: <TriggerTypeQuestion form={form} />,
        },
        {
            title: 'Source',
            content: <InputSourceTypeQuestion form={form} />,
        },
        {
            title: 'Activation',
            content: <EnablingQuestion />,
        },
    ];

    const onTabClick = (key: string) => {
        const newActiveStep = steps.findIndex(({ title }) => title === key);
        setActiveStep(newActiveStep);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            name="workflow"
            requiredMark={false}
            initialValues={{ validated: false, version: '0' }}
        >
            <FormItem name={['inputSource', 'channelIdentifiers']} initialValue={[]} hidden />
            <FormItem name={['inputSource', 'splitIdentifiers']} initialValue={[]} hidden />
            <FormItem name={['inputSource', 'ignoreIdentifiers']} initialValue={[]} hidden />
            <FormItem name={['inputSource', 'extraInfoIdentifiers']} initialValue={[]} hidden />
            <FormItem name="validated" hidden />
            <FormItem name="version" hidden />
            <Tabs tabPosition="left" tabBarGutter={30} activeKey={steps[activeStep].title} onTabClick={onTabClick}>
                {steps.map(({ title, content }) => (
                    <TabPane tab={title} key={title} forceRender>
                        <TabContent>
                            <Content>{content}</Content>
                            <NavigationButtons>
                                {activeStep < steps.length - 1 && (
                                    <PrimaryButton onClick={() => onNext()}>{tCommon('next')}</PrimaryButton>
                                )}
                                {activeStep === steps.length - 1 && (
                                    <PrimaryButton onClick={() => onDone()}>{tCommon('done')}</PrimaryButton>
                                )}
                            </NavigationButtons>
                        </TabContent>
                    </TabPane>
                ))}
            </Tabs>
        </Form>
    );
};

export default WorkflowDetailNew;
