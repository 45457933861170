import { IgnoreIdentifierKind } from '@eservices/shared/constant';
import { PDFIgnoreIdentifier } from '@eservices/shared/type';
import React, { useState } from 'react';
import { ReactComponent as IgnoreDocumentIcon } from '../../../../../assets/images/pdf_ignore.svg';
import { useTranslation } from '../../../../../hooks/translationHook';
import TabContent from '../Common/TabContent';
import { WorkflowPDFInputSourceActions, WorkflowPDFInputSourceDrawingActions } from '../workflowPDFInputSourceReducer';
import IgnoreChoices from './IgnoreChoices';
import IgnoreOverview from './IgnoreOverview';

interface IgnoringProps {
    isReadOnly: boolean;
    ignoreIdentifiers: PDFIgnoreIdentifier[];
    workflowPDFInputSourceDispatch: React.Dispatch<WorkflowPDFInputSourceActions>;
    setAction: React.Dispatch<React.SetStateAction<WorkflowPDFInputSourceDrawingActions>>;
}

const Ignoring: React.FC<IgnoringProps> = ({
    isReadOnly,
    setAction,
    ignoreIdentifiers,
    workflowPDFInputSourceDispatch,
}) => {
    const { t } = useTranslation('workflow');
    const [isShowIgnoreChoicesVisible, setIsShowIgnoreChoicesVisible] = useState(false);

    const onIgnoreChoiceSelected = (kind: IgnoreIdentifierKind) => {
        setIsShowIgnoreChoicesVisible(false);
        setAction({
            type: 'insertIgnoreIdentifier',
            payload: {
                kind,
                regionOfInterest: {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 0,
                },
            },
        });
    };

    const onUpdateIgnoreIdentifier = (order: number, value: string) => {
        workflowPDFInputSourceDispatch({
            type: 'updateIgnoreIdentifier',
            payload: {
                order,
                value,
            },
        });
    };

    const onDeleteIgnoreIdentifier = (order: number) => {
        workflowPDFInputSourceDispatch({
            type: 'removeIgnoreIdentifier',
            payload: { order },
        });
    };

    return (
        <TabContent
            title={t('ignore_document_title')}
            description={t('markdown_ignore_document_description')}
            icon={IgnoreDocumentIcon}
        >
            {isShowIgnoreChoicesVisible ? (
                <IgnoreChoices isReadOnly={isReadOnly} onIgnoreChoiceSelected={onIgnoreChoiceSelected} />
            ) : (
                <IgnoreOverview
                    isReadOnly={isReadOnly}
                    ignoreIdentifiers={ignoreIdentifiers}
                    onAddNewIgnoreChoice={() => setIsShowIgnoreChoicesVisible(true)}
                    onUpdateIgnoreIdentifier={onUpdateIgnoreIdentifier}
                    onDeleteIgnoreIdentifier={onDeleteIgnoreIdentifier}
                />
            )}
        </TabContent>
    );
};

export default Ignoring;
