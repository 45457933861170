import { BookOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { UserRole } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Space, TablePaginationConfig, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';
import { Paragraph } from '../../components/Paragraph';
import { useGetUsers } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import { getPaginationDetails } from '../../utils/helpers';
import styled from 'styled-components';
import { IconButton } from '../Buttons';
import { Header3 } from '../Headers';
import { SearchInput } from '../Input';
import { Modal } from '../Modal';
import Table from '../Table';
import UserAvatar from '../UserAvatar';
import AvatarWithDelete from '../AvatarWithDelete';
import { Colors } from '@eservices/ui-constants/colors';

const FullWithSpace = styled(Space)`
    width: 100%;
`;

const SearchContainer = styled.div`
    width: 95%;
    margin: 0 auto;
`;

const Selection = styled.div`
    margin-top: 5%;
`;

interface AddUsersModalProps {
    role: UserRole;
    companyId: string;
    alreadySelectedUsers: API.User[];
    onComplete: (newlyAddedUsers: API.User[]) => void;
    onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const AddUsersModal: React.FC<AddUsersModalProps> = ({
    role,
    companyId,
    alreadySelectedUsers,
    onComplete,
    onCancel,
}) => {
    const { t, tCommon } = useTranslation('add_users_modal');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const { isLoading, data: fetchedUsers = [] } = useGetUsers(companyId);

    useEffect(() => {
        if (fetchedUsers) {
            const filteredUsers = fetchedUsers.filter(
                (user) => role.includes(user.role) && !alreadySelectedUsers.map(({ id }) => id).includes(user.id),
            );
            setAllUsers([...filteredUsers]);
            setFilteredUsers([...filteredUsers]);
            setSelectedUsers([]);
        }
    }, [JSON.stringify(fetchedUsers)]);

    useEffect(() => {
        setFilteredUsers(allUsers.filter((u) => !selectedUsers.includes(u)));
    }, [JSON.stringify(selectedUsers), JSON.stringify(allUsers)]);

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === '') {
            setFilteredUsers([...allUsers]);
        } else {
            setFilteredUsers(
                allUsers.filter(
                    ({ firstName, lastName }) =>
                        firstName.toLowerCase().includes(value.toLowerCase()) ||
                        lastName.toLowerCase().includes(value.toLowerCase()),
                ),
            );
        }
    };

    const handleAddUser = (user: API.User) => {
        setSelectedUsers([...selectedUsers, user]);
    };

    const removeFromSelectedUsers = (id: string) => {
        setSelectedUsers(selectedUsers.filter((user) => user.id !== id));
    };

    const handleOnOk = () => {
        onComplete(selectedUsers);
    };

    const paginationConfig = getPaginationDetails(filteredUsers.length, {
        hideOnSinglePage: true,
    });

    const columns: ColumnsType<API.User> = [
        {
            render: (user: API.User) => <>{`${user.firstName} ${user.lastName}`}</>,
        },

        {
            align: 'right',
            width: 10,
            render: (user: API.User) => <UserAvatar user={user} opacity="50%" />,
        },
        {
            align: 'center',
            width: 10,
            render: (user: API.User) => (
                <Tooltip title={t('add_user')} placement="right" mouseEnterDelay={1}>
                    <IconButton color={Colors[user.role]} icon={<PlusOutlined />} onClick={() => handleAddUser(user)} />
                </Tooltip>
            ),
        },
    ];

    return (
        <Modal
            title={t('add_users_title')}
            visible={true}
            footer={
                <>
                    <SecondaryButton aria-label="cancel" icon={<CloseOutlined />} onClick={onCancel}>
                        {tCommon('cancel')}
                    </SecondaryButton>
                    <PrimaryButton icon={<BookOutlined />} aria-label="save" onClick={handleOnOk}>
                        {tCommon('save')}
                    </PrimaryButton>
                </>
            }
        >
            <FullWithSpace direction="vertical">
                <SearchContainer>
                    <SearchInput placeholder={t('search_placeholder')} onChange={onSearch} />
                </SearchContainer>
                <Table
                    showHeader={false}
                    loading={isLoading}
                    rowKey="id"
                    columns={columns}
                    dataSource={filteredUsers}
                    pagination={paginationConfig}
                    locale={{ emptyText: t('no_users_available') }}
                />
                <Selection>
                    <Header3>{t('selection')}</Header3>
                    {selectedUsers.length === 0 && <Paragraph>{t('no_selection')}</Paragraph>}
                    <Space>
                        {selectedUsers.map((user) => (
                            <AvatarWithDelete
                                key={user.id}
                                id={user.id}
                                color={Colors[user.role]}
                                name={`${user.firstName} ${user.lastName}`}
                                onRemove={removeFromSelectedUsers}
                            />
                        ))}
                    </Space>
                </Selection>
            </FullWithSpace>
        </Modal>
    );
};

export default AddUsersModal;
