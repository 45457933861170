import { SplitIdentifierKind } from '@eservices/shared/constant';
import { Space } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as EmptySpaceIcon } from '../../../../../assets/images/empty_space.svg';
import { ReactComponent as RegexIcon } from '../../../../../assets/images/regex.svg';
import { ReactComponent as TextIcon } from '../../../../../assets/images/text.svg';
import { Header3 } from '../../../../../components/Headers';
import { useTranslation } from '../../../../../hooks/translationHook';
import SplitChoice from './SplitChoice';

const Choices = styled.div`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-evenly;
`;

const Container = styled.div`
    text-align: center;
`;

interface SplitChoicesProps {
    isReadOnly: boolean;
    onSplitChoiceSelected: (kind: SplitIdentifierKind) => void;
}

const SplitChoices: React.FC<SplitChoicesProps> = ({ isReadOnly, onSplitChoiceSelected }) => {
    const { t } = useTranslation('workflow');

    return (
        <Container>
            <Space direction="vertical">
                <Header3>{t('choose_split_option')}</Header3>
                <Choices>
                    <SplitChoice
                        onClick={() => onSplitChoiceSelected(SplitIdentifierKind.TEXT)}
                        icon={TextIcon}
                        description={t('markdown_split_on_text')}
                        isReadOnly={isReadOnly}
                    />
                    <SplitChoice
                        onClick={() => onSplitChoiceSelected(SplitIdentifierKind.REGEX)}
                        icon={RegexIcon}
                        description={t('markdown_split_on_regex')}
                        isReadOnly={isReadOnly}
                    />
                    <SplitChoice
                        onClick={() => onSplitChoiceSelected(SplitIdentifierKind.EMPTY_TEXT)}
                        icon={EmptySpaceIcon}
                        description={t('markdown_split_on_empty')}
                        isReadOnly={isReadOnly}
                    />
                </Choices>
            </Space>
        </Container>
    );
};

export default SplitChoices;
