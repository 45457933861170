import { PDFExtraInfoIdentifier } from '@eservices/shared/type';
import React from 'react';
import { ReactComponent as CollectDataIcon } from '../../../../../assets/images/collect_data.svg';
import { useTranslation } from '../../../../../hooks/translationHook';
import TabContent from '../Common/TabContent';
import { WorkflowPDFInputSourceActions, WorkflowPDFInputSourceDrawingActions } from '../workflowPDFInputSourceReducer';
import CollectedDataOverview from './CollectedDataOverview';

interface DataCollectingProps {
    isReadOnly: boolean;
    extraInfoIdentifiers: PDFExtraInfoIdentifier[];
    workflowPDFInputSourceDispatch: React.Dispatch<WorkflowPDFInputSourceActions>;
    setAction: React.Dispatch<React.SetStateAction<WorkflowPDFInputSourceDrawingActions>>;
}

const DataCollecting: React.FC<DataCollectingProps> = ({
    isReadOnly,
    setAction,
    extraInfoIdentifiers,
    workflowPDFInputSourceDispatch,
}) => {
    const { t } = useTranslation('workflow');

    const onAddDataCollector = () => {
        setAction({
            type: 'insertExtraInfoIdentifier',
            payload: {
                name: '',
                regionOfInterest: {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 0,
                },
            },
        });
    };

    const onUpdateExtraInfoIdentifier = (order: number, value: string) => {
        workflowPDFInputSourceDispatch({
            type: 'updateExtraInfoIdentifier',
            payload: {
                order,
                value,
            },
        });
    };

    const onDeleteExtraInfoIdentifier = (order: number) => {
        workflowPDFInputSourceDispatch({
            type: 'removeExtraInfoIdentifier',
            payload: { order },
        });
    };

    return (
        <TabContent
            title={t('collect_data_title')}
            description={t('markdown_collect_data_description')}
            icon={CollectDataIcon}
        >
            <CollectedDataOverview
                isReadOnly={isReadOnly}
                identifiers={extraInfoIdentifiers}
                onAddDataCollector={onAddDataCollector}
                onUpdateExtraInfoIdentifier={onUpdateExtraInfoIdentifier}
                onDeleteExtraInfoIdentifier={onDeleteExtraInfoIdentifier}
            />
        </TabContent>
    );
};

export default DataCollecting;
