import { TaskType } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { TaskActionType } from '../../constants';
import QerdsTaskRetract from './qerds/QerdsTaskRetract';

interface TaskActionProps {
    task: API.TaskDetails;
    isLoading: boolean;
    onConfirmTaskAction: (taskId: string, actionType: TaskActionType) => void;
}

const TaskActions: React.FC<TaskActionProps> = ({ task, ...props }) => {
    switch (task.taskType) {
        case TaskType.QERDS:
            return <QerdsTaskRetract task={task} {...props} />;
        default:
            return <></>;
    }
};

export default TaskActions;
