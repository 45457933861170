import {
    AlertFilled,
    CheckCircleFilled,
    ClockCircleFilled,
    CloseCircleFilled,
    CloseOutlined,
    FireFilled,
    Loading3QuartersOutlined,
    QuestionCircleFilled,
    ReloadOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import { ApprovalStatus, JobStatus } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Colors } from '@eservices/ui-constants/colors';
import { Checkbox, Form, message, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { format, subMonths } from 'date-fns';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SecondaryButton } from '../../components/Buttons';
import DatePicker from '../../components/DatePicker';
import { FormItem } from '../../components/FormItem';
import { Input, Select } from '../../components/Input';
import JobsTable from '../../components/job/JobsTable';
import { TABLE_PAGE_SIZE } from '../../constants';
import { useGetDepartments, useGetWorkflows, usePerformJobAction, useSearchJobs } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import useQueryParams from '../../hooks/useQueryHook';
import ResourcesTableLayout from '../../layouts/ResourcesTableLayout';
import { formatSearchTerm, getRangeDates } from '../../utils/helpers';

const dateFormat = 'DD/MM/YYYY';

const defaultSearchValues = {
    range: [subMonths(new Date(), 1), new Date()],
};

const StyledInput = styled(Input)`
    min-width: 254px !important;
`;

const StyledSelect = styled(Select)`
    min-width: 254px !important;
`;

const SearchForm = styled(Form)`
    display: flex;
    justify-content: flex-start;
    align-items: start;
    width: 100%;

    .ant-form-item {
        margin-bottom: 0;
    }
`;

const ReloadContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1%;
`;

const loadingMessageKey = 'loadingMessageKey';

const defaultPagination = { page: 1, pageSize: TABLE_PAGE_SIZE };

interface SearchFormValues {
    name: string;
    workflowId: string;
    departmentId: string;
    containWarnings: boolean;
    status: JobStatus;
    range: [Date, Date];
}
interface JobsOverviewFetchedProps {
    companyId: string;
    actions?: React.ReactNode;
    canCancelJobs: boolean;
    canApproveJobs: boolean;
}

const JobOverviewFetching: React.FC<JobsOverviewFetchedProps> = ({
    companyId,
    actions,
    canCancelJobs,
    canApproveJobs,
}) => {
    const { t, tCommon, tValidation } = useTranslation('jobs_overview');
    const [form] = useForm<SearchFormValues>();
    const { data: departments = [], isLoading: isLoadingDepartments } = useGetDepartments(companyId);
    const { data: workflows = [], isLoading: isLoadingWorkflows } = useGetWorkflows(companyId);
    const searchMutation = useSearchJobs(companyId);
    const jobActionMutation = usePerformJobAction(companyId);

    const params = useQueryParams();
    const [pagination, setPagination] = useState(defaultPagination);

    const workflowsOptions = workflows.map((workflow) => ({ label: workflow.name, value: workflow.id }));
    const departmentsOptions = departments.map((department) => ({ label: department.name, value: department.id }));

    useEffect(() => {
        const statusStr = params.get('status');
        const departmentId = params.get('departmentId');
        const rangeDate = params.get('rangeDate');
        const containWarnings = params.get('containWarnings');

        if (rangeDate) {
            const { startDate, endDate } = getRangeDates(rangeDate);
            const status = JobStatus[statusStr];

            form.setFieldsValue({
                containWarnings: containWarnings === 'true' ? true : undefined,
                departmentId,
                range: [startDate, endDate],
                status,
            });
        }

        onSearch();
    }, []);

    const statusOptions = [
        {
            label: (
                <Space>
                    <ClockCircleFilled /> {tCommon('waiting')}
                </Space>
            ),
            value: JobStatus.WAITING,
        },
        {
            label: (
                <Space>
                    <CheckCircleFilled /> {tCommon('ok')}
                </Space>
            ),
            value: JobStatus.OK,
        },
        {
            label: (
                <Space>
                    <Loading3QuartersOutlined /> {tCommon('processing')}
                </Space>
            ),
            value: JobStatus.PROCESSING,
        },
        {
            label: (
                <Space>
                    <AlertFilled /> {tCommon('action_required')}
                </Space>
            ),
            value: JobStatus.ACTION_REQUIRED,
        },
        {
            label: (
                <Space>
                    <FireFilled /> {tCommon('error')}
                </Space>
            ),
            value: JobStatus.ERROR,
        },
        {
            label: (
                <Space>
                    <CloseOutlined /> {tCommon('canceled')}
                </Space>
            ),
            value: JobStatus.CANCELLED,
        },
    ];

    const approvalStatusOptions = [
        {
            label: (
                <Space>
                    <QuestionCircleFilled style={{ color: Colors.PRIMARY }} /> {tCommon('awaiting_approval')}
                </Space>
            ),
            value: ApprovalStatus.AWAITING_APPROVAL,
        },
        {
            label: (
                <Space>
                    <CheckCircleFilled /> {tCommon('approved')}
                </Space>
            ),
            value: ApprovalStatus.APPROVED,
        },
        {
            label: (
                <Space>
                    <CloseCircleFilled style={{ color: Colors.BAD }} /> {tCommon('rejected')}
                </Space>
            ),
            value: ApprovalStatus.REJECTED,
        },
    ];

    const onSearch = async (page = defaultPagination.page, pageSize = defaultPagination.pageSize) => {
        const { name, range, containWarnings, ...rest } = await form.validateFields();

        const filter = {
            name: formatSearchTerm(true, name),
            containWarnings,
            from: format(range[0], "yyyy-MM-dd'T00:00:00.000Z'"),
            until: format(range[1], "yyyy-MM-dd'T23:59:59.999Z'"),
            page,
            pageSize,
            ...rest,
        };
        setPagination({ page, pageSize });
        searchMutation.mutate(filter);
    };

    const handlePaginationChange = (page: number, pageSize: number) => {
        setPagination({ page, pageSize });
        onSearch(page, pageSize);
    };

    const handleCancelJob = (jobId: string, departmentId: string) => {
        void message.loading({ key: loadingMessageKey, content: t('cancel_job_loading') }, 0);
        jobActionMutation.mutate(
            { jobId, departmentId, action: API.JobActionType.CANCEL },
            {
                onSuccess: () => {
                    void message.success(t('cancel_job_success', { name: form.getFieldValue('name') }), 3);
                },
                onError: () => {
                    void message.error(t('cancel_company_error'), 3);
                },
                onSettled: () => {
                    void message.destroy(loadingMessageKey);
                },
            },
        );
    };

    const handleApproveJob = (jobId: string, departmentId: string) => {
        void message.loading({ key: loadingMessageKey, content: t('approve_job_loading') }, 0);
        jobActionMutation.mutate(
            { jobId, departmentId, action: API.JobActionType.APPROVE },
            {
                onSuccess: () => {
                    void message.success(t('approve_job_success', { name: form.getFieldValue('name') }), 3);
                },
                onError: (e) => {
                    const errorMessage = e.response?.data?.error;
                    void message.error(t(errorMessage || 'approve_job_error'), 5);
                },
                onSettled: () => {
                    void message.destroy(loadingMessageKey);
                },
            },
        );
    };

    const handleRejectJob = (jobId: string, departmentId: string) => {
        void message.loading({ key: loadingMessageKey, content: t('reject_job_loading') }, 0);
        jobActionMutation.mutate(
            { jobId, departmentId, action: API.JobActionType.REJECT },
            {
                onSuccess: () => {
                    void message.success(t('reject_job_success', { name: form.getFieldValue('name') }), 3);
                },
                onError: (e) => {
                    const errorMessage = e.response?.data?.error;
                    void message.error(t(errorMessage || 'reject_job_error'), 5);
                },
                onSettled: () => {
                    void message.destroy(loadingMessageKey);
                },
            },
        );
    };

    const Filter = (
        <SearchForm form={form} initialValues={defaultSearchValues} requiredMark={false} layout="vertical">
            <Space align="start">
                <Space direction="vertical">
                    <Space>
                        <FormItem name="name" rules={[{ min: 3, message: tValidation('custom.min_3') }]}>
                            <StyledInput placeholder={tCommon('job_name')} />
                        </FormItem>
                        <FormItem name="workflowId">
                            <StyledSelect
                                placeholder={tCommon('workflow')}
                                allowClear
                                showSearch
                                options={workflowsOptions}
                                loading={isLoadingWorkflows}
                                onChange={() => onSearch()}
                            />
                        </FormItem>
                        <FormItem name="containWarnings" valuePropName="checked">
                            <Checkbox onChange={() => onSearch()}>{t('show_only_warnings')}</Checkbox>
                        </FormItem>
                    </Space>
                    <Space>
                        <FormItem name="departmentId">
                            <StyledSelect
                                placeholder={tCommon('department')}
                                allowClear
                                showSearch
                                options={departmentsOptions}
                                loading={isLoadingDepartments}
                                onChange={() => onSearch()}
                            />
                        </FormItem>
                        <FormItem name="status">
                            <StyledSelect
                                placeholder={tCommon('status')}
                                allowClear
                                options={statusOptions}
                                onChange={() => onSearch()}
                            />
                        </FormItem>
                        <FormItem name="approvalStatus">
                            <StyledSelect
                                placeholder={tCommon('approval_status')}
                                allowClear
                                options={approvalStatusOptions}
                                onChange={() => onSearch()}
                            />
                        </FormItem>
                        <FormItem
                            name="range"
                            rules={[
                                {
                                    required: true,
                                    message: tValidation('required.range'),
                                },
                            ]}
                        >
                            <DatePicker.RangePicker format={dateFormat} onChange={() => onSearch()} />
                        </FormItem>
                        <SecondaryButton icon={<SearchOutlined />} aria-label="save" onClick={() => onSearch()}>
                            {tCommon('search')}
                        </SecondaryButton>
                    </Space>
                </Space>
            </Space>
        </SearchForm>
    );
    const detail = (
        <>
            <ReloadContainer>
                <SecondaryButton icon={<ReloadOutlined />} aria-label="refresh" onClick={() => onSearch()}>
                    {tCommon('refresh')}
                </SecondaryButton>
            </ReloadContainer>
            <JobsTable
                companyId={companyId}
                total={searchMutation.data?.pagination.totalItems}
                isLoading={searchMutation.isLoading}
                jobs={searchMutation.data?.items}
                onPaginationChange={handlePaginationChange}
                departments={departments}
                workflows={workflows}
                canCancelJobs={canCancelJobs}
                canApproveJobs={canApproveJobs}
                pagination={pagination}
                onCancelJob={handleCancelJob}
                onApproveJob={handleApproveJob}
                onRejectJob={handleRejectJob}
            />
        </>
    );

    return (
        <ResourcesTableLayout isError={false} title={t('title')} filter={Filter} detail={detail} actions={actions} />
    );
};

export default JobOverviewFetching;
