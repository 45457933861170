import { DailyConsumptionAnnouncement, WorkflowConfiguration } from '.';
import { EventBusName, QERDSDetailType } from '../constant/core';

export type WorkflowConfigurationUpsertEvent = Pick<WorkflowConfiguration, 'companyId' | 'id'> & {
    eventId: string;
};

export type DailyConsumptionAnnouncementEvent = Omit<DailyConsumptionAnnouncement, 'ttl'>;

export type QERDSEvent = {
    eventBus: EventBusName.QERDS;
    detailType: QERDSDetailType;
    detail: string;
};

export type EBEvent = QERDSEvent;
