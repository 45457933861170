import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGetDepartment } from '../../hooks/apiHooks';
import DepartmentDetailFetching from './DepartmentDetailFetching';

interface DepartmentDetailViewProps {
    companyId: string;
}

const DepartmentDetailView: React.FC<DepartmentDetailViewProps> = ({ companyId }) => {
    const history = useHistory();
    const [form] = useForm();
    const [users, setUsers] = useState([]);
    const { id: departmentId }: { id: string } = useParams();
    const query = useGetDepartment(companyId, departmentId);

    const onBackClick = () => {
        history.goBack();
    };

    return (
        <DepartmentDetailFetching
            isUsersReadonly={false}
            isDetailsReadOnly={true}
            companyId={companyId}
            query={query}
            form={form}
            users={users}
            setUsers={setUsers}
            onBack={onBackClick}
        />
    );
};

export default DepartmentDetailView;
