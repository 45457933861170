import { ClockCircleOutlined, DragOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { API } from '@eservices/shared/type';
import { Colors } from '@eservices/ui-constants/colors';
import { Badge, Space } from 'antd';
import { PresetStatusColorType } from 'antd/lib/_util/colors';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Fonts, iconsPerTask } from '../../../constants';
import { useTranslation } from '../../../hooks/translationHook';
import { getDaysFrom } from '../../../utils/helpers';
import { SecondaryButton } from '../../Buttons';

const Container = styled.div<ContainerProps>`
    z-index: ${({ selected }) => selected && '99'};
    ${({ enabled, selected }) => !enabled && !selected && 'opacity: 0.3'}
`;

const taskValidationBadgeStatus: Record<string, PresetStatusColorType> = {
    VALIDATED: 'success',
    PENDING: 'processing',
    ERROR: 'error',
};

interface ContainerProps {
    selected: boolean;
    enabled: boolean;
}

const TaskName = styled.span`
    font-size: 16px;
    font-family: ${Fonts.HURME4_LIGHT};
`;

const TaskStatus = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 9px;
    span {
        color: ${({ color }) => Colors[color]};
    }
`;

const Card = styled.div<CardProps>`
    width: 300px;
    margin: 0 auto;
    border: 1px solid ${Colors.DARK_SECONDARY};
    border-radius: 5px;
    position: relative;
    background-color: ${Colors.WHITE};
    cursor: ${({ enabled }) => (enabled ? 'pointer' : 'cursor')};
`;
interface CardProps {
    enabled: boolean;
}

const TopNodeContainer = styled.div`
    text-align: center;
    line-height: 1px;
    margin: -4px 0 -4px 0;
`;

const BottomNode = styled.div`
    text-align: center;
    position: relative;
    margin: 0 0 -1px 0;
    height: 50px;
`;

const MaxDurationContainer = styled.div`
    position: absolute;
    top: 25%;
    left: 55%;
`;

const DetailInner = styled.div`
    display: flex;
`;

const Icon = styled.span`
    font-size: 18px;
`;

const Detail = styled.div`
    margin: 15px 10px;
`;

const MoveButton = styled(SecondaryButton)`
    cursor: move;
    padding: 0 !important;
    width: 24px !important;
    height: 35px !important;
    position: absolute;
    right: -35px;
    top: 50%;
    transform: translateY(-50%);
`;

interface TaskCardProps {
    selected: boolean;
    enabled: boolean;
    task: API.TaskConfiguration;
    order: number;
    id: string;
    onTaskSelected: (order: number, task: API.TaskConfiguration) => void;
    workflow: API.WorkflowConfiguration;
    companyId: string;
}

const TaskCard: React.FC<TaskCardProps> = ({ id, task, order, onTaskSelected, enabled, selected }) => {
    const { t } = useTranslation('workflow');
    const [active, setActive] = useState(false);
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const dot = (
        <TopNodeContainer>
            <svg width="18" height="9" viewBox="0 0 10 9" fill={Colors.WHITE}>
                <circle cx="5.125" cy="4.58447" r="3.25" stroke={Colors.DARK_SECONDARY} strokeWidth="1" />
            </svg>
        </TopNodeContainer>
    );

    const bottom = (
        <BottomNode>
            <svg width="1px" height="48">
                <line y1="2" y2="48" style={{ stroke: Colors.DARK_SECONDARY, strokeWidth: 4 }} />
            </svg>
            <MaxDurationContainer>
                <Space align="center">
                    <ClockCircleOutlined />
                    {t('dayWithCount', { count: getDaysFrom(task.maxDurationInSeconds) })}
                </Space>
            </MaxDurationContainer>
        </BottomNode>
    );

    return (
        <Container enabled={enabled} selected={active || selected} ref={setNodeRef} style={style}>
            {dot}
            <Card
                enabled={enabled}
                onMouseEnter={() => enabled && setActive(true)}
                onMouseLeave={() => setActive(false)}
                onClick={() => enabled && onTaskSelected(order, task)}
            >
                <Detail>
                    <DetailInner>
                        <Space>
                            <Icon>{iconsPerTask[task.taskType]}</Icon>
                            <TaskName>{task.name}</TaskName>
                            <TaskStatus color={task.validationStatus}>
                                <Badge status={taskValidationBadgeStatus[task.validationStatus]} />
                                <span>{t(`validation_status_${task.validationStatus.toLowerCase()}`)}</span>
                            </TaskStatus>
                        </Space>
                    </DetailInner>
                </Detail>
                <MoveButton type="dashed" size="small" icon={<DragOutlined />} {...attributes} {...listeners} />
            </Card>
            {dot}
            {bottom}
        </Container>
    );
};

export default TaskCard;
