import { DeleteOutlined } from '@ant-design/icons';
import { SecondaryButton } from '../../../../../components/Buttons';
import styled from 'styled-components';

const DeleteButton = styled(SecondaryButton)``;
DeleteButton.defaultProps = {
    danger: true,
    size: 'small',
    shape: 'circle',
    icon: <DeleteOutlined />,
};

export default DeleteButton;
