import { PlusOutlined } from '@ant-design/icons';
import { UserRole } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Space, Tooltip } from 'antd';
import React, { useState } from 'react';
import { Fonts } from '../../constants';
import { useTranslation } from '../../hooks/translationHook';
import styled from 'styled-components';
import DepartmentAdministratorLogo from '../../assets/images/department-admin.svg';
import OperatorLogo from '../../assets/images/operator.svg';
import ViewerLogo from '../../assets/images/viewer.svg';
import { IconButton } from '../Buttons';
import UserAvatar from '../UserAvatar';
import AvatarWithDelete from '../AvatarWithDelete';
import AddUsersModal from './AddUsersModal';
import { Colors } from '@eservices/ui-constants/colors';
import Card from '../Card';

const logosPerRole = {
    [UserRole.CLIENT_DEPARTMENT_ADMIN]: DepartmentAdministratorLogo,
    [UserRole.CLIENT_OPERATOR]: OperatorLogo,
    [UserRole.CLIENT_VIEWER]: ViewerLogo,
};

const Container = styled(Card)`
    width: 30%;
    height: 450px;
`;

const RoleInfo = styled.div`
    text-align: center;
    height: 180px;
`;

const RoleTitle = styled.div`
    font-family: ${Fonts.HURME4_LIGHT};
    font-size: 16px;
    margin: 16px 0 8px 0px;
    text-transform: capitalize;
`;

const RoleLogo = styled.img`
    width: 70px;
    height: 70px;
`;

interface DepartmentUsersEditorProps {
    isReadOnly: boolean;
    role: UserRole;
    users: API.User[];
    companyId: string;
    onAddUsers: (newlyAddedUsers: API.User[]) => void;
    onRemoveUser: (id: string) => void;
}

const DepartmentUsersEditor: React.FC<DepartmentUsersEditorProps> = ({
    isReadOnly,
    role,
    users,
    companyId,
    onAddUsers,
    onRemoveUser,
}) => {
    const { t, tCommon } = useTranslation('department');
    const [modalVisible, setModalVisible] = useState(false);

    const handleAddUsers = (newlyAddedUsers: API.User[]) => {
        onAddUsers(newlyAddedUsers);
        setModalVisible(false);
    };

    return (
        <Container>
            <RoleInfo>
                <RoleLogo src={logosPerRole[role]} alt={role} />
                <RoleTitle>{tCommon(role)}</RoleTitle>
            </RoleInfo>
            <Space wrap>
                {users.map((user) =>
                    isReadOnly ? (
                        <UserAvatar key={user.id} user={user} />
                    ) : (
                        <AvatarWithDelete
                            key={user.id}
                            id={user.id}
                            color={Colors[user.role]}
                            name={`${user.firstName} ${user.lastName}`}
                            onRemove={onRemoveUser}
                        />
                    ),
                )}
                {!isReadOnly && (
                    <Tooltip title={t('add_new_user', { role: tCommon(role) })} mouseEnterDelay={1} placement="bottom">
                        <IconButton
                            color={Colors.PRIMARY}
                            icon={<PlusOutlined />}
                            onClick={() => setModalVisible(true)}
                        />
                    </Tooltip>
                )}
            </Space>
            {modalVisible && (
                <AddUsersModal
                    role={role}
                    companyId={companyId}
                    alreadySelectedUsers={users}
                    onComplete={handleAddUsers}
                    onCancel={() => setModalVisible(false)}
                />
            )}
        </Container>
    );
};

export default DepartmentUsersEditor;
