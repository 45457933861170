import {
    DEFAULT_SENDER_MAIL_ADDRESS,
    EMAIL_DEFAULT_SUPPLIER,
    TaskType,
    TaskValidationStatus,
} from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Tabs } from 'antd';
import Form, { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import { FormItem } from '../../../components/FormItem';
import { useTranslation } from '../../../hooks/translationHook';
import { useUploadBlob } from '../../../hooks/uploadBlobHook';
import { TaskDetailProps } from '../../../types';
import { getDaysFrom } from '../../../utils/helpers';
import EmailTemplate from '../common/EmailTemplate';
import TaskActions from '../common/TaskActions';
import TaskDangerousActions from '../common/TaskDangerousActions';
import { convertFormToEmailTask, EmailTaskFormFields } from './formHelpers';
import SendingConfiguration from './SendingConfiguration';

const { TabPane } = Tabs;

const tabConfiguration = 'configuration';
const tabTemplate = 'template';

const defaultFormValues: EmailTaskFormFields = {
    taskType: TaskType.EMAIL,
    name: '',
    supplierId: EMAIL_DEFAULT_SUPPLIER,
    timeToWait: 1, // Always assum
    validationStatus: TaskValidationStatus.PENDING,
    sender: {
        displayName: '',
        emailAddress: DEFAULT_SENDER_MAIL_ADDRESS,
    },
    subject: '',
};

interface EmailTaskConfigurationProps extends TaskDetailProps<API.TaskEmail> {
    workflow: API.WorkflowConfiguration;
    placeholders: string[];
    departmentDetails: API.Department;
}

const EmailTaskConfiguration: React.FC<EmailTaskConfigurationProps> = ({
    order,
    task,
    companyId,
    workflow,
    isReadOnly,
    onSave,
    onCancel,
    onError,
    onDelete,
    allowedToSave,
    placeholders,
    departmentDetails,
}) => {
    const { t, tValidation } = useTranslation('workflow');
    const [isLoading, setIsLoading] = useState(false);
    const { uploadBlob: uploadHTMLTemplate } = useUploadBlob(companyId);
    const { uploadBlob: uploadJSONTemplate } = useUploadBlob(companyId);
    const emailEditorRef = useRef<EmailEditor>();
    const [form] = useForm<EmailTaskFormFields>();

    useEffect(() => {
        if (task) {
            form.setFieldsValue({
                ...task,
                timeToWait: getDaysFrom(task.maxDurationInSeconds),
            });
        }
    }, []);

    const saveTask = async () => {
        try {
            setIsLoading(true);
            const taskFields = await form.validateFields();

            if (emailEditorRef.current) {
                const { bodyTemplate, bodyJsonTemplate } = await new Promise((resolve) => {
                    emailEditorRef.current.editor.exportHtml(async (data: { html: string; design: string }) => {
                        const { html, design } = data;

                        if (design === undefined) {
                            throw new Error(tValidation('template'));
                        }

                        const filenamePrefix = `${workflow.id}-${new Date().toISOString()}`;

                        const [bodyTemplate, bodyJsonTemplate] = await Promise.all([
                            uploadHTMLTemplate(`${filenamePrefix}.html`, html),
                            uploadJSONTemplate(`${filenamePrefix}.json`, JSON.stringify(design)),
                        ]);

                        resolve({ bodyTemplate, bodyJsonTemplate });
                    });
                });
                if (bodyTemplate.url === undefined) {
                    throw Error(tValidation('template'));
                } else {
                    const task = convertFormToEmailTask(taskFields, bodyTemplate, bodyJsonTemplate);
                    onSave(order, task);
                }
            } else {
                throw Error(tValidation('template'));
            }
        } catch (e) {
            let errors = [];
            if (e?.errorFields) {
                errors = e?.errorFields.map(({ errors }) => errors.join(', '));
            } else {
                errors.push(e.message);
            }
            onError(errors);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Form
            form={form}
            initialValues={defaultFormValues}
            layout="vertical"
            name="emailTaskConfiguration"
            requiredMark={false}
        >
            <FormItem name="validationStatus" hidden />
            <FormItem name="id" hidden />
            <FormItem name="taskType" hidden />
            <FormItem name="supplierId" hidden />
            <FormItem hidden name={['attachment', 'url']} />
            <FormItem hidden name={['attachment', 'filename']} />
            <FormItem hidden name={['htmlTemplate', 'url']} />
            <FormItem hidden name={['htmlTemplate', 'filename']} />
            <Tabs
                defaultActiveKey={tabConfiguration}
                tabBarExtraContent={
                    <TaskActions
                        isLoading={isLoading}
                        onSave={saveTask}
                        onCancel={onCancel}
                        allowedToSave={allowedToSave && !isLoading}
                        taskType={TaskType.EMAIL}
                        isReadOnly={isReadOnly}
                    />
                }
            >
                <TabPane tab={t('configuration')} key={tabConfiguration}>
                    <SendingConfiguration
                        form={form}
                        inputSource={workflow.inputSource}
                        departmentDetails={departmentDetails}
                        isReadOnly={isReadOnly}
                    />
                </TabPane>
                <TabPane tab={t('template')} key={tabTemplate} forceRender={true}>
                    <EmailTemplate
                        ref={emailEditorRef}
                        placeholders={placeholders}
                        bodyJsonTemplateUrl={task?.bodyJsonTemplate?.url}
                        setLoading={(isLoading) => setIsLoading(isLoading)}
                    />
                </TabPane>
            </Tabs>
            <TaskDangerousActions canBeDeleted={task !== undefined && !isReadOnly} onDelete={onDelete} />
        </Form>
    );
};

export default EmailTaskConfiguration;
