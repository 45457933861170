import { Colors } from '@eservices/ui-constants/colors';
import styled from 'styled-components';
import { Fonts } from '../constants';

export const Header1 = styled.h1`
    font-family: ${Fonts.HURME4_SEMI_BOLD};
    font-size: 33px;
    font-weight: bold;
    color: ${Colors.GRAY_PRIMARY};
`;

export const Header2 = styled.h2`
    font-family: ${Fonts.HURME4_SEMI_BOLD};
    font-size: 22px;
    font-weight: bold;
    color: ${Colors.GRAY_PRIMARY};
`;

export const Header3 = styled.h3`
    font-family: ${Fonts.HURME4_SEMI_BOLD} !important;
    font-size: 15px;
    color: ${Colors.GRAY_PRIMARY};
`;

export const Header4 = styled.h4`
    font-family: ${Fonts.HURME4_SEMI_BOLD} !important;
    font-size: 15px;
    color: ${Colors.GRAY_PRIMARY};
`;
