import { UserRole } from '@eservices/shared/constant';
import { User } from '@eservices/shared/type';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SearchInput } from '../../components/Input';
import SubscriptionCounter from '../../components/subscription/SubscriptionCounter';
import UsersTable from '../../components/user/UsersTable';
import { SubscriptionContext } from '../../constants';
import { useGetMyUser, useGetUsers } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import ResourcesTableLayout from '../../layouts/ResourcesTableLayout';

const FilterContainer = styled.div`
    width: 400px;
`;

const TableHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

interface UsersOverviewFetchedProps {
    companyId: string;
    actions?: React.ReactNode;
}

const UsersOverviewFetching: React.FC<UsersOverviewFetchedProps> = ({ companyId, actions }) => {
    const [filteredUsers, setFilteredUsers] = useState([]);
    const { isLoading, isError, data: users = [] } = useGetUsers(companyId);
    const { data: user } = useGetMyUser();
    const { t } = useTranslation('user_overview');

    useEffect(() => {
        if (users) {
            if (user.role === UserRole.CLIENT_DEPARTMENT_ADMIN) {
                // Filter users belonging to the admin's departments
                setFilteredUsers([
                    ...users.filter(
                        (usr: User) =>
                            usr.id !== user.id && usr.departments.some((dpt: string) => user.departments.includes(dpt)),
                    ),
                ]);
            } else {
                setFilteredUsers([...users]);
            }
        }
    }, [JSON.stringify(users)]);

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === '') {
            setFilteredUsers([...users]);
        } else {
            setFilteredUsers(users.filter((user) => user.username.toLowerCase().includes(value.toLowerCase())));
        }
    };

    const filter = (
        <TableHeader>
            <FilterContainer>
                <SearchInput placeholder={t('search_placeholder')} onChange={onSearch} />
            </FilterContainer>
            <SubscriptionCounter companyId={companyId} context={SubscriptionContext.USER} />
        </TableHeader>
    );

    const detail = <UsersTable isLoading={isLoading} users={filteredUsers} />;

    return (
        <ResourcesTableLayout isError={isError} title={t('title')} filter={filter} detail={detail} actions={actions} />
    );
};

export default UsersOverviewFetching;
