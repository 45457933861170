import { useTranslation } from '../../../hooks/translationHook';
import DepartmentSelector from '../../DepartmentSelector';
import { FormItem } from '../../FormItem';
import { Header2 } from '../../Headers';
import { Input, TextArea } from '../../Input';
import { Paragraph } from '../../Paragraph';

interface DetailsQuestionProps {
    companyId: string;
}

const DetailsQuestion: React.FC<DetailsQuestionProps> = ({ companyId }) => {
    const { t, tCommon, tValidation } = useTranslation('workflow');

    return (
        <div>
            <Header2>{t('configuration')}</Header2>
            <Paragraph>{t('configuration_description')}</Paragraph>
            <FormItem
                name="name"
                rules={[
                    {
                        required: true,
                        message: tValidation('required.workflow_name'),
                    },
                    { min: 3, message: tValidation('custom.min_3') },
                ]}
            >
                <Input placeholder={t('workflow_name')} type="text" aria-label="name input" />
            </FormItem>
            <FormItem name="description">
                <TextArea placeholder={tCommon('description')} rows={5} aria-label="description input" />
            </FormItem>
            <FormItem
                name="departmentId"
                rules={[
                    {
                        required: true,
                        message: tValidation('required.department_name'),
                    },
                ]}
            >
                <DepartmentSelector companyId={companyId} />
            </FormItem>
        </div>
    );
};

export default DetailsQuestion;
