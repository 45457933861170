import { Skeleton } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { useEffect } from 'react';
import FailedToLoadData from '../../components/FailedToLoadData';
import UserDetail from '../../components/user/UserDetail';
import { useGetDepartments, useGetMyUser, useGetUser } from '../../hooks/apiHooks';
import ResourceDetailLayout from '../../layouts/ResourceDetailLayout';

interface UserDetailFetchingProps {
    companyId: string;
    userId: string;
    isUpdate: boolean;
    form: FormInstance;
    actions: React.ReactNode;
    errors?: string[];
    onBack: () => void;
}

const UserDetailFetching: React.FC<UserDetailFetchingProps> = ({
    companyId,
    userId,
    isUpdate,
    actions,
    form,
    errors,
    onBack,
}) => {
    const { data: user, isLoading: isUserLoading, isError: isUserError } = useGetUser(companyId, userId);
    const { data: author, isLoading: isAuthorLoading, isError: isAuthorError } = useGetMyUser();
    const {
        data: departments = [],
        isLoading: isDepartmentsLoading,
        isError: isDepartmentsError,
    } = useGetDepartments(companyId);

    useEffect(() => {
        form.setFieldsValue({ ...user });
    }, [user]);

    if (isUserLoading || isAuthorLoading || isDepartmentsLoading) return <Skeleton />;

    if (isUserError || isAuthorError || isDepartmentsError) return <FailedToLoadData />;

    const detail = <UserDetail isUpdate={isUpdate} form={form} departments={departments} authorRole={author.role} />;

    return (
        <ResourceDetailLayout
            title={user.username}
            createdAt={user.createdAt}
            lastModifiedAt={user?.lastModifiedAt}
            lastModifiedBy={user?.lastModifiedBy}
            errors={errors}
            detail={detail}
            actions={actions}
            onBack={onBack}
        />
    );
};

export default UserDetailFetching;
