import { UserOutlined } from '@ant-design/icons';
import { ApplicationModule, UserRole } from '@eservices/shared/constant';
import { Routes } from '../../constants';
import { BasicRoute, MainRoute } from '../../types';
import UserDetailCreate from './UserDetailCreate';
import UserDetailEdit from './UserDetailEdit';
import UsersOverviewEdit from './UsersOverviewEdit';

const editUserPage = (companyId: string): BasicRoute => ({
    path: Routes.EDIT_USER,
    component: <UserDetailEdit companyId={companyId} />,
});

const editUserOverviewPage = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.USERS,
    component: <UsersOverviewEdit companyId={companyId} />,
});

const createUserPage = (companyId: string): BasicRoute => ({
    path: Routes.CREATE_USER,
    component: <UserDetailCreate companyId={companyId} />,
});

export const createUsersRouter = (userRole: UserRole, companyId: string): MainRoute => {
    const userRoutes = {
        [UserRole.CLIENT_DEPARTMENT_ADMIN]: [
            createUserPage(companyId),
            editUserOverviewPage(companyId),
            editUserPage(companyId),
        ],
        [UserRole.CLIENT_ADMIN]: [createUserPage(companyId), editUserOverviewPage(companyId), editUserPage(companyId)],
        [UserRole.SUPER_USER]: [createUserPage(companyId), editUserOverviewPage(companyId), editUserPage(companyId)],
        [UserRole.CLIENT_OPERATOR]: [],
        [UserRole.CLIENT_VIEWER]: [],
    };

    return {
        path: Routes.USERS,
        title: 'users',
        icon: <UserOutlined />,
        subRoutes: userRoutes[userRole],
        module: ApplicationModule.OMNI,
    };
};
