import { API } from '@eservices/shared/type';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from '../../hooks/translationHook';
import { FormItem } from '../FormItem';
import { Input } from '../Input';

interface QERDSAPIKeyFormProps {
    form: FormInstance<API.RegisterQERDSApiKey>;
}

const QERDSAPIKeyForm: React.FC<QERDSAPIKeyFormProps> = ({ form }) => {
    const { t, tValidation } = useTranslation('qerds');
    return (
        <>
            <ReactMarkdown>{t('api_key_intro')}</ReactMarkdown>
            <Form form={form} layout="vertical" name="qerdsApiKey" requiredMark={false}>
                <FormItem
                    label={t('api_key_value')}
                    name={'value'}
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.api_key_value'),
                        },
                        {
                            pattern: new RegExp(/^[a-zA-Z0-9]+$/g),
                            message: tValidation('format.api_key_value'),
                        },
                    ]}
                >
                    <Input />
                </FormItem>
            </Form>
        </>
    );
};

export default QERDSAPIKeyForm;
