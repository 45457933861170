import { ApplicationModule } from '@eservices/shared/constant';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Routes } from '../constants';
import NotFound from '../screens/utils/NotFound';
import { MainRoute } from '../types';

interface ScreenRouterProps {
    routes: MainRoute[];
}

const ScreenRouter: React.FC<ScreenRouterProps> = ({ routes }) => {
    const module = localStorage.getItem('module');
    const rootRedirect = module === ApplicationModule.EASYPOST_PORTAL ? Routes.PARCELS : Routes.DASHBOARD;

    return (
        <Switch>
            <Redirect exact from={Routes.ROOT} to={rootRedirect} />
            <Redirect exact from={Routes.CONFIRM_PASSWORD} to={rootRedirect} />
            <Redirect exact from={Routes.FORGOT_PASSWORD_SUBMIT_FORM} to={rootRedirect} />
            {routes.map(({ subRoutes }) =>
                subRoutes.map(({ path, exact, component }) => (
                    <Route key={path} path={path} exact={exact} children={component} />
                )),
            )}
            <Route path="*">{<NotFound />}</Route>
        </Switch>
    );
};

export default ScreenRouter;
