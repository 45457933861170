import { BookOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../components/Buttons';
import DepartmentDetail from '../../components/department/DepartmentDetail';
import SubscriptionModal from '../../components/subscription/SubscriptionModal';
import { Routes, SubscriptionContext } from '../../constants';
import { useCreateDepartment } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import ResourceDetailLayout from '../../layouts/ResourceDetailLayout';

interface DepartmentDetailCreateProps {
    companyId: string;
}

const loadingMessageKey = 'loadingMessageKey';

const DepartmentDetailCreate: React.FC<DepartmentDetailCreateProps> = ({ companyId }) => {
    const history = useHistory();
    const { t, tCommon } = useTranslation('department');
    const [form] = useForm();
    const [users, setUsers] = useState<API.User[]>([]);
    const mutation = useCreateDepartment(companyId);

    useEffect(() => {
        if (mutation.isLoading) {
            message.loading({ key: loadingMessageKey, content: t('creating_new_department_loading') }, 0);
        } else {
            message.destroy(loadingMessageKey);
        }
    }, [mutation.isLoading]);

    useEffect(() => {
        if (mutation.isSuccess) {
            history.push(Routes.DEPARTMENTS);
            message.success(t('creating_new_department_success', { name: form.getFieldValue('name') }), 3);
        }
    }, [mutation.isSuccess]);

    useEffect(() => {
        if (mutation.isError) {
            message.error(t('creating_new_department_error'), 3);
        }
    }, [mutation.isError]);

    const onBackClick = () => {
        history.goBack();
    };

    const onClickSaveDepartment = async () => {
        const { countryCode, city, street, postalCode, streetNr, ...otherFormValues } = await form.validateFields();
        const newDepartment: API.CreateDepartment = {
            ...otherFormValues,
            headAddress: { countryCode, city, street, postalCode, streetNr },
            users: users.map(({ id }) => id),
        };
        mutation.mutate(newDepartment);
    };

    const detail = (
        <DepartmentDetail
            isDetailsReadOnly={false}
            isUsersReadOnly={false}
            companyId={companyId}
            form={form}
            users={users}
            setUsers={setUsers}
        />
    );

    const actions = [
        <PrimaryButton
            icon={<BookOutlined />}
            aria-label="submit new department button"
            disabled={mutation.isLoading}
            htmlType="submit"
            onClick={onClickSaveDepartment}
        >
            {tCommon('save')}
        </PrimaryButton>,
    ];

    return (
        <>
            <ResourceDetailLayout
                title={t('create_department_title')}
                detail={detail}
                actions={actions}
                onBack={onBackClick}
            />
            <SubscriptionModal companyId={companyId} context={SubscriptionContext.DEPARTMENT} />
        </>
    );
};

export default DepartmentDetailCreate;
