import { ClearOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { ChannelProperties } from '@eservices/shared/constant';
import { PDFChannelIdentifier } from '@eservices/shared/type';
import { Colors } from '@eservices/ui-constants/colors';
import { Badge } from 'antd';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import { HighlightRectangleContext } from '../..';
import { SecondaryButton } from '../../../../../components/Buttons';
import ConditionalWrap from '../../../../../components/ConditionalWrap';
import { useTranslation } from '../../../../../hooks/translationHook';

const StyledSecondaryButton = styled(SecondaryButton)`
    width: 150px;
`;

interface TaskTypePropertyButtonProps {
    type: ChannelProperties;
    isReadOnly: boolean;
    identifier: PDFChannelIdentifier;
    onChannelPropertyClick: (type: ChannelProperties) => void;
    onClearClick: (type: ChannelProperties) => void;
}

const TaskTypePropertyButton: React.FC<TaskTypePropertyButtonProps> = ({
    type,
    isReadOnly,
    identifier,
    onChannelPropertyClick,
    onClearClick,
}) => {
    const { tCommon } = useTranslation('workflow');
    const setHighlightedRectangle = useContext(HighlightRectangleContext);
    const [isHovering, setIsHovering] = useState(false);

    const isChannelDrawn = Boolean(identifier);

    const icon = (isChannelDrawn && isHovering && <ClearOutlined />) || (!isChannelDrawn && <PlusCircleOutlined />);
    const text = (isChannelDrawn && isHovering && tCommon('clear')) || tCommon(type.toLowerCase());

    return (
        <ConditionalWrap
            key={type}
            condition={isChannelDrawn}
            wrap={(content) => (
                <Badge dot color={Colors.OK}>
                    {content}
                </Badge>
            )}
        >
            <StyledSecondaryButton
                type="dashed"
                shape="default"
                danger={isChannelDrawn && isHovering}
                aria-label={tCommon(`${type.toLocaleLowerCase()}_draw_label_button`)}
                icon={icon}
                onClick={() => (isChannelDrawn && isHovering ? onClearClick(type) : onChannelPropertyClick(type))}
                onMouseEnter={() => {
                    identifier && setHighlightedRectangle(identifier.regionOfInterest);
                    setIsHovering(true);
                }}
                onMouseLeave={() => {
                    {
                        identifier && setHighlightedRectangle(undefined);
                        setIsHovering(false);
                    }
                }}
                disabled={isReadOnly}
            >
                {text}
            </StyledSecondaryButton>
        </ConditionalWrap>
    );
};

export default TaskTypePropertyButton;
