import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { ChannelProperties, TaskType } from '@eservices/shared/constant';
import { PDFChannelIdentifier } from '@eservices/shared/type';
import { Colors } from '@eservices/ui-constants/colors';
import { Space } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../../../../hooks/translationHook';
import { Header4 } from '../../../../Headers';
import TaskTypePropertyButton from './TaskTypePropertyButton';

const Content = styled.div`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
`;

interface ChannelProperty {
    type: ChannelProperties;
}

interface TaskTypeEditorProps {
    isReadOnly: boolean;
    taskType: TaskType;
    channelIdentifiers: PDFChannelIdentifier[];
    channelProperties: ChannelProperty[];
    onChannelPropertyClick: (type: ChannelProperties) => void;
    onClearClick: (type: ChannelProperties) => void;
}

const TaskTypeEditor: React.FC<TaskTypeEditorProps> = ({
    isReadOnly,
    taskType,
    onChannelPropertyClick,
    onClearClick,
    channelProperties,
    channelIdentifiers,
}) => {
    const { tCommon } = useTranslation('workflow');

    return (
        <div>
            <Space align="start">
                <Header4>{tCommon(taskType.toLowerCase())}</Header4>
                {channelProperties.every(({ type }) =>
                    channelIdentifiers.some((identifier) => identifier.kind === type),
                ) ? (
                    <CheckCircleFilled />
                ) : (
                    <CloseCircleOutlined style={{ color: Colors.ALERT }} />
                )}
            </Space>
            <Content>
                {channelProperties.map(({ type }) => {
                    const identifier = channelIdentifiers.find((identifier) => identifier.kind === type);

                    return (
                        <Space key={type} align="baseline">
                            <TaskTypePropertyButton
                                type={type}
                                isReadOnly={isReadOnly}
                                identifier={identifier}
                                onChannelPropertyClick={onChannelPropertyClick}
                                onClearClick={onClearClick}
                            />
                        </Space>
                    );
                })}
            </Content>
        </div>
    );
};

export default TaskTypeEditor;
