import { StopOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { Popover } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from '../../../components/Buttons';
import { TaskActionType } from '../../../constants';
import { useTranslation } from '../../../hooks/translationHook';

const Actions = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    gap: 10px;
`;

interface QerdsTaskRetractProps {
    task: API.TaskDetails;
    isLoading: boolean;
    onConfirmTaskAction: (taskId: string, actionType: TaskActionType) => void;
}

const QerdsTaskRetract: React.FC<QerdsTaskRetractProps> = ({ task, isLoading, onConfirmTaskAction }) => {
    const { t, tCommon } = useTranslation('qerds_task');
    const [popoverVisible, setPopoverVisible] = useState<boolean>(false);

    return (
        <Popover
            title={tCommon('confirm_action')}
            visible={popoverVisible}
            onVisibleChange={(visible) => setPopoverVisible(visible)}
            content={
                <>
                    <p>{t('confirm_retract_qerds')}</p>
                    <Actions>
                        <SecondaryButton onClick={() => setPopoverVisible(false)}>{tCommon('cancel')}</SecondaryButton>
                        <PrimaryButton onClick={() => onConfirmTaskAction(task.id, TaskActionType.RETRACT_QERDS)}>
                            {tCommon('confirm')}
                        </PrimaryButton>
                    </Actions>
                </>
            }
            trigger="click"
        >
            <SecondaryButton danger disabled={isLoading} icon={<StopOutlined />}>
                {t('retract_qerds_sending')}
            </SecondaryButton>
        </Popover>
    );
};

export default QerdsTaskRetract;
