import { API } from '@eservices/shared/type';
import { FormInstance } from 'antd/lib/form/Form';
import { useEffect } from 'react';
import { UseQueryResult } from 'react-query';
import CompanyDetail from '../../components/company/CompanyDetail';
import ResourceDetailLayout from '../../layouts/ResourceDetailLayout';

interface CompanyDetailFetchingProps {
    isReadOnly: boolean;
    query: UseQueryResult<API.Company, Error>;
    form: FormInstance;
    actions?: React.ReactNode;
    errors?: string[];
    onBack?: () => void;
}

const CompanyDetailFetching: React.FC<CompanyDetailFetchingProps> = ({
    isReadOnly,
    actions,
    query,
    form,
    errors,
    onBack,
}) => {
    useEffect(() => {
        if (query.data) {
            const {
                name,
                headAddress: { countryCode, city, street, postalCode, streetNr },
                phoneNumber,
                emailAddress,
                erpId,
                identificationNumber,
                volume,
                processingType,
            } = query.data;
            form.setFieldsValue({
                name,
                countryCode,
                city,
                street,
                postalCode,
                streetNr,
                phoneNumber,
                emailAddress,
                erpId,
                identificationNumber,
                volume,
                processingType,
            });
        }
    }, [query.data]);

    const detail = <CompanyDetail isReadOnly={isReadOnly} form={form} />;

    return (
        <ResourceDetailLayout
            isLoading={query.isLoading}
            isError={query.isError}
            title={query.data?.name}
            createdAt={query.data?.createdAt}
            lastModifiedAt={query.data?.lastModifiedAt}
            lastModifiedBy={query.data?.lastModifiedBy}
            errors={errors}
            detail={detail}
            actions={actions}
            onBack={onBack}
        />
    );
};

export default CompanyDetailFetching;
