import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { noop } from '../../utils/helpers';
import { Input as BaseInput } from '../Input';

const Input = styled(BaseInput)`
    width: 50px;
    height: 50px;
    margin-right: 5px;
    text-align: center;
`;

interface PinCodeProps {
    value?: string;
    onChange?: (val: string) => void;
    onCodeFilled?: () => void;
    length: number;
}

const PinCode: React.FC<PinCodeProps> = ({
    value: initialValue = '',
    length,
    onChange = noop,
    onCodeFilled = noop,
}) => {
    const [itemValues, setItemValues] = useState<Array<string>>(new Array<string>(length).fill(undefined));
    const pinElementsRefs = useRef([]);

    useEffect(() => {
        const newItemValues = [...itemValues];

        for (let i = 0; i < initialValue.length && i < length; i++) {
            newItemValues[i] = initialValue[i];
        }

        setItemValues(newItemValues);
    }, []);

    function onItemKeyDown(idx: number, event: React.KeyboardEvent<HTMLInputElement>) {
        const val = event.currentTarget.value;
        const key = event.key.toLocaleLowerCase();
        if (!val && pinElementsRefs.current[idx - 1] && /(backspace|delete)/.test(key)) {
            pinElementsRefs.current[idx - 1].focus();
        }
    }

    const onItemChange = (idx: number, event: ChangeEvent<HTMLInputElement>) => {
        let newValue = event.currentTarget.value;
        const nextRef = pinElementsRefs.current[idx + 1];
        if (Number.isNaN(Number(newValue))) {
            newValue = '';
        }
        if (nextRef && Boolean(newValue)) {
            nextRef.focus();
        }

        const newItemValues = [...itemValues];
        newItemValues[idx] = newValue;

        onChange(newItemValues.join(''));
        setItemValues(newItemValues);
        if (newItemValues.filter((v) => Boolean(v)).length === length) {
            onCodeFilled();
        }
    };
    return (
        <>
            {itemValues.map((value, key) => (
                <Input
                    ref={(el) => (pinElementsRefs.current[key] = el)}
                    inputMode="numeric"
                    min={0}
                    minLength={1}
                    maxLength={1}
                    onChange={(event) => onItemChange(key, event)}
                    onKeyDown={(event) => onItemKeyDown(key, event)}
                    value={value}
                    key={key}
                    autoComplete="off"
                />
            ))}
        </>
    );
};

export default PinCode;
