import { NotificationOutlined } from '@ant-design/icons';
import { ApplicationModule, UserRole } from '@eservices/shared/constant';
import { Routes } from '../../constants';
import { BasicRoute, MainRoute } from '../../types';
import DailyAnnouncementsOverviewView from './DailyAnnouncementsOverviewView';

const viewDailyAnnouncementsOverviewPageThatCanCreateAnnouncements = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.DAILY_ANNOUNCEMENTS,
    component: <DailyAnnouncementsOverviewView companyId={companyId} canCreateAnnouncement />,
});

const viewDailyAnnouncementOverviewPage = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.DAILY_ANNOUNCEMENTS,
    component: <DailyAnnouncementsOverviewView companyId={companyId} />,
});

export const createDailyAnnouncementsRouter = (userRole: UserRole, companyId: string): MainRoute => {
    const dailyAnnouncementsRoutes = {
        [UserRole.SUPER_USER]: [viewDailyAnnouncementsOverviewPageThatCanCreateAnnouncements(companyId)],
        [UserRole.CLIENT_ADMIN]: [viewDailyAnnouncementsOverviewPageThatCanCreateAnnouncements(companyId)],
        [UserRole.CLIENT_DEPARTMENT_ADMIN]: [viewDailyAnnouncementsOverviewPageThatCanCreateAnnouncements(companyId)],
        [UserRole.CLIENT_OPERATOR]: [viewDailyAnnouncementsOverviewPageThatCanCreateAnnouncements(companyId)],
        [UserRole.CLIENT_VIEWER]: [viewDailyAnnouncementOverviewPage(companyId)],
    };

    return {
        path: Routes.DAILY_ANNOUNCEMENTS,
        title: 'daily announcements',
        icon: <NotificationOutlined />,
        subRoutes: dailyAnnouncementsRoutes[userRole],
        module: ApplicationModule.EASYPOST_PORTAL,
    };
};
