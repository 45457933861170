import { CheckCircleFilled, FireFilled, LoadingOutlined } from '@ant-design/icons';
import { QERDSApiKeyStatus } from '@eservices/shared/constant';

import { API } from '@eservices/shared/type';
import { useTranslation } from '../../hooks/translationHook';
import { toReadableDateTime } from '../../utils/helpers';
import DescriptionItem from '../DescriptionItem';

interface QERDSAPIKeyDetailsProps {
    apiKeyDetails: API.QERDSApiKeyDetails;
}

const logosPerStatus = {
    [QERDSApiKeyStatus.TO_BE_CHECKED]: <LoadingOutlined spin />,
    [QERDSApiKeyStatus.VALID]: <CheckCircleFilled />,
    [QERDSApiKeyStatus.INVALID]: <FireFilled />,
};

const QERDSAPIKeyDetails: React.FC<QERDSAPIKeyDetailsProps> = ({ apiKeyDetails }) => {
    const { t, tCommon } = useTranslation('qerds');

    return (
        <>
            <div>
                <DescriptionItem
                    title="status"
                    content={
                        <>
                            {logosPerStatus[apiKeyDetails.status]}
                            <span>{t(`api_key_status_${apiKeyDetails.status}`)}</span>
                        </>
                    }
                />
            </div>

            <div>
                <DescriptionItem title={tCommon('created_at')} content={toReadableDateTime(apiKeyDetails.createdAt)} />
            </div>
            <div>
                <DescriptionItem
                    title={tCommon('last_modified_at')}
                    content={toReadableDateTime(apiKeyDetails.lastModifiedAt)}
                />
            </div>
        </>
    );
};

export default QERDSAPIKeyDetails;
