import { Skeleton } from 'antd';
import styled from 'styled-components';
import { useGetMyUser, useGetQERDSApiKeyDetails } from '../../hooks/apiHooks';
import ChangePassword from './ChangePassword';
import DepartmentsList from './DepartmentsList';
import MFASettings from './MFASettings';
import QERDSSetup from './QERDSSetup';
import UserDetailEdit from './UserDetailEdit';

const Container = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;
`;

const Section = styled.div`
    width: 31%;
    min-width: 400px;
    display: flex;
    flex-flow: column wrap;
    gap: 20px;
`;

interface ProfileFetchingProps {
    companyId: string;
}

const ProfileFetching: React.FC<ProfileFetchingProps> = ({ companyId }) => {
    const { data: myUser, isLoading: isMyUserLoading } = useGetMyUser();
    const { data: myQERDSKeyDetails, isLoading: isQERDSKeyDetailsLoading } = useGetQERDSApiKeyDetails(companyId);

    if (isMyUserLoading || isQERDSKeyDetailsLoading) return <Skeleton />;

    return (
        <Container>
            <Section>
                <UserDetailEdit user={myUser} />
                <MFASettings hasMFALoginEnabled={myUser.hasMFALoginEnabled} />
            </Section>
            <Section>
                <ChangePassword />
                <QERDSSetup companyId={companyId} user={myUser} qerdsAPIKeyDetails={myQERDSKeyDetails} />
            </Section>
            <Section>
                <DepartmentsList companyId={companyId} />
            </Section>
        </Container>
    );
};

export default ProfileFetching;
