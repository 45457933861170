import { API } from '@eservices/shared/type';
import { Card } from 'antd';
import React from 'react';
import { useGetTemplatePreviewUrl } from '../../../hooks/apiHooks';
import styled from 'styled-components';
import FailedToLoadData from '../../FailedToLoadData';

export const cardWidth = '240px';
export const cardHeight = '330px';

const { Meta } = Card;

const Template = styled(Card)`
    width: ${cardWidth};
    height: ${cardHeight};
`;

const Preview = styled.img`
    height: 250px;
`;

interface SampleEmailTemplateProps {
    name: string;
    design: API.EmailDesign;
    onSelect: () => void;
}

const SampleEmailTemplate: React.FC<SampleEmailTemplateProps> = ({ name, design, onSelect }) => {
    const { data, isLoading, isError } = useGetTemplatePreviewUrl(design);

    if (isError) return <FailedToLoadData />;

    return (
        <Template
            onClick={onSelect}
            loading={isLoading}
            hoverable
            cover={data?.url && <Preview alt="example" src={data.url} />}
        >
            <Meta title={name.split('|')[0]} />
        </Template>
    );
};

export default SampleEmailTemplate;
