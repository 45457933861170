import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { RecipientWithParcelInfo } from '@eservices/shared/type';
import { Badge, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import ReactMarkdown from 'react-markdown';
import { Colors } from '@eservices/ui-constants/colors';
import { useBpostCountries } from '../../hooks/bpostCountriesHook';
import { useTranslation } from '../../hooks/translationHook';
import styled from 'styled-components';
import { IconButton } from '../Buttons';
import { Checkbox } from '../Checkbox';
import Footnote from '../Footnote';
import Table from '../Table';
import EditRecipient from './EditRecipient';

const Details = styled.div`
    color: ${Colors.GRAY_SEPTENARY};
    font-size: 12px;
`;

const FootnoteStyled = styled(Footnote)`
    ul,
    li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        padding-left: 2%;
    }

    li:before {
        content: '*';
        padding-right: 1%;
    }
`;

type ParcelRecipientWithKey = RecipientWithParcelInfo & {
    key: number;
    hasError: boolean;
};

interface RecipientTableProps {
    recipients: ParcelRecipientWithKey[];
    onEditRecipient: (index: number, recipient: RecipientWithParcelInfo) => void;
    onRemoveRecipient: (index: number) => void;
    addRecipientAtIndex: (index: number, item: RecipientWithParcelInfo) => void;
}

const RecipientsTable: React.FC<RecipientTableProps> = ({
    recipients,
    onEditRecipient,
    onRemoveRecipient,
    addRecipientAtIndex,
}) => {
    const { t, tCommon } = useTranslation('create_parcels');
    const { isLoadingCountries, findCountryName } = useBpostCountries();

    const columns: ColumnsType<ParcelRecipientWithKey> = [
        {
            title: tCommon('Detail'),
            width: 150,
            render: (
                _any,
                { parcelName, companyName, name, address: { countryCode, city, postalCode, street, streetNr, box } },
            ) => (
                <>
                    <div>{parcelName}</div>
                    <Details>
                        <div>
                            {companyName || name}
                            <br />
                            {companyName && name}
                        </div>
                        {street} {streetNr}
                        {box && ` ${box}`}, {postalCode} {city}, {findCountryName(countryCode)}
                    </Details>
                </>
            ),
        },
        {
            title: tCommon('insurance'),
            width: 50,
            align: 'center',
            render: (_any, recipient, i) => (
                <Checkbox
                    checked={recipient.bpostOptions.hasInsurance}
                    onChange={() =>
                        onEditRecipient(recipient.key, {
                            ...recipient,
                            bpostOptions: {
                                ...recipient.bpostOptions,
                                hasInsurance: !recipient.bpostOptions.hasInsurance,
                            },
                        })
                    }
                />
            ),
        },
        {
            title: tCommon('signature'),
            width: 50,
            align: 'center',
            render: (_any, recipient) => (
                <Checkbox
                    checked={recipient.bpostOptions.hasSignature}
                    onChange={() =>
                        onEditRecipient(recipient.key, {
                            ...recipient,
                            bpostOptions: {
                                ...recipient.bpostOptions,
                                hasSignature: !recipient.bpostOptions.hasSignature,
                            },
                        })
                    }
                />
            ),
        },
        {
            title: t('send_mail'),
            width: 50,
            align: 'center',
            render: (_any, recipient) => (
                <Checkbox
                    checked={recipient.bpostOptions.needsMailAnnouncement}
                    onChange={() =>
                        onEditRecipient(recipient.key, {
                            ...recipient,
                            bpostOptions: {
                                ...recipient.bpostOptions,
                                needsMailAnnouncement: !recipient.bpostOptions.needsMailAnnouncement,
                            },
                        })
                    }
                />
            ),
        },
        {
            width: 80,
            align: 'right',
            render: (_any, recipient) => {
                const editButton = (
                    <EditRecipient
                        recipient={recipient}
                        onSave={(editedRecipient) => onEditRecipient(recipient.key, editedRecipient)}
                    />
                );

                return (
                    <Space>
                        <IconButton
                            icon={<CopyOutlined />}
                            onClick={() => addRecipientAtIndex(recipient.key + 1, recipient)}
                        />
                        {recipient.hasError ? <Badge dot>{editButton}</Badge> : editButton}
                        <IconButton icon={<DeleteOutlined />} onClick={() => onRemoveRecipient(recipient.key)} />
                    </Space>
                );
            },
        },
    ];

    const footer = () => (
        <FootnoteStyled>
            <ReactMarkdown>{t('recipients_table_footnote')}</ReactMarkdown>
        </FootnoteStyled>
    );

    return (
        <>
            <Table
                scroll={{ y: '42vh' }}
                rowKey="key"
                columns={columns}
                dataSource={recipients}
                pagination={{ pageSize: 100 }}
                footer={footer}
                loading={isLoadingCountries}
            />
        </>
    );
};

export default RecipientsTable;
