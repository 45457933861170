import { Result } from 'antd';

interface CustomErrorProps {
    status: 'warning' | 'error';
    title: string;
}

const CustomError: React.FC<CustomErrorProps> = ({ status, title }) => {
    return <Result status={status} title={title} />;
};

export default CustomError;
