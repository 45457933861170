import { MehOutlined } from '@ant-design/icons';
import React from 'react';
import { Colors } from '@eservices/ui-constants/colors';
import styled from 'styled-components';
import { useTranslation } from '../../hooks/translationHook';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 50px;
    max-width: 70%;
    margin: 0 auto;
    text-align: center;
`;

const IconContainer = styled.div`
    font-size: 80px;
    color: ${Colors.QUATERNARY};
`;

const ErrorDetails = styled.div`
    font-size: 14px;
    color: ${Colors.GRAY_SECONDARY};
    max-width: 70%;
    margin: 0 auto;
`;

interface FatalErrorProps {
    error: Error;
}
const FatalError: React.FC<FatalErrorProps> = ({ error }) => {
    const { tError } = useTranslation();

    return (
        <Container role="alert">
            <div>
                <div>{tError('FatalError')}</div>
                <IconContainer>
                    <MehOutlined />
                </IconContainer>
                <ErrorDetails>{error.stack || error.message || error.name}</ErrorDetails>
            </div>
        </Container>
    );
};

export default FatalError;
