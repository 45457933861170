import { TaskType } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Colors } from '@eservices/ui-constants/colors';
import { Tabs } from 'antd';
import styled from 'styled-components';
import { useTranslation } from '../../hooks/translationHook';
import { toReadableDateTime } from '../../utils/helpers';
import DetailTabs from '../DetailTabs';
import { Header2 } from '../Headers';
import EasyPrintTaskDetail from './easy-print/EasyPrintTaskDetail';
import EasyPrintTaskProgress from './easy-print/EasyPrintTaskProgress';
import EmailTaskDetail from './email/EmailTaskDetail';
import EmailTaskProgress from './email/EmailTaskProgress';
import ErdsTaskDetail from './erds/ErdsTaskDetail';
import ErdsTaskProgress from './erds/ErdsTaskProgress';
import QerdsTaskDetail from './qerds/QerdsTaskDetail';
import QerdsTaskProgress from './qerds/QerdsTaskProgress';
import TaskExpiration from './TaskExpiration';

const { TabPane } = Tabs;

const TaskDetailTitle = styled.div`
    margin-bottom: 10%;
`;

const TaskDescription = styled.div`
    color: ${Colors.GRAY_SEPTENARY};
`;

const Progress = styled.div`
    display: flex;
    justify-content: space-between;
`;

function taskDetailHandler(companyId: string, task: API.TaskDetails, taskConfiguration: API.TaskConfiguration) {
    switch (task.taskType) {
        case TaskType.EASY_PRINT:
            return [
                <EasyPrintTaskProgress task={task} taskConfiguration={taskConfiguration as API.TaskEasyPrint} />,
                <EasyPrintTaskDetail
                    companyId={companyId}
                    task={task as API.PrintTaskDetails}
                    taskConfiguration={taskConfiguration as API.TaskEasyPrint}
                />,
            ];

        case TaskType.ERDS:
            return [
                <ErdsTaskProgress task={task} taskConfiguration={taskConfiguration as API.TaskERDS} />,
                <ErdsTaskDetail
                    companyId={companyId}
                    task={task as API.ERDSTaskDetails}
                    taskConfiguration={taskConfiguration as API.TaskERDS}
                />,
            ];
        case TaskType.EMAIL:
            return [
                <EmailTaskProgress task={task} taskConfiguration={taskConfiguration as API.TaskEmail} />,
                <EmailTaskDetail
                    companyId={companyId}
                    task={task as API.EmailTaskDetails}
                    taskConfiguration={taskConfiguration as API.TaskEmail}
                />,
            ];
        case TaskType.QERDS:
            return [
                <QerdsTaskProgress task={task} />,
                <QerdsTaskDetail
                    companyId={companyId}
                    task={task as API.QERDSTaskDetails}
                    taskConfiguration={taskConfiguration as API.TaskQERDS}
                />,
            ];
        default:
            return [<>{JSON.stringify(task)}</>, <>{JSON.stringify(taskConfiguration)}</>];
    }
}

const formatErrors = (taskType: TaskType, errors: string[]) => {
    // TODO - Refactor error handling in each task type implementation
    if (taskType !== TaskType.QERDS) {
        return errors;
    }
    return errors.map((error) => {
        const customError: API.CustomError = JSON.parse(error);
        return `${toReadableDateTime(customError.timestamp)}: ${customError.message}`;
    });
};

interface TaskDetailProps {
    companyId: string;
    task: API.TaskDetails;
    taskConfiguration: API.TaskConfiguration;
}

const TaskDetail: React.FC<TaskDetailProps> = ({ companyId, task, taskConfiguration }) => {
    const { tCommon } = useTranslation();

    task.warnings = task.warnings || [];
    task.errors = task.errors || [];

    const timeToWait = new Date(task.startDate).getTime() + taskConfiguration.maxDurationInSeconds * 1000;

    const [progress, taskDetail] = taskDetailHandler(companyId, task, taskConfiguration);

    const tabs = [
        <TabPane tab={tCommon('details')} key="1">
            {taskDetail}
        </TabPane>,
    ];

    return (
        <div>
            <TaskDetailTitle>
                <Header2>{taskConfiguration.name}</Header2>
                <TaskDescription>{taskConfiguration.description}</TaskDescription>
            </TaskDetailTitle>
            <Progress>
                {progress}
                {task.startDate && !task.endDate && <TaskExpiration timeToWait={timeToWait} />}
            </Progress>
            <DetailTabs
                tabs={tabs}
                warnings={task.warnings}
                errors={formatErrors(task.taskType, task.errors)}
            ></DetailTabs>
        </div>
    );
};

export default TaskDetail;
