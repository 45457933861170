import { API } from '@eservices/shared/type';
import { Col, Row } from 'antd';
import { useTranslation } from '../../hooks/translationHook';
import DescriptionItem from '../DescriptionItem';
import { Header3 } from '../Headers';

interface ParcelDetailSubDetailBpostDetailProps {
    parcel: API.Parcel;
}

const ParcelDetailSubDetailBpostDetail: React.FC<ParcelDetailSubDetailBpostDetailProps> = ({ parcel }) => {
    const { t, tCommon } = useTranslation('parcel_detail');

    const { barCode, reference, itemDetail } = parcel;

    return (
        <div>
            <Header3>{t('bpost_detail')}</Header3>
            <Row>
                <Col span={12}>
                    <DescriptionItem title={tCommon('barcode')} content={barCode} />
                </Col>
                <Col span={12}>
                    <DescriptionItem title={tCommon('reference')} content={reference} />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem title={t('weight_in_grams')} content={itemDetail?.weightInGrams} />
                </Col>
            </Row>
        </div>
    );
};

export default ParcelDetailSubDetailBpostDetail;
