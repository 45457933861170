import { Subscriptions } from '@eservices/shared/constant';
import { Alert, Skeleton } from 'antd';
import { SubscriptionContext } from '../../constants';
import { useGetCompanySubscriptionDetails } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import styled from 'styled-components';

const SkeletonInput = styled(Skeleton.Input)`
    width: 10%;
`;

interface SubscriptionHeaderProps {
    companyId: string;
    context: SubscriptionContext;
}

const SubscriptionCounter: React.FC<SubscriptionHeaderProps> = ({ companyId, context }) => {
    const { t } = useTranslation('subscription');
    const { isError, error, data, isLoading } = useGetCompanySubscriptionDetails(companyId);

    if (isLoading) return <SkeletonInput active={true} size="small" />;

    if (isError) return <Alert type="error" message={t('subscription_error')} description={error.message} />;

    const contextKey = context.toLowerCase();
    const count = data[`${contextKey}Count`];
    const maxAmount = Subscriptions[data.subscriptionType][`maxAmountOf${context}`];

    return (
        <>
            {t(`subscription_${contextKey}_counter`, {
                count,
                maxAmount,
            })}
        </>
    );
};

export default SubscriptionCounter;
