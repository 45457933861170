import { RadioGroupProps } from 'antd';
import { useTranslation } from '../../../hooks/translationHook';
import { Radio } from '../../Radio';

type DateSelector = RadioGroupProps;

const DateSelector: React.FC<RadioGroupProps> = ({ ...restProps }) => {
    const { tCommon } = useTranslation();
    const options = [
        { label: tCommon('date_filter_today'), value: 'today' },
        { label: tCommon('date_filter_yesterday'), value: 'yesterday' },
        { label: tCommon('date_filter_last_week'), value: 'last_week' },
        { label: tCommon('date_filter_last_month'), value: 'last_month' },
    ];

    return <Radio.Group optionType="button" buttonStyle="solid" options={options} {...restProps} />;
};

export default DateSelector;
