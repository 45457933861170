import { DesktopOutlined } from '@ant-design/icons';
import { ApplicationModule, UserRole } from '@eservices/shared/constant';
import { Routes } from '../../constants';
import { BasicRoute, MainRoute } from '../../types';
import JobsOverviewView from './JobsOverviewView';
import JobSubmission from './JobSubmission';

const createJobSubmissionPage = (companyId: string): BasicRoute => {
    return {
        path: Routes.SUBMIT_JOB,
        component: <JobSubmission companyId={companyId} />,
    };
};

const viewJobsOverviewPage = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.JOBS,
    component: (
        <JobsOverviewView companyId={companyId} canSubmitJob={false} canCancelJobs={false} canApproveJobs={false} />
    ),
});

const viewJobsOverviewPageThatCanSubmitJobsAndCancelJobsAndApproveJobs = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.JOBS,
    component: <JobsOverviewView companyId={companyId} canSubmitJob canCancelJobs canApproveJobs />,
});

export const createJobsRouter = (userRole: UserRole, companyId: string): MainRoute => {
    const jobRoutes = {
        [UserRole.SUPER_USER]: [
            viewJobsOverviewPageThatCanSubmitJobsAndCancelJobsAndApproveJobs(companyId),
            createJobSubmissionPage(companyId),
        ],
        [UserRole.CLIENT_ADMIN]: [
            viewJobsOverviewPageThatCanSubmitJobsAndCancelJobsAndApproveJobs(companyId),
            createJobSubmissionPage(companyId),
        ],
        [UserRole.CLIENT_DEPARTMENT_ADMIN]: [
            viewJobsOverviewPageThatCanSubmitJobsAndCancelJobsAndApproveJobs(companyId),
            createJobSubmissionPage(companyId),
        ],
        [UserRole.CLIENT_OPERATOR]: [
            viewJobsOverviewPageThatCanSubmitJobsAndCancelJobsAndApproveJobs(companyId),
            createJobSubmissionPage(companyId),
        ],
        [UserRole.CLIENT_VIEWER]: [viewJobsOverviewPage(companyId)],
    };

    return {
        path: Routes.JOBS,
        title: 'jobs',
        icon: <DesktopOutlined />,
        subRoutes: jobRoutes[userRole],
        module: ApplicationModule.EASYPOST_CONNECT,
    };
};
