import { useTranslation } from '../../../hooks/translationHook';
import { FormItem } from '../../FormItem';
import { Header2 } from '../../Headers';
import { Input } from '../../Input';
import { Paragraph } from '../../Paragraph';

interface SftpQuestionProps {}

const SftpQuestion: React.FC<SftpQuestionProps> = () => {
    const { t } = useTranslation('workflow');

    return (
        <div>
            <Header2>{t('sftp_title')}</Header2>
            <Paragraph>{t('sftp_description')}</Paragraph>
            <FormItem name="sFTPFolderName">
                <Input placeholder={t('sftp_folder_name')} type="text" aria-label="sFTP Folder Name input" />
            </FormItem>
        </div>
    );
};

export default SftpQuestion;
