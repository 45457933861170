import { API } from '@eservices/shared/type';

export type ContactsActions =
    | { type: 'insert'; payload: API.ParcelContactListItem }
    | { type: 'delete'; payload: string }
    | {
          type: 'empty';
      };

interface ContactsState {
    contacts?: API.ParcelContactListItem[];
}

const defaultState: ContactsState = {
    contacts: [],
};

export function contactsReducer(state: ContactsState = defaultState, action: ContactsActions): ContactsState {
    switch (action.type) {
        case 'insert': {
            return { contacts: [action.payload, ...state.contacts] };
        }
        case 'delete': {
            const { contacts } = state;
            const contactToBeRemoved = state.contacts.findIndex((contact) => contact.id === action.payload);
            return {
                contacts: [...contacts.slice(0, contactToBeRemoved), ...contacts.slice(contactToBeRemoved + 1)],
            };
        }
        case 'empty': {
            return defaultState;
        }
        default:
            throw new Error();
    }
}
