import { BookOutlined, CloseOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';
import { Routes } from '../../constants';
import { useGetCompany, useUpdateCompany } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import { translateFieldErrors } from '../../utils/helpers';
import CompanyDetailFetching from './CompanyDetailFetching';

const loadingMessageKey = 'loadingMessageKey';

const CompanyDetailEdit: React.FC = () => {
    const history = useHistory();
    const { t, tError, tCommon } = useTranslation('company');
    const [form] = useForm();
    const [errors, setErrors] = useState([]);
    const { id: companyId }: { id: string } = useParams();
    const query = useGetCompany(companyId);
    const mutation = useUpdateCompany(companyId);

    useEffect(() => {
        if (mutation.isLoading) {
            setErrors([]);
            message.loading({ key: loadingMessageKey, content: t('creating_new_company_loading') }, 0);
        } else {
            message.destroy(loadingMessageKey);
        }
    }, [mutation.isLoading]);

    useEffect(() => {
        if (mutation.isSuccess) {
            history.push(Routes.COMPANIES);
            message.success(t('editing_company_success', { name: form.getFieldValue('name') }), 3);
        }
    }, [mutation.isSuccess]);

    useEffect(() => {
        if (mutation.error) {
            if (mutation.error.response?.data?.fieldErrors) {
                form.setFields(translateFieldErrors(tError, mutation.error.response.data.fieldErrors));
            }

            const error = mutation.error.response?.data?.error;
            if (error) {
                setErrors([error]);
            }

            message.error(t('editing_company_error'), 3);
        }
    }, [mutation.isError]);

    const onClickCancel = () => {
        history.goBack();
    };

    const onClickSaveCompany = async () => {
        const { id, createdAt, lastModifiedAt, lastModifiedBy } = query.data;
        const { countryCode, city, street, postalCode, streetNr, ...otherFormValues } = await form.validateFields();

        const editedCompany: API.EditCompany = {
            id,
            createdAt,
            lastModifiedAt,
            lastModifiedBy,
            companyId,
            ...otherFormValues,
            headAddress: { countryCode, city, street, postalCode, streetNr },
        };

        mutation.mutate(editedCompany);
    };

    const actions = (
        <PrimaryButton
            icon={<BookOutlined />}
            aria-label="save button"
            disabled={query.isLoading || mutation.isLoading}
            htmlType="submit"
            onClick={onClickSaveCompany}
        >
            {tCommon('save')}
        </PrimaryButton>
    );

    return (
        <>
            <CompanyDetailFetching
                onBack={onClickCancel}
                isReadOnly={false}
                query={query}
                form={form}
                errors={errors}
                actions={actions}
            />
        </>
    );
};

export default CompanyDetailEdit;
