import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

const Loading = () => (
    <Container role="status">
        <Spin aria-label="loading" size="large" />
    </Container>
);

export default Loading;
