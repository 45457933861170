import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { ColumnsType } from 'antd/es/table';
import Table from '../../components/Table';
import { Routes } from '../../constants';
import { getPaginationDetails } from '../../utils/helpers';
import { useTranslation } from '../../hooks/translationHook';
import { IconButton } from '../Buttons';

interface DepartmentsTableProps {
    departments: API.DepartmentListItem[];
    isLoading: boolean;
    isReadOnly?: boolean;
}

const DepartmentsTable: React.FC<DepartmentsTableProps> = ({ departments = [], isLoading, isReadOnly }) => {
    const { t, tCommon } = useTranslation('department_overview');

    const paginationConfig = getPaginationDetails(departments.length, {});

    const columns: ColumnsType<API.DepartmentListItem> = [
        {
            title: tCommon('name'),
            dataIndex: 'name',
        },
        {
            title: tCommon('users'),
            width: 10,
            align: 'right',
            dataIndex: 'amountOfUsers',
        },
        {
            title: tCommon('invoice_reference'),
            align: 'right',
            dataIndex: 'erpId',
        },
    ];

    if (isReadOnly) {
        columns.push({
            width: 10,
            align: 'right',
            render: ({ id }: API.DepartmentListItem) => (
                <IconButton icon={<EyeOutlined />} href={Routes.VIEW_DEPARTMENT.replace(':id', id)} />
            ),
        });
    } else {
        columns.push({
            width: 10,
            align: 'right',
            render: ({ id }: API.DepartmentListItem) => (
                <IconButton icon={<EditOutlined />} href={Routes.EDIT_DEPARTMENT.replace(':id', id)} />
            ),
        });
    }

    return (
        <Table
            loading={isLoading}
            rowKey="id"
            columns={columns}
            dataSource={departments}
            pagination={paginationConfig}
        />
    );
};

export default DepartmentsTable;
