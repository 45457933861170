import React from 'react';
import { AuthProvider } from './auth-context';
import { AxiosProvider } from './axios-context';
import { ReactQueryProvider } from './query-context';

export const AppProviders: React.FC = ({ children }) => {
    return (
        <AuthProvider>
            <AxiosProvider>
                <ReactQueryProvider>{children}</ReactQueryProvider>
            </AxiosProvider>
        </AuthProvider>
    );
};
