import { ApplicationModule, UserRole } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Routes } from './constants';
import { createCompaniesRouter } from './screens/companies/router';
import { createDailyAnnouncementsRouter } from './screens/daily-announcement/router';
import { createDashboardOperatorRouter } from './screens/dashboard/router';
import { createDepartmentsRouter } from './screens/departments/router';
import { createJobsRouter } from './screens/jobs/router';
import { createOrdersRouter } from './screens/orders/router';
import { createParcelRouter } from './screens/parcels/router';
import PoliciesView from './screens/policies/PoliciesView';
import ProfileEdit from './screens/profile/ProfileEdit';
import { createRegisteredMailRouter } from './screens/registered-mail/router';
import { createSendingEventsRouter } from './screens/sending-events/router';
import { createUsersRouter } from './screens/users/router';
import { createVignettesRouter } from './screens/vignettes/router';
import { createWorkflowRouter } from './screens/workflow/router';
import { MainRoute } from './types';

export const createRoutesFor = (user: API.User, companyId: string): MainRoute[] => {
    // TODO: can be removed once we have a home and dashboard
    /* const homeRoute: MainRoute = {
        path: Routes.ROOT,
        exact: true,
        title: 'home',
        component: <div data-testid={'Home Page'}>Home</div>,
        icon: <HomeOutlined />,
    };
    */

    const omniRoutes: MainRoute[] = [
        {
            path: Routes.PROFILE,
            subRoutes: [
                {
                    path: Routes.PROFILE,
                    component: <ProfileEdit companyId={companyId} />,
                },
            ],
            title: '',
            icon: '',
            module: ApplicationModule.OMNI,
        },
        {
            path: Routes.POLICIES,
            subRoutes: [
                {
                    path: Routes.POLICIES,
                    exact: true,
                    component: <PoliciesView companyId={companyId} isEditable={user.role === UserRole.SUPER_USER} />,
                },
            ],
            title: '',
            icon: '',
            module: ApplicationModule.OMNI,
        },
        createDepartmentsRouter(user.role, companyId),
        createUsersRouter(user.role, companyId),
    ];

    const eServicesRoutes = {
        [UserRole.SUPER_USER]: [
            createDashboardOperatorRouter(UserRole.SUPER_USER, companyId),
            createWorkflowRouter(UserRole.SUPER_USER, companyId),
            createJobsRouter(UserRole.SUPER_USER, companyId),
            createSendingEventsRouter(UserRole.SUPER_USER, companyId),
            createCompaniesRouter(UserRole.SUPER_USER),
        ],
        [UserRole.CLIENT_ADMIN]: [
            createDashboardOperatorRouter(UserRole.CLIENT_ADMIN, companyId),
            createWorkflowRouter(UserRole.CLIENT_ADMIN, companyId),
            createJobsRouter(UserRole.CLIENT_ADMIN, companyId),
            createSendingEventsRouter(UserRole.CLIENT_ADMIN, companyId),
        ],
        [UserRole.CLIENT_DEPARTMENT_ADMIN]: [
            createDashboardOperatorRouter(UserRole.CLIENT_DEPARTMENT_ADMIN, companyId),
            createWorkflowRouter(UserRole.CLIENT_DEPARTMENT_ADMIN, companyId),
            createJobsRouter(UserRole.CLIENT_DEPARTMENT_ADMIN, companyId),
            createSendingEventsRouter(UserRole.CLIENT_DEPARTMENT_ADMIN, companyId),
        ],
        [UserRole.CLIENT_OPERATOR]: [
            createDashboardOperatorRouter(UserRole.CLIENT_OPERATOR, companyId),
            createWorkflowRouter(UserRole.CLIENT_OPERATOR, companyId),
            createJobsRouter(UserRole.CLIENT_OPERATOR, companyId),
            createSendingEventsRouter(UserRole.CLIENT_OPERATOR, companyId),
        ],
        [UserRole.CLIENT_VIEWER]: [
            createDashboardOperatorRouter(UserRole.CLIENT_VIEWER, companyId),
            createWorkflowRouter(UserRole.CLIENT_VIEWER, companyId),
            createJobsRouter(UserRole.CLIENT_VIEWER, companyId),
            createSendingEventsRouter(UserRole.CLIENT_VIEWER, companyId),
        ],
    };

    const easyPostPortalRoutes = {
        [UserRole.SUPER_USER]: [
            createOrdersRouter(UserRole.SUPER_USER, companyId),
            createParcelRouter(UserRole.SUPER_USER, companyId),
            createVignettesRouter(UserRole.SUPER_USER, companyId),
            createRegisteredMailRouter(UserRole.SUPER_USER, companyId),
            createDailyAnnouncementsRouter(UserRole.SUPER_USER, companyId),
        ],
        [UserRole.CLIENT_ADMIN]: [
            createParcelRouter(UserRole.CLIENT_ADMIN, companyId),
            createOrdersRouter(UserRole.CLIENT_ADMIN, companyId),
            createVignettesRouter(UserRole.CLIENT_ADMIN, companyId),
            createRegisteredMailRouter(UserRole.CLIENT_ADMIN, companyId),
            createDailyAnnouncementsRouter(UserRole.CLIENT_ADMIN, companyId),
        ],
        [UserRole.CLIENT_DEPARTMENT_ADMIN]: [
            createParcelRouter(UserRole.CLIENT_DEPARTMENT_ADMIN, companyId),
            createOrdersRouter(UserRole.CLIENT_DEPARTMENT_ADMIN, companyId),
            createVignettesRouter(UserRole.CLIENT_DEPARTMENT_ADMIN, companyId),
            createRegisteredMailRouter(UserRole.CLIENT_DEPARTMENT_ADMIN, companyId),
            createDailyAnnouncementsRouter(UserRole.CLIENT_DEPARTMENT_ADMIN, companyId),
        ],
        [UserRole.CLIENT_OPERATOR]: [
            createParcelRouter(UserRole.CLIENT_OPERATOR, companyId),
            createOrdersRouter(UserRole.CLIENT_OPERATOR, companyId),
            createVignettesRouter(UserRole.CLIENT_OPERATOR, companyId),
            createRegisteredMailRouter(UserRole.CLIENT_OPERATOR, companyId),
            createDailyAnnouncementsRouter(UserRole.CLIENT_OPERATOR, companyId),
        ],
        [UserRole.CLIENT_VIEWER]: [
            createParcelRouter(UserRole.CLIENT_VIEWER, companyId),
            createVignettesRouter(UserRole.CLIENT_VIEWER, companyId),
            createDailyAnnouncementsRouter(UserRole.CLIENT_VIEWER, companyId),
        ],
    };

    const routePerAccessModule = {
        [ApplicationModule.EASYPOST_CONNECT]: eServicesRoutes,
        [ApplicationModule.EASYPOST_PORTAL]: easyPostPortalRoutes,
    };

    let routes = [];
    for (const module of user.modulesAccess) {
        const moduleRoutes = routePerAccessModule[module];
        routes = routes.concat(moduleRoutes[user.role]);
    }
    routes = routes.concat(omniRoutes);

    return routes;
};
