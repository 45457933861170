import { VignetteType } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Col, Row } from 'antd';
import Barcode from 'react-barcode';
import styled from 'styled-components';
import Logo from '../../assets/images/login-logo.svg';
import { Fonts } from '../../constants';
import {
    CheckboxContainer,
    CheckedBox,
    ColonContainer,
    DateLabelContainer,
    DateLineContainer,
    DatePartContainer,
    ImageContainer,
    Label,
    TitleLabel,
    VignetteTypeCheckContainer,
    VignetteTypeContainer,
    VignetteTypeText,
} from './Common';

const Container = styled.div`
    margin: 30px;
`;

const BarcodePartContainer = styled.div`
    min-width: 200px;
    text-align: center;
`;

const DepartmentLabel = styled.div`
    text-align: left;
    font-family: ${Fonts.HURME4};
    font-size: 32px;
    margin-top: 5px;
    margin-left: 20px;
    overflow: visible;
    white-space: nowrap;
`;

const VignetteTypeTitle = styled.div`
    padding: 5px;
    font-size: 14px;
    border-bottom: 2px solid black;
`;

const DepartmentErpIdLabel = styled.div`
    font-family: ${Fonts.HURME4_LIGHT};
    margin-left: 20px;
    font-size: 14px;
`;

const truncateDepartmentName = (departmentName: string) => {
    return departmentName.length > 50 ? departmentName.substring(0, 50) : departmentName;
};

const generateBarcodeValue = (type: VignetteType, erpId: string) => {
    switch (type) {
        case VignetteType.REGISTERED:
            return `A$${erpId}`;
        case VignetteType.PRIOR:
            return `P$${erpId}`;
        case VignetteType.NON_PRIOR:
            return `N$${erpId}`;
    }
};

interface VignetteComponentProps {
    department: API.DepartmentListItem;
    type: VignetteType;
}

const VignetteComponent: React.FC<VignetteComponentProps> = ({ department, type }) => {
    const BarcodePart = () => {
        return (
            <BarcodePartContainer>
                <ImageContainer>
                    <img src={Logo} />
                </ImageContainer>
                <div>
                    <Barcode
                        format="CODE39"
                        value={generateBarcodeValue(type, department.erpId)}
                        displayValue={false}
                        height={55}
                    />
                </div>
                <DepartmentLabel>{truncateDepartmentName(department.name)}</DepartmentLabel>
            </BarcodePartContainer>
        );
    };

    const VignetteTypePart = () => {
        return (
            <div>
                <VignetteTypeContainer>
                    <VignetteTypeTitle>
                        {type === VignetteType.REGISTERED ? (
                            <>
                                <Label>Aangetekende zendingen</Label>
                                <Label>Envois recommandés</Label>
                            </>
                        ) : (
                            <>
                                <Label>Post nationaal & internationaal</Label>
                                <Label>Courrier national & international</Label>
                            </>
                        )}
                    </VignetteTypeTitle>
                    <VignetteTypeCheckContainer>
                        <CheckboxContainer>{CheckedBox}</CheckboxContainer>
                        <VignetteTypeText>
                            {type === VignetteType.REGISTERED && (
                                <>
                                    <TitleLabel>Aangetekend</TitleLabel>
                                    <TitleLabel>Recommandé</TitleLabel>
                                </>
                            )}
                            {type === VignetteType.PRIOR && <TitleLabel>Prior</TitleLabel>}
                            {type === VignetteType.NON_PRIOR && <TitleLabel>Non Prior</TitleLabel>}
                        </VignetteTypeText>
                    </VignetteTypeCheckContainer>
                </VignetteTypeContainer>
                <DepartmentErpIdLabel>{department.erpId}</DepartmentErpIdLabel>
            </div>
        );
    };

    const DatesPart = () => {
        return (
            <DatePartContainer>
                <DateLineContainer>
                    <DateLabelContainer>
                        <Label>DATUM</Label>
                        <Label>DATE</Label>
                    </DateLabelContainer>
                    <ColonContainer>
                        <Label>:</Label>
                    </ColonContainer>
                </DateLineContainer>
                <DateLineContainer>
                    <DateLabelContainer>
                        <Label>AANTAL</Label>
                        <Label>NOMBRE</Label>
                    </DateLabelContainer>
                    <ColonContainer>
                        <Label>:</Label>
                    </ColonContainer>
                </DateLineContainer>
            </DatePartContainer>
        );
    };

    return (
        <Container>
            <Row justify="start">
                <Col span={8}>
                    <BarcodePart />
                </Col>
                <Col span={8}>
                    <VignetteTypePart />
                </Col>
            </Row>
            <Row justify="end">
                <Col span={8}>
                    <DatesPart />
                </Col>
            </Row>
        </Container>
    );
};

export default VignetteComponent;
