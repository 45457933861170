import { LoadingOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { Space, Timeline } from 'antd';
import { useTranslation } from '../../../hooks/translationHook';
import { toReadableDateTime } from '../../../utils/helpers';

interface EasyPrintTaskProgressProps {
    task: API.PrintTaskDetails;
    taskConfiguration: API.TaskEasyPrint;
}

const EasyPrintTaskProgress: React.FC<EasyPrintTaskProgressProps> = ({ task, taskConfiguration }) => {
    const { t, tCommon } = useTranslation('easy_print_task');

    const getColor = (endDate: string): string => {
        if (endDate) return 'green';
        else return 'gray';
    };

    const showLoadingIcon = (endDate: string, index: number): boolean => {
        if (endDate || task.endDate) return false;
        if (task.statusHistory[index - 1]?.date) return true;
    };

    return (
        <Timeline>
            {task.statusHistory.map(({ value, date }, index) => (
                <Timeline.Item
                    key={value}
                    color={getColor(date)}
                    dot={showLoadingIcon(date, index) && <LoadingOutlined spin />}
                >
                    <Space>
                        {t(value.toLocaleLowerCase())} {date && toReadableDateTime(date)}
                    </Space>
                </Timeline.Item>
            ))}
            <Timeline.Item color={task.endDate ? 'green' : 'gray'}>
                <Space>
                    {tCommon('end')} {task.endDate && toReadableDateTime(task.endDate)}
                </Space>
            </Timeline.Item>
        </Timeline>
    );
};

export default EasyPrintTaskProgress;
