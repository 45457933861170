import { DownloadOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { useTranslation } from '../../hooks/translationHook';
import styled from 'styled-components';
import { PrimaryButton } from '../Buttons';
import DownloadLink from '../DownloadLink';

const DownloadContainer = styled.div`
    margin: 3% 0;
`;

const LabelDownloads = styled.div`
    margin: 5% 0 0 0;
    text-align: center;
`;

interface ParcelDetailSubDetailDownloadsProps {
    companyId: string;
    departmentId: string;
    parcel: API.Parcel;
    departments: API.DepartmentListItem[];
}

const ParcelDetailSubDetailDownloads: React.FC<ParcelDetailSubDetailDownloadsProps> = ({
    companyId,
    departmentId,
    parcel,
}) => {
    const { t } = useTranslation('parcel_detail');

    const { id, parcelGroupId, statusHistory } = parcel;

    return (
        <LabelDownloads>
            {statusHistory.LABEL_CREATED && (
                <DownloadContainer>
                    <DownloadLink
                        companyId={companyId}
                        source={API.FileSource.PARCEL}
                        location={`${companyId}/${departmentId}/${parcelGroupId}/label-${id}.pdf`}
                        content={<PrimaryButton icon={<DownloadOutlined />}>{t('download_label')}</PrimaryButton>}
                    />
                </DownloadContainer>
            )}
            {statusHistory.LABEL_MERGED && (
                <DownloadContainer>
                    <DownloadLink
                        companyId={companyId}
                        source={API.FileSource.PARCEL}
                        location={`${companyId}/${departmentId}/${parcelGroupId}/${parcelGroupId}-merge.pdf`}
                        content={
                            <PrimaryButton icon={<DownloadOutlined />}>{t('download_merged_labels')}</PrimaryButton>
                        }
                    />
                </DownloadContainer>
            )}
        </LabelDownloads>
    );
};

export default ParcelDetailSubDetailDownloads;
