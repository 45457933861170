import styled from 'styled-components';
import { useTranslation } from '../../hooks/translationHook';
import { FormItem } from '../FormItem';
import { InputNumber } from '../Input';

interface BasicAnnoucementProps {
    maxInput: number;
}

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

const BasicAnnoucement: React.FC<BasicAnnoucementProps> = ({ maxInput }) => {
    const { t } = useTranslation('daily_announcements');

    const formItemInputs = [
        { name: 'amountPrior', label: t('post_type_prior') },
        { name: 'amountNonPrior', label: t('post_type_non_prior') },
        { name: 'amountRegistered', label: t('post_type_registered') },
        { name: 'amountParcels', label: t('post_type_parcel') },
    ];

    const createFormItems = (inputs) => {
        return inputs.map(function (elem, i) {
            return (
                <FormItem key={i} name={elem.name} label={elem.label} labelAlign="left">
                    <InputNumber min={0} max={maxInput} />
                </FormItem>
            );
        });
    };

    return <Wrapper>{createFormItems(formItemInputs)}</Wrapper>;
};

export default BasicAnnoucement;
