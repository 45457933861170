import { UserRole } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { FormInstance } from 'antd';
import styled from 'styled-components';
import { useTranslation } from '../../hooks/translationHook';
import UserForm from './UserForm';

const Container = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
`;

interface UserDetailProps {
    isUpdate?: boolean;
    authorRole: UserRole;
    departments: API.DepartmentListItem[];
    form: FormInstance;
}

const UserDetail: React.FC<UserDetailProps> = ({ isUpdate = false, authorRole, departments, form }) => {
    const { t } = useTranslation('user');

    return (
        <Container>
            <UserForm isUpdate={isUpdate} form={form} authorRole={authorRole} departments={departments} />
        </Container>
    );
};

export default UserDetail;
