import { ClockCircleOutlined, CopyOutlined, UploadOutlined } from '@ant-design/icons';
import { Subscriptions, WorkflowTriggerType, WorkflowType } from '@eservices/shared/constant';
import { Colors } from '@eservices/ui-constants/colors';
import { Descriptions, Form, FormInstance, message, Radio, RadioChangeEvent, Skeleton, Space, Switch } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { FormItem } from '../../components/FormItem';
import { Input, InputNumber as AntInputNumber, TextArea } from '../../components/Input';
import { Fonts } from '../../constants';
import { environment } from '../../environments/environment';
import { useGetCompanySubscriptionDetails } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import { IconButton } from '../Buttons';
import DatePicker from '../DatePicker';
import DepartmentSelector from '../DepartmentSelector';
import FailedToLoadData from '../FailedToLoadData';

const Container = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 3%;
`;

const Box = styled.div`
    width: 300px;
`;

const TriggerFormItem = styled(FormItem)`
    .ant-form-item-label {
        padding: 0 0 3px 0;
    }

    .ant-form-item-label > label {
        color: ${Colors.GRAY_SEPTENARY};
        font-size: 12px;
        font-family: ${Fonts.ARIAL_REGULAR};
    }
`;

const InputNumber = styled(AntInputNumber)`
    width: 100px;
`;

const EmailLink = styled.a`
    overflow-wrap: anywhere;
`;

InputNumber.defaultProps = {
    min: 0,
    max: 999,
};

interface GeneralConfigurationProps {
    workflowId: string;
    isReadOnly: boolean;
    form: FormInstance;
    companyId: string;
}

const GeneralConfiguration: React.FC<GeneralConfigurationProps> = ({ workflowId, companyId, form, isReadOnly }) => {
    const { t, tCommon, tValidation } = useTranslation('workflow');
    const {
        isError,
        isLoading: isLoadingSubscription,
        data: subscriptionDetails,
    } = useGetCompanySubscriptionDetails(companyId);
    const [triggerType, setTriggerType] = useState<WorkflowTriggerType>(
        form.getFieldValue(['trigger', 'type']) || WorkflowTriggerType.FILE_DROP,
    );

    if (isLoadingSubscription) return <Skeleton />;
    if (isError) return <FailedToLoadData />;

    const email = `${companyId}.${workflowId}@${environment.emailDomain}`;

    const availableWorkflowTypes = Subscriptions[subscriptionDetails.subscriptionType].availableWorkflowTypes;

    const triggerOptions = [
        {
            label: (
                <Space>
                    {t('file_drop')}
                    <UploadOutlined />
                </Space>
            ),
            value: WorkflowTriggerType.FILE_DROP,
        },
        {
            label: (
                <Space>
                    {t('scheduled')}
                    <ClockCircleOutlined />
                </Space>
            ),
            value: WorkflowTriggerType.SCHEDULED,
        },
    ];

    const typeOptions = [
        { label: t('basic'), value: WorkflowType.BASIC },
        { label: t('advanced'), value: WorkflowType.ADVANCED },
    ];

    const onTriggerChange = (e: RadioChangeEvent): void => {
        setTriggerType(e.target.value);
    };

    const copyToClipBoard = async () => {
        await navigator.clipboard.writeText(email);
        await message.success(tCommon('copied_to_clipboard'));
    };

    return (
        <Form
            form={form}
            layout="vertical"
            name="workflow"
            requiredMark={false}
            initialValues={{ validated: false, version: '0' }}
        >
            <Container>
                <FormItem name={['inputSource', 'type']} hidden />
                <FormItem name={['inputSource', 'channelIdentifiers']} initialValue={[]} hidden />
                <FormItem name={['inputSource', 'splitIdentifiers']} initialValue={[]} hidden />
                <FormItem name={['inputSource', 'ignoreIdentifiers']} initialValue={[]} hidden />
                <FormItem name={['inputSource', 'extraInfoIdentifiers']} initialValue={[]} hidden />
                <FormItem name="validated" hidden />
                <FormItem name="version" hidden />
                <Box>
                    <FormItem
                        label={t('workflow_name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.workflow_name'),
                            },
                            { min: 3, message: tValidation('custom.min_3') },
                        ]}
                    >
                        <Input disabled={isReadOnly} type="text" aria-label="name input" />
                    </FormItem>
                    <FormItem label={tCommon('description')} name="description">
                        <TextArea rows={5} disabled={isReadOnly} aria-label="description input" />
                    </FormItem>
                </Box>
                <Box>
                    <FormItem
                        label={tCommon('department')}
                        name="departmentId"
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.department_name'),
                            },
                        ]}
                    >
                        <DepartmentSelector companyId={companyId} disabled />
                    </FormItem>
                    <FormItem label={t('sftp_folder_name')} name="sFTPFolderName">
                        <Input disabled={isReadOnly} type="text" aria-label="sFTP Folder Name input" />
                    </FormItem>
                </Box>
                <Box>
                    <FormItem
                        name="enabled"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        label={tCommon('enabled')}
                        valuePropName="checked"
                    >
                        <Switch checkedChildren={tCommon('enabled')} unCheckedChildren={tCommon('disabled')} />
                    </FormItem>
                    <FormItem
                        label={t('type')}
                        initialValue={WorkflowType.BASIC}
                        name="type"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Radio.Group
                            disabled={isReadOnly}
                            options={typeOptions.filter((typeOption) =>
                                availableWorkflowTypes.includes(typeOption.value),
                            )}
                        />
                    </FormItem>
                    <FormItem
                        label={t('trigger')}
                        name={['trigger', 'type']}
                        initialValue={WorkflowTriggerType.FILE_DROP}
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.trigger'),
                            },
                        ]}
                    >
                        <Radio.Group disabled={isReadOnly} onChange={onTriggerChange} options={triggerOptions} />
                    </FormItem>
                    {triggerType === WorkflowTriggerType.SCHEDULED && (
                        <TriggerFormItem
                            name="scheduleTime"
                            label={t('schedule_time')}
                            rules={[
                                {
                                    required: true,
                                    message: tValidation('required.trigger'),
                                },
                            ]}
                        >
                            <DatePicker disabled={isReadOnly} picker="time" format={'HH:mm'} />
                        </TriggerFormItem>
                    )}
                    {triggerType === WorkflowTriggerType.FILE_DROP && (
                        <TriggerFormItem
                            name={['trigger', 'secondsToWait']}
                            label={t('buffer_sec')}
                            initialValue={0}
                            rules={[
                                {
                                    required: true,
                                    pattern: new RegExp(/\d+/g),
                                    message: tValidation('required.seconds_to_wait'),
                                },
                            ]}
                        >
                            <InputNumber disabled={isReadOnly} aria-label="buffer time input" />
                        </TriggerFormItem>
                    )}
                </Box>
                <Descriptions.Item label={tCommon('email')}>
                    <Space align="start">
                        <IconButton size="small" icon={<CopyOutlined />} onClick={copyToClipBoard} />
                        <EmailLink target="_blank" href={`mailto: ${email}`}>
                            {email}
                        </EmailLink>
                    </Space>
                </Descriptions.Item>
            </Container>
        </Form>
    );
};

export default GeneralConfiguration;
