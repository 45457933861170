import { DeleteOutlined, FileTextOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Colors } from '@eservices/ui-constants/colors';
import { message, Upload as AntUpload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useGetFileUrl, useGetUploadUrl, usePutFile } from '../hooks/apiHooks';
import { useTranslation } from '../hooks/translationHook';
import { IconButton } from './Buttons';

const vectorFormats = ['.ai', '.eps'];

const PreviewContainer = styled.div`
    width: 100%;
    position: relative;

    .ant-upload {
        margin: 0 auto;
        display: block;
    }

    .ant-upload.ant-upload-select-picture-card {
        min-width: 104px;
        min-height: 104px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        opacity: 0;
    }

    &:hover button {
        opacity: 1;
    }

    &:hover img {
        opacity: 0.5;
    }

    &:hover .anticon-file-text {
        opacity: 0.5;
    }
`;

const UploadIcon = styled.div`
    position: absolute;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FilePreview = styled(FileTextOutlined)`
    font-size: 40px;
`;

const FileImagePreview = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

interface CustomUploadProps {
    accept: string;
    disabled: boolean;
    companyId: string;
    previewUrl?: string;
    isImageUpload: boolean;
    hasFile?: boolean;
    onFilenameChange?: (filename: string) => void;
    onDownloadUrlChange?: (url: string) => void;
    onOriginalFilenameChange?: (filename: string) => void;
    onIsUploading?: (flag: boolean) => void;
}

const Upload: React.FC<CustomUploadProps> = ({
    accept,
    disabled,
    companyId,
    isImageUpload,
    hasFile,
    previewUrl,
    onFilenameChange = () => ({}),
    onDownloadUrlChange = (url) => ({}),
    onOriginalFilenameChange = () => ({}),
    onIsUploading = () => ({}),
}) => {
    const { t, tCommon } = useTranslation('upload');
    const [isUploading, setIsUploading] = useState(false);
    const [filename, setFilename] = useState<string>(undefined);
    const [scopedPreviewUrl, setScopedPreviewUrl] = useState<string>(previewUrl);
    const [scopedHasFile, setScopedHasFile] = useState<boolean>(hasFile);
    const [file, setFile] = useState<RcFile>(undefined);

    const getUploadUrlMutation = useGetUploadUrl(companyId);
    const uploadMutation = usePutFile();
    const getDownloadUrlMutation = useGetFileUrl(companyId);

    useEffect(() => {
        if (!hasFile) {
            clearUpload();
        }
    }, [hasFile]);

    useEffect(() => {
        setScopedPreviewUrl(previewUrl);
    }, [previewUrl]);

    const updateFilename = (filename: string) => {
        if (filename) {
            onFilenameChange(filename);
            setFilename(filename);
        }
    };

    const updateDownloadUrl = (filename: string) => {
        onDownloadUrlChange(filename);
        setScopedPreviewUrl(filename);
    };

    useEffect(() => {
        if (getUploadUrlMutation.data) {
            const { filename, url } = getUploadUrlMutation.data;
            updateFilename(filename);
            uploadMutation.mutate({ url: url, file: file });
        }
    }, [getUploadUrlMutation.data]);

    useEffect(() => {
        if (uploadMutation.isSuccess) {
            getDownloadUrlMutation.mutate(filename);
            setScopedHasFile(true);
        } else {
            setIsUploading(false);
            onIsUploading(false);
        }
    }, [uploadMutation.isSuccess]);

    useEffect(() => {
        if (getDownloadUrlMutation.data) {
            updateDownloadUrl(getDownloadUrlMutation.data);
            setIsUploading(false);
            onIsUploading(false);
        }
    }, [getDownloadUrlMutation.data]);

    useEffect(() => {
        if (getUploadUrlMutation.isError || uploadMutation.isError || getDownloadUrlMutation.isError) {
            setIsUploading(false);
            onIsUploading(false);
            message.error(t('upload_failed'), 5);
        }
    }, [getUploadUrlMutation.isError, uploadMutation.isError, getDownloadUrlMutation.isError]);

    const beforeLogoUpload = async (file: RcFile) => {
        setIsUploading(true);
        onIsUploading(true);
        setFile(file);

        updateFilename(undefined);
        onOriginalFilenameChange(file.name);

        getUploadUrlMutation.mutate(file.name);

        return false;
    };

    const clearUpload = () => {
        setFile(undefined);
        setScopedPreviewUrl(undefined);
        updateFilename(undefined);
        onDownloadUrlChange(undefined);
        onOriginalFilenameChange(undefined);
        setScopedHasFile(false);
    };

    let preview: React.ReactNode;

    if (
        !isUploading &&
        scopedPreviewUrl &&
        !vectorFormats.some((format) => scopedPreviewUrl.includes(format)) &&
        isImageUpload
    ) {
        preview = <FileImagePreview src={scopedPreviewUrl} alt="file" />;
    }

    if (
        (!isUploading && hasFile) ||
        (scopedHasFile && !isImageUpload) ||
        (scopedPreviewUrl && vectorFormats.some((format) => scopedPreviewUrl.includes(format)) && isImageUpload)
    ) {
        preview = <FilePreview />;
    }

    if (!scopedHasFile && !scopedPreviewUrl) {
        preview = (
            <UploadIcon>
                {isUploading ? <LoadingOutlined /> : <UploadOutlined />}
                <div>{tCommon('upload')}</div>
            </UploadIcon>
        );
    }

    return (
        <PreviewContainer>
            <AntUpload
                accept={accept}
                disabled={disabled}
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeLogoUpload}
            >
                {preview}
            </AntUpload>
            {(scopedPreviewUrl || hasFile) && !isUploading && !disabled && (
                <IconButton icon={<DeleteOutlined />} color={Colors.ALERT} onClick={clearUpload} />
            )}
        </PreviewContainer>
    );
};
export default Upload;
