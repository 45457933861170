import { PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { PrimaryButton } from '../../components/Buttons';
import { Routes } from '../../constants';
import { useCloneWorkflow } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import WorkflowsOverviewFetching from './WorkflowsOverviewFetching';

const loadingMessageKey = 'loadingMessageKey';

interface WorkflowsOverviewEditProps {
    companyId: string;
    canCreateWorkflow: boolean;
}

const WorkflowsOverviewEdit: React.FC<WorkflowsOverviewEditProps> = ({ companyId, canCreateWorkflow }) => {
    const { t } = useTranslation('workflow_overview');
    const history = useHistory();

    const mutation = useCloneWorkflow(companyId);

    const cloneWorkflow = (departmentId: string, workflowId: string, targetDepartmentId: string) => {
        mutation.mutate({ departmentId, workflowId, targetDepartmentId });
    };

    useEffect(() => {
        if (mutation.isLoading) {
            message.loading({ key: loadingMessageKey, content: t('cloning_new_workflow_loading') }, 0);
        } else {
            message.destroy(loadingMessageKey);
        }
    }, [mutation.isLoading]);

    useEffect(() => {
        if (mutation.isSuccess) {
            const newWorkflow = mutation.data;
            history.push(
                Routes.EDIT_WORKFLOW.replace(':departmentId', newWorkflow.departmentId).replace(':id', newWorkflow.id),
            );
            message.success(t('cloning_new_workflow_success'), 3);
        }
    }, [mutation.isSuccess]);

    useEffect(() => {
        if (mutation.isError) {
            message.error(t('cloning_new_workflow_error'), 3);
        }
    }, [mutation.isError]);

    const actions = canCreateWorkflow && (
        <PrimaryButton
            icon={<PlusOutlined />}
            aria-label="create workflow link"
            onClick={() => history.push(Routes.CREATE_WORKFLOW)}
        >
            {t('action_add')}
        </PrimaryButton>
    );

    return (
        <WorkflowsOverviewFetching
            isReadOnly={false}
            companyId={companyId}
            actions={actions}
            isCloning={mutation.isLoading}
            cloneWorkflow={cloneWorkflow}
        />
    );
};

export default WorkflowsOverviewEdit;
