import { Document, Page } from 'react-pdf';
import styled from 'styled-components';

interface CanvasContainerProps {
    width: number;
    height: number;
}

const PDFContainer = styled.div`
    overflow: auto;
    height: 70vh;
    width: 100%;
`;

const CanvasContainer = styled.div<CanvasContainerProps>`
    position: relative;
    canvas {
        position: absolute;
    }
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.06);

    .react-pdf__Document {
        width: ${(props) => props.width}px;
        height: 0;
    }
`;

const BackgroundCanvas = styled.canvas`
    background-color: #f6f6f633;
`;

interface PDFOnePageProps {
    canvasWidth: number;
    canvasHeight: number;
    pdfUrl: string;
    onDocumentLoadSuccess: ({ numPages }: { numPages: any }) => void;
    scale: number;
    pageNumber: number;
    canvasPDF: (node: any) => void;
    onRenderSuccess: () => void;
    onPageLoadSuccess: ({ height, width }: { height: any; width: any }) => void;
    children;
}

const PDFOnePage: React.FC<PDFOnePageProps> = ({
    canvasWidth,
    canvasHeight,
    pdfUrl,
    onDocumentLoadSuccess,
    scale,
    pageNumber,
    canvasPDF,
    onRenderSuccess,
    onPageLoadSuccess,
    children,
}) => {
    return (
        <PDFContainer>
            <CanvasContainer width={canvasWidth} height={canvasHeight}>
                <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page
                        scale={scale}
                        pageNumber={pageNumber}
                        canvasRef={canvasPDF}
                        renderTextLayer={false}
                        onRenderSuccess={onRenderSuccess}
                        onLoadSuccess={onPageLoadSuccess}
                    ></Page>
                </Document>
                <BackgroundCanvas id="background_canvas" width={canvasWidth} height={canvasHeight} />
                {children}
            </CanvasContainer>
        </PDFContainer>
    );
};
export default PDFOnePage;
