import { arrayMove } from '@dnd-kit/sortable';
import { API } from '@eservices/shared/type';

export type TasksActions =
    | { type: 'insert'; payload: API.TaskConfiguration }
    | { type: 'insertList'; payload: API.TaskConfiguration[] }
    | { type: 'update'; payload: { index: number; task: API.TaskConfiguration } }
    | { type: 'move'; payload: { currentIndex: number; nextIndex: number } }
    | { type: 'delete'; payload: number }
    | {
          type: 'empty';
      };

interface TasksState {
    tasks?: Array<API.TaskConfiguration>;
}

export function taskReducer(state: TasksState, action: TasksActions): TasksState {
    switch (action.type) {
        case 'insert': {
            return { tasks: [...state.tasks, action.payload] };
        }
        case 'insertList': {
            return { tasks: [...state.tasks, ...action.payload] };
        }
        case 'update': {
            const updatedTasks = [...state.tasks];
            updatedTasks[action.payload.index] = action.payload.task;
            return { tasks: updatedTasks };
        }
        case 'move': {
            const movedTasks = arrayMove(state.tasks, action.payload.currentIndex, action.payload.nextIndex);
            return { tasks: movedTasks };
        }
        case 'delete': {
            const { tasks } = state;
            const removeElement = action.payload;
            return { tasks: [...tasks.slice(0, removeElement), ...tasks.slice(removeElement + 1)] };
        }
        case 'empty': {
            return { tasks: [] };
        }
        default:
            throw new Error();
    }
}
