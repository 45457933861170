import { BookOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { API, PhysicalAddress } from '@eservices/shared/type';
import { Colors } from '@eservices/ui-constants/colors';
import { message, Modal, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useHistory, useParams } from 'react-router-dom';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';
import { Routes } from '../../constants';
import { useGetDepartment, useUpdateDepartment } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import { translateFieldErrors } from '../../utils/helpers';
import DepartmentDetailFetching from './DepartmentDetailFetching';

function addressDiff(addressA: PhysicalAddress, addressB: PhysicalAddress): boolean {
    return (
        addressA.street !== addressB.street ||
        addressA.streetNr !== addressB.streetNr ||
        addressA.postalCode !== addressB.postalCode ||
        addressA.countryCode !== addressB.countryCode ||
        addressA.city !== addressB.city
    );
}

const loadingMessageKey = 'loadingMessageKey';

interface DepartmentDetailEditProps {
    companyId: string;
    isDetailsReadOnly: boolean;
    isUsersReadOnly: boolean;
}

const DepartmentDetailEdit: React.FC<DepartmentDetailEditProps> = ({
    companyId,
    isDetailsReadOnly,
    isUsersReadOnly,
}) => {
    const history = useHistory();
    const { t, tError, tCommon } = useTranslation('department');
    const [form] = useForm();
    const [oldUsers, setOldUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [errors, setErrors] = useState([]);
    const { id: departmentId }: { id: string } = useParams();
    const query = useGetDepartment(companyId, departmentId);
    const mutation = useUpdateDepartment(companyId, departmentId);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        if (query.data) {
            setOldUsers(query.data.users);
        }
    }, [query.data]);

    useEffect(() => {
        if (mutation.isLoading) {
            setErrors([]);
            message.loading({ key: loadingMessageKey, content: t('editing_department_loading') }, 0);
        } else {
            message.destroy(loadingMessageKey);
        }
    }, [mutation.isLoading]);

    useEffect(() => {
        if (mutation.isSuccess) {
            history.push(Routes.DEPARTMENTS);
            message.success(t('editing_department_success', { name: form.getFieldValue('name') }), 3);
        }
    }, [mutation.isSuccess]);

    useEffect(() => {
        if (mutation.error) {
            if (mutation.error.response?.data?.fieldErrors) {
                form.setFields(translateFieldErrors(tError, mutation.error.response.data.fieldErrors));
            }

            const error = mutation.error.response?.data?.error;
            if (error) {
                setErrors([error]);
            }

            message.error(t('editing_department_error'), 3);
        }
    }, [mutation.isError]);

    const onBackClick = () => {
        history.goBack();
    };

    const onClickSaveDepartment = async () => {
        const { headAddress } = query.data;
        const { countryCode, city, street, postalCode, streetNr } = await form.validateFields();

        if (addressDiff({ city, street, streetNr, postalCode, countryCode }, headAddress)) {
            setIsModalVisible(true);
        } else {
            saveDepartment();
        }
    };

    const saveDepartment = async () => {
        const { id, createdAt, lastModifiedAt, lastModifiedBy, companyId } = query.data;
        const { countryCode, city, street, postalCode, streetNr, ...otherFormValues } = await form.validateFields();

        const editedDepartment: API.EditDepartment = {
            id,
            createdAt,
            lastModifiedAt,
            lastModifiedBy,
            companyId,
            ...otherFormValues,
            headAddress: { countryCode, city, street, postalCode, streetNr },
            usersToAdd: users.filter((user) => !oldUsers.includes(user)).map(({ id }) => id),
            usersToDelete: oldUsers.filter((user) => !users.includes(user)).map(({ id }) => id),
        };
        mutation.mutate(editedDepartment);
    };

    const handleOk = () => {
        saveDepartment();
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const actions = [
        <PrimaryButton
            icon={<BookOutlined />}
            aria-label="save button"
            disabled={query.isLoading || mutation.isLoading}
            htmlType="submit"
            onClick={onClickSaveDepartment}
        >
            {tCommon('save')}
        </PrimaryButton>,
    ];

    return (
        <>
            <DepartmentDetailFetching
                isUsersReadonly={isUsersReadOnly}
                isDetailsReadOnly={isDetailsReadOnly}
                companyId={companyId}
                query={query}
                form={form}
                users={users}
                setUsers={setUsers}
                errors={errors}
                actions={actions}
                onBack={onBackClick}
            />
            <Modal
                title={
                    <Space>
                        <ExclamationCircleOutlined color={Colors.ALERT} />
                        {t('warning_title')}
                    </Space>
                }
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={tCommon('yes')}
                cancelText={tCommon('no')}
            >
                <ReactMarkdown>{t('warning_detail')}</ReactMarkdown>
            </Modal>
        </>
    );
};

export default DepartmentDetailEdit;
