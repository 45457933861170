import { Search } from '.';
import { ItemsWithPagination, Pagination, Range } from './utils';

import {
    ApprovalStatus,
    EmailTaskStatus,
    ERDSTaskStatus,
    ErrorCode,
    JobStatus,
    OrderStatus,
    ParcelStatus,
    PrintAttachmentPosition,
    PrintTaskStatus,
    QERDSTaskStatus,
    SendingEventBusinessStatus,
    TaskType,
    WorkflowInputSourceType,
    WorkflowTriggerType,
} from '../constant';
import * as Domain from './domain-model';
import * as Generic from './generic';
import * as Lambda from './lambda-model';

// API Specific constants and enums

export enum JobActionType {
    RESEND = 'RESEND',
    CANCEL = 'CANCEL',
    APPROVE = 'APPROVE',
    REJECT = 'REJECT',
}

export enum FileSource {
    JOB = 'JOB',
    SENDING_EVENT = 'SENDING_EVENT',
    FILE_UPLOAD = 'FILE_UPLOAD',
    REGISTERED_MAIL = 'REGISTERED_MAIL',
    PARCEL = 'PARCEL',
    POLICY = 'POLICY',
}

// API Specific types

export type SimpleResponse = {
    message: string;
};

export interface FileDownloadLinkRequest {
    location: string;
    source: FileSource;
}
export interface FileUploadLink {
    filename: string;
    url: string; // Signed URL to upload to s3 bucket (tmp/)
}
export interface ConfigurationFile {
    filename: string;
    url?: string; // Signed URL to s3 bucket
}

export interface StatusDate<T> {
    value: T;
    date?: string;
}

// Error models
export interface BusinessErrorResponse {
    error?: ErrorCode;
    fieldErrors?: Array<FormFieldError>;
}

export interface FormFieldError {
    name: string;
    errors: Array<ErrorCode>;
}

// Elastic Search models

type OmitCreate<T> = Omit<
    T,
    'companyId' | 'departmentId' | 'id' | 'createdAt' | 'lastModifiedAt' | 'lastModifiedBy' | 'isActive'
>;
type NonEditableFields = 'companyId' | 'departmentId' | 'id' | 'lastModifiedBy' | 'createdAt' | 'modelVersion';
type OmitEdit<T> = Omit<T, NonEditableFields>;

//TODO replace reference with the ItemsWithPagination in utils
type List<T> = {
    items: Array<T>;

    pagination: {
        page: number;
        pageItems: number;
        totalItems: number;
    };
};

// User models
export type CreateUser = Pick<
    Domain.User,
    | 'departments'
    | 'role'
    | 'firstName'
    | 'lastName'
    | 'preferredLanguage'
    | 'modulesAccess'
    | 'hasQERDSMandate'
    | 'username'
    | 'emailAddress'
>;
export type User = Domain.User & { enabled?: boolean };
export type EditUser = Pick<
    Domain.User,
    | 'departments'
    | 'role'
    | 'firstName'
    | 'lastName'
    | 'hasQERDSMandate'
    | 'preferredLanguage'
    | 'lastModifiedAt'
    | 'modulesAccess'
> & { enabled: boolean };
export type UpdateUserProfile = Pick<Domain.User, 'firstName' | 'lastName' | 'preferredLanguage' | 'lastModifiedAt'>;

// Company models
export type CreateCompany = OmitCreate<Domain.Company>;
export type EditCompany = OmitEdit<Domain.Company>;
export type CompanyListItem = Pick<Domain.Company, 'id' | 'name' | 'erpId' | 'isActive'>;
export type Company = Domain.Company;
export type CompanySubscriptionDetails = Domain.SubscriptionDetails;

// Department models
export type CreateDepartment = OmitCreate<Domain.Department> & { users: Array<string> };
export type EditDepartment = OmitEdit<Domain.Department> & { usersToDelete: Array<string>; usersToAdd: Array<string> };
export type Department = Domain.Department & { users: Array<User> };
export type DepartmentListItem = Pick<
    Domain.Department,
    'id' | 'companyId' | 'erpId' | 'name' | 'consumptionAnnouncementType' | 'isActive'
> & {
    amountOfUsers: number;
};

// Task configurations

export type Paper = Omit<Domain.WorkflowResourcePaper, 'logo' | 'supplierExample'> & {
    logo?: ConfigurationFile;
    supplierExample?: ConfigurationFile;
};
export type Envelope = Omit<Domain.WorkflowResourceEnvelope, 'logo' | 'supplierExample'> & {
    logo?: ConfigurationFile;
    supplierExample?: ConfigurationFile;
};

export interface BaseTaskParams {
    id?: string;
    modelVersion?: string;
}

export type TaskEasyPrint = Omit<Domain.TaskEasyPrint, 'id' | 'modelVersion' | 'resources' | 'attachment'> &
    BaseTaskParams & {
        resources: { paper: Paper; envelope: Envelope };
        attachment?: PrintAttachment;
    };

export interface PrintAttachment extends ConfigurationFile {
    position: PrintAttachmentPosition;
}

export type TaskERDS = Omit<
    Domain.TaskERDS,
    'id' | 'modelVersion' | 'attachment' | 'bodyTemplate' | 'bodyJsonTemplate'
> &
    BaseTaskParams & {
        bodyTemplate: ConfigurationFile;
        bodyJsonTemplate: ConfigurationFile;
        attachment?: ConfigurationFile;
    };

export type TaskQERDS = Omit<Domain.TaskQERDS, 'id' | 'modelVersion' | 'attachment'> &
    BaseTaskParams & {
        attachment?: ConfigurationFile;
    };

export type TaskEmail = Omit<
    Domain.TaskEmail,
    'id' | 'modelVersion' | 'attachment' | 'bodyTemplate' | 'bodyJsonTemplate'
> &
    BaseTaskParams & {
        bodyTemplate: ConfigurationFile;
        bodyJsonTemplate: ConfigurationFile;
        attachment?: ConfigurationFile;
    };

export type TaskConfiguration = TaskEasyPrint | TaskEmail | TaskERDS | TaskQERDS;

export type TaskConfigurationListItem = Pick<Domain.TaskConfiguration, 'id' | 'name' | 'taskType' | 'validationStatus'>;

/* Workflow configuration */

export type Watermark = Omit<Domain.Watermark, 'image'> & {
    image: ConfigurationFile;
};

export type WorkflowConfiguration = Omit<
    Domain.WorkflowConfiguration,
    NonEditableFields | 'tasks' | 'version' | 'lastModifiedAt' | 'watermark'
> & {
    companyId?: string;
    departmentId?: string;
    id?: string;
    modelVersion?: string;
    lastModifiedAt?: string;
    lastModifiedBy?: string;
    version?: number;
    createdAt?: string;
    tasks: Array<TaskConfiguration>;
    watermark?: Watermark;
};

export type WorkflowInputSource = Domain.WorkflowInputSource;
export type WorkflowPDFInputSource = Domain.WorkflowPDFInputSource;
export type WorkflowCSVInputSource = Domain.WorkflowCSVInputSource;
export type WorkflowExcelInputSource = Domain.WorkflowExcelInputSource;
export type PDFChannelIdentifier = Domain.PDFChannelIdentifier;
export type PDFSplitIdentifier = Domain.PDFSplitIdentifier;
export type PDFExtraInfoIdentifier = Domain.PDFExtraInfoIdentifier;
export type PDFIgnoreIdentifier = Domain.PDFIgnoreIdentifier;

// List models
export type WorkflowConfigurationListItem = Pick<
    Domain.WorkflowConfiguration,
    'id' | 'departmentId' | 'description' | 'name' | 'createdAt' | 'lastModifiedAt' | 'type' | 'validated' | 'enabled'
> & {
    tasks: Array<TaskConfigurationListItem>;
    watermark?: Watermark;
    trigger: WorkflowTriggerType;
    inputSource: WorkflowInputSourceType;
};

/* Job */
export interface ESJobFilter extends Pagination {
    name?: string;
    workflowId?: string;
    status?: JobStatus;
    approvalStatus?: ApprovalStatus;
    containWarnings?: boolean;
    from?: string;
    until?: string;
    departmentId?: string;
    departments?: string[];
}

export type JobListItem = Pick<
    Search.Job,
    | 'id'
    | 'workflowId'
    | 'name'
    | 'originalFilename'
    | 'location'
    | 'sendingEvents'
    | 'status'
    | 'approval'
    | 'startDate'
    | 'departmentId'
    | 'executionDate'
    | 'isResend'
    | 'author'
    | 'preprocessingExecutionId'
    | 'orchestrationExecutionId'
>;

export type JobList = List<JobListItem>;
export interface SubmitJob {
    jobName: string;
    filename: string;
    originalFilename: string;
}

export interface JobActionRequest {
    actionType: JobActionType;
}

/* Sending Event */
export type BaseTaskDetails = Pick<Domain.BaseTaskDetails, 'extraInfo'>;
export type EmailTaskDetails = Omit<Domain.EmailTaskDetails, 'statusHistory'> & {
    statusHistory: Array<StatusDate<EmailTaskStatus>>;
};
export type ERDSTaskDetails = Omit<Domain.ERDSTaskDetails, 'statusHistory'> & {
    statusHistory: Array<StatusDate<ERDSTaskStatus>>;
};
export type QERDSTaskDetails = Omit<Domain.QERDSTaskDetails, 'statusHistory'> & {
    statusHistory: Array<StatusDate<QERDSTaskStatus>>;
};
export type PrintTaskDetails = Omit<Domain.PrintTaskDetails, 'statusHistory'> & {
    statusHistory: Array<StatusDate<PrintTaskStatus>>;
};

export type CustomError = Domain.CustomError;

export type TaskDetails = EmailTaskDetails | PrintTaskDetails | ERDSTaskDetails | QERDSTaskDetails;
export interface SendingEventFilter {
    jobId?: string;
    jobName?: string;
    workflowId?: string;
    containWarnings?: boolean;
    businessStatus?: SendingEventBusinessStatus;
    departmentId?: string;
    search?: string; // Term designed for task details > recipient
    pagination: Pagination;
    range: Range;
}

export type SendingEvent = Omit<Domain.SendingEvent, 'config' | 'taskDetails'> & {
    config: WorkflowConfiguration;
    taskDetails: Array<TaskDetails>;
};

export interface RecipientInfo {
    value: string;
    taskId: string;
    taskType: TaskType;
    order: number;
}

export type ExtraInfo = Domain.ExtraInfo;

export interface SendingEventListItem {
    id: string;
    workflowId: string;
    jobId: string;
    jobName: string;
    businessStatus: SendingEventBusinessStatus;
    startDate: string;
    departmentId: string;
    recipientInfo: Array<RecipientInfo>;
    extraInfo: Array<ExtraInfo>;
}
export type SendingEventList = List<SendingEventListItem>;

/* Products */
export type ProductListItem = Domain.Product;
export type ProductList = Domain.Product[];

/* Order */
export type Order = Omit<
    Domain.Order,
    'id' | 'author' | 'createdAt' | 'lastModifiedAt' | 'lastModifiedBy' | 'status'
> & {
    id?: string;
    author?: string;
    createdAt?: string;
    lastModifiedAt?: string;
    lastModifiedBy?: string;
    status?: OrderStatus;
};
export type OrderItem = Domain.OrderItem;

/* Registered Mail */

export type RegisteredMail = Domain.RegisteredMail;
export type RegisteredMailList = List<RegisteredMail>;

export interface RegisteredMailFilter {
    registeredMailId?: string;
    departmentId?: string;
    barCode?: string;
    fromScannedAt?: string;
    untilScannedAt?: string;
    fromBpostStampDate?: string;
    untilBpostStampDate?: string;
    pagination: Pagination;
}

/* Parcels */
export type Parcel = Domain.Parcel;
export type ParcelListItem = Pick<
    Domain.Parcel,
    'id' | 'parcelGroupId' | 'name' | 'bpostOptions' | 'recipient' | 'departmentId' | 'createdAt' | 'errors' | 'barCode'
> & {
    statusHistory: Array<StatusDate<ParcelStatus>>;
};
export type ParcelList = ItemsWithPagination<ParcelListItem>;
export type ParcelSender = Domain.ParcelSender;
export type ParcelRecipient = Domain.ParcelRecipient;
export type ParcelBpostOptions = Domain.ParcelBpostOptions;
export type ParcelCustomsContentDetail = Domain.ParcelCustomsContentDetail;
export type RecipientWithParcelInfo = Lambda.RecipientWithParcelInfo;
export type ParcelContactListItem = Pick<
    Domain.ParcelContact,
    'id' | 'companyName' | 'name' | 'address' | 'lastUsedAt'
>;
export type ParcelContactList = ItemsWithPagination<ParcelContactListItem>;

export interface ParcelFilter {
    search: string;
    departmentId?: string;
    range: Range;
    pagination: Pagination;
}

export interface ParcelContactsFilter {
    search: string;
    departmentId?: string;
    pagination: Pagination;
    sortByLastUsed?: boolean;
}

export type BpostCountries = Generic.BpostCountries;

export interface ParcelRequests {
    sender: Domain.ParcelSender;
    fileName: string;
}
export type CreateBasicDailyConsumptionAnnouncement = Omit<
    Domain.BasicDailyConsumptionAnnouncement,
    'ttl' | 'companyId' | 'departmentId' | 'announcementDate'
>;
export type CreateAdvancedDailyConsumptionAnnouncement = Omit<
    Domain.AdvancedDailyConsumptionAnnouncement,
    'ttl' | 'companyId' | 'departmentId' | 'announcementDate'
>;

export type CreateDailyConsumptionAnnouncement =
    | CreateBasicDailyConsumptionAnnouncement
    | CreateAdvancedDailyConsumptionAnnouncement;
export type BasicDailyConsumptionAnnouncement = Omit<Domain.BasicDailyConsumptionAnnouncement, 'ttl'>;
export type AdvancedDailyConsumptionAnnouncement = Omit<Domain.AdvancedDailyConsumptionAnnouncement, 'ttl'>;
export type DailyConsumptionAnnouncement = BasicDailyConsumptionAnnouncement | AdvancedDailyConsumptionAnnouncement;
export type MonthDailyConsumptionAnnouncement = {
    dailyAnnouncements: DailyConsumptionAnnouncement[];
};
export type AdvancedDailyConsumptionProductAndQuantity = Domain.AdvancedDailyConsumptionProductAndQuantity;
export type PostalProductCatalog = Domain.PostalProductsCatalog;

export type EmailDesign = {
    body: object;
    counters: object;
    schemaVersion: number;
};

export type EmailTemplate = {
    id: string;
    name: string;
    design: EmailDesign;
};

export type EmailTemplatePicturePreview = {
    url: string;
};

export interface RegisterQERDSApiKey {
    value: string;
}

export type QERDSApiKeyDetails = Pick<Domain.QERDSApiKey, 'createdAt' | 'status' | 'lastModifiedAt' | 'lastModifiedBy'>;

export interface DashboardFilter {
    departmentId?: string;
    range: Range;
}

export interface DashboardOperatorJobsDetails {
    OK: number;
    PROCESSING: number;
    ERROR: number;
    ACTION_REQUIRED: number;
    WARNING: number;
}

export interface DashboardOperatorSendingsDetails {
    SUCCESS: number;
    IN_PROGRESS: number;
    FAILURE: number;
    WARNING: number;
}

export interface DashboardOperatorDetails {
    jobs: DashboardOperatorJobsDetails;
    sendings: DashboardOperatorSendingsDetails;
}

export interface SubmitPolicy {
    filename: string;
    version: string;
}

export type Policy = Domain.Policy;
export type PoliciesInventory = Domain.PoliciesInventory;
