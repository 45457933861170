import { API } from '@eservices/shared/type';
import { Avatar as AntAvatar, Tooltip } from 'antd';
import { Colors } from '@eservices/ui-constants/colors';
import { generateInitials } from '../utils/helpers';
import styled from 'styled-components';

interface AvatarProps {
    color?: string;
    opacity?: string;
}
const Avatar = styled(AntAvatar)<AvatarProps>`
    opacity: ${(props) => props.opacity};
    color: ${(props) => props.color};
    background-color: ${Colors.WHITE};
    border: 1px solid ${Colors.GRAY_QUATERNARY};

    &:hover {
        opacity: 100%;
    }
`;

interface UserAvatarProps {
    user: API.User;
    opacity?: string;
    withTooltip?: boolean;
    src?: string;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ withTooltip, user: { role, firstName, lastName }, ...rest }) => {
    const avatar = (
        <Avatar size="default" color={Colors[role]} {...rest}>
            {generateInitials(`${firstName} ${lastName}`)}
        </Avatar>
    );

    if (withTooltip) return <Tooltip title={`${firstName} ${lastName}`}>{avatar}</Tooltip>;

    return avatar;
};
UserAvatar.defaultProps = {
    withTooltip: true,
};

export default UserAvatar;
