import { ExclamationCircleOutlined, WarningFilled } from '@ant-design/icons';
import { Space, Tabs } from 'antd';
import React from 'react';
import { Colors } from '@eservices/ui-constants/colors';
import { useTranslation } from '../hooks/translationHook';

const { TabPane } = Tabs;

interface DetailTabsProps {
    tabs: React.ReactNode[];
    warnings: string[];
    errors: string[];
}

const DetailTabs: React.FC<DetailTabsProps> = ({ tabs, warnings, errors }) => {
    const { tCommon } = useTranslation();

    return (
        <Tabs defaultActiveKey="1">
            {tabs}
            <TabPane
                tab={
                    <div>
                        {warnings.length > 0 && <WarningFilled style={{ color: Colors.WARNING }} />}
                        {tCommon('warningsWithCount', { count: warnings.length })}
                    </div>
                }
                key={tabs.length + 1}
            >
                <Space direction="vertical">
                    {warnings.map((warning, i) => (
                        <Space key={warning + i}>
                            <WarningFilled style={{ color: Colors.WARNING }} /> {warning}
                        </Space>
                    ))}
                </Space>
            </TabPane>
            <TabPane
                tab={
                    <div>
                        {errors.length > 0 && <ExclamationCircleOutlined style={{ color: Colors.ERROR }} />}
                        {tCommon('errorsWithCount', { count: errors.length })}
                    </div>
                }
                key={tabs.length + 2}
            >
                <Space direction="vertical">
                    {errors.map((error, i) => (
                        <Space key={error + i}>
                            <ExclamationCircleOutlined style={{ color: Colors.ERROR }} /> {error}
                        </Space>
                    ))}
                </Space>
            </TabPane>
        </Tabs>
    );
};

export default DetailTabs;
