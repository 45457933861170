import { Skeleton } from 'antd';
import { useGetSendingEvent } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import DetailDrawer from '../DetailDrawer';
import FailedToLoadData from '../FailedToLoadData';
import SendingEventsDetail from './SendingEventDetail';

interface SendingEventsDetailProps {
    companyId: string;
    departmentId: string;
    jobId: string;
    seId: string;
    handleOnClose: () => void;
}

const SendingEventDetailFetching: React.FC<SendingEventsDetailProps> = ({
    companyId,
    departmentId,
    jobId,
    seId,
    handleOnClose,
}) => {
    const { t } = useTranslation('se_detail');
    const { data: sendingEvent, isLoading, isError } = useGetSendingEvent(companyId, departmentId, jobId, seId);

    if (isLoading) return <DetailDrawer title={t('title')} onClose={handleOnClose} main={<Skeleton active />} />;
    if (isError) return <DetailDrawer title={t('title')} onClose={handleOnClose} main={<FailedToLoadData />} />;

    return <SendingEventsDetail companyId={companyId} sendingEvent={sendingEvent} handleOnClose={handleOnClose} />;
};

export default SendingEventDetailFetching;
