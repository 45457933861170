import { ParcelWithCustoms } from '@eservices/shared/type';
import { Col, List, Row } from 'antd';
import { useBpostCountries } from '../../hooks/bpostCountriesHook';
import { useTranslation } from '../../hooks/translationHook';
import styled from 'styled-components';
import DescriptionItem from '../DescriptionItem';
import { Header4 } from '../Headers';

const CustomsItems = styled.div`
    margin-top: 3%;
`;

interface ParcelDetailSubDetailCustomsProps {
    parcel: ParcelWithCustoms;
}

const ParcelDetailSubDetailCustoms: React.FC<ParcelDetailSubDetailCustomsProps> = ({ parcel }) => {
    const { t, tCommon } = useTranslation('parcel_detail');
    const { findCountryName } = useBpostCountries();

    const { customsDetails } = parcel;

    return (
        <div>
            <Row>
                <Col span={24}>
                    <DescriptionItem title={t('content_description')} content={customsDetails.contentDescription} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem
                        title={t('customs_shipmentType')}
                        content={t(customsDetails.shipmentType.toLocaleLowerCase())}
                    />
                </Col>
            </Row>
            <CustomsItems>
                <Header4>{tCommon('items')}</Header4>
                <List
                    itemLayout="horizontal"
                    dataSource={customsDetails.contents}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                title={<DescriptionItem title={t('item_description')} content={item.itemDescription} />}
                                description={
                                    <>
                                        <Row>
                                            <Col span={12}>
                                                <DescriptionItem
                                                    title={t('weight_in_grams')}
                                                    content={item.nettoWeightInGrams}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <DescriptionItem
                                                    title={t('number_of_items')}
                                                    content={item.numberOfItems}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>
                                                <DescriptionItem
                                                    title={t('origin_of_goods')}
                                                    content={findCountryName(item.originOfGoods)}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <DescriptionItem
                                                    title={t('hs_tariff_code')}
                                                    content={item.hsTariffCode}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                }
                            />
                        </List.Item>
                    )}
                />
            </CustomsItems>
        </div>
    );
};

export default ParcelDetailSubDetailCustoms;
