import { BarcodeOutlined } from '@ant-design/icons';
import { ApplicationModule, UserRole } from '@eservices/shared/constant';
import { Routes } from '../../constants';
import { BasicRoute, MainRoute } from '../../types';
import VignettesOverviewView from './VignettesOverviewView';

const viewVignettesOverviewPage = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.VIGNETTES,
    component: <VignettesOverviewView companyId={companyId} />,
});

export const createVignettesRouter = (userRole: UserRole, companyId: string): MainRoute => {
    const vignettesRoutes = {
        [UserRole.SUPER_USER]: [viewVignettesOverviewPage(companyId)],
        [UserRole.CLIENT_ADMIN]: [viewVignettesOverviewPage(companyId)],
        [UserRole.CLIENT_DEPARTMENT_ADMIN]: [viewVignettesOverviewPage(companyId)],
        [UserRole.CLIENT_OPERATOR]: [viewVignettesOverviewPage(companyId)],
        [UserRole.CLIENT_VIEWER]: [viewVignettesOverviewPage(companyId)],
    };

    return {
        path: Routes.VIGNETTES,
        title: 'vignettes',
        icon: <BarcodeOutlined />,
        subRoutes: vignettesRoutes[userRole],
        module: ApplicationModule.EASYPOST_PORTAL,
    };
};
