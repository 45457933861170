import { Colors } from '@eservices/ui-constants/colors';
import { Document, Page } from 'react-pdf';
import styled from 'styled-components';

const PDFContainer = styled.div`
    overflow: auto;
    height: 60vh;
    width: 100%;
    position: relative;
`;
const StyledDocument = styled(Document)`
    position: relative;
`;

const StyledPage = styled(Page)`
    border-top: 2px solid ${Colors.GRAY_QUATERNARY};
    border-bottom: 2px solid ${Colors.GRAY_QUATERNARY};
    box-sizing: border-box;
    &:first-of-type {
        border-top: 0px;
    }
    &:last-of-type {
        border-bottom: 0px;
    }
    .annotationLayer {
        display: none;
    }
`;

const BackgroundCanvas = styled.canvas`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #f6f6f633;
`;

interface PDFAllPagesProps {
    canvasWidth: number;
    canvasHeight: number;
    pageContainerRef: React.Ref<HTMLDivElement>;
    updatePageNumberOnScroll: () => void;
    pdfUrl: string;
    onDocumentLoadSuccess: ({ numPages }: { numPages: any }) => void;
    scale: number;
    numberPages: number;
    constructPagesRefs: (el: React.Ref<HTMLDivElement>, index: number) => void;
}

const PDFAllPages: React.FC<PDFAllPagesProps> = ({
    canvasWidth,
    canvasHeight,
    pageContainerRef,
    updatePageNumberOnScroll,
    pdfUrl,
    onDocumentLoadSuccess,
    scale,
    numberPages,
    constructPagesRefs,
}) => {
    return (
        <PDFContainer ref={pageContainerRef} onScroll={updatePageNumberOnScroll}>
            <StyledDocument file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numberPages), (_el, index) => (
                    <StyledPage
                        inputRef={(ref) => constructPagesRefs(ref, index)}
                        key={`page_${index + 1}`}
                        scale={scale}
                        pageNumber={index + 1}
                        renderTextLayer={false}
                    ></StyledPage>
                ))}
                <BackgroundCanvas id="background_canvas" width={canvasWidth} height={canvasHeight} />
            </StyledDocument>
        </PDFContainer>
    );
};
export default PDFAllPages;
