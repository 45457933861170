import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import React from 'react';
import { useTranslation } from '../../../hooks/translationHook';
import { SecondaryButton } from '../../Buttons';

interface TaskDangerousActionsProps {
    canBeDeleted: boolean;
    onDelete: () => void;
}

const TaskDangerousActions: React.FC<TaskDangerousActionsProps> = ({ canBeDeleted, onDelete }) => {
    const { t, tCommon } = useTranslation();

    return (
        <div>
            {canBeDeleted && (
                <Popconfirm
                    title={t('confirm_task_delete_question')}
                    onConfirm={onDelete}
                    okText={tCommon('yes')}
                    cancelText={tCommon('no')}
                >
                    <SecondaryButton shape="default" aria-label="delete task button" icon={<DeleteOutlined />} danger>
                        {t('delete_task')}
                    </SecondaryButton>
                </Popconfirm>
            )}
        </div>
    );
};

export default TaskDangerousActions;
