import { PlusCircleOutlined } from '@ant-design/icons';
import { PDFExtraInfoIdentifier } from '@eservices/shared/type';
import { Form, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../../../../hooks/translationHook';
import { SecondaryButton } from '../../../../Buttons';
import DataCollector from './DataCollector';

const AddNewDataCollector = styled(SecondaryButton)`
    margin: 20px 0;
`;

interface CollectedDataOverviewProps {
    isReadOnly: boolean;
    identifiers: PDFExtraInfoIdentifier[];
    onAddDataCollector: () => void;
    onUpdateExtraInfoIdentifier: (order: number, value: string) => void;
    onDeleteExtraInfoIdentifier: (order: number) => void;
}

const CollectedDataOverview: React.FC<CollectedDataOverviewProps> = ({
    isReadOnly,
    identifiers,
    onAddDataCollector,
    onUpdateExtraInfoIdentifier,
    onDeleteExtraInfoIdentifier,
}) => {
    const { t } = useTranslation('workflow');

    const fields = identifiers.map((identifier, index) => ({
        name: [index, 'name'],
        value: identifier.name,
    }));

    const onFormValuesChange = (changedValues: unknown) => {
        const order = Number(Object.keys(changedValues)[0]);
        const value = changedValues[order].name;
        onUpdateExtraInfoIdentifier(order, value);
    };

    return (
        <Space direction="vertical">
            <Form fields={fields} onValuesChange={onFormValuesChange}>
                <Space direction="vertical">
                    {identifiers.map((identifier, index) => (
                        <DataCollector
                            isReadOnly={isReadOnly}
                            identifier={identifier}
                            key={index}
                            order={index}
                            onDeleteExtraInfoIdentifier={onDeleteExtraInfoIdentifier}
                        />
                    ))}
                </Space>
            </Form>
            <AddNewDataCollector
                icon={<PlusCircleOutlined />}
                type="dashed"
                shape="default"
                onClick={onAddDataCollector}
                disabled={isReadOnly}
            >
                {t('add_new_data_collector')}
            </AddNewDataCollector>
        </Space>
    );
};

export default CollectedDataOverview;
