import { RecipientWithParcelInfo } from '@eservices/shared/type';
import { Space, Switch } from 'antd';
import { useState } from 'react';
import { useTranslation } from '../../hooks/translationHook';
import { RecipientsActions } from '../../screens/parcels/recipientReducer';
import styled from 'styled-components';
import AddContact from './AddContact';
import AddressBook from './AddressBook';
import RecipientsTable from './RecipientsTable';

const Actions = styled.div`
    margin: 0 0 2% 0;
    display: flex;
    justify-content: space-between;
    gap: 2%;
    align-items: center;
`;

interface ManualInputProps {
    companyId: string;
    recipients: Array<RecipientWithParcelInfo>;
    recipientsDispatch: React.Dispatch<RecipientsActions>;
}

const ManualInput: React.FC<ManualInputProps> = ({ companyId, recipients, recipientsDispatch }) => {
    const { t } = useTranslation('create_parcels');
    const [onlyShowErrors, setOnlyShowErrors] = useState(false);

    const recipientsWithKey = recipients.map((recipient, index) => ({ ...recipient, key: index }));
    const recipientsWithKeyHasError = recipientsWithKey.filter((recipient) => recipient.hasError);
    const hasRecipientsWithError = recipientsWithKeyHasError.length > 0;

    const addRecipient = (recipient: RecipientWithParcelInfo) => {
        recipientsDispatch({ type: 'insert', payload: recipient });
    };

    const addRecipientAtIndex = (index: number, recipient: RecipientWithParcelInfo) => {
        recipientsDispatch({ type: 'insertAtIndex', payload: { index, recipient } });
    };

    const addRecipients = (recipients: RecipientWithParcelInfo[]) => {
        recipientsDispatch({ type: 'insertList', payload: recipients });
    };

    const onEditRecipient = (index: number, recipient: RecipientWithParcelInfo) => {
        recipientsDispatch({
            type: 'update',
            payload: {
                index,
                recipient,
            },
        });
    };

    const onRemoveRecipient = (index: number) => {
        recipientsDispatch({
            type: 'delete',
            payload: index,
        });
    };

    return (
        <>
            <Actions>
                <div>
                    {hasRecipientsWithError && (
                        <Space>
                            {t('only_show_errors')}
                            <Switch size="small" onChange={() => setOnlyShowErrors(!onlyShowErrors)} />
                        </Space>
                    )}
                </div>
                <div>
                    <AddContact onSave={addRecipient} />
                    <AddressBook companyId={companyId} onSave={addRecipients} />
                </div>
            </Actions>
            <RecipientsTable
                recipients={onlyShowErrors ? recipientsWithKeyHasError : recipientsWithKey}
                onEditRecipient={onEditRecipient}
                onRemoveRecipient={onRemoveRecipient}
                addRecipientAtIndex={addRecipientAtIndex}
            />
        </>
    );
};

export default ManualInput;
