import { Colors } from '@eservices/ui-constants/colors';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { WatermarkUI } from '../../../../types';
import PDFViewer from '../../../PDFViewer';

const DrawingCanvas = styled.canvas<DrawingCanvasProps>`
    cursor: ${({ canDraw }) => canDraw && 'crosshair'};
`;
interface DrawingCanvasProps {
    canDraw: boolean;
}

const defaultStroke = Colors.PRIMARY;
const defaultFill = Colors.QUINARY_TRANSPARENT;

export interface Rectangle {
    x: number;
    y: number;
    width: number;
    height: number;
    highlighted: boolean;
    fill?: string;
    stroke?: string;
    lineDash?: Array<number>;
}

export interface RectangleBoundaries {
    maxWidth?: number;
    maxHeight?: number;
}

interface RectanglePDFEditorProps {
    pdfUrl: string;
    onRectangleDrawn: (rectangle: Rectangle) => void;
    boundaries?: RectangleBoundaries;
    items: Rectangle[];
    extra: React.ReactNode;
    watermark: WatermarkUI;
    opacity: number;
    canDraw: boolean;
}

const RectanglePDFEditor: React.FC<RectanglePDFEditorProps> = ({
    pdfUrl,
    onRectangleDrawn,
    boundaries = {},
    items,
    extra,
    watermark,
    opacity,
    canDraw,
}) => {
    const drawingCanvas = useRef<HTMLCanvasElement>(null);
    const layer0Canvas = useRef<HTMLCanvasElement>(null);
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [canvasWidth, setCanvasWidth] = useState(0);
    const [startX, setStartX] = useState<number>(null);
    const [startY, setStartY] = useState<number>(null);
    const [isDown, setIsDown] = useState<boolean>(null);
    const [scale, setScale] = useState<number>(null);

    useEffect(() => {
        if (watermark) {
            drawWatermark();
        } else {
            drawRectangles();
        }
    }, [items, opacity]);

    const drawRectangles = (h?: number) => {
        const height = h ?? canvasHeight;
        const context = layer0Canvas.current.getContext('2d');
        context.globalAlpha = opacity;
        context.clearRect(0, 0, canvasWidth, height);
        items.forEach((item) => {
            const { x, y, width, highlighted, fill = defaultFill, stroke = defaultStroke, lineDash = [] } = item;
            context.strokeStyle = stroke;
            context.fillStyle = highlighted ? fill.slice(0, -2) + '85' : fill;
            context.setLineDash(lineDash);
            context.fillRect(x * scale, height - y * scale, width * scale, item.height * scale);
            context.strokeRect(x * scale, height - y * scale, width * scale, item.height * scale);
        });
    };

    const drawWatermark = (h?: number) => {
        const height = h ?? canvasHeight;
        const context = layer0Canvas.current.getContext('2d');
        context.clearRect(0, 0, canvasWidth, height);
        context.globalAlpha = opacity;
        const dx = watermark.x * scale;
        const dy = height - (watermark.height + watermark.y) * scale;
        const dWidth = watermark.width * scale;
        const dHeight = watermark.height * scale;
        context.drawImage(watermark.imageElement, dx, dy, dWidth, dHeight);
    };

    function limitUnderMax(value: number, max?: number) {
        return max && Math.abs(value / scale) > max ? max * scale * Math.sign(value) : value;
    }

    function addRectangleOnMouseEvent(e: MouseEvent<HTMLCanvasElement>) {
        const offset = drawingCanvas.current.getBoundingClientRect();
        const context = drawingCanvas.current.getContext('2d');
        const mouseX = e.clientX - offset.left;
        const mouseY = e.clientY - offset.top;
        const width = limitUnderMax(mouseX - startX, boundaries.maxWidth);
        const height = limitUnderMax(mouseY - startY, boundaries.maxHeight);

        const newRectangle: Rectangle = {
            x: startX / scale,
            y: (canvasHeight - startY) / scale,
            width: width / scale,
            height: height / scale,
            highlighted: false,
        };

        context.clearRect(0, 0, canvasWidth, canvasHeight);
        onRectangleDrawn(newRectangle);
    }

    function handleMouseDown(e: MouseEvent<HTMLCanvasElement>) {
        e.preventDefault();
        e.stopPropagation();

        if (isDown) {
            setIsDown(false);
            addRectangleOnMouseEvent(e);
        } else {
            const offset = drawingCanvas.current.getBoundingClientRect();
            setStartX(e.clientX - offset.left);
            setStartY(e.clientY - offset.top);
            setIsDown(true);
        }
    }

    function handleMouseUp(e: MouseEvent<HTMLCanvasElement>) {
        e.preventDefault();
        e.stopPropagation();

        if (isDown) {
            setIsDown(false);
            addRectangleOnMouseEvent(e);
        }
    }

    function handleMouseMove(e: MouseEvent<HTMLCanvasElement>) {
        e.preventDefault();
        e.stopPropagation();

        if (!isDown || !canDraw) {
            return;
        }

        const context = drawingCanvas.current.getContext('2d');
        context.globalAlpha = opacity;

        const offset = drawingCanvas.current.getBoundingClientRect();

        context.clearRect(0, 0, canvasWidth, canvasHeight);

        const mouseX = e.clientX - offset.left;
        const mouseY = e.clientY - offset.top;
        const width = limitUnderMax(mouseX - startX, boundaries.maxWidth);
        const height = limitUnderMax(mouseY - startY, boundaries.maxHeight);

        context.strokeStyle = defaultStroke;
        context.fillStyle = defaultFill;

        context.fillRect(startX, startY, width, height);
        context.strokeRect(startX, startY, width, height);
        if (watermark) {
            context.drawImage(watermark.imageElement, startX, startY, width, height);
        }
    }

    const onSizeChange = (width: number, height: number) => {
        setCanvasWidth(width);
        setCanvasHeight(height);
        if (watermark) {
            drawWatermark(height);
        } else {
            drawRectangles(height);
        }
    };

    return (
        <PDFViewer pdfUrl={pdfUrl} onSizeChange={onSizeChange} onScaleChange={setScale} extra={extra}>
            <canvas ref={layer0Canvas} width={canvasWidth} height={canvasHeight} />
            <DrawingCanvas
                ref={drawingCanvas}
                width={canvasWidth}
                height={canvasHeight}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                canDraw={canDraw}
            />
        </PDFViewer>
    );
};

export default RectanglePDFEditor;
