import { StopOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { Alert, message, Popover } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';
import Card from '../../components/Card';
import QERDSAPIKeyDetails from '../../components/profile/QERDSAPIKeyDetails';
import QERDSAPIKeyForm from '../../components/profile/QERDSAPIKeyForm';
import { useRegisterQERDSAPIKey, useRevokeQERDSAPIKey } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';

interface QERDSApiKeyProps {
    companyId: string;
    user: API.User;
    qerdsAPIKeyDetails?: API.QERDSApiKeyDetails;
}

const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-flow: row wrap;
    gap: 10px;
`;

const messageLoadingKey = 'messageLoadingKey';

const QERDSSetup: React.FC<QERDSApiKeyProps> = ({ companyId, user, qerdsAPIKeyDetails: apiKeyDetails }) => {
    const { t, tCommon } = useTranslation('qerds');
    const [form] = useForm<API.RegisterQERDSApiKey>();
    const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
    const saveQERDSAPIKeyMutation = useRegisterQERDSAPIKey(companyId);
    const revokeQERDSAPIKeyMutation = useRevokeQERDSAPIKey(companyId);

    const onClickRevoke = () => {
        void message.loading({ key: messageLoadingKey, content: t('api_key_being_revoked') }, 0);

        revokeQERDSAPIKeyMutation.mutate(null, {
            onSuccess: () => {
                void message.success(t('api_key_successfully_revoked'), 3);
            },
            onError: () => {
                void message.error(t('api_key_revoke_error'), 3);
            },
            onSettled: () => {
                message.destroy(messageLoadingKey);
                setPopoverVisible(false);
            },
        });
    };

    const onClickConfirm = async () => {
        const qerdsAPIKey = await form.validateFields();

        void message.loading({ key: messageLoadingKey, content: t('api_key_being_saved') }, 0);

        saveQERDSAPIKeyMutation.mutate(qerdsAPIKey, {
            onSuccess: () => {
                void message.success(t('api_key_successfully_saved'), 3);
            },
            onError: () => {
                void message.error(t('api_key_save_error'), 3);
            },
            onSettled: () => {
                message.destroy(messageLoadingKey);
                form.resetFields();
            },
        });
    };

    const content = () => {
        if (user.hasMFALoginEnabled && user.hasQERDSMandate)
            return apiKeyDetails ? (
                <>
                    <QERDSAPIKeyDetails apiKeyDetails={apiKeyDetails} />
                    <Actions>
                        <Popover
                            title={tCommon('confirm_action')}
                            visible={popoverVisible}
                            onVisibleChange={(visible) => setPopoverVisible(visible)}
                            content={
                                <>
                                    <p>{t('confirm_revoke_api_key')}</p>
                                    <Actions>
                                        <SecondaryButton onClick={() => setPopoverVisible(false)}>
                                            {tCommon('cancel')}
                                        </SecondaryButton>
                                        <PrimaryButton onClick={onClickRevoke}>{tCommon('confirm')}</PrimaryButton>
                                    </Actions>
                                </>
                            }
                            trigger="click"
                        >
                            <SecondaryButton danger icon={<StopOutlined />}>
                                {t('revoke_key')}
                            </SecondaryButton>
                        </Popover>
                    </Actions>
                </>
            ) : (
                <>
                    <QERDSAPIKeyForm form={form} />
                    <Actions>
                        <PrimaryButton disabled={saveQERDSAPIKeyMutation.isLoading} onClick={onClickConfirm}>
                            {tCommon('save')}
                        </PrimaryButton>
                    </Actions>
                </>
            );
        else {
            return (
                <Alert
                    type="warning"
                    showIcon
                    message={t('not_allowed')}
                    description={<ReactMarkdown>{t('conditions_for_qerds_api_key')}</ReactMarkdown>}
                />
            );
        }
    };

    return <Card title={t('qerds_api_key_setup')}>{content()}</Card>;
};

export default QERDSSetup;
