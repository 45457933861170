import { DownloadOutlined } from '@ant-design/icons';
import { TaskType } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Col, Row, Skeleton } from 'antd';
import styled from 'styled-components';
import { useGetSendingEvent } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import { PrimaryButton } from '../Buttons';
import DescriptionItem from '../DescriptionItem';
import DownloadLink from '../DownloadLink';
import FailedToLoadData from '../FailedToLoadData';

const DownloadContainer = styled.div`
    text-align: center;
    margin-top: 10%;
`;

interface SendingEventDetailProps {
    companyId: string;
    job: API.JobListItem;
    seId: string;
}

const SendingEventDetail: React.FC<SendingEventDetailProps> = ({ companyId, job, seId }) => {
    const { t: tQerds, tCommon } = useTranslation('qerds_task');
    const { data: sendingEvent, isError, isLoading } = useGetSendingEvent(companyId, job.departmentId, job.id, seId);

    const NA = <>{tCommon('na')}</>;

    if (isLoading) return <Skeleton loading />;
    if (isError) return <FailedToLoadData />;

    const task = sendingEvent?.taskDetails.find((task) => task.taskType === TaskType.QERDS) as API.QERDSTaskDetails;

    return (
        <div>
            <Row>
                <Col span={24}>
                    <DescriptionItem
                        title={tQerds('recipient_email_address')}
                        content={task.recipientInfo.emailAddress}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem
                        title={tQerds('recipient_first_name')}
                        content={task.recipientInfo.firstName ?? NA}
                    />
                </Col>
                <Col span={12}>
                    <DescriptionItem
                        title={tQerds('recipient_last_name')}
                        content={task.recipientInfo.lastName ?? NA}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem
                        title={tQerds('recipient_birth_date')}
                        content={task.recipientInfo.birthDate ?? NA}
                    />
                </Col>
                <Col span={12}>
                    <DescriptionItem
                        title={tQerds('recipient_company_identification_number')}
                        content={task.recipientInfo.companyIdentificationNumber ?? NA}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem title={tQerds('document_hash_before_sign')} content={task.documentHash} />
                </Col>
            </Row>
            <Row>
                <Col span={12} offset={6}>
                    <DownloadContainer>
                        <DownloadLink
                            companyId={companyId}
                            source={API.FileSource.SENDING_EVENT}
                            location={task.location}
                            content={
                                <PrimaryButton icon={<DownloadOutlined />}>
                                    {tCommon('download_document')}
                                </PrimaryButton>
                            }
                        />
                    </DownloadContainer>
                </Col>
            </Row>
        </div>
    );
};

export default SendingEventDetail;
