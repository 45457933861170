import { ReloadOutlined } from '@ant-design/icons';
import { Alert, Form } from 'antd';
import { useEffect } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { PrimaryButton } from '../../components/Buttons';
import { Input, Password } from '../../components/Input';
import { FormItemBold } from '../../components/FormItem';
import { useAuth } from '../../context/auth-context';
import { useTranslation } from '../../hooks/translationHook';
import { Routes } from '../../constants';
import Layout from '../../layouts/LoginLayout';

const ResetPasswordContainer = styled.div`
    float: right;
`;

const ForgotPasswordSubmitForm = () => {
    const history = useHistory();
    const { forgotPasswordSubmit } = useAuth();
    const [form] = Form.useForm();
    const { t, tCommon, tValidation, tError } = useTranslation('forgot_password_submit');

    const { execute, error, loading } = useAsyncCallback(async ({ username, recoveryCode, password }) => {
        await forgotPasswordSubmit(username, recoveryCode, password);
        history.push(Routes.ROOT);
    });

    useEffect(() => {
        if (error) {
            form.resetFields(['recoveryCode', 'password']);
        }
    }, [error]);

    return (
        <Layout title={t('title')}>
            <Form form={form} layout="vertical" name="login" onFinish={execute} requiredMark={false} autoComplete="off">
                {error && (
                    <FormItemBold>
                        <Alert message={tError(error.name)} description={error.message} type="error" />
                    </FormItemBold>
                )}
                <FormItemBold
                    label={tCommon('username')}
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.username'),
                        },
                    ]}
                >
                    <Input aria-label="username input" />
                </FormItemBold>
                <FormItemBold
                    label={t('recovery_code')}
                    name="recoveryCode"
                    rules={[{ required: true, message: tValidation('required.recovery_code') }]}
                >
                    <Input aria-label="recover code input" autoComplete="off" />
                </FormItemBold>
                <FormItemBold
                    label={tCommon('new_password')}
                    name="password"
                    rules={[{ required: true, message: tValidation('required.new_password') }]}
                >
                    <Password aria-label="password input" autoComplete="new-password" />
                </FormItemBold>
                <FormItemBold>
                    <ResetPasswordContainer>
                        <PrimaryButton
                            aria-label="submit button"
                            icon={<ReloadOutlined />}
                            disabled={loading}
                            htmlType="submit"
                        >
                            {t('reset_password')}
                        </PrimaryButton>
                    </ResetPasswordContainer>
                </FormItemBold>
            </Form>
        </Layout>
    );
};

export default ForgotPasswordSubmitForm;
