import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { JobStatus } from '@eservices/shared/constant';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { format, subMonths } from 'date-fns';
import { useEffect, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';
import DatePicker from '../../components/DatePicker';
import { FormItem } from '../../components/FormItem';
import { Input, Select } from '../../components/Input';
import ParcelsTable from '../../components/parcel/ParcelsTable';
import { TABLE_PAGE_SIZE } from '../../constants';
import { useGetDepartments, useSearchParcels } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import ResourcesTableLayout from '../../layouts/ResourcesTableLayout';
import { addWildcards } from '../../utils/helpers';
import styled from 'styled-components';

const dateFormat = 'DD/MM/YYYY';

const defaultSearchValues = {
    range: [subMonths(new Date(), 1), new Date()],
};

const StyledSelect = styled(Select)`
    min-width: 254px !important;
`;

const StyledInput = styled(Input)`
    min-width: 254px !important;
    text-transform: capitalize;
`;

const SearchForm = styled(Form)`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;

    .ant-form-item {
        margin-bottom: 0;
    }
`;

const ReloadContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1%;
`;

const defaultPagination = { page: 1, pageSize: TABLE_PAGE_SIZE };

interface SearchFormValues {
    search: string;
    workflowId: string;
    departmentId: string;
    status: JobStatus;
    range: [Date, Date];
}

interface ParcelsOverviewViewFetchingProps {
    companyId: string;
    actions?: React.ReactNode;
}

const ParcelsOverviewViewFetching: React.FC<ParcelsOverviewViewFetchingProps> = ({ companyId, actions }) => {
    const { t, tCommon, tValidation } = useTranslation('parcels_overview');
    const [form] = useForm<SearchFormValues>();
    const { data: departments = [], isLoading: isLoadingDepartments } = useGetDepartments(companyId);
    const searchParcelsMutation = useSearchParcels(companyId);
    const [pagination, setPagination] = useState(defaultPagination);
    const [isError, setIsError] = useState(false);

    const departmentsOptions = departments.map((department) => ({ label: department.name, value: department.id }));

    useEffect(() => {
        const searchParcels = async () => await onSearch();
        searchParcels().catch(() => setIsError(true));
    }, []);

    const onSearch = async (page = defaultPagination.page, pageSize = defaultPagination.pageSize) => {
        await form.validateFields();
        const { range, search = '', ...rest } = form.getFieldsValue();
        const filter = {
            range: {
                from: format(range[0], "yyyy-MM-dd'T00:00:00.000Z'"),
                until: format(range[1], "yyyy-MM-dd'T23:59:59.999Z'"),
            },
            pagination: {
                page,
                pageSize,
            },
            search: addWildcards(search),
            ...rest,
        };
        setPagination({ page, pageSize });
        searchParcelsMutation.mutate(filter);
    };

    const handlePaginationChange = async (page: number, pageSize: number) => {
        setPagination({ page, pageSize });
        await onSearch(page, pageSize);
    };

    const ParcelFilter = (
        <SearchForm form={form} initialValues={defaultSearchValues} requiredMark={false} layout="vertical">
            <FormItem name="search" rules={[{ min: 3, message: tValidation('custom.min_3') }]}>
                <StyledInput placeholder={tCommon('search')} onChange={() => onSearch()} />
            </FormItem>
            <FormItem name="departmentId">
                <StyledSelect
                    placeholder={tCommon('department')}
                    allowClear
                    showSearch
                    options={departmentsOptions}
                    loading={isLoadingDepartments}
                    onChange={() => onSearch()}
                />
            </FormItem>
            <FormItem
                name="range"
                rules={[
                    {
                        required: true,
                        message: tValidation('required.range'),
                    },
                ]}
            >
                <DatePicker.RangePicker format={dateFormat} onChange={() => onSearch()} />
            </FormItem>
            <SecondaryButton icon={<SearchOutlined />} aria-label="save" onClick={() => onSearch()}>
                {tCommon('search')}
            </SecondaryButton>
        </SearchForm>
    );

    const ParcelTable = (
        <>
            <ReloadContainer>
                <SecondaryButton icon={<ReloadOutlined />} aria-label="refresh" onClick={() => onSearch()}>
                    {tCommon('refresh')}
                </SecondaryButton>
            </ReloadContainer>
            <ParcelsTable
                companyId={companyId}
                total={searchParcelsMutation.data?.pagination.totalItems}
                isLoading={searchParcelsMutation.isLoading || isLoadingDepartments}
                parcels={searchParcelsMutation.data?.items}
                onPaginationChange={handlePaginationChange}
                pagination={pagination}
                departments={departments}
            />
        </>
    );

    return (
        <ResourcesTableLayout
            isError={isError}
            title={t('title')}
            filter={ParcelFilter}
            detail={ParcelTable}
            actions={actions}
        />
    );
};

export default ParcelsOverviewViewFetching;
