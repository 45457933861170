import { SearchOutlined } from '@ant-design/icons';
import { Input as AntInput, InputNumber as AntInputNumber, Select as AntSelect } from 'antd';
import styled from 'styled-components';

const borderRadius = '7px';

const { TextArea: AntTextArea } = AntInput;

export const Input = styled(AntInput)`
    border-radius: ${borderRadius};

    .ant-input {
        border-radius: ${borderRadius};
    }
`;

export const InputNumber = styled(AntInputNumber)`
    border-radius: ${borderRadius};

    .ant-input {
        border-radius: ${borderRadius};
    }
`;

export const InputNumberWithLeftAddon = styled(InputNumber)`
    .ant-input-number-group-addon {
        border-top-left-radius: ${borderRadius};
        border-bottom-left-radius: ${borderRadius};
    }
`;

export const InputWithRightAddon = styled(Input)`
    .ant-input-group-addon {
        border-top-right-radius: ${borderRadius};
        border-bottom-right-radius: ${borderRadius};
    }
`;

export const InputWithLeftAddon = styled(Input)`
    .ant-input-group-addon {
        border-top-left-radius: ${borderRadius};
        border-bottom-left-radius: ${borderRadius};
    }
`;

export const Password = styled(Input.Password)`
    border-radius: ${borderRadius};
`;

export const SearchInput = styled(InputWithRightAddon)``;
SearchInput.defaultProps = {
    addonAfter: <SearchOutlined />,
};

export const TextArea = styled(AntTextArea)`
    border-radius: ${borderRadius};
`;

export const Select = styled(AntSelect)`
    .ant-select-selector {
        border-radius: ${borderRadius} !important;
    }
`;
Select.defaultProps = {
    optionFilterProp: 'label',
};
