import { Product } from '@eservices/shared/type';
import styled from 'styled-components';
import { Fonts, ProductPictures } from '../../constants';
import { useTranslation } from '../../hooks/translationHook';

import { Colors } from '@eservices/ui-constants/colors';
import Card from '../Card';

const Title = styled.div`
    font-family: ${Fonts.ARIAL_BOLD};
`;

const SubTitle = styled.div`
    color: ${Colors.GRAY_SEPTENARY};
    margin: 0 0 20px 0;
`;

const Image = styled.img`
    height: 145px;
    margin: 5% auto 1% auto;
    display: block;
`;

const StyledCard = styled(Card)`
    width: 300px;
`;

const Content = styled.div`
    height: 230px;
`;

interface ProductCardProps {
    product: Product;
    loading?: boolean;
    actions?: React.ReactNode[];
}

const ProductCard: React.FC<ProductCardProps> = ({ product, loading, actions = [] }) => {
    const { t } = useTranslation('order');

    return (
        <StyledCard loading={loading} actions={actions} bordered={false}>
            <Content>
                <Title>{t(product.nameKey)}</Title>
                <SubTitle>{t(`${product.code}_description`)}</SubTitle>
                <Image src={ProductPictures[product.code]} alt={product.nameKey} />
            </Content>
        </StyledCard>
    );
};

export default ProductCard;
