import SendingEventsOverviewFetching from './SendingEventsOverviewFetching';

interface SendingEventsOverviewViewProps {
    companyId: string;
}

const SendingEventsOverviewView: React.FC<SendingEventsOverviewViewProps> = ({ companyId }) => {
    return <SendingEventsOverviewFetching companyId={companyId} />;
};

export default SendingEventsOverviewView;
