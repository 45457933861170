import { BookOutlined, CloseOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { Select } from 'antd';
import { useState } from 'react';
import { useTranslation } from '../../hooks/translationHook';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { Modal } from '../Modal';

const { Option } = Select;

const FullWidthSelect = styled(Select)`
    width: 100%;
`;

interface CloneWorkflowModalProps {
    departmentId: string;
    workflowId: string;
    departments: API.DepartmentListItem[];
    isLoading: boolean;
    disableSaveButton: boolean;
    onSave: (departmentId: string, workflowId: string, targetDepartmentId: string) => void;
    onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const CloneWorkflowModal: React.FC<CloneWorkflowModalProps> = ({
    departmentId,
    workflowId,
    departments,
    isLoading,
    disableSaveButton,
    onSave,
    onCancel,
}) => {
    const { t, tCommon } = useTranslation('clone_workflow_modal');
    const [selectedDepartment, setSelectedDepartment] = useState<API.DepartmentListItem>(undefined);

    const onChange = (departmentId: string) => {
        setSelectedDepartment(departments.find((department) => department.id === departmentId));
    };

    return (
        <Modal
            title={t('clone_workflow_title')}
            visible={true}
            onCancel={onCancel}
            footer={
                <>
                    <SecondaryButton aria-label="cancel" icon={<CloseOutlined />} onClick={onCancel}>
                        {tCommon('cancel')}
                    </SecondaryButton>
                    <PrimaryButton
                        icon={<BookOutlined />}
                        aria-label="save"
                        onClick={() => onSave(departmentId, workflowId, selectedDepartment.id)}
                        disabled={!selectedDepartment || disableSaveButton}
                    >
                        {tCommon('save')}
                    </PrimaryButton>
                </>
            }
        >
            <FullWidthSelect
                loading={isLoading}
                showSearch
                placeholder={t('search_placeholder')}
                optionFilterProp="children"
                onChange={onChange}
                filterOption
            >
                {departments.map(({ id, name }: API.DepartmentListItem) => (
                    <Option key={id} value={id}>
                        {name}
                    </Option>
                ))}
            </FullWidthSelect>
        </Modal>
    );
};

export default CloneWorkflowModal;
