import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../components/Buttons';
import { Routes } from '../../constants';
import { useTranslation } from '../../hooks/translationHook';
import CompaniesOverviewFetching from './CompaniesOverviewFetching';

const CompaniesOverviewEdit: React.FC = () => {
    const { t } = useTranslation('company_overview');
    const history = useHistory();

    const actions = (
        <PrimaryButton
            icon={<PlusOutlined />}
            aria-label="create company link"
            onClick={() => history.push(Routes.CREATE_COMPANY)}
        >
            {t('action_add')}
        </PrimaryButton>
    );

    return <CompaniesOverviewFetching isReadOnly={false} actions={actions} />;
};

export default CompaniesOverviewEdit;
