import ClockCircleOutlined from '@ant-design/icons/lib/icons/ClockCircleOutlined';
import UploadOutlined from '@ant-design/icons/lib/icons/UploadOutlined';
import { WorkflowTriggerType } from '@eservices/shared/constant';
import { FormInstance } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../../hooks/translationHook';
import DatePicker from '../../DatePicker';
import { FormItem } from '../../FormItem';
import { Header2 } from '../../Headers';
import { InputNumber } from '../../Input';
import { Paragraph } from '../../Paragraph';
import WizardChoiceWithIcon from '../../WizardChoiceWithIcon';

const Choices = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 30px;
    margin: 5% 0 0 0;
`;

const StyledInputNumber = styled(InputNumber)`
    width: 100px;
`;
StyledInputNumber.defaultProps = {
    min: 0,
    max: 999,
};

interface TriggerTypeQuestionProps {
    form: FormInstance;
}

const TriggerTypeQuestion: React.FC<TriggerTypeQuestionProps> = ({ form }) => {
    const { t, tValidation } = useTranslation('workflow');
    const [triggerType, setTriggerType] = useState(form.getFieldValue(['trigger', 'type']));

    const triggerTypes = [
        {
            type: WorkflowTriggerType.FILE_DROP,
            title: t('file_drop_option_title'),
            description: t('file_drop_option_description'),
            cover: <UploadOutlined />,
            enabled: true,
            extra: (
                <FormItem
                    name={['trigger', 'secondsToWait']}
                    label={t('buffer_sec')}
                    initialValue={0}
                    rules={[
                        {
                            required: true,
                            pattern: new RegExp(/\d+/g),
                            message: tValidation('required.seconds_to_wait'),
                        },
                    ]}
                >
                    <StyledInputNumber aria-label="buffer time input" />
                </FormItem>
            ),
        },
        {
            type: WorkflowTriggerType.SCHEDULED,
            title: t('scheduled_option_title'),
            description: t('scheduled_option_description'),
            cover: <ClockCircleOutlined />,
            enabled: true,
            extra: (
                <FormItem
                    name="scheduleTime"
                    label={t('schedule_time')}
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.trigger'),
                        },
                    ]}
                >
                    <DatePicker picker="time" format={'HH:mm'} />
                </FormItem>
            ),
        },
    ];

    return (
        <>
            <Header2>{t('trigger_title')}</Header2>
            <Paragraph>{t('trigger_description')}</Paragraph>
            <FormItem
                name={['trigger', 'type']}
                rules={[
                    {
                        required: true,
                        message: tValidation('required.trigger'),
                    },
                ]}
                hidden
            />

            <Choices>
                {triggerTypes.map(({ type, enabled, cover, title, description, extra }) => (
                    <div key={type}>
                        <WizardChoiceWithIcon
                            onClick={() => {
                                setTriggerType(type);
                                form.setFieldsValue({
                                    trigger: { type },
                                });
                            }}
                            enabled={enabled}
                            selected={triggerType === type}
                            icon={cover}
                            title={title}
                            description={description}
                            extra={extra}
                        />
                    </div>
                ))}
            </Choices>
        </>
    );
};

export default TriggerTypeQuestion;
