import DepartmentsListFetching from './DepartmentsListFetching';

interface DepartmentsListProps {
    companyId: string;
}
const DepartmentsList: React.FC<DepartmentsListProps> = ({ companyId }) => (
    <DepartmentsListFetching companyId={companyId} />
);

export default DepartmentsList;
