/**
 *
 * Reference: https://usehooks.com/useAuth/
 */
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { createContext, useContext, useState } from 'react';
import { CognitoChallenges } from '../constants';
import { ExtendedCognitoUser } from '../types';

interface AuthContext {
    user: ExtendedCognitoUser;
    isAuthenticated: boolean;
    onLoad: () => Promise<void>;
    retrieveAccessToken: () => Promise<string>;
    signIn: (username: string, password: string) => Promise<void>;
    signOut: () => Promise<void>;
    completeNewPassword: (password: string) => Promise<void>;
    forgotPassword: (username: string) => Promise<void>;
    forgotPasswordSubmit: (username: string, code: string, password: string) => Promise<void>;
    changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
    getTOTPSecretKey: () => Promise<string>;
    verifyTOTPCode: (challengeResponse: string) => Promise<void>;
    confirmSignIn: (code: string) => Promise<void>;
}

const authContext = createContext({} as AuthContext);

export const useAuth = (): AuthContext => {
    return useContext(authContext);
};

export function AuthProvider({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

function useProvideAuth(): AuthContext {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState<ExtendedCognitoUser>();

    const onLoad = async (): Promise<void> => {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        setUser(user);
        setIsAuthenticated(true);
    };

    const signIn = async (username: string, password: string): Promise<void> => {
        const userObj: ExtendedCognitoUser = await Auth.signIn(username, password);
        if (userObj?.challengeName === undefined) {
            setIsAuthenticated(true);
        }
        setUser(userObj);
    };

    const signOut = async (): Promise<void> => {
        await Auth.signOut();
        setIsAuthenticated(false);
    };

    const completeNewPassword = async (password: string): Promise<void> => {
        await Auth.completeNewPassword(user, password);
        setIsAuthenticated(true);
    };

    const forgotPassword = async (username: string): Promise<void> => {
        await Auth.forgotPassword(username);
    };

    const forgotPasswordSubmit = async (username: string, code: string, password: string): Promise<void> => {
        await Auth.forgotPasswordSubmit(username, code, password);
        const user = await Auth.signIn(username, password);
        setUser(user);
        setIsAuthenticated(true);
    };

    const changePassword = async (oldPassword: string, newPassword: string): Promise<void> => {
        await Auth.currentAuthenticatedUser().then(
            async (user: CognitoUser) => await Auth.changePassword(user, oldPassword, newPassword),
        );
    };

    const retrieveAccessToken = async () => {
        const currentSession = await Auth.currentSession();
        const token = currentSession.getIdToken().getJwtToken();
        return token;
    };

    const getTOTPSecretKey = async (): Promise<string> => {
        return await Auth.setupTOTP(user);
    };

    const verifyTOTPCode = async (challengeAnswer: string): Promise<void> => {
        await Auth.verifyTotpToken(user, challengeAnswer);
    };

    const confirmSignIn = async (code: string): Promise<void> => {
        await Auth.confirmSignIn(user, code, CognitoChallenges.SOFTWARE_TOKEN_MFA);
        setIsAuthenticated(true);
    };

    return {
        user,
        isAuthenticated,
        onLoad,
        retrieveAccessToken,
        signIn,
        signOut,
        completeNewPassword,
        forgotPassword,
        forgotPasswordSubmit,
        changePassword,
        getTOTPSecretKey,
        verifyTOTPCode,
        confirmSignIn,
    };
}
