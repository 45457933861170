import { Alert, Descriptions, PageHeader, Skeleton, Space } from 'antd';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import FailedToLoadData from '../components/FailedToLoadData';
import Loading from '../components/Loading';
import { useTranslation } from '../hooks/translationHook';
import { toReadableDateTime } from '../utils/helpers';

const AlertContainer = styled.div`
    margin: 0 0 1% 0;
`;

const Content = styled.div`
    margin: 0 24px;
`;

interface ResourceDetailProps {
    isLoading?: boolean;
    isError?: boolean;
    title: string | React.ReactNode;
    subTitle?: string | React.ReactNode;
    createdAt?: string;
    lastModifiedAt?: string;
    lastModifiedBy?: string;
    errors?: string[];
    detail: React.ReactNode;
    actions?: React.ReactNode;
    onBack?: () => void;
    isWaiting?: boolean;
    additionalDescriptions?: JSX.Element;
}

const ResourceDetailLayout: React.FC<ResourceDetailProps> = ({
    isLoading,
    isError,
    title,
    subTitle,
    createdAt,
    lastModifiedAt,
    lastModifiedBy,
    errors = [],
    detail,
    actions,
    onBack,
    isWaiting,
    additionalDescriptions,
}) => {
    const { tCommon } = useTranslation();

    if (isLoading) return <Skeleton />;
    if (isError) return <FailedToLoadData />;

    return (
        <>
            <PageHeader
                title={title}
                subTitle={subTitle}
                extra={isWaiting ? <Loading /> : actions}
                ghost
                onBack={onBack}
            >
                {errors.length > 0 && (
                    <AlertContainer>
                        <Alert
                            message={tCommon('error')}
                            description={
                                <ul>
                                    {errors.map((error) => (
                                        <li key={error}>{error}</li>
                                    ))}
                                </ul>
                            }
                            type="error"
                            showIcon
                        />
                    </AlertContainer>
                )}
                {createdAt && lastModifiedAt && (
                    <Descriptions column={3}>
                        <Descriptions.Item label={tCommon('created_at')}>
                            {toReadableDateTime(createdAt)}
                        </Descriptions.Item>
                        <Descriptions.Item label={tCommon('last_modified_at')}>
                            {toReadableDateTime(lastModifiedAt)}
                        </Descriptions.Item>
                        <Descriptions.Item label={tCommon('last_modified_by')}>{lastModifiedBy}</Descriptions.Item>
                        {additionalDescriptions}
                    </Descriptions>
                )}
            </PageHeader>
            <Content>{detail}</Content>
        </>
    );
};

export default ResourceDetailLayout;
