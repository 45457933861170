import { CheckCircleFilled, DownloadOutlined } from '@ant-design/icons';
import { RegisteredMailStatus } from '@eservices/shared/constant';
import { API, PartialRecord } from '@eservices/shared/type';
import { Colors } from '@eservices/ui-constants/colors';
import { Progress, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';
import ScanCheckIcon from '../../assets/images/scan_check_icon.svg';
import ScanIcon from '../../assets/images/scan_icon.svg';
import { Fonts } from '../../constants';
import { useTranslation } from '../../hooks/translationHook';
import { getPaginationDetails, toReadableDate, toReadableDateTime } from '../../utils/helpers';
import { IconButton } from '../Buttons';
import DownloadLink from '../DownloadLink';
import Table from '../Table';

const IconContainer = styled.div`
    width: 20px;
    height: 20px;
`;

const SecondaryBarcodeLabel = styled.div`
    font-family: '${Fonts.HURME4_LIGHT}';
    font-size: 12px;
    color: ${Colors.GRAY_SEPTENARY};
`;

const StatusContainer = styled.div`
    display: flex;
`;

const SeparatorContainer = styled.div`
    width: 40px;
    margin-left: 5px;
    margin-right: 5px;
`;

const tooltipDelay = 0.5;

interface RegisteredMailTableProps {
    companyId: string;
    registeredMails: API.RegisteredMail[];
    departments: API.DepartmentListItem[];
    total: number;
    isLoading: boolean;
    onPaginationChange: (page: number, pageSize?: number) => void;
    pagination: { page: number; pageSize: number };
}

const RegisteredMailTable: React.FC<RegisteredMailTableProps> = ({
    companyId,
    registeredMails = [],
    departments = [],
    total,
    isLoading,
    onPaginationChange,
    pagination,
}) => {
    const { t, tCommon } = useTranslation('registered_mail_overview');

    const paginationConfig = getPaginationDetails(total, {
        current: pagination.page,
        pageSize: pagination.pageSize,
        onChange: onPaginationChange,
        showSizeChanger: true,
    });

    const StatusDetail: React.FC<{ statusHistory: PartialRecord<RegisteredMailStatus, string> }> = ({
        statusHistory,
    }) => (
        <StatusContainer>
            <Tooltip
                title={`${t(RegisteredMailStatus.PROCESSED_BY_EASYPOST.toLocaleLowerCase())}: ${toReadableDateTime(
                    statusHistory[RegisteredMailStatus.PROCESSED_BY_EASYPOST],
                )}`}
                mouseEnterDelay={tooltipDelay}
            >
                <IconContainer>
                    <img src={ScanIcon} alt={RegisteredMailStatus.PROCESSED_BY_EASYPOST.toLocaleLowerCase()} />
                </IconContainer>
            </Tooltip>
            <SeparatorContainer>
                <Progress
                    percent={statusHistory[RegisteredMailStatus.PROCESSED_BY_BPOST] ? 100 : 0}
                    size="small"
                    showInfo={false}
                    strokeColor={Colors.PRIMARY}
                />
            </SeparatorContainer>
            {statusHistory[RegisteredMailStatus.PROCESSED_BY_BPOST] ? (
                <Tooltip
                    title={`${t(RegisteredMailStatus.PROCESSED_BY_BPOST.toLocaleLowerCase())}: ${toReadableDateTime(
                        statusHistory[RegisteredMailStatus.PROCESSED_BY_BPOST],
                    )}`}
                    mouseEnterDelay={tooltipDelay}
                >
                    <IconContainer>
                        <img src={ScanCheckIcon} alt={RegisteredMailStatus.PROCESSED_BY_BPOST.toLocaleLowerCase()} />
                    </IconContainer>
                </Tooltip>
            ) : (
                <Tooltip title={t('not_processed_by_bpost_yet')} mouseEnterDelay={tooltipDelay}>
                    <IconContainer>
                        <CheckCircleFilled style={{ color: Colors.NEUTRAL }} />
                    </IconContainer>
                </Tooltip>
            )}
        </StatusContainer>
    );

    const columns: ColumnsType<API.RegisteredMail> = [
        {
            title: tCommon('registered_mail'),
            width: '15%',
            render: (record: API.RegisteredMail) => (
                <div>
                    {record.primaryBarCode}
                    <SecondaryBarcodeLabel>{record.secondaryBarCode}</SecondaryBarcodeLabel>
                </div>
            ),
        },
        {
            title: tCommon('department'),
            render: (record: API.RegisteredMail) =>
                departments.find((department) => department.id === record.departmentId)?.name,
        },
        {
            title: tCommon('status'),
            align: 'right',
            width: '5%',
            render: ({ statusHistory }: API.RegisteredMail) => <StatusDetail statusHistory={statusHistory} />,
        },
        {
            title: t('bpost_stamp_date'),
            align: 'right',
            width: '10%',
            render: ({ bpostStampDate }: API.RegisteredMail) => {
                return bpostStampDate && toReadableDate(bpostStampDate);
            },
        },
        {
            title: t('scanned_at'),
            align: 'right',
            width: '10%',
            render: ({ scannedAt }: API.RegisteredMail) => {
                return scannedAt && toReadableDateTime(scannedAt);
            },
        },
        {
            align: 'right',
            width: '5%',
            render: (registeredMail: API.RegisteredMail) =>
                registeredMail.ePodLocation && (
                    <DownloadLink
                        companyId={companyId}
                        source={API.FileSource.REGISTERED_MAIL}
                        location={registeredMail.ePodLocation}
                        content={
                            <Tooltip title={registeredMail.ePodLocation}>
                                <IconButton icon={<DownloadOutlined />} />
                            </Tooltip>
                        }
                    />
                ),
        },
    ];

    return (
        <Table
            loading={isLoading}
            rowKey="id"
            columns={columns}
            dataSource={registeredMails}
            pagination={paginationConfig}
        />
    );
};

export default RegisteredMailTable;
