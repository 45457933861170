import { CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { OrderStatus } from '@eservices/shared/constant';
import { Col, Divider, Row, Space } from 'antd';
import { Fonts } from '../../constants';
import { useTranslation } from '../../hooks/translationHook';
import { toReadableDateTime } from '../../utils/helpers';
import styled from 'styled-components';
import { Colors } from '@eservices/ui-constants/colors';

const PreHeader = styled.div`
    text-align: left;
`;

const TailHeader = styled.div`
    text-align: right;
`;

const OrderSizeLabel = styled.span`
    font-family: ${Fonts.HURME4}
    font-size: 15px;
    line-height: 22px;
    padding-right: 30px;
`;

const DateLabel = styled.span`
    font-family: ${Fonts.HURME4_BOLD}
    font-size: 15px;
    line-height: 22px;
`;

const StatusLabel = styled.span`
    font-family: Roboto;
    font-size: 12px;
    line-height: 20px;
`;

interface OrderPanelHeaderProps {
    creationDate: string;
    status: OrderStatus;
    amount: number;
}

const OrderPanelHeader: React.FC<OrderPanelHeaderProps> = ({ creationDate, status, amount }) => {
    const { t } = useTranslation('order');
    const getOrderStatusIcon = (status: OrderStatus) => {
        switch (status) {
            case OrderStatus.SUBMITTED:
                return (
                    <>
                        <CheckCircleFilled style={{ paddingRight: '5px', color: Colors.NEUTRAL }} />
                        {t('order_submitted')}
                    </>
                );
            case OrderStatus.PROCESSING:
                return (
                    <>
                        <LoadingOutlined spin style={{ paddingRight: '5px', color: Colors.PROCESSING }} />
                        <StatusLabel>{t('order_processing')}</StatusLabel>
                    </>
                );
            case OrderStatus.DELIVERED:
                return (
                    <>
                        <CheckCircleFilled style={{ paddingRight: '5px', color: Colors.OK }} />
                        <StatusLabel>{t('order_delivered')}</StatusLabel>
                    </>
                );
            default:
                return;
        }
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <PreHeader>
                        <Space>
                            <DateLabel>{toReadableDateTime(creationDate)}</DateLabel>
                            <Divider type="vertical" />
                            {getOrderStatusIcon(status)}
                        </Space>
                    </PreHeader>
                </Col>
                <Col offset={4} span={8}>
                    <TailHeader>
                        <OrderSizeLabel>
                            {amount > 1 ? `${amount} ${t('product_plural')}` : `${amount} ${t('product')}`}
                        </OrderSizeLabel>
                    </TailHeader>
                </Col>
            </Row>
        </div>
    );
};

export default OrderPanelHeader;
