import { Drawer, DrawerProps } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { Colors } from '@eservices/ui-constants/colors';
import { Header1 } from './Headers';

const WorkflowTitle = styled(Header1)`
    margin-bottom: 10%;
`;

const SubHeader = styled.div`
    color: ${Colors.GRAY_SEPTENARY};
    font-size: 14px;
    text-transform: uppercase;
`;

const Part = styled.div`
    margin: 5% 5%;
`;

interface DetailDrawerProps extends DrawerProps {
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    main?: React.ReactNode;
    detail?: React.ReactNode;
}

const DetailDrawer: React.FC<DetailDrawerProps> = ({ title, subtitle, main, detail, ...rest }) => (
    <Drawer width={640} placement="right" closable visible {...rest}>
        <Part>
            <WorkflowTitle>
                {title}
                <SubHeader>{subtitle}</SubHeader>
            </WorkflowTitle>
            {main}
        </Part>
        {detail && <Part>{detail}</Part>}
    </Drawer>
);

export default DetailDrawer;
