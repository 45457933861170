import { API } from '@eservices/shared/type';
import { Tabs } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../../../hooks/translationHook';
import { WatermarkUI } from '../../../../types';
import DataCollecting from './DataCollecting';
import Ignoring from './Ignore';
import Splitting from './Splitting';
import TaskTypeProperties from './TaskTypeProperties';
import Watermark from './Watermark';
import { WorkflowPDFInputSourceActions } from './workflowPDFInputSourceReducer';

const { TabPane } = Tabs;

export const defaultActiveKey = 'channelIdentifiers';

const StyledTabs = styled(Tabs)`
    width: 100%;
`;

interface IdentifiersManagerProps {
    isReadOnly: boolean;
    companyId: string;
    tasks: Array<API.TaskConfiguration>;
    workflowPDFInputSource: API.WorkflowPDFInputSource;
    workflowPDFInputSourceDispatch: React.Dispatch<WorkflowPDFInputSourceActions>;
    setAction: React.Dispatch<React.SetStateAction<WorkflowPDFInputSourceActions>>;
    setWatermark: React.Dispatch<React.SetStateAction<WatermarkUI>>;
    watermark: WatermarkUI;
    onTabChange: (activeTabKey: string) => void;
}

const IdentifiersManager: React.FC<IdentifiersManagerProps> = ({
    companyId,
    tasks,
    workflowPDFInputSource,
    workflowPDFInputSourceDispatch,
    setAction,
    setWatermark,
    watermark,
    onTabChange,
    isReadOnly,
}) => {
    const { t } = useTranslation('workflow');
    return (
        <StyledTabs defaultActiveKey={defaultActiveKey} onChange={onTabChange}>
            <TabPane tab={t('task_type_properties')} key="channelIdentifiers">
                <TaskTypeProperties
                    isReadOnly={isReadOnly}
                    tasks={tasks}
                    channelIdentifiers={workflowPDFInputSource.channelIdentifiers}
                    workflowPDFInputSourceDispatch={workflowPDFInputSourceDispatch}
                    setAction={setAction}
                />
            </TabPane>
            <TabPane tab={t('splitting')} key="splitIdentifiers">
                <Splitting
                    isReadOnly={isReadOnly}
                    splitIdentifiers={workflowPDFInputSource.splitIdentifiers}
                    workflowPDFInputSourceDispatch={workflowPDFInputSourceDispatch}
                    setAction={setAction}
                />
            </TabPane>
            <TabPane tab={t('ignoring')} key="ignoreIdentifiers">
                <Ignoring
                    isReadOnly={isReadOnly}
                    ignoreIdentifiers={workflowPDFInputSource.ignoreIdentifiers}
                    workflowPDFInputSourceDispatch={workflowPDFInputSourceDispatch}
                    setAction={setAction}
                />
            </TabPane>
            <TabPane tab={t('data_collecting')} key="extraInfoIdentifiers">
                <DataCollecting
                    isReadOnly={isReadOnly}
                    extraInfoIdentifiers={workflowPDFInputSource.extraInfoIdentifiers}
                    workflowPDFInputSourceDispatch={workflowPDFInputSourceDispatch}
                    setAction={setAction}
                />
            </TabPane>
            <TabPane tab={t('watermark')} key="watermark">
                <Watermark
                    isReadOnly={isReadOnly}
                    companyId={companyId}
                    setAction={setAction}
                    setWatermark={setWatermark}
                    watermark={watermark}
                />
            </TabPane>
        </StyledTabs>
    );
};

export default IdentifiersManager;
