import { useEffect, useState } from 'react';
import CompaniesTable from '../../components/company/CompaniesTable';
import { SearchInput } from '../../components/Input';
import { useGetCompanies } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import ResourcesTableLayout from '../../layouts/ResourcesTableLayout';
import styled from 'styled-components';

const FilterContainer = styled.div`
    width: 250px;
`;

interface CompaniesOverviewFetchedProps {
    isReadOnly: boolean;
    actions?: React.ReactNode;
}

const CompaniesOverviewFetching: React.FC<CompaniesOverviewFetchedProps> = ({ isReadOnly, actions }) => {
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const { isLoading, isError, data: companies = [] } = useGetCompanies();
    const { t } = useTranslation('company_overview');

    useEffect(() => {
        if (companies) {
            setFilteredCompanies([...companies]);
        }
    }, [JSON.stringify(companies)]);

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === '') {
            setFilteredCompanies([...companies]);
        } else {
            setFilteredCompanies(
                companies.filter(
                    (company) =>
                        company.name.toLowerCase().includes(value.toLowerCase()) ||
                        company.erpId.toLowerCase().includes(value.toLowerCase()),
                ),
            );
        }
    };

    const filter = (
        <FilterContainer>
            <SearchInput placeholder={t('search_placeholder')} onChange={onSearch} />
        </FilterContainer>
    );
    const detail = <CompaniesTable isLoading={isLoading} companies={filteredCompanies} isReadOnly={isReadOnly} />;

    return (
        <ResourcesTableLayout isError={isError} title={t('title')} filter={filter} detail={detail} actions={actions} />
    );
};

export default CompaniesOverviewFetching;
