import { WorkflowInputSource, WorkflowPDFInputSource } from '@eservices/shared/type';
import { Button, Dropdown, FormInstance, Input as AntInput, Menu } from 'antd';
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from '../../../hooks/translationHook';
import { normalizeString } from '@eservices/utils/string';
import { FormItem } from '../../FormItem';
import { Input } from '../../Input';
import styled from 'styled-components';

const AddExtraInfoButton = styled(Button)`
    margin-left: 45px;
`;

interface SubjectInputWithExtraInfoProps {
    inputSource: WorkflowInputSource;
    form: FormInstance;
    isReadOnly: boolean;
}

export const SubjectInputWithExtraInfo: React.FC<SubjectInputWithExtraInfoProps> = ({
    form,
    inputSource,
    isReadOnly,
}) => {
    const { tCommon, tValidation } = useTranslation();
    const inputRef = createRef<AntInput>();
    const [subject, setSubject] = useState<string>(form.getFieldValue('subject'));

    useEffect(() => {
        form.setFieldsValue({ subject });
    }, [subject]);

    const addPlaceholderToSubject = (placeholder: string) => {
        const { value, selectionEnd: pos } = inputRef.current.input;
        const newValue = [value.slice(0, pos), `{{${normalizeString(placeholder)}}}`, value.slice(pos)].join('');
        setSubject(newValue);
    };

    const menuItems = (inputSource as WorkflowPDFInputSource).extraInfoIdentifiers.map(
        ({ name: placeholder }, index) => (
            <Menu.Item key={`${index + 1}`} onClick={() => addPlaceholderToSubject(placeholder)}>
                {placeholder}
            </Menu.Item>
        ),
    );
    const hasExtraInfo = menuItems.length > 0;
    const menu = <Menu>{menuItems}</Menu>;

    return (
        <>
            <FormItem
                label={tCommon('email_subject')}
                name="subject"
                rules={[
                    {
                        required: true,
                        message: tValidation('required.subject'),
                    },
                ]}
            >
                <Input
                    ref={inputRef}
                    disabled={isReadOnly}
                    type="text"
                    aria-label="subject input"
                    onChange={(event) => {
                        setSubject(event.target.value);
                    }}
                />
            </FormItem>

            {hasExtraInfo && (
                <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
                    <AddExtraInfoButton type="dashed" size="small" shape="default">
                        {tCommon('add_collected_data_to_email_subject')}
                    </AddExtraInfoButton>
                </Dropdown>
            )}
        </>
    );
};
