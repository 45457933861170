import { LoginOutlined } from '@ant-design/icons';
import { Alert, Form } from 'antd';
import { useEffect } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { PrimaryButton } from '../../components/Buttons';
import { Input } from '../../components/Input';
import { useAuth } from '../../context/auth-context';
import { useTranslation } from '../../hooks/translationHook';
import { Routes } from '../../constants';
import Layout from '../../layouts/LoginLayout';
import { FormItem, FormItemBold } from '../../components/FormItem';

const SubmitContainer = styled.div`
    float: right;
`;

const CompleteNewPasswordForm = () => {
    const history = useHistory();
    const { user, completeNewPassword } = useAuth();
    const [form] = Form.useForm();
    const { t, tValidation, tError } = useTranslation('complete_new_password');

    const { execute, status, loading, error } = useAsyncCallback(async ({ newPassword, passwordConfirm }) => {
        if (newPassword === passwordConfirm) {
            await completeNewPassword(newPassword);
        } else {
            const e = new Error("Passwords don't match");
            e.name = 'ConfirmPasswordMatchException';
            throw e;
        }
    });

    useEffect(() => {
        if (!user) {
            history.push(Routes.ROOT);
        }
    }, [user]);

    useEffect(() => {
        if (status === 'success') {
            history.push(Routes.ROOT);
        } else if (status === 'error') {
            form.resetFields();
        }
    }, [status]);

    return (
        <Layout title={t('title')}>
            <Form
                form={form}
                layout="vertical"
                name="completeNewPassword"
                onFinish={execute}
                requiredMark={false}
                autoComplete="off"
            >
                {error && (
                    <FormItem>
                        <Alert message={tError(error.name)} description={error.message} type="error" />
                    </FormItem>
                )}
                <FormItemBold
                    label={t('new_password')}
                    name="newPassword"
                    rules={[{ required: true, message: tValidation('required.password') }]}
                >
                    <Input.Password aria-label="password input" autoComplete="new-password" />
                </FormItemBold>
                <FormItemBold
                    label={t('repeat_new_password')}
                    name="passwordConfirm"
                    rules={[{ required: true, message: tValidation('required.password') }]}
                >
                    <Input.Password aria-label="input-confirm-password" autoComplete="new-password" />
                </FormItemBold>
                <Form.Item>
                    <SubmitContainer>
                        <PrimaryButton
                            aria-label="submit button-password"
                            icon={<LoginOutlined />}
                            disabled={loading}
                            htmlType="submit"
                        >
                            {t('reset_password')}
                        </PrimaryButton>
                    </SubmitContainer>
                </Form.Item>
            </Form>
        </Layout>
    );
};

export default CompleteNewPasswordForm;
