import { RegionOfInterest } from '@eservices/shared/type';
import { ButtonProps } from 'antd/lib/button/button';
import { useContext } from 'react';
import { HighlightRectangleContext } from '../..';
import { useTranslation } from '../../../../../hooks/translationHook';
import { SecondaryButton } from '../../../../Buttons';

interface RegionButtonProps extends ButtonProps {
    regionOfInterest: RegionOfInterest;
}

const RegionButton: React.FC<RegionButtonProps> = ({ ...props }) => {
    const { t } = useTranslation('workflow');
    const setHighlightedRectangle = useContext(HighlightRectangleContext);

    return (
        <SecondaryButton
            {...props}
            shape="default"
            type="dashed"
            onMouseEnter={() => setHighlightedRectangle(props.regionOfInterest)}
            onMouseLeave={() => setHighlightedRectangle(undefined)}
        >
            {t('region')}
        </SecondaryButton>
    );
};

export default RegionButton;
