import Radio from 'antd/lib/radio';
import { useTranslation } from '../../../hooks/translationHook';
import { FormItem } from '../../FormItem';
import { Header2 } from '../../Headers';
import { Paragraph } from '../../Paragraph';

interface EnablingQuestionProps {}

const EnablingQuestion: React.FC<EnablingQuestionProps> = () => {
    const { t, tCommon } = useTranslation('workflow');

    const statusOptions = [
        { label: tCommon('yes'), value: true },
        { label: tCommon('no'), value: false },
    ];

    return (
        <div>
            <Header2>{t('activation')}</Header2>
            <Paragraph>{t('activation_description')}</Paragraph>
            <FormItem
                initialValue={false}
                name="enabled"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Radio.Group options={statusOptions} size="large" />
            </FormItem>
        </div>
    );
};

export default EnablingQuestion;
