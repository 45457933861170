import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SearchInput } from '../../components/Input';
import SubscriptionCounter from '../../components/subscription/SubscriptionCounter';
import WorkflowsTable from '../../components/workflow/WorkflowsTable';
import { SubscriptionContext } from '../../constants';
import { useGetWorkflows } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import ResourcesTableLayout from '../../layouts/ResourcesTableLayout';

const FilterContainer = styled.div`
    width: 400px;
`;

const TableHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

interface WorkflowsOverviewFetchedProps {
    companyId: string;
    isReadOnly: boolean;
    actions?: React.ReactNode;
    isCloning?: boolean;
    cloneWorkflow?: (departmentId: string, workflowId: string, targetDepartmentId: string) => void;
}

const WorkflowsOverviewFetching: React.FC<WorkflowsOverviewFetchedProps> = ({
    companyId,
    isReadOnly,
    actions,
    isCloning,
    cloneWorkflow,
}) => {
    const [filteredWorkflows, setFilteredWorkflows] = useState([]);
    const { isLoading, isError, data: workflows = [] } = useGetWorkflows(companyId);
    const { t } = useTranslation('workflow_overview');

    useEffect(() => {
        if (workflows) {
            setFilteredWorkflows([...workflows]);
        }
    }, [JSON.stringify(workflows)]);

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === '') {
            setFilteredWorkflows([...workflows]);
        } else {
            setFilteredWorkflows(
                workflows.filter((workflow) => workflow.name.toLowerCase().includes(value.toLowerCase())),
            );
        }
    };

    const filter = (
        <TableHeader>
            <FilterContainer>
                <SearchInput placeholder={t('search_placeholder')} onChange={onSearch} />
            </FilterContainer>
            <SubscriptionCounter companyId={companyId} context={SubscriptionContext.WORKFLOW} />
        </TableHeader>
    );

    const detail = (
        <WorkflowsTable
            companyId={companyId}
            isLoading={isLoading}
            workflows={filteredWorkflows}
            isReadOnly={isReadOnly}
            isCloning={isCloning}
            cloneWorkflow={cloneWorkflow}
        />
    );

    return (
        <ResourcesTableLayout isError={isError} title={t('title')} filter={filter} detail={detail} actions={actions} />
    );
};

export default WorkflowsOverviewFetching;
