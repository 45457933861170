import { CloseOutlined, DeleteOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { ProductsCatalog } from '@eservices/shared/constant';
import { OrderItem } from '@eservices/shared/type';
import { Form, List, Space } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { Colors } from '@eservices/ui-constants/colors';
import { useTranslation } from '../../hooks/translationHook';
import styled from 'styled-components';
import { PrimaryButton, RemoveButton } from '../Buttons';
import { InputQuantity } from './Catalogue';
import ProductItem from './ProductItem';

const StyledList = styled(List)`
    width: 100%;
`;

const ActionContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

interface CartProps {
    form: FormInstance;
    items: OrderItem[];
    setItems: (items: OrderItem[]) => void;
    purchaseItems: () => void;
}

const Cart: React.FC<CartProps> = ({ form, items, setItems, purchaseItems }) => {
    const { t } = useTranslation('order');

    const onChangeCartItem = async (productCode: string) => {
        const fields = await form.validateFields();
        const newItems = items.map((item) => {
            const newItem = Object.assign({}, item);
            if (newItem.code === productCode) {
                newItem.amount = fields[`cart_input_${productCode}`];
            }
            return newItem;
        });
        setItems(newItems);
    };

    const handleRemoveItem = (productCode: string) => {
        setItems(items.filter((orderItem: OrderItem) => orderItem.code !== productCode));
    };

    useEffect(() => {
        items.forEach((item: OrderItem) => form.setFieldsValue({ [`cart_input_${item.code}`]: item.amount }));
    }, [items]);

    const renderItem = (item: OrderItem) => {
        const product = ProductsCatalog[item.code];
        return (
            <List.Item
                actions={[
                    <Space>
                        <Form.Item name={`cart_input_${item.code}`} initialValue={item.amount} noStyle>
                            <InputQuantity
                                key={`cart_input_${item.code}`}
                                min={0}
                                max={product.maxAmount}
                                type="number"
                                step={1}
                                aria-label={`cart input quantity ${product.nameKey}`}
                                onChange={() => onChangeCartItem(item.code)}
                            />
                        </Form.Item>
                        <RemoveButton
                            size="small"
                            icon={<DeleteOutlined />}
                            onClick={() => handleRemoveItem(item.code)}
                            shape="circle"
                        />
                    </Space>,
                ]}
            >
                <ProductItem productCode={item.code} />
            </List.Item>
        );
    };

    return (
        <>
            <StyledList itemLayout="horizontal" dataSource={items} renderItem={renderItem} />
            <ActionContainer>
                <Space direction="horizontal">
                    <PrimaryButton
                        icon={<ShoppingCartOutlined />}
                        onClick={purchaseItems}
                        disabled={items.length === 0}
                    >
                        {t('checkout')}
                    </PrimaryButton>
                </Space>
            </ActionContainer>
        </>
    );
};

export default Cart;
