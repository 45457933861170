import { TaskType, WorkflowInputSourceType } from '@eservices/shared/constant';
import { API, TaskEasyPrint, TaskEmail, TaskERDS, TaskQERDS } from '@eservices/shared/type';
import { Colors } from '@eservices/ui-constants/colors';
import { default as React, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../../hooks/translationHook';
import { Header2 } from '../../Headers';
import EasyPrintTaskConfiguration from '../EasyPrintTask/EasyPrintTaskConfiguration';
import EmailTaskConfiguration from '../EmailTask/EmailTaskConfiguration';
import ErdsTaskConfiguration from '../ErdsTask/ErdsTaskConfiguration';
import QerdsTaskConfiguration from '../QerdsTask/QerdsTaskConfiguration';

const Errors = styled.ul`
    color: ${Colors.BAD};
`;

interface TaskTypeCheck {
    name: string;
    value: boolean;
    optional?: boolean;
}

interface TasksOverviewProps {
    departmentDetails: API.Department;
    order: number;
    isReadOnly: boolean;
    companyId: string;
    task?: API.TaskConfiguration;
    taskType: TaskType;
    onCancel: () => void;
    onOk: (order: number, task: API.TaskConfiguration) => void;
    onDelete?: (order: number) => void;
    checks?: TaskTypeCheck[];
    workflow: API.WorkflowConfiguration;
}

const TaskDetail: React.FC<TasksOverviewProps> = ({
    departmentDetails,
    task,
    taskType,
    onCancel,
    onOk,
    onDelete,
    isReadOnly,
    companyId,
    order,
    workflow,
}) => {
    const { t } = useTranslation('workflow');
    const [errors, setErrors] = useState([]);

    const cleanup = () => {
        setErrors([]);
    };

    const placeholders: string[] = [];
    switch (workflow.inputSource.type) {
        case WorkflowInputSourceType.PDF:
            placeholders.push(...workflow.inputSource.extraInfoIdentifiers.map((i) => i.name));
            break;
        case WorkflowInputSourceType.CSV:
            placeholders.push(...workflow.inputSource.extraInfoIdentifiers.map((i) => i.column.label));
            break;
        default:
            break;
    }

    const sharedTaskProps = {
        order,
        isReadOnly,
        companyId,
        allowedToSave: !isReadOnly,
        onSave: (order: number, task: API.TaskConfiguration) => {
            cleanup();
            onOk(order, task);
        },
        onCancel: () => {
            cleanup();
            onCancel();
        },
        onError: setErrors,
        onDelete: () => onDelete(order),
    };

    let detail;
    switch (taskType) {
        case TaskType.EASY_PRINT:
            detail = <EasyPrintTaskConfiguration {...sharedTaskProps} task={task as TaskEasyPrint} />;
            break;
        case TaskType.ERDS:
            detail = (
                <ErdsTaskConfiguration
                    {...sharedTaskProps}
                    task={task as TaskERDS}
                    workflow={workflow}
                    placeholders={placeholders}
                />
            );
            break;
        case TaskType.QERDS:
            detail = <QerdsTaskConfiguration {...sharedTaskProps} task={task as TaskQERDS} workflow={workflow} />;
            break;
        case TaskType.EMAIL:
            detail = (
                <EmailTaskConfiguration
                    {...sharedTaskProps}
                    task={task as TaskEmail}
                    workflow={workflow}
                    placeholders={placeholders}
                    departmentDetails={departmentDetails}
                />
            );
            break;

        default:
            detail = <>Task Not supported</>;
    }

    return (
        <div>
            <Header2>{task ? task.name : t('new_task')}</Header2>
            {errors.length > 0 && (
                <Errors>
                    {errors.map((error) => (
                        <li key={error}>{error}</li>
                    ))}
                </Errors>
            )}
            {detail}
        </div>
    );
};

export default TaskDetail;
