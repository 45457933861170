import { API } from '@eservices/shared/type';
import { Space } from 'antd';
import React from 'react';
import { useTranslation } from '../../../../../hooks/translationHook';
import RegionButton from '../Common/RegionButton';

interface EmptyTextIgnoreProps {
    identifier: API.PDFIgnoreIdentifier;
}

const EmptyTextIgnore: React.FC<EmptyTextIgnoreProps> = ({ identifier }) => {
    const { t } = useTranslation('workflow');

    return (
        <Space>
            {t('ignore_overview_part1')}
            <RegionButton regionOfInterest={identifier.regionOfInterest} />
            <strong>{t('ignore_overview_empty_text_part2')}</strong>
        </Space>
    );
};

export default EmptyTextIgnore;
