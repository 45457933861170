import { DownloadOutlined } from '@ant-design/icons';
import { VignetteType } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { useCallback, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import { useTranslation } from '../../hooks/translationHook';
import { PrimaryButton } from '../Buttons';
import Card from '../Card';
import PrintableVignette from './PrintableVignette';
import VignetteComponent from './VignetteComponent';
import VignettePreview from './VignettePreview';

const Container = styled.div`
    width: 100%;
`;

const HiddenContainer = styled.div`
    display: none;
`;

const StyledCard = styled(Card)``;

const Actions = styled.div`
    display: flex;
    justify-content: center;
    margin: 5% 0 2% 0;
`;

interface VignetteCardProps {
    companyId: string;
    type: VignetteType;
    department: API.DepartmentListItem;
    isLoading: boolean;
}

const VignetteCard: React.FC<VignetteCardProps> = ({ companyId, type, department, isLoading }) => {
    const { t } = useTranslation('vignettes');
    const vignetteRef = useRef(null);
    const reactToPrintContent = useCallback(() => {
        return vignetteRef.current;
    }, [vignetteRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: 'vignette',
        removeAfterPrint: true,
    });

    const wrapper = (children: React.ReactNode) => (
        <StyledCard title={`${t(type)}`} loading={isLoading}>
            {children}
        </StyledCard>
    );

    if (!department) return wrapper(t('department_required'));

    return wrapper(
        <Container>
            <VignettePreview type={type} department={department} />
            <HiddenContainer>
                <PrintableVignette
                    vignette={<VignetteComponent type={type} department={department} />}
                    printRef={vignetteRef}
                />
            </HiddenContainer>
            <Actions>
                <PrimaryButton icon={<DownloadOutlined />} onClick={handlePrint}>
                    {t('download')}
                </PrimaryButton>
            </Actions>
        </Container>,
    );
};

export default VignetteCard;
