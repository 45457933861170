import { API } from '@eservices/shared/type';
import { FormInstance } from 'antd/lib/form/Form';
import { useState } from 'react';
import Recipients from './Recipients';
import Sender from './Sender';
import styled from 'styled-components';
import { RecipientsActions } from '../../screens/parcels/recipientReducer';
import Card from '../Card';

const Container = styled.div`
    display: flex;
    justify-content: justify;
    gap: 5%;
`;

const RecipientPart = styled(Card)`
    min-width: 600px;
`;

interface ParcelCreatorProps {
    company: API.Company;
    departments: API.DepartmentListItem[];
    senderForm: FormInstance<API.ParcelSender>;
    setUploadedFileKey: (uploadedFileKey: string) => void;
    uploadedFileKey: string;
    recipients: Array<API.RecipientWithParcelInfo>;
    recipientsDispatch: React.Dispatch<RecipientsActions>;
}

const ParcelCreator: React.FC<ParcelCreatorProps> = ({
    company,
    departments,
    senderForm,
    recipients,
    recipientsDispatch,
    setUploadedFileKey,
    uploadedFileKey,
}) => {
    const [senderAddressDirtyFlag, setSenderAddressDirtyFlag] = useState(false);
    const [senderDepartmentId, setSenderDepartmentId] = useState<string>(departments[0].id);

    return (
        <Container>
            <Sender
                company={company}
                form={senderForm}
                departmentId={senderDepartmentId}
                onChangeDepartment={(departmentId) => {
                    setSenderAddressDirtyFlag(false);
                    setSenderDepartmentId(departmentId);
                }}
                senderAddressDirtyFlag={senderAddressDirtyFlag}
            />
            <RecipientPart>
                <Recipients
                    companyId={company.id}
                    recipients={recipients}
                    recipientsDispatch={recipientsDispatch}
                    setUploadedFileKey={setUploadedFileKey}
                    uploadedFileKey={uploadedFileKey}
                />
            </RecipientPart>
        </Container>
    );
};

export default ParcelCreator;
