import { message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import styled from 'styled-components';
import { PrimaryButton } from '../../components/Buttons';
import Card from '../../components/Card';
import ChangePasswordForm from '../../components/profile/ChangePasswordForm';
import { useAuth } from '../../context/auth-context';
import { useTranslation } from '../../hooks/translationHook';

const Actions = styled.div`
    margin: 24px 0 0 0;
    display: flex;
    justify-content: flex-end;
`;

const List = styled.ul`
    padding: 10px;
`;

interface ChangePasswordFormProps {
    oldPassword: string;
    newPassword: string;
    repeatNewPassword: string;
}

const ChangePassword: React.FC = () => {
    const { t, tValidation } = useTranslation('profile');
    const [form] = useForm<ChangePasswordFormProps>();
    const { changePassword } = useAuth();

    const {
        execute: modifyPassword,
        error,
        status,
    } = useAsyncCallback(async (oldPassword: string, newPassword: string) => {
        await changePassword(oldPassword, newPassword);
    });

    const updatePassword = async () => {
        const { oldPassword, newPassword } = await form.validateFields();
        await modifyPassword(oldPassword, newPassword);
    };

    useEffect(() => {
        switch (status) {
            case 'error':
                void message.error(tValidation(error.name), 3);
                form.resetFields();
                break;
            case 'success':
                void message.success(
                    t('editing_profile_password_success', { username: form.getFieldValue('username') }),
                    3,
                );
                form.resetFields();
                break;
        }
    }, [status]);

    const actions = <PrimaryButton onClick={updatePassword}>{t('change_password')}</PrimaryButton>;

    return (
        <Card title={t('change_password')}>
            <ChangePasswordForm form={form} />
            <Card title={t('password_requirements')} type="inner">
                <List>
                    <li>{t('password_requirement_length')}</li>
                    <li>{t('password_requirement_content')}</li>
                    <li>{t('password_requirement_case')}</li>
                    <li>{t('password_requirement_special')}</li>
                </List>
            </Card>
            <Actions>{actions}</Actions>
        </Card>
    );
};

export default ChangePassword;
