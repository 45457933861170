import { WorkflowInputSourceType } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { FormInstance, Tabs } from 'antd';
import styled from 'styled-components';
import { useTranslation } from '../../hooks/translationHook';
import { WatermarkUI } from '../../types';
import GeneralConfiguration from './GeneralConfiguration';
import InputSourceConfiguration from './InputSourceConfiguration';
import { WorkflowPDFInputSourceActions } from './PDFInputSourceConfiguration/IdentifiersManager/workflowPDFInputSourceReducer';
import TaskManager from './TaskManager';
import { TasksActions } from './TaskManager/taskReducer';
const { TabPane } = Tabs;

const Container = styled.div``;

interface WorkflowDetailEditProps {
    departmentDetails: API.Department;
    isReadOnly: boolean;
    workflow: API.WorkflowConfiguration;
    companyId: string;
    form: FormInstance;
    tasks: Array<API.TaskConfiguration>;
    tasksDispatch: React.Dispatch<TasksActions>;
    workflowPDFInputSource: API.WorkflowPDFInputSource;
    workflowPDFInputSourceDispatch: React.Dispatch<WorkflowPDFInputSourceActions>;
    setWatermark: React.Dispatch<React.SetStateAction<WatermarkUI>>;
    watermark: WatermarkUI;
    isLoading: boolean;
    sampleFile: API.ConfigurationFile;
    setSampleFile: React.Dispatch<React.SetStateAction<API.ConfigurationFile>>;
}

const WorkflowDetailEdit: React.FC<WorkflowDetailEditProps> = ({
    departmentDetails,
    isLoading,
    isReadOnly,
    form,
    tasks,
    tasksDispatch,
    workflowPDFInputSource,
    workflowPDFInputSourceDispatch,
    setWatermark,
    watermark,
    companyId,
    workflow,
    sampleFile,
    setSampleFile,
}) => {
    const { t } = useTranslation('workflow');

    return (
        <Container>
            <Tabs>
                <TabPane tab={t('tasks')} key="tasks">
                    <TaskManager
                        companyId={companyId}
                        departmentDetails={departmentDetails}
                        isReadOnly={isReadOnly}
                        workflow={workflow}
                        tasks={tasks}
                        tasksDispatch={tasksDispatch}
                        isLoading={isLoading}
                    />
                </TabPane>
                <TabPane tab="Identifiers" key="identifiers">
                    <InputSourceConfiguration
                        inputSourceType={WorkflowInputSourceType.PDF}
                        isReadOnly={isReadOnly}
                        tasks={tasks}
                        companyId={companyId}
                        workflowPDFInputSource={workflowPDFInputSource}
                        workflowPDFInputSourceDispatch={workflowPDFInputSourceDispatch}
                        setWatermark={setWatermark}
                        watermark={watermark}
                        form={form}
                        sampleFile={sampleFile}
                        setSampleFile={setSampleFile}
                    />
                </TabPane>
                <TabPane tab={t('details')} key="details" forceRender>
                    <GeneralConfiguration
                        workflowId={workflow.id}
                        isReadOnly={isReadOnly}
                        form={form}
                        companyId={companyId}
                    />
                </TabPane>
            </Tabs>
        </Container>
    );
};

export default WorkflowDetailEdit;
