import {
    CheckCircleFilled,
    EllipsisOutlined,
    EyeOutlined,
    FireFilled,
    Loading3QuartersOutlined,
} from '@ant-design/icons';
import { SendingEventBusinessStatus } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Colors } from '@eservices/ui-constants/colors';
import { Space, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../hooks/translationHook';
import { getPaginationDetails, toReadableDateTime } from '../../utils/helpers';
import { IconButton } from '../Buttons';
import RecipientIndicator from '../RecipientIndicator';
import Table from '../Table';
import SendingEventDetailFetching from './SendingEventDetailFetching';

const tooltipDelay = 1;

const InfoItem = styled.div`
    padding-left: 20px;
`;

interface SendingEventsTableProps {
    companyId: string;
    sendingEvents: API.SendingEventListItem[];
    total: number;
    isLoading: boolean;
    onPaginationChange: (page: number, pageSize?: number) => void;
    departments: API.DepartmentListItem[];
    workflows: API.WorkflowConfigurationListItem[];
    pagination: { page: number; pageSize: number };
}

const SendingEventsTable: React.FC<SendingEventsTableProps> = ({
    companyId,
    sendingEvents = [],
    total,
    isLoading,
    onPaginationChange,
    departments,
    workflows,
    pagination,
}) => {
    const { tCommon } = useTranslation('se_overview');
    const [selectedSE, setSelectedSE] = useState<API.SendingEventListItem>();

    const paginationConfig = getPaginationDetails(total, {
        current: pagination.page,
        pageSize: pagination.pageSize,
        onChange: onPaginationChange,
        showSizeChanger: true,
    });

    const expandedRowRender = (se: API.SendingEventListItem) => {
        return (
            <Space direction="vertical">
                <InfoItem>
                    {se.recipientInfo.map((recipientInfo) => (
                        <RecipientIndicator type={recipientInfo.taskType} recipient={recipientInfo.value} />
                    ))}
                </InfoItem>
                {se.extraInfo.map((extraInfo) => (
                    <InfoItem>{`${extraInfo.name} : ${extraInfo.value}`}</InfoItem>
                ))}
            </Space>
        );
    };

    const columns: ColumnsType<API.SendingEventListItem> = [
        {
            title: tCommon('id'),
            dataIndex: 'id',
        },
        {
            title: tCommon('job_name'),
            dataIndex: 'jobName',
        },
        {
            title: tCommon('department'),
            render: (record: API.JobListItem) =>
                departments.find((department) => department.id === record.departmentId)?.name,
        },
        {
            title: tCommon('workflow'),
            render: (record: API.JobListItem) => workflows.find((workflow) => workflow.id === record.workflowId)?.name,
        },
        {
            title: tCommon('status'),
            align: 'center',
            width: 10,
            render: ({ businessStatus }: API.SendingEventListItem) => (
                <Tooltip title={tCommon(businessStatus.toLocaleLowerCase())} mouseEnterDelay={tooltipDelay}>
                    {businessStatus === SendingEventBusinessStatus.SUCCESS && (
                        <CheckCircleFilled style={{ color: Colors[businessStatus] }} />
                    )}
                    {businessStatus === SendingEventBusinessStatus.IN_PROGRESS && (
                        <Loading3QuartersOutlined style={{ color: Colors[businessStatus] }} />
                    )}
                    {businessStatus === SendingEventBusinessStatus.FAILURE && (
                        <FireFilled style={{ color: Colors[businessStatus] }} />
                    )}
                    {businessStatus === SendingEventBusinessStatus.NOT_STARTED && (
                        <EllipsisOutlined style={{ color: Colors[businessStatus] }} />
                    )}
                </Tooltip>
            ),
        },
        {
            title: tCommon('start_date'),
            align: 'right',
            render: ({ startDate }: API.SendingEventListItem) => startDate && toReadableDateTime(startDate),
        },
        {
            align: 'right',
            render: (se: API.SendingEventListItem) => (
                <>
                    <Space>
                        <IconButton icon={<EyeOutlined />} onClick={() => setSelectedSE(se)} />
                    </Space>
                </>
            ),
        },
    ];

    return (
        <>
            <Table
                loading={isLoading}
                rowKey={({ id, jobId }: API.SendingEventListItem) => `${id}${jobId}`}
                columns={columns}
                dataSource={sendingEvents}
                pagination={paginationConfig}
                expandable={{
                    expandedRowRender,
                    expandRowByClick: true,
                }}
            />
            {selectedSE && (
                <SendingEventDetailFetching
                    companyId={companyId}
                    departmentId={selectedSE.departmentId}
                    jobId={selectedSE.jobId}
                    seId={selectedSE.id}
                    handleOnClose={() => setSelectedSE(undefined)}
                />
            )}
        </>
    );
};

export default SendingEventsTable;
