import { API } from '@eservices/shared/type';
import React, { useEffect } from 'react';
import { useGetDownloadUrl } from '../hooks/apiHooks';

interface DownloadLinkProps {
    companyId: string;
    source: API.FileSource;
    location: string;
    content: React.ReactElement;
}

const DownloadLink: React.FC<DownloadLinkProps> = ({ companyId, source, location, content }) => {
    const getDownloadUrlMutation = useGetDownloadUrl(companyId);

    useEffect(() => {
        if (getDownloadUrlMutation.data) {
            const downloadUrl = getDownloadUrlMutation.data;
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.target = '_blank';
            const downloadUrlParts = downloadUrl.split;
            a.download = downloadUrlParts[downloadUrlParts.length - 1];
            a.click();
        }
    }, [getDownloadUrlMutation.data]);

    const downloadFile = (location: string) => {
        if (location) {
            getDownloadUrlMutation.mutate({ location, source });
        }
    };

    return (
        <a
            onClick={(e) => {
                e.preventDefault();
                downloadFile(location);
            }}
        >
            {content}
        </a>
    );
};

export default DownloadLink;
