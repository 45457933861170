import { ProductCode, ProductsCatalog } from '@eservices/shared/constant';
import { Col, Image as AntdImage, Row } from 'antd';
import styled from 'styled-components';
import { ProductPictures } from '../../constants';
import { useTranslation } from '../../hooks/translationHook';

const Image = styled(AntdImage)`
    min-width: 65px;
    width: 7%;
`;

interface ProductItemProps {
    productCode: ProductCode;
    amount?: number;
}

const ProductItem: React.FC<ProductItemProps> = ({ productCode, amount }) => {
    const { t } = useTranslation('order');
    const productName = t(ProductsCatalog[productCode].nameKey);
    return (
        <Row align="middle">
            <Col span={4}>
                <Image src={ProductPictures[productCode]} alt={productName} preview={false} />
            </Col>
            {amount ? (
                <Col offset={1} span={8}>{`${productName} ${amount} u`}</Col>
            ) : (
                <Col offset={10} span={10}>
                    {productName}
                </Col>
            )}
        </Row>
    );
};

export default ProductItem;
