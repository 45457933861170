import { TaskType } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { useEffect } from 'react';
import { useGetDownloadUrl, useGetSendingEvent } from '../../hooks/apiHooks';
import FailedToLoadData from '../FailedToLoadData';
import PDFViewer from '../PDFViewer';

interface SendingEventPDFPreviewProps {
    companyId: string;
    job: API.JobListItem;
    seId: string;
}

const SendingEventPDFPreview: React.FC<SendingEventPDFPreviewProps> = ({ companyId, job, seId }) => {
    const { data: sendingEvent, isError: isErrorSendingEvent } = useGetSendingEvent(
        companyId,
        job.departmentId,
        job.id,
        seId,
    );
    const { mutate, data: pdfUrl, isError: isErrorLoadingFile } = useGetDownloadUrl(companyId);

    useEffect(() => {
        if (sendingEvent)
            mutate({
                location: sendingEvent.taskDetails.find((task) => task.taskType === TaskType.QERDS)?.location,
                source: API.FileSource.SENDING_EVENT,
            });
    }, [sendingEvent]);

    if (isErrorSendingEvent || isErrorLoadingFile) return <FailedToLoadData />;

    return (
        <div>
            <PDFViewer scrollThroughDocument pdfUrl={pdfUrl} />
        </div>
    );
};

export default SendingEventPDFPreview;
