import { TaskType, WorkflowType } from '@eservices/shared/constant';
import { Tag } from 'antd';
import React from 'react';

const colors = {
    [WorkflowType.ADVANCED]: 'gold',
};

interface WorkflowTypeIndicatorProps {
    type: WorkflowType;
}

const WorkflowTypeIndicator: React.FC<WorkflowTypeIndicatorProps> = ({ type }) => (
    <Tag color={colors[type]}>{type}</Tag>
);

export default WorkflowTypeIndicator;
