import { API } from '@eservices/shared/type';
import { Col, Row } from 'antd';
import { useBpostCountries } from '../../hooks/bpostCountriesHook';
import { useTranslation } from '../../hooks/translationHook';
import DescriptionItem from '../DescriptionItem';
import { Header3 } from '../Headers';

interface ParcelDetailSubDetailRecipientProps {
    parcel: API.Parcel;
}

const ParcelDetailSubDetailRecipient: React.FC<ParcelDetailSubDetailRecipientProps> = ({ parcel }) => {
    const { t, tCommon } = useTranslation('parcel_detail');
    const { findCountryName } = useBpostCountries();

    const { recipient } = parcel;

    return (
        <div>
            <Header3>{tCommon('recipient')}</Header3>
            <Row>
                <Col span={12}>
                    <DescriptionItem title={tCommon('company')} content={recipient.companyName} />
                </Col>
                <Col span={12}>
                    <DescriptionItem title={tCommon('name')} content={recipient.name} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem
                        title={tCommon('address')}
                        content={
                            <div>
                                {recipient.address.street} {recipient.address.streetNr} {recipient.address.box},
                                {recipient.address.postalCode} {recipient.address.city},{' '}
                                {findCountryName(recipient.address.countryCode)}
                            </div>
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem title={tCommon('email')} content={recipient.email} />
                </Col>
            </Row>
        </div>
    );
};

export default ParcelDetailSubDetailRecipient;
