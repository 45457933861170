import { message, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useHistory } from 'react-router-dom';
import SetupTOTPForm from '../../components/profile/SetupTOTPForm';
import { Routes } from '../../constants';
import { useAuth } from '../../context/auth-context';
import { useSetupMFALoginForMyUser } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';

interface setupTOTPFormProps {
    confirmationCode: string;
}

const SetupTOTPFetching: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation('profile');
    const { getTOTPSecretKey, verifyTOTPCode, signOut } = useAuth();
    const [form] = useForm<setupTOTPFormProps>();

    const setupMFALoginMutation = useSetupMFALoginForMyUser();

    const {
        execute: fetchTOTPKSecretKey,
        loading: isSecretKeyLoading,
        result: secretKey,
    } = useAsyncCallback(async () => {
        return await getTOTPSecretKey();
    });
    const { execute: verifyGeneratedCode, status: verificationStatus } = useAsyncCallback(
        async (confirmationCode: string) => {
            await verifyTOTPCode(confirmationCode);
        },
    );

    useEffect(() => {
        void fetchTOTPKSecretKey();
    }, []);

    useEffect(() => {
        switch (verificationStatus) {
            case 'success':
                form.resetFields();
                setupMFALoginMutation.mutate(true, {
                    onSuccess: async () => {
                        void message.success(t('mfa_setup_complete_logout'), 3);
                        await signOut();
                        history.push(Routes.ROOT);
                    },
                    onError: () => {
                        void message.error(t('mfa_setup_incomplete_unexpected'), 3);
                    },
                });
                break;
            case 'error':
                void message.error(t('mfa_setup_code_mismatch'), 3);
                form.resetFields();
                break;
        }
    }, [verificationStatus]);

    const onCodeFilled = async () => {
        const { confirmationCode } = await form.validateFields();
        await verifyGeneratedCode(confirmationCode);
    };

    if (isSecretKeyLoading) return <Spin />;

    return (
        <>
            <SetupTOTPForm
                onCodeFilled={!setupMFALoginMutation.isLoading && onCodeFilled}
                form={form}
                secretKey={secretKey}
            />
        </>
    );
};

export default SetupTOTPFetching;
