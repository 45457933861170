import Icon from '@ant-design/icons';
import MailOutlined from '@ant-design/icons/lib/icons/MailOutlined';
import PrinterOutlined from '@ant-design/icons/lib/icons/PrinterOutlined';
import { ProductCode, RecipientIdentifierKind, TaskType, UserRole } from '@eservices/shared/constant';
import {
    PDFExtraInfoIdentifier,
    PDFIgnoreIdentifier,
    PDFRecipientIdentifier,
    PDFSplitIdentifier,
} from '@eservices/shared/type';
import TiewrapsBEPicture from './assets/images/B001.png';
import BarcodesRegNormalPicture from './assets/images/B003.png';
import BarcodesRegA4Picture from './assets/images/B004.png';
import BarcodesRegInternationalPicture from './assets/images/B005.png';
import ArCardsPicture from './assets/images/B006.png';
import YellowBagsPicture from './assets/images/B008.png';
import RedBagsBEPicture from './assets/images/B009.png';
import BlueCratesPicture from './assets/images/B010.png';
import TransparentBagsPicture from './assets/images/B011.png';
import BarcodesA42082BCPicture from './assets/images/B012.png';
import GreyCratesPicture from './assets/images/B013.png';
import BpostOnTheRoadIcon from './assets/images/bpost-on-the-road.svg';
import CreatedInPortalIcon from './assets/images/created-in-portal.svg';
import DeliveredAtEasyPostIcon from './assets/images/delivered-at-easypost.svg';
import DeliveredByBpostIcon from './assets/images/delivered-by-bpost.svg';
import { ReactComponent as ERDSLogo } from './assets/images/erds.svg';
import OrangeCratesFRPicture from './assets/images/F004.png';
import LiasseRecommandePicture from './assets/images/F006.png';
import LiasseRecommandeARPicture from './assets/images/F007.png';
import ImageNotAvailable from './assets/images/image_not_available.jpg';
import { ReactComponent as QERDSLogo } from './assets/images/qerds.svg';

const TiewrapsFRPicture = TiewrapsBEPicture;
const RedBagsFRPicture = RedBagsBEPicture;
const YellowBagsFRPicture = YellowBagsPicture;
const GreyCratesFRPicture = GreyCratesPicture;

export enum Fonts {
    ARIAL_REGULAR = 'ArialRegular',
    HURME1 = 'HurmeGeometricSans1',
    HURME4 = 'HurmeGeometricSans4',
    HURME4_LIGHT = 'HurmeGeometricSans4Light',
    HURME4_BOLD = 'HurmeGeometricSans4Bold',
    HURME4_SEMI_BOLD = 'HurmeGeometricSans4SemiBold',
    ARIAL_BOLD = 'ArialBold',
}

export enum CognitoChallenges {
    NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
    SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
}

export enum CognitoExceptions {
    NOT_AUTHORIZED = 'NotAuthorizedException',
    USER_NOT_FOUND = 'UserNotFoundException',
    PASSWORD_RESET_REQUIRED = 'PasswordResetRequiredException',
    EXPIRED_CODE = 'ExpiredCodeException',
    CODE_MISMATCH = 'CodeMismatchException',
    INVALID_PASSWORD = 'InvalidPasswordException',
    UNAUTHORIZED = 'UnauthorizedException',
    USER_NOT_CONFIRMED = 'UserNotConfirmedException',
    LIMIT_EXCEEDED = 'LimitExceededException',
}

export const enum Routes {
    ROOT = '/',
    DASHBOARD = '/dashboard',
    WORKFLOWS = '/workflows',
    CREATE_WORKFLOW = `/workflows/create`,
    EDIT_WORKFLOW = `/workflows/:departmentId/edit/:id`,
    EDIT_WORKFLOW_TASK = `/workflows/:departmentId/edit/:id/task/:order`,
    ADD_WORKFLOW_TASK = `/workflows/:departmentId/add/:id/task/:taskType`,
    VIEW_WORKFLOW = `/workflows/:departmentId/view/:id`,
    VIEW_WORKFLOW_TASK = `/workflows/:departmentId/view/:id/task/:order`,
    JOBS = '/jobs',
    SUBMIT_JOB = '/jobs/submit',
    DEPARTMENTS = '/departments',
    CREATE_DEPARTMENT = `/departments/create`,
    EDIT_DEPARTMENT = `/departments/edit/:id`,
    VIEW_DEPARTMENT = `/departments/view/:id`,
    COMPANIES = '/companies',
    CREATE_COMPANY = `/companies/new`,
    EDIT_COMPANY = `/companies/edit/:id`,
    VIEW_COMPANY = `/companies/view/:id`,
    SENDING_EVENTS = '/sending-events',
    USERS = '/users',
    CREATE_USER = '/users/new',
    EDIT_USER = '/users/edit/:id',
    VIEW_USER = '/users/view/:id',
    PROFILE = '/profile',
    CONFIRM_PASSWORD = '/confirm-password',
    CONFIRM_MFA_CODE = '/confirm-mfa-code',
    FORGOT_PASSWORD_FORM = '/forgot-password',
    FORGOT_PASSWORD_SUBMIT_FORM = '/forgot-password-submit',
    POLICIES = '/policies',
    POLICIES_POLICY_VIEWER = '/policies/:policyId',
    //EASYPOST PORTAL
    PARCELS = '/parcels',
    CREATE_PARCEL = '/parcels/create',
    ORDERS = '/orders',
    VIGNETTES = '/vignettes',
    REGISTERED_MAIL = '/registered-mail',
    DAILY_ANNOUNCEMENTS = '/daily-announcements',
    CREATE_DAILY_ANNOUNCEMENT = '/daily-announcements/create',
}

export const EASYPOST_ROUTES = [
    Routes.PARCELS.split('/')[1],
    Routes.CREATE_PARCEL.split('/')[1],
    Routes.ORDERS.split('/')[1],
    Routes.VIGNETTES.split('/')[1],
    Routes.REGISTERED_MAIL.split('/')[1],
    Routes.DAILY_ANNOUNCEMENTS.split('/')[1],
];

export const OMNI_ROUTES = [
    Routes.PROFILE.split('/')[1],
    Routes.DASHBOARD.split('/')[1],
    Routes.POLICIES.split('/')[1],
    Routes.DEPARTMENTS.split('/')[1],
    Routes.USERS.split('/')[1],
];

export const localStorageModuleKey = 'module';

export const assignableRolesPerDepartment = [
    UserRole.CLIENT_DEPARTMENT_ADMIN,
    UserRole.CLIENT_OPERATOR,
    UserRole.CLIENT_VIEWER,
];

export const iconsPerTask = {
    [TaskType.EASY_PRINT]: <PrinterOutlined />,
    [TaskType.ERDS]: <Icon component={ERDSLogo} />,
    [TaskType.QERDS]: <Icon component={QERDSLogo} />,
    [TaskType.EMAIL]: <MailOutlined />,
};

export const TABLE_PAGE_SIZE = 20;

export const IMAGE_NOT_AVAILABLE = ImageNotAvailable;

export const ProductPictures: Record<ProductCode, string> = {
    [ProductCode.TIEWRAPS_BE]: TiewrapsBEPicture,
    // [ProductCode.PRIOR_LABELS]: ImageNotAvailable, REMOVED upon request of Bart
    [ProductCode.BARCODES_REG_NORMAL]: BarcodesRegNormalPicture,
    [ProductCode.BARCODES_REG_A4_203_12_POD]: BarcodesRegA4Picture,
    [ProductCode.BARCODES_REG_INTERNATIONAL]: BarcodesRegInternationalPicture,
    [ProductCode.AR_CARDS]: ArCardsPicture,
    // [ProductCode.LETTER_AS_PARCEL]: ImageNotAvailable, REMOVED upon request of Bart
    [ProductCode.YELLOW_BAGS]: YellowBagsPicture,
    [ProductCode.RED_BAGS_BE]: RedBagsBEPicture,
    [ProductCode.BLUE_CRATES]: BlueCratesPicture,
    [ProductCode.TRANSPARENT_BAGS]: TransparentBagsPicture,
    [ProductCode.BARCODES_REG_A4_208_BC]: BarcodesA42082BCPicture,
    [ProductCode.GREY_CRATES]: GreyCratesPicture,
    [ProductCode.YELLOW_BAGS_FR]: YellowBagsFRPicture,
    [ProductCode.RED_BAGS_FR]: RedBagsFRPicture,
    [ProductCode.GREY_CRATES_FR]: GreyCratesFRPicture,
    [ProductCode.ORANGE_CRATES_FR]: OrangeCratesFRPicture,
    [ProductCode.TIEWRAPS_FR]: TiewrapsFRPicture,
    [ProductCode.LIASSE_RECOMMANDE]: LiasseRecommandePicture,
    [ProductCode.LIASSE_RECOMMANDE_AR]: LiasseRecommandeARPicture,
};

export enum IdentifierType {
    RECIPIENT = 'recipient',
    SPLIT = 'split',
    IGNORE = 'ignore',
    EXTRA = 'extra',
    WATERMARK = 'watermark',
    MULTI_RECIPIENT = 'multi_recipient',
}

interface Details {
    needsConfig: boolean;
    fillStyle: string;
    strokeStyle: string;
}

interface IIdentifiersDetails {
    [IdentifierType.RECIPIENT]: Details & {
        defaultConfig: Omit<PDFRecipientIdentifier, 'regionOfInterest'>;
    };
    [IdentifierType.SPLIT]: Details & {
        defaultConfig: Omit<PDFSplitIdentifier, 'regionOfInterest' | 'kind' | 'value'>;
    };
    [IdentifierType.IGNORE]: Details & {
        defaultConfig: Omit<PDFIgnoreIdentifier, 'regionOfInterest' | 'kind'>;
    };
    [IdentifierType.EXTRA]: Details & {
        defaultConfig: Omit<PDFExtraInfoIdentifier, 'regionOfInterest' | 'name'>;
    };
}

export const IdentifiersDetails: IIdentifiersDetails = {
    [IdentifierType.RECIPIENT]: {
        needsConfig: false,
        fillStyle: '#FF262652',
        strokeStyle: 'black',
        defaultConfig: {
            kind: RecipientIdentifierKind.ADDRESS_ENGINE,
        },
    },
    [IdentifierType.SPLIT]: {
        needsConfig: true,
        fillStyle: '#0459FF52',
        strokeStyle: 'black',
        defaultConfig: {
            splitOnChange: false,
        },
    },
    [IdentifierType.IGNORE]: {
        needsConfig: true,
        fillStyle: '#51D08452',
        strokeStyle: 'black',
        defaultConfig: {},
    },
    [IdentifierType.EXTRA]: {
        needsConfig: true,
        fillStyle: '#e550da52',
        strokeStyle: 'black',
        defaultConfig: {},
    },
};

export enum SubscriptionContext {
    WORKFLOW = 'Workflows',
    DEPARTMENT = 'Departments',
    USER = 'Users',
}

//TODO add missing icons for statuses
export const bpostStatusIcons = {
    LABEL_REQUEST_RECEIVED: CreatedInPortalIcon,
    LABEL_CREATED: CreatedInPortalIcon,
    LABEL_MERGED: CreatedInPortalIcon,
    PARCEL_SORTED: DeliveredAtEasyPostIcon,
    PARCEL_OUT_FOR_DELIVERY: BpostOnTheRoadIcon,
    CUSTOMER_REFUSED_PARCEL: BpostOnTheRoadIcon,
    PARCEL_DELIVERED: DeliveredByBpostIcon,
    BACK_TO_SENDER_PARCEL_OUT_FOR_DELIVERY: BpostOnTheRoadIcon,
    BACK_TO_SENDER_PARCEL_DELIVERED: BpostOnTheRoadIcon,
    CUSTOMER_NOT_AT_HOME_MESSAGE_LETTER_BOX: BpostOnTheRoadIcon,
    PARCEL_ARRIVED_IN_POST_OFFICE_OR_POSTAL_POINT: BpostOnTheRoadIcon,
    PARCEL_NOT_COLLECTED_AT_POST_OFFICE_OR_POSTAL_POINT: BpostOnTheRoadIcon,
    NOT_DELIVERED_WRONG_ADDRESS: BpostOnTheRoadIcon,
};

export enum TaskActionType {
    RETRACT_QERDS = 'retract-qerds',
}
