import { TaskType } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { getSecondsFrom } from '../../../utils/helpers';

export type QerdsTaskFormFields = Omit<API.TaskQERDS, 'maxDurationInSeconds' | 'validationErrors'> & {
    timeToWait: number;
};

export const convertFormToQerdsTask = (form: QerdsTaskFormFields): API.TaskQERDS => {
    const { timeToWait, ...rest } = form;
    let { htmlTemplate, attachment } = form;

    if (!attachment.filename) {
        attachment = undefined;
    }

    if (!htmlTemplate.filename) {
        htmlTemplate = undefined;
    }

    return {
        ...rest,
        taskType: TaskType.QERDS,
        validationErrors: [],
        maxDurationInSeconds: getSecondsFrom(timeToWait),
        attachment,
        htmlTemplate,
    };
};
