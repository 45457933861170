// Easy Post Portal Store Available Items

import { CountryCode } from '.';
import { Product } from '../type';

// Registered Letters
export enum RegisteredMailStatus {
    PROCESSED_BY_EASYPOST = 'PROCESSED_BY_EASYPOST', // Moment at which EasyPost production scans the barcode
    PROCESSED_BY_BPOST = 'PROCESSED_BY_BPOST', // Moment at which the ePod is generated at EasyPost
}

// Orders
export const EASYPOST_ERP_AUTHOR = 'EasyPost.ERP';

// Orders
export enum OrderStatus {
    SUBMITTED = 'SUBMITTED',
    PROCESSING = 'PROCESSING',
    DELIVERED = 'DELIVERED',
}

// EventBridge
export const PORTAL_SOURCE_NAME = 'eu.easypost.portal';
export const PORTAL_EVENT_BUS_NAME = 'PortalEventBus';
export const NEW_ORDER_EVENT_TYPE = 'NEW_ORDER';
export const ORDER_UPDATE_EVENT_TYPE = 'ORDER_UPDATE';
export const INVOICE_ERDS_EVENT_TYPE = 'INVOICE_ERDS_SENDING';
export const COMPANY_UPSERT_EVENT_TYPE = 'COMPANY_UPSERT';
export const DEPARTMENT_UPSERT_EVENT_TYPE = 'DEPARTMENT_UPSERT';
export const USER_UPSERT_EVENT_TYPE = 'USER_UPSERT';
export const CREATE_REGISTERED_MAIL_EVENT_TYPE = 'CREATE_REGISTERED_MAIL';
export const EPOD_REGISTERED_MAIL_EVENT_TYPE = 'EPOD_REGISTERED_MAIL';
export const DAILY_CONSUMPTION_ANNOUNCEMENT_EVENT_TYPE = 'DAILY_CONSUMPTION_ANNOUNCEMENT';

// SQS
export const PORTAL_REGISTERED_MAIL_CREATE_DEAD_LETTER_QUEUE = 'PortalRegisteredMailCreateDeadLetterQueue';
export const PORTAL_REGISTERED_MAIL_UPDATE_DEAD_LETTER_QUEUE = 'PortalRegisteredMailUpdateDeadLetterQueue';
export const PORTAL_ORDER_UPDATE_DEAD_LETTER_QUEUE = 'PortalOrderUpdateDeadLetterQueue';
export const PORTAL_COMPANY_UPSERT_DEAD_LETTER_QUEUE = 'PortalCompanyUpsertDeadLetterQueue';
export const PORTAL_DEPARTMENT_UPSERT_DEAD_LETTER_QUEUE = 'PortalDepartmentUpsertDeadLetterQueue';
export const PORTAL_USER_UPSERT_DEAD_LETTER_QUEUE = 'PortalUserUpsertDeadLetterQueue';

export enum ProductCode {
    TIEWRAPS_BE = 'B001',
    // PRIOR_LABELS = 'B002', REMOVED on request of Bart
    BARCODES_REG_NORMAL = 'B003',
    BARCODES_REG_A4_203_12_POD = 'B004',
    BARCODES_REG_INTERNATIONAL = 'B005',
    AR_CARDS = 'B006',
    // LETTER_AS_PARCEL = 'B007', REMOVED upon request of Bart
    YELLOW_BAGS = 'B008',
    RED_BAGS_BE = 'B009',
    BLUE_CRATES = 'B010',
    TRANSPARENT_BAGS = 'B011',
    BARCODES_REG_A4_208_BC = 'B012',
    GREY_CRATES = 'B013',
    YELLOW_BAGS_FR = 'F001',
    RED_BAGS_FR = 'F002',
    GREY_CRATES_FR = 'F003',
    ORANGE_CRATES_FR = 'F004',
    TIEWRAPS_FR = 'F005',
    LIASSE_RECOMMANDE = 'F006',
    LIASSE_RECOMMANDE_AR = 'F007',
}

export const ProductsCatalog: Record<ProductCode, Product> = {
    [ProductCode.TIEWRAPS_BE]: {
        code: ProductCode.TIEWRAPS_BE,
        nameKey: 'tiewraps',
        imageFilename: 'B001.jpg',
        maxAmount: 1,
        countryCode: CountryCode.BE,
    },
    [ProductCode.BARCODES_REG_NORMAL]: {
        code: ProductCode.BARCODES_REG_NORMAL,
        nameKey: 'barcodes_reg_normal',
        imageFilename: 'B003.jpg',
        maxAmount: 1,
        countryCode: CountryCode.BE,
    },
    [ProductCode.BARCODES_REG_A4_203_12_POD]: {
        code: ProductCode.BARCODES_REG_A4_203_12_POD,
        nameKey: 'barcodes_a4_203_12_POD',
        imageFilename: 'B004.jpg',
        maxAmount: 1,
        countryCode: CountryCode.BE,
    },
    [ProductCode.BARCODES_REG_INTERNATIONAL]: {
        code: ProductCode.BARCODES_REG_INTERNATIONAL,
        nameKey: 'barcode_reg_international',
        imageFilename: 'B005.jpg',
        maxAmount: 1,
        countryCode: CountryCode.BE,
    },
    [ProductCode.AR_CARDS]: {
        code: ProductCode.AR_CARDS,
        nameKey: 'ar_cards',
        imageFilename: 'B006.jpg',
        maxAmount: 1,
        countryCode: CountryCode.BE,
    },
    [ProductCode.YELLOW_BAGS]: {
        code: ProductCode.YELLOW_BAGS,
        nameKey: 'yelow_bags',
        imageFilename: 'B008.jpg',
        maxAmount: 5,
        countryCode: CountryCode.BE,
    },
    [ProductCode.RED_BAGS_BE]: {
        code: ProductCode.RED_BAGS_BE,
        nameKey: 'red_bags',
        imageFilename: 'B009.jpg',
        maxAmount: 5,
        countryCode: CountryCode.BE,
    },
    [ProductCode.BLUE_CRATES]: {
        code: ProductCode.BLUE_CRATES,
        nameKey: 'blue_crates',
        imageFilename: 'B010.jpg',
        maxAmount: 5,
        countryCode: CountryCode.BE,
    },
    [ProductCode.TRANSPARENT_BAGS]: {
        code: ProductCode.TRANSPARENT_BAGS,
        nameKey: 'transparent_bags',
        imageFilename: 'B011.jpg',
        maxAmount: 5,
        countryCode: CountryCode.BE,
    },
    [ProductCode.BARCODES_REG_A4_208_BC]: {
        code: ProductCode.BARCODES_REG_A4_208_BC,
        nameKey: 'barcodes_a4_208_2_BC',
        imageFilename: 'B012.png',
        maxAmount: 1,
        countryCode: CountryCode.BE,
    },
    [ProductCode.GREY_CRATES]: {
        code: ProductCode.GREY_CRATES,
        nameKey: 'grey_crates',
        imageFilename: 'B013.jpg',
        maxAmount: 5,
        countryCode: CountryCode.BE,
    },
    [ProductCode.YELLOW_BAGS_FR]: {
        code: ProductCode.YELLOW_BAGS_FR,
        nameKey: 'yellow_bags',
        imageFilename: 'F001.jpg',
        maxAmount: 5,
        countryCode: CountryCode.FR,
    },
    [ProductCode.RED_BAGS_FR]: {
        code: ProductCode.RED_BAGS_FR,
        nameKey: 'red_bags_fr',
        imageFilename: 'F002.jpg',
        maxAmount: 5,
        countryCode: CountryCode.FR,
    },
    [ProductCode.GREY_CRATES_FR]: {
        code: ProductCode.GREY_CRATES_FR,
        nameKey: 'grey_crates',
        imageFilename: 'F003.jpg',
        maxAmount: 5,
        countryCode: CountryCode.FR,
    },
    [ProductCode.ORANGE_CRATES_FR]: {
        code: ProductCode.ORANGE_CRATES_FR,
        nameKey: 'orange_crates',
        imageFilename: 'F004.jpg',
        maxAmount: 5,
        countryCode: CountryCode.FR,
    },
    [ProductCode.TIEWRAPS_FR]: {
        code: ProductCode.TIEWRAPS_FR,
        nameKey: 'tiewraps_fr',
        imageFilename: 'F005.jpg',
        maxAmount: 5,
        countryCode: CountryCode.FR,
    },
    [ProductCode.LIASSE_RECOMMANDE]: {
        code: ProductCode.LIASSE_RECOMMANDE,
        nameKey: 'liasse_recommande_x10',
        imageFilename: 'F006.jpg',
        maxAmount: 5,
        countryCode: CountryCode.FR,
    },
    [ProductCode.LIASSE_RECOMMANDE_AR]: {
        code: ProductCode.LIASSE_RECOMMANDE_AR,
        nameKey: 'liasse_recommandé_ar_x10',
        imageFilename: 'F007.jpg',
        maxAmount: 5,
        countryCode: CountryCode.FR,
    },
};

export enum VignetteType {
    PRIOR = 'prior',
    NON_PRIOR = 'non_prior',
    REGISTERED = 'registered_mail',
}
