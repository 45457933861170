import { TaskType } from '@eservices/shared/constant';
import React from 'react';
import { iconsPerTask } from '../constants';
import styled from 'styled-components';
import { Space } from 'antd';

const IconWrapper = styled.div`
    margin-right: 8px;
    img {
        width: 20px;
    }
`;

const RecipientWrapper = styled.p`
    display: inline-block;
`;

interface RecipientIndicatorProps {
    type: TaskType;
    recipient: string;
}

const RecipientIndicator: React.FC<RecipientIndicatorProps> = ({ type, recipient }) => (
    <div>
        <Space>
            <IconWrapper>{iconsPerTask[type]}</IconWrapper>
        </Space>
        <RecipientWrapper>{recipient}</RecipientWrapper>
    </div>
);

export default RecipientIndicator;
