import { Result } from 'antd';
import React from 'react';
import { ButtonLink } from '../../components/Links';
import { Routes } from '../../constants';
import { useTranslation } from '../../hooks/translationHook';

const NotFound = () => {
    const { t } = useTranslation('404_page');

    return (
        <Result
            status="404"
            title={t('title')}
            subTitle={t('sub_title')}
            extra={<ButtonLink to={Routes.ROOT}>{t('back_home')}</ButtonLink>}
        />
    );
};

export default NotFound;
