import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import Table from '../../components/Table';
import { Routes } from '../../constants';
import { getPaginationDetails } from '../../utils/helpers';
import { useTranslation } from '../../hooks/translationHook';
import { IconButton } from '../Buttons';

interface CompaniesTableProps {
    companies: API.CompanyListItem[];
    isLoading: boolean;
    isReadOnly?: boolean;
}

const CompaniesTable: React.FC<CompaniesTableProps> = ({ companies = [], isLoading, isReadOnly }) => {
    const { t, tCommon } = useTranslation('company_overview');

    const paginationConfig = getPaginationDetails(companies.length, {});

    const columns: ColumnsType<API.CompanyListItem> = [
        {
            title: tCommon('name'),
            dataIndex: 'name',
        },
        {
            title: tCommon('invoice_reference'),
            align: 'right',
            dataIndex: 'erpId',
        },
    ];

    if (isReadOnly) {
        columns.push({
            width: 10,
            align: 'right',
            render: ({ id }: API.CompanyListItem) => (
                <Link to={Routes.VIEW_COMPANY.replace(':id', id)}>
                    <EyeOutlined />
                </Link>
            ),
        });
    } else {
        columns.push({
            width: 10,
            align: 'right',
            render: ({ id }: API.CompanyListItem) => (
                <IconButton icon={<EditOutlined />} href={Routes.EDIT_COMPANY.replace(':id', id)} />
            ),
        });
    }

    return (
        <Table loading={isLoading} rowKey="id" columns={columns} dataSource={companies} pagination={paginationConfig} />
    );
};

export default CompaniesTable;
