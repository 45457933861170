import { CheckCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { API } from '@eservices/shared/type';
import { Colors } from '@eservices/ui-constants/colors';
import React from 'react';
import styled from 'styled-components';
import { Fonts } from '../../../constants';
import { useTranslation } from '../../../hooks/translationHook';
import TaskCard from './TaskCard';
import { TasksActions } from './taskReducer';

const Tasks = styled.div`
    display: flex;
    flex-direction: column;
`;

const TimelineText = styled.span`
    text-transform: capitalize;
    font-size: 20px;
    font-family: ${Fonts.HURME4_LIGHT};
`;

const TimeLine = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const FinishTimeLine = styled(TimeLine)`
    margin: -2px 0 0 0;
`;

const StartIcon = styled(PlayCircleOutlined)`
    font-size: 20px;
    color: ${Colors.GRAY_PRIMARY};
`;

const FinishIcon = styled(CheckCircleOutlined)`
    font-size: 20px;
    color: ${Colors.GRAY_PRIMARY} !important;
`;

interface TasksOverviewProps {
    selectedTaskOrder: number;
    enabled: boolean;
    workflow: API.WorkflowConfiguration;
    isReadOnly: boolean;
    companyId: string;
    tasks: API.TaskConfiguration[];
    tasksDispatch: React.Dispatch<TasksActions>;
    onTaskSelected: (order: number, task: API.TaskConfiguration) => void;
}

const TasksOverview: React.FC<TasksOverviewProps> = ({
    selectedTaskOrder,
    enabled,
    workflow,
    companyId,
    tasks,
    tasksDispatch,
    onTaskSelected,
}) => {
    const { tCommon } = useTranslation();

    const sensors = useSensors(useSensor(PointerSensor));

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const currentIndex = tasks.findIndex(({ name }) => name === active.id);
            const nextIndex = tasks.findIndex(({ name }) => name === over.id);
            tasksDispatch({ type: 'move', payload: { currentIndex, nextIndex } });
        }
    };

    const line = (
        <svg x1="20" height="30" width="300">
            <line x1="50%" y1="0" x2="50%" y2="30" style={{ stroke: Colors.DARK_SECONDARY, strokeWidth: 1 }} />
        </svg>
    );

    const Start = (
        <TimeLine>
            <TimelineText>{tCommon('start')}</TimelineText>
            <StartIcon />
            {line}
        </TimeLine>
    );

    const Finish = (
        <FinishTimeLine>
            <FinishIcon />
            <TimelineText>{tCommon('finish')}</TimelineText>
        </FinishTimeLine>
    );

    return (
        <>
            {Start}
            <DndContext
                modifiers={[restrictToParentElement]}
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <SortableContext items={tasks.map((task) => task.name)} strategy={verticalListSortingStrategy}>
                    <Tasks>
                        {tasks.map((task, i) => (
                            <TaskCard
                                key={`${i}-${task.name}`}
                                id={task.name}
                                task={task}
                                order={i}
                                companyId={companyId}
                                onTaskSelected={onTaskSelected}
                                workflow={workflow}
                                enabled={enabled}
                                selected={selectedTaskOrder === i}
                            />
                        ))}
                    </Tasks>
                </SortableContext>
            </DndContext>
            {Finish}
        </>
    );
};

export default TasksOverview;
