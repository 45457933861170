import { DeliveryType, WorkflowEnvelopeType } from '@eservices/shared/constant';

interface VerticalProps {
    topY: number;
    height: number;
}

interface HorizontalProps {
    leftX: number;
    width: number;
}
const envelopeWindowWidth = 283;

const envelopeWindowVerticalProps: Record<DeliveryType, VerticalProps> = {
    [DeliveryType.NON_PRIOR]: { height: 75.4, topY: 715.32 },
    [DeliveryType.PRIOR]: { height: 75.4, topY: 715.32 },
    [DeliveryType.REGISTERED]: { height: 55.56, topY: 695.48 },
};

const envelopeWindowHorizontalProps: Record<WorkflowEnvelopeType, HorizontalProps> = {
    [WorkflowEnvelopeType.US_LEFT_5GR]: { width: envelopeWindowWidth, leftX: 47 },
    [WorkflowEnvelopeType.US_RIGHT_5GR]: { width: envelopeWindowWidth, leftX: 278 },
};

export const getEnvelopeWindowDimensions = (envelopeType: WorkflowEnvelopeType, deliveryType: DeliveryType) => {
    return {
        ...envelopeWindowVerticalProps[deliveryType],
        ...envelopeWindowHorizontalProps[envelopeType],
    };
};
