import { WorkflowTriggerType } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { WatermarkUI } from '../../types';

type FormFieldsScheduledWorkflow = Omit<API.WorkflowConfiguration, 'scheduleTimeUTC'> & {
    scheduleTime: Date;
};

type FormFieldsFileDropWorkflow = API.WorkflowConfiguration;

export type FormFieldsWorkflow = FormFieldsScheduledWorkflow | FormFieldsFileDropWorkflow;

const constructScheduledWorkflow = (
    fields: FormFieldsScheduledWorkflow,
    tasks: Array<API.TaskConfiguration>,
    originalWorkflow?: API.WorkflowConfiguration,
): API.WorkflowConfiguration => {
    const { scheduleTime, ...restFields } = fields;
    const date: Date = scheduleTime;

    const scheduleTimeUTC = {
        hours: date.getHours(),
        minutes: date.getMinutes(),
    };

    return {
        ...originalWorkflow,
        ...restFields,
        tasks,
        trigger: {
            type: WorkflowTriggerType.SCHEDULED,
            scheduleTimeUTC,
        },
    };
};

const constructFileDropWorkflow = (
    fields: FormFieldsFileDropWorkflow,
    tasks: Array<API.TaskConfiguration>,
    originalWorkflow?: API.WorkflowConfiguration,
): API.WorkflowConfiguration => {
    return {
        ...originalWorkflow,
        ...fields,
        tasks,
    };
};

export const constructWorkflow = (
    fields: FormFieldsWorkflow,
    tasks: Array<API.TaskConfiguration>,
    workflowPDFInputSource: API.WorkflowInputSource,
    sampleFile: API.ConfigurationFile,
    watermark: WatermarkUI,
    originalWorkflow?: API.WorkflowConfiguration,
): API.WorkflowConfiguration => {
    const { imageElement: _imageElement, ...plainWatermark } = watermark;
    const updatedFields: API.WorkflowConfiguration = {
        ...fields,
        inputSource: { ...workflowPDFInputSource, sampleFile },
        watermark: watermark && {
            ...plainWatermark,
            image: { filename: watermark.image.filename },
        },
    };

    switch (fields.trigger.type) {
        case WorkflowTriggerType.SCHEDULED:
            return constructScheduledWorkflow(
                updatedFields as unknown as FormFieldsScheduledWorkflow,
                tasks,
                originalWorkflow,
            );
        default:
            return constructFileDropWorkflow(
                updatedFields as unknown as FormFieldsFileDropWorkflow,
                tasks,
                originalWorkflow,
            );
    }
};

export const checkForErrors = (tasks: API.TaskConfiguration[]) => {
    const errors = [];
    return errors;
};
