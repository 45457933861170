import { ClockCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { WorkflowTriggerType } from '@eservices/shared/constant';
import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
    font-size: 16px;
`;

const icons = {
    [WorkflowTriggerType.FILE_DROP]: <UploadOutlined />,
    [WorkflowTriggerType.SCHEDULED]: <ClockCircleOutlined />,
};

interface TriggerIndicatorProps {
    type: WorkflowTriggerType;
}

const TriggerIndicator: React.FC<TriggerIndicatorProps> = ({ type }) => <IconWrapper>{icons[type]}</IconWrapper>;

export default TriggerIndicator;
