import { EllipsisOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { TaskType, WorkflowTriggerType, WorkflowType } from '@eservices/shared/constant';
import { Divider, Space, Steps } from 'antd';
import { useState } from 'react';
import { Colors } from '@eservices/ui-constants/colors';
import { useGetDepartment } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import { headAddressToString } from '../../utils/helpers';
import styled from 'styled-components';
import LocationIcon from '../../assets/images/location_icon.svg';
import Card from '../Card';
import ChannelIndicator from '../ChannelIndicator';
import { Paragraph } from '../Paragraph';
import TriggerIndicator from '../TriggerIndicator';
import WorkflowTypeIndicator from '../WorkflowTypeIndicator';
const { Step } = Steps;

const StyledCard = styled(Card)`
    height: 260px;
    background-color: ${Colors.HIGHLIGHT};
    .ant-card-actions {
        background-color: ${Colors.HIGHLIGHT};
    }
`;

const StyledParagraph = styled(Paragraph)`
    height: 50px;
`;

const DetailsContainer = styled.div`
    height: 100px;
    overflow: auto;
`;

interface WorkflowSmallDetailProps {
    companyId: string;
    departmentId: string;
    name: string;
    type: WorkflowType;
    trigger: WorkflowTriggerType;
    description?: string;
    tasks: Array<{
        id?: string;
        taskType: TaskType;
        name: string;
    }>;
}

const WorkflowSmallDetail: React.FC<WorkflowSmallDetailProps> = ({
    name,
    description,
    companyId,
    departmentId,
    type,
    trigger,
    tasks,
}) => {
    const { tCommon } = useTranslation();
    const [showTaskInfo, setShowTaskInfo] = useState(false);
    const { isLoading, data: department } = useGetDepartment(companyId, departmentId);

    const Extra = (
        <Space>
            <WorkflowTypeIndicator type={type} />
            <Divider type="vertical" />
            <TriggerIndicator type={trigger} />
        </Space>
    );

    const Info = (
        <DetailsContainer>
            <StyledParagraph>{description ? description : tCommon('no_description')}</StyledParagraph>
            <Space>
                <img src={LocationIcon} />
                {department && headAddressToString(department.headAddress)}
            </Space>
        </DetailsContainer>
    );

    const Tasks = (
        <DetailsContainer>
            <Steps direction="vertical" size="small">
                {tasks.map((task) => (
                    <Step
                        key={task.id}
                        title={
                            <Space>
                                <ChannelIndicator key={task.id} type={task.taskType} /> {task.name}
                            </Space>
                        }
                    />
                ))}
            </Steps>
        </DetailsContainer>
    );

    const content = showTaskInfo ? Tasks : Info;

    return (
        <StyledCard
            loading={isLoading}
            title={name}
            extra={Extra}
            actions={[
                <InfoCircleOutlined key="setting" onClick={() => setShowTaskInfo(false)} />,
                <EllipsisOutlined key="ellipsis" onClick={() => setShowTaskInfo(true)} />,
            ]}
        >
            {content}
        </StyledCard>
    );
};

export default WorkflowSmallDetail;
