import { BlockOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import React, { useEffect, useState } from 'react';
import EmailEditor from 'react-email-editor';
import styled from 'styled-components';
import { PrimaryButton } from '../../../components/Buttons';
import { useGetFile } from '../../../hooks/apiHooks';
import { useTranslation } from '../../../hooks/translationHook';
import EmailTemplateEditor from '../common/EmailTemplateEditor';
import EmailTemplateSamplesSelector from '../common/EmailTemplateSamplesSelector';

const Actions = styled.div`
    margin: 1% 0;
    display: flex;
    justify-content: flex-end;
`;

interface EmailTemplateProps {
    bodyJsonTemplateUrl: string;
    placeholders: string[];
    setLoading: (isLoading: boolean) => void;
}

const EmailTemplate = React.forwardRef<EmailEditor, EmailTemplateProps>(
    ({ bodyJsonTemplateUrl, placeholders, setLoading }, ref) => {
        const { t } = useTranslation('emailTemplate');
        const [emailDesign, setEmailDesign] = useState<API.EmailDesign>();
        const [isCustomTemplate, setIsCustomTemplate] = useState<boolean>();
        const {
            mutate: getFileMutation,
            isLoading: isLoadingEmailTemplate,
            isError: isErrorEmailTemplate,
        } = useGetFile<API.EmailDesign>();

        useEffect(() => {
            if (bodyJsonTemplateUrl) {
                getFileMutation(bodyJsonTemplateUrl, {
                    onSuccess: (data) => {
                        setEmailDesign(data);
                    },
                });
            }
        }, [bodyJsonTemplateUrl]);

        const onChangeTemplate = (newTemplate: API.EmailDesign) => {
            setEmailDesign(newTemplate);
            setIsCustomTemplate(false);
        };

        const onCustomTemplate = () => {
            setEmailDesign(undefined);
            setIsCustomTemplate(true);
        };

        const resetTemplate = () => {
            setEmailDesign(undefined);
            setIsCustomTemplate(false);
        };

        return (
            <>
                {isLoadingEmailTemplate || emailDesign || isCustomTemplate ? (
                    <>
                        <Actions>
                            <PrimaryButton icon={<BlockOutlined />} aria-label="changeTemplate" onClick={resetTemplate}>
                                {t('changeTemplate')}
                            </PrimaryButton>
                        </Actions>
                        <EmailTemplateEditor
                            isLoading={isLoadingEmailTemplate}
                            setLoading={setLoading}
                            isError={isErrorEmailTemplate}
                            ref={ref}
                            tags={placeholders}
                            design={emailDesign}
                        />
                    </>
                ) : (
                    <EmailTemplateSamplesSelector
                        onChangeTemplate={onChangeTemplate}
                        onCustomTemplate={onCustomTemplate}
                    />
                )}
            </>
        );
    },
);

export default EmailTemplate;
