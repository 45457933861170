import { Subscription } from '../type';

/*
 * DynamoDB constants and enums
 */
export enum DataType {
    USER_DETAILS = 'USER_DETAILS',
    SENDING_EVENT = 'SENDING_EVENT',
    WORKFLOW_CONFIGURATION = 'WORKFLOW_CONFIGURATION',
    DEPARTMENT = 'DEPARTMENT',
    COMPANY = 'COMPANY',
    JOB = 'JOB',
    SYSTEM_PARAMETERS = 'SYSTEM_PARAMETERS',
    ORDER = 'ORDER',
    REGISTERED_MAIL = 'REGISTERED_MAIL',
    SUBSCRIPTION_DETAILS = 'SUBSCRIPTION_DETAILS',
    PARCEL = 'PARCEL',
    PARCEL_CONTACT = 'PARCEL_CONTACT',
    DAILY_CONSUMPTION_ANNOUNCEMENT = 'DAILY_CONSUMPTION_ANNOUNCEMENT',
    POSTAL_PRODUCT_CATALOG = 'POSTAL_PRODUCT_CATALOG',
    QERDS_API_KEY = 'QERDS_API_KEY',
    QERDS_MANDATE_HOLDER_NOTIFICATION = 'QERDS_MANDATE_HOLDER_NOTIFICATION',
    POLICY = 'POLICY',
}

// Indexes
export const MAIN_TABLE_PK = 'pk';
export const MAIN_TABLE_SK = 'sk';

export const CACHE_GLOBAL_SECONDARY_INDEX = 'CACHE_GLOBAL_SECONDARY_INDEX';
export const CACHE_GLOBAL_SECONDARY_PK = 'gsi1pk';
export const CACHE_GLOBAL_SECONDARY_SK = 'gsi1sk';

export const TTL_ATTRIBUTE = 'ttl';
export const TTL_PARCEL_MONTHS = 12;

// Prefixes used by PK or SK to enable the single table design
export enum DynamoDBPrefix {
    COMP = 'COMP',
    DEPT = 'DEPT',
    USER = 'USER',
    CONFIG = 'CONFIG',
    JOB = 'JOB',
    SENDING_EVENT = 'SENDING_EVENT',
    SCHEDULER_ERROR = 'SCHEDULER_ERROR',
    ERROR = 'ERROR',
    DOMAIN = 'DOMAIN',
    ORDER = 'ORDER',
    REGISTERED_MAIL = 'REGISTERED_MAIL',
    SUBSCRIPTION_DETAILS = 'SUBSCRIPTION_DETAILS',
    PARCEL = 'PARCEL',
    PARCEL_CONTACT = 'PARCEL_CONTACT',
    ANNOUNCEMENT_DATE = 'ANNOUNCEMENT_DATE',
    POLICY = 'POLICY',
}

export enum ApplicationModule {
    EASYPOST_CONNECT = 'EASYPOST_CONNECT',
    EASYPOST_PORTAL = 'EASYPOST_PORTAL',
    OMNI = 'OMNI',
}

export enum CacheDataType {
    REGISTERED_MAIL = 'REGISTERED_MAIL',
    EASYPRINT_CLIENT = 'EASYPRINT_CLIENT',
    EASYPRINT_REALM = 'EASYPRINT_REALM',
    EASYPRINT_CONFIG = 'EASYPRINT_CONFIG',
    EASYPRINT_JOB = 'EASYPRINT_JOB',
    ERDS_SENDING = 'ERDS_SENDING',
    QERDS_SENDING = 'QERDS_SENDING',
}

export enum EasyprintPrefix {
    CLIENT = 'CLIENT',
    REALM = 'REALM',
    CONFIG = 'CONFIG',
    JOB = 'JOB',
}
export enum EMAILSupplier {
    AWS_SES = 'AWS_SES',
}

export enum EasyprintSupplier {
    EASYPOST = 'EASYPOST',
}

export enum ERDSSupplier {
    DIOSS = 'DIOSS',
}

export enum QERDSSupplier {
    DIOSS = 'DIOSS',
}

export enum ERDSPrefix {
    SUBCLIENT_REALM = 'SUBCLIENT_REALM',
    DELIVERY = 'DELIVERY',
}

export enum OrchestrationTaskTrigger {
    EASYPRINT_TASK_TRIGGER = 'EASYPRINT_TASK_TRIGGER',
    EMAIL_TASK_TRIGGER = 'EMAIL_TASK_TRIGGER',
    ERDS_TASK_TRIGGER = 'ERDS_TASK_TRIGGER',
    QERDS_TASK_TRIGGER = 'QERDS_TASK_TRIGGER',
}

export const MAIN_TABLE_NAME = 'EServices';
export const CACHE_TABLE_NAME = 'Cache';

export const TTL_MONTHS = 6;
export const NOT_STARTED_SENDING_EVENT_TTL_DAYS = 4;
export const MAX_DURATION_JOB_APPROVAL_SECONDS = 3 * 24 * 3600;

export const MAX_DURATION_LONG_RUNNING_LAMBDAS_SECONDS = 10 * 60 * 1000; // 10 min

// Delimiter used in PK and SK
export const DELIMITER = '#';

export const COMPANY_DETAILS_PK = 'COMPANY_DETAILS';

export const POSTAL_PRODUCTS_CATALOG_PK = 'POSTAL_PRODUCTS_CATALOG';

export const SYSTEM_PARAMETERS_PK = 'SYSTEM_PARAMETERS';

// Elastic Search constants and enums
export enum ESPrefix {
    SE = 'se',
    JOB = 'job',
    REGISTERED_MAIL = 'registered_mail',
}

export enum ES_BULK_OPERATIONS {
    INDEX = 'index',
    DELETE = 'delete',
}

export const ES_DELIMITER = '-';

export const SENDING_EVENT_SHARED_INDEX = 'se-shared_index';

export const JOB_SHARED_INDEX = 'job-shared_index';

export const REGISTERED_MAIL_SHARED_INDEX = 'registered_mail-shared_index';

export const PARCELS_SHARED_INDEX = 'parcels-shared_index';

export const PARCELS_CONTACT_SHARED_INDEX = 'parcels-contact-shared_index';

/*
 * EventBridge constants
 */
// Global
export const ESERVICES_SOURCE_NAME = 'eu.easypost.eservices';
export const EASYPRINT_SOURCE_NAME = 'eu.easypost.easyprint';

// configuration
export const CONFIGURATION_EVENT_BUS_NAME = 'ConfigurationEventBus';
export const WORKFLOW_CONFIGURATION = 'WorkflowConfiguration';

export const TaskValidationDetailTypes: Record<TaskType, string> = {
    EASY_PRINT: 'EasyprintTaskValidation',
    EMAIL: 'EmailTaskValidation',
    ERDS: 'ERDSTaskValidation',
    QERDS: 'QERDSTaskValidation',
};

export const TaskValidationMaximumDurationMinutes: Record<TaskType, number> = {
    EASY_PRINT: 24 * 60 * 30,
    EMAIL: 24 * 60 * 7,
    ERDS: 24 * 60 * 14,
    QERDS: 24 * 60 * 14,
};
// Job
export const JOB_EVENT_BUS_NAME = 'JobBus';
export const JOB_ORCHESTRATION_START = 'JobOrchestrationStart';
export const JOB_CONTINUATION_EVENT = 'JobContinuation';
export const JOB_HANDLE_APPROVAL_REQUIREMENTS = 'JobHandleApprovalRequirements';

// SendingEvent
export const SENDING_EVENT_BUS_NAME = 'SendingEventBus';
export const SENDING_EVENT_ORCHESTRATION_START_TYPE = 'SendingEventOrchestrationStart';

// Orchestration
export const FAILED_SUBMIT_QUEUE = 'FailedSubmitQueue.fifo';

export const FIFO_DEFAULT_MESSAGE_GROUP_ID = 'DefaultHandler';

// Email
export const ConfigurationSetName = 'EasypostConnectConfigSet';
export const EMAIL_DEFAULT_SUPPLIER = EMAILSupplier.AWS_SES;

// System Parameters
export const EASYPRINT_SYSTEM_PARAMETERS = 'EASYPRINT_SYSTEM_PARAMETERS';
export const ERDS_SYSTEM_PARAMETERS = 'ERDS_SYSTEM_PARAMETERS';

//  Easyprint
export const EASYPRINT_CREATE_JOB_DEAD_LETTER_QUEUE = 'EasyprintCreateJobDeadLetterQueue.fifo';
export const EASYPRINT_CONFIG_SYNCHRONIZATION_DEAD_LETTER_QUEUE = 'EasyprintConfigSynchronizationDeadLetterQueue.fifo';
export const EASYPRINT_CONFIG_SYNCHRONIZATION_QUEUE = 'EasyprintConfigSynchronizationQueue.fifo';
export const EASYPRINT_CREATE_JOB_QUEUE = 'EasyprintCreateJobQueue.fifo';
export const EASYPRINT_EVENT_BUS_NAME = 'EasyPrintEventBus';
export const EASYPRINT_CONFIG_UPDATE = 'EASYPRINT_CONFIG_UPDATE';
export const EASYPRINT_DEPARTMENT_UPDATE = 'EASYPRINT_DEPARTMENT_UPDATE';
export const EASYPRINT_CONFIG_VALIDATION_REQUEST = 'EASYPRINT_CONFIG_VALIDATION_REQUEST';
export const EASYPRINT_CONFIG_VALIDATED = 'EASYPRINT_CONFIG_VALIDATED';
export const EASYPRINT_SEND_UPDATE = 'EASYPRINT_SEND_UPDATE';
export const EASYPRINT_DEFAULT_SUPPLIER = EasyprintSupplier.EASYPOST;

// QeRDS
export const QERDS_CONFIGURATION_SET_NAME = 'QERDS_CONFIGURATION_SET_NAME';
export const QERDS_DEFAULT_SUPPLIER = QERDSSupplier.DIOSS;
export const QERDS_SECRETS_ID = '/qerds/dioss/api';
export enum QERDSDetailType {
    ValidateApiKey = 'QERDS_VALIDATE_API_KEY',
    Retract = 'QERDS_RETRACT_SENDING',
}

// eRDS
export const ERDS_DEFAULT_SUPPLIER = ERDSSupplier.DIOSS;
export const ERDS_SUBMIT_DEAD_LETTER_QUEUE = 'ERDSSubmitDeadLetterQueue.fifo';
export const ERDS_SUBMIT_QUEUE = 'ERDSSubmitQueue.fifo';
export const ERDS_DOWNLOAD_REPORT_DEAD_LETTER_QUEUE = 'ERDSDownloadReportDeadLetterQueue';
export const ERDS_DOWNLOAD_REPORT_QUEUE = 'ERDSDownloadReportQueue';
export const ERDS_GET_STATUS_DEAD_LETTER_QUEUE = 'ERDSGetStatusDeadLetterQueue.fifo';
export const ERDS_GET_STATUS_QUEUE = 'ERDSGetStatusQueue.fifo';

//  Preprocessing
export const PREPROCESSING_EVENT_BUS_NAME = 'PreprocessingEventBus';
export const PREPROCESSING_EVENT_START_TYPE = 'PreprocessingEventStart';

// Parcels
export const PARCEL_EVENT_BUS_NAME = 'ParcelEventBus';
export const PARCEL_REQUESTS_EVENT = 'ParcelRequests';
export const PARCEL_STATUS_UPDATE_EVENT = 'ParcelStatusUpdate';

/*
 * Cognito constants
 */
export const COGNITO_CUSTOM_ATTR_COMPANY_ID = 'custom:companyId';
export const COGNITO_CUSTOM_ATTR_USER_ID = 'custom:userId';

/*
 * S3 Constants
 */
export const WEBAPP_S3_OBJECT_PREFIX = 'webapp';
export const SFTP_S3_OBJECT_PREFIX = 'sftp';

/*
 * Domain constants and enums
 */
export enum CompanyVolume {
    LARGE = 'LARGE',
    SMALL = 'SMALL',
}

export enum CompanyProcessingType {
    BATCH_FILE = 'BATCH_FILE',
    SINGLE_FILE = 'SINGLE_FILE',
}

export enum SubscriptionType {
    NONE = 'NONE',
    FREE = 'FREE',
    BASIC = 'BASIC',
    PRO = 'PRO',
    ELITE = 'ELITE',
    ENTERPRISE = 'ENTERPRISE',
}

export enum SubscriptionSupportType {
    SUPPORT_BASIC = 'SUPPORT_BASIC',
    SUPPORT_48 = 'SUPPORT_48',
    SUPPORT_24 = 'SUPPORT_24',
}

export enum SubscriptionStatus {
    PAID = 'PAID',
    REQUESTED = 'REQUESTED',
}

export enum WorkflowResourceStatus {
    CREATED = 'CREATED',
    UNDER_REVIEW = 'UNDER_REVIEW',
    REVIEWED = 'REVIEWED',
    CONFIRMED = 'CONFIRMED',
}

export enum CSVQuoteStyle {
    NONE = 'NONE',
    SINGLE = 'SINGLE',
    DOUBLE = 'DOUBLE',
}

export type TaskConfigurationStatus = WorkflowResourceStatus;

export enum WorkflowPaperType {
    A4_5GR = 'A4_5GR',
}

export enum WorkflowEnvelopeType {
    US_LEFT_5GR = 'US_LEFT_5GR',
    US_RIGHT_5GR = 'US_RIGHT_5GR',
}

export enum MultiTaskRecipientIdentifierKind {
    REGEX = 'REGEX',
    SELECT_ALL = 'SELECT_ALL',
}

export enum RecipientIdentifierKind {
    ADDRESS_ENGINE = 'ADDRESS_ENGINE',
    SELECT_ALL = 'SELECT_ALL',
}

export enum ChannelProperties {
    ADDRESS = 'ADDRESS',
    EMAIL_ADDRESS = 'EMAIL_ADDRESS',
    PHONE_NUMBER = 'PHONE_NUMBER',
    COMPANY_VAT = 'COMPANY_VAT',
    LAST_NAME = 'LAST_NAME',
    FIRST_NAME = 'FIRST_NAME',
    BIRTH_DATE = 'BIRTH_DATE',
}

export enum SplitIdentifierKind {
    RECIPIENT_ID = 'RECIPIENT_ID',
    REGEX = 'REGEX',
    TEXT = 'TEXT',
    EMPTY_TEXT = 'EMPTY_TEXT',
}

export enum IgnoreIdentifierKind {
    REGEX = 'REGEX',
    TEXT = 'TEXT',
    EMPTY_TEXT = 'EMPTY_TEXT',
}

export enum InputFileType {
    PDF = 'PDF',
    CSV = 'CSV',
}

export enum TaskType {
    EASY_PRINT = 'EASY_PRINT',
    EMAIL = 'EMAIL',
    ERDS = 'ERDS',
    QERDS = 'QERDS',
}

export enum PrintColor {
    BW = 'BW',
    COLORS = 'COLORS',
}

export enum PrintSide {
    RECTO = 'RECTO',
    RECTO_VERSO = 'RECTO_VERSO',
}

export enum DeliveryType {
    PRIOR = 'PRIOR',
    NON_PRIOR = 'NON_PRIOR',
    REGISTERED = 'REGISTERED',
}

export enum WorkflowTriggerType {
    FILE_DROP = 'FILE_DROP',
    SCHEDULED = 'SCHEDULED',
}

export enum WorkflowInputSourceType {
    PDF = 'PDF',
    EXCEL = 'EXCEL',
    CSV = 'CSV',
}

export enum WorkflowType {
    BASIC = 'BASIC',
    ADVANCED = 'ADVANCED',
}
export enum TaskValidationStatus {
    PENDING = 'PENDING',
    VALIDATED = 'VALIDATED',
    ERROR = 'ERROR',
}

export enum TaskTechnicalStatus {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    ERROR = 'ERROR',
    TIMEOUT = 'TIMEOUT',
    MAX_ATTEMPTS_EXCEEDED = 'MAX_ATTEMPTS_EXCEEDED',
}

export enum TaskBusinessStatus {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

export enum SendingEventTechnicalStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESS = 'SUCCESS',
    WARNING = 'WARNING',
    FAILURE = 'FAILURE',
}

export enum SendingEventBusinessStatus {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

export enum JobSource {
    SFTP_EVENT = 'SFTP_EVENT',
    WEB_APP = 'WEB_APP',
    MAIL = 'MAIL',
}

export enum JobStatus {
    WAITING = 'WAITING',
    PROCESSING = 'PROCESSING',
    OK = 'OK',
    ACTION_REQUIRED = 'ACTION_REQUIRED',
    ERROR = 'ERROR',
    CANCELLED = 'CANCELLED',
}

export enum ApprovalStatus {
    AWAITING_APPROVAL = 'AWAITING_APPROVAL',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    TIMEOUT = 'TIMEOUT',
}

export enum EmailTaskStatus {
    Send = 'Send',
    Delivery = 'Delivery',
    Open = 'Open',
    Click = 'Click',
    Bounce = 'Bounce',
    Complaint = 'Complaint',
    Reject = 'Reject',
    RenderingFailure = 'Rendering Failure',
    DeliveryDelay = 'DeliveryDelay',
}

export enum ERDSTaskStatus {
    RECEIVED = 'RECEIVED',
    DOCUMENTS_SIGNED = 'DOCUMENTS_SIGNED',
    DELIVERED = 'DELIVERED',
    SUBMISSION_REPORT_GENERATED = 'SUBMISSION_REPORT_GENERATED',
    DOCUMENTS_DOWNLOAD_STARTED = 'DOCUMENTS_DOWNLOAD_STARTED',
    DOCUMENTS_DOWNLOAD_FAILED = 'DOCUMENTS_DOWNLOAD_FAILED',
    DOCUMENTS_DOWNLOAD_SUCCESSFUL = 'DOCUMENTS_DOWNLOAD_SUCCESSFUL',
    DOCUMENTS_REFUSED = 'DOCUMENTS_REFUSED',
}

export enum QERDSTaskStatus {
    RECEIVED = 'RECEIVED',
    DELIVERY_SENDER_UPLOADED_DOCUMENTS_SIGNED = 'DELIVERY_SENDER_UPLOADED_DOCUMENTS_SIGNED',
    DELIVERY_RETRACTED = 'DELIVERY_RETRACTED',
    EMAIL_RECEIVER_NEW_DELIVERY = 'EMAIL_RECEIVER_NEW_DELIVERY',
    DELIVERY_RECEIVER_IDENTITY_MATCHED = 'DELIVERY_RECEIVER_IDENTITY_MATCHED',
    DELIVERY_RECEIVER_IDENTITY_VERIFIED = 'DELIVERY_RECEIVER_IDENTITY_VERIFIED',
    DELIVERY_RECEIVER_ACCEPTED = 'DELIVERY_RECEIVER_ACCEPTED',
    DELIVERY_PROCESSED = 'DELIVERY_PROCESSED',
    DELIVERY_EXPIRED = 'DELIVERY_EXPIRED',
    DELIVERY_ARCHIVED = 'DELIVERY_ARCHIVED',
}

export enum ParcelStatus {
    LABEL_REQUEST_RECEIVED = 'LABEL_REQUEST_RECEIVED',
    LABEL_CREATED = 'LABEL_CREATED',
    LABEL_MERGED = 'LABEL_MERGED',
    PARCEL_SORTED = 'PARCEL_SORTED',
    PARCEL_OUT_FOR_DELIVERY = 'PARCEL_OUT_FOR_DELIVERY',
    CUSTOMER_REFUSED_PARCEL = 'CUSTOMER_REFUSED_PARCEL',
    PARCEL_DELIVERED = 'PARCEL_DELIVERED',
    BACK_TO_SENDER_PARCEL_SORTED = 'BACK_TO_SENDER_PARCEL_SORTED',
    BACK_TO_SENDER_PARCEL_OUT_FOR_DELIVERY = 'BACK_TO_SENDER_PARCEL_OUT_FOR_DELIVERY',
    BACK_TO_SENDER_PARCEL_DELIVERED = 'BACK_TO_SENDER_PARCEL_DELIVERED',
    CUSTOMER_NOT_AT_HOME_MESSAGE_LETTER_BOX = 'CUSTOMER_NOT_AT_HOME_MESSAGE_LETTER_BOX',
    PARCEL_ARRIVED_IN_POST_OFFICE_OR_POSTAL_POINT = 'PARCEL_ARRIVED_IN_POST_OFFICE_OR_POSTAL_POINT',
    PARCEL_NOT_COLLECTED_AT_POST_OFFICE_OR_POSTAL_POINT = 'PARCEL_NOT_COLLECTED_AT_POST_OFFICE_OR_POSTAL_POINT',
    NOT_DELIVERED_WRONG_ADDRESS = 'NOT_DELIVERED_WRONG_ADDRESS',
}

export enum ParcelCustomsShipmentType {
    OTHER = 'OTHER',
    GOODS = 'GOODS',
    GIFT = 'GIFT',
    DOCUMENTS = 'DOCUMENTS',
    SAMPLE = 'SAMPLE',
}

export enum ParcelType {
    PARCEL_WITH_CUSTOMS = 'PARCEL_WITH_CUSTOMS',
    PARCEL_WITHOUT_CUSTOMS = 'PARCEL_WITHOUT_CUSTOMS',
}

export enum ConsumptionAnnouncementType {
    NONE = 'NONE',
    BASIC = 'BASIC',
    ADVANCED = 'ADVANCED',
}

// The sequence of statuses is given by the order of the enum
export enum PrintTaskStatus {
    RECEIVED = 'RECEIVED',
    TRANSFERRED = 'TRANSFERRED',
    PRINTED = 'PRINTED',
    FOLDED = 'FOLDED',
    SORTED = 'SORTED',
    DELIVERED = 'DELIVERED',
}
export enum PrintTaskMailFormat {
    SMALL = 'SMALL',
    LARGE = 'LARGE',
}

// Enum representing all the roles stored in our main table
export enum UserRole {
    CLIENT_VIEWER = 'CLIENT_VIEWER',
    CLIENT_OPERATOR = 'CLIENT_OPERATOR',
    CLIENT_DEPARTMENT_ADMIN = 'CLIENT_DEPARTMENT_ADMIN',
    CLIENT_ADMIN = 'CLIENT_ADMIN',
    SUPER_USER = 'SUPER_USER',
}

export const rolesRequiringDeparmentsAccess = [
    UserRole.CLIENT_VIEWER,
    UserRole.CLIENT_OPERATOR,
    UserRole.CLIENT_DEPARTMENT_ADMIN,
];

export enum Language {
    EN = 'EN',
    NL = 'NL',
    FR = 'FR',
}

export enum CountryCode {
    FR = 'FR',
    BE = 'BE',
}

export const MAX_CONFIGURATION_RESOURCES_ON_SUBSCRIPTION = 1000;

export const Subscriptions: Record<SubscriptionType, Subscription> = {
    [SubscriptionType.NONE]: {
        subscriptionType: SubscriptionType.NONE,
        maxAmountOfDepartments: MAX_CONFIGURATION_RESOURCES_ON_SUBSCRIPTION,
        maxAmountOfWorkflows: MAX_CONFIGURATION_RESOURCES_ON_SUBSCRIPTION,
        maxAmountOfUsers: MAX_CONFIGURATION_RESOURCES_ON_SUBSCRIPTION,
        availableWorkflowTypes: [WorkflowType.BASIC],
        availableTaskTypes: [TaskType.EASY_PRINT, TaskType.EMAIL, TaskType.ERDS],
        supportType: SubscriptionSupportType.SUPPORT_BASIC,
    },

    [SubscriptionType.FREE]: {
        subscriptionType: SubscriptionType.FREE,
        maxAmountOfDepartments: 1,
        maxAmountOfWorkflows: 3,
        maxAmountOfUsers: 3,
        availableWorkflowTypes: [WorkflowType.BASIC],
        availableTaskTypes: [TaskType.EASY_PRINT, TaskType.EMAIL, TaskType.ERDS],
        supportType: SubscriptionSupportType.SUPPORT_BASIC,
    },
    [SubscriptionType.BASIC]: {
        subscriptionType: SubscriptionType.BASIC,
        maxAmountOfDepartments: 1,
        maxAmountOfWorkflows: 3,
        maxAmountOfUsers: 3,
        availableWorkflowTypes: [WorkflowType.BASIC],
        availableTaskTypes: [TaskType.EASY_PRINT, TaskType.EMAIL, TaskType.ERDS],
        supportType: SubscriptionSupportType.SUPPORT_48,
    },

    [SubscriptionType.PRO]: {
        subscriptionType: SubscriptionType.PRO,
        maxAmountOfDepartments: 5,
        maxAmountOfWorkflows: 10,
        maxAmountOfUsers: 10,
        availableWorkflowTypes: [WorkflowType.BASIC],
        availableTaskTypes: [TaskType.EASY_PRINT, TaskType.EMAIL, TaskType.ERDS, TaskType.QERDS],
        supportType: SubscriptionSupportType.SUPPORT_24,
    },

    [SubscriptionType.ELITE]: {
        subscriptionType: SubscriptionType.ELITE,
        maxAmountOfDepartments: MAX_CONFIGURATION_RESOURCES_ON_SUBSCRIPTION,
        maxAmountOfWorkflows: MAX_CONFIGURATION_RESOURCES_ON_SUBSCRIPTION,
        maxAmountOfUsers: MAX_CONFIGURATION_RESOURCES_ON_SUBSCRIPTION,
        availableWorkflowTypes: [WorkflowType.BASIC, WorkflowType.ADVANCED],
        availableTaskTypes: [TaskType.EASY_PRINT, TaskType.EMAIL, TaskType.ERDS, TaskType.QERDS],
        supportType: SubscriptionSupportType.SUPPORT_24,
    },

    [SubscriptionType.ENTERPRISE]: {
        subscriptionType: SubscriptionType.ENTERPRISE,
        maxAmountOfDepartments: MAX_CONFIGURATION_RESOURCES_ON_SUBSCRIPTION,
        maxAmountOfWorkflows: MAX_CONFIGURATION_RESOURCES_ON_SUBSCRIPTION,
        maxAmountOfUsers: MAX_CONFIGURATION_RESOURCES_ON_SUBSCRIPTION,
        availableWorkflowTypes: [WorkflowType.BASIC, WorkflowType.ADVANCED],
        availableTaskTypes: [TaskType.EASY_PRINT, TaskType.EMAIL, TaskType.ERDS, TaskType.QERDS],
        supportType: SubscriptionSupportType.SUPPORT_24,
    },
};

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE = 1; // The pagination system is assumed to be 1-indexed in our system

export const CURRENT_WORKFLOW_MODEL_VERSION = '0.0.1';
export const CURRENT_EASYPRINT_TASK_MODEL_VERSION = '0.0.1';
export const CURRENT_ERDS_TASK_MODEL_VERSION = '0.0.1';
export const CURRENT_EMAIL_TASK_MODEL_VERSION = '0.0.1';
export const CURRENT_QERDS_TASK_MODEL_VERSION = '0.0.1';

export const EASYPOST_CONNECT_SENDER_NAME = 'EasyPost Connect';
export const DEFAULT_SENDER_MAIL_ADDRESS = 'connect@easypost.eu';

export const DEFAULT_SECONDS_TO_WAIT = 60 * 15; // 15 minutes of bailout by default on the  workflow with trigger FILE_DROP
export const DEFAULT_MAX_DURATION_IN_SECONDS = 60 * 60 * 24 * 30; // The step functions for given tasks can run for max 30 days by default
export const MIN_TASK_DURATION_IN_SECONDS = 60 * 60 * 24; // = 1 day

export const PDF_POINTS_TO_MM_COEFF = 0.3527778;
export const A4_HEIGHT_IN_MM = 297;
export const A4_HEIGHT_IN_POINTS = A4_HEIGHT_IN_MM / PDF_POINTS_TO_MM_COEFF;

export const DEFAULT_CHECKSUM_ALGORITHM = 'SHA256';

export enum WatermarkLocation {
    FIRST_PAGE = 'FIRST_PAGE',
    ALL_PAGES = 'ALL_PAGES',
}

export enum ErrorCode {
    /* AWS Services related errors */
    COGNITO_CLIENT_ERROR = 'COGNITO_CLIENT_ERROR',
    S3_CLIENT_ERROR = 'S3_CLIENT_ERROR',
    DYNAMODB_CLIENT_ERROR = 'DYNAMODB_CLIENT_ERROR',
    ELASTIC_SEARCH_ERROR = 'ELASTIC_SEARCH_ERROR',
    EVENT_BRIDGE_CLIENT_ERROR = 'EVENT_BRIDGE_CLIENT_ERROR',
    /* Web related errors */
    RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND',
    DEPARTMENT_MISMATCH = 'DEPARTMENT_MISMATCH',
    STALE_RESOURCE = 'STALE_RESOURCE',
    /* Business related errors */
    FORBIDDEN = 'FORBIDDEN',
    USERNAME_TAKEN = 'USERNAME_TAKEN',
    DUPLICATE_DEPARTMENT_NAME = 'DUPLICATE_DEPARTMENT_NAME',
    DUPLICATE_SFTP_FOLDER_NAME = 'DUPLICATE_SFTP_FOLDER_NAME',
    MULTIRECIPIENT_IDENTIFIER_ERROR = 'MULTIRECIPIENT_IDENTIFIER_ERROR',
    ES_CREATE_COMPANY_ALIAS = 'ES_CREATE_COMPANY_ALIAS',
    DEFAULT_DEPARTMENT_CREATE_ERROR = 'DEFAULT_DEPARTMENT_CREATE_ERROR',
    CONFIGURATION_NOT_AVAILABLE = 'CONFIGURATION_NOT_AVAILABLE',
    JOB_CANT_BE_CANCELLED = 'JOB_CANT_BE_CANCELLED',
    JOB_CAN_NOT_BE_CONTINUED = 'JOB_CAN_NOT_BE_CONTINUED',
    USER_HAS_NO_QERDS_MANDATE = 'USER_HAS_NO_QERDS_MANDATE',
    USER_HAS_NO_MFA_SETUP = 'USER_HAS_NO_MFA_SETUP',
    JOB_ALREADY_REVIEWED_FOR_APPROVAL = 'JOB_ALREADY_REVIEWED_FOR_APPROVAL',
    USER_HAD_NO_VALID_QERDS_API_KEY = 'USER_HAD_NO_VALID_QERDS_API_KEY',
    UNSUPPORTED_FILE_TYPE = 'UNSUPPORTED_FILE_TYPE',
    MORE_THAN_ONE_ORDER_PER_DAY = 'MORE_THAN_ONE_ORDER_PER_DAY',
    MAX_AMMOUNT_OF_PRODUCT_ITEMS_REACHED = 'MAX_AMMOUNT_OF_PRODUCT_ITEMS_REACHED',
    ORDER_NOT_EDITABLE = 'ORDER_NOT_EDITABLE',
    PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
    REQUESTED_PRODUCT_NOT_AVAILABLE = 'REQUESTED_PRODUCT_NOT_AVAILABLE',
    MODULE_ACCESS_EMPTY = 'MODULE_ACCESS_EMPTY',
    SUBSCRIPTION_DETAILS_NOT_FOUND = 'SUBSCRIPTION_DETAILS_NOT_FOUND',
    SUBSCRIPTION_LIMITS_EXCEEDED = 'SUBSCRIPTION_LIMITS_EXCEEDED',
    INCORRECT_DATES = 'INCORRECT_DATES',
    EMAIL_TEMPLATE = 'EMAIL_TEMPLATE',
    RESOURCE_NOT_FOUND_EMPTY_RESPONSE = 'RESOURCE_NOT_FOUND_EMPTY_RESPONSE',
    DEPARTMENT_CONSUMPTION_ANNOUNCEMENT_TYPE_MISMATCH = 'DEPARTMENT_CONSUMPTION_ANNOUNCEMENT_TYPE_MISMATCH',
}

export const BpostCountryZones = ['WORLD', 'EUROPEAN_UNION', 'DOMESTIC'];

export enum PrintAttachmentPosition {
    VERSO_ALL_PAGES = 'VERSO_ALL_PAGES',
    END_OF_DOCUMENT = 'END_OF_DOCUMENT',
}

export enum QERDSApiKeyStatus {
    TO_BE_CHECKED = 'TO_BE_CHECKED',
    VALID = 'VALID',
    INVALID = 'INVALID',
}

export enum EventBusName {
    QERDS = 'QERDSEventBus',
}

export enum PolicyService {
    EASY_PRINT = 'EASY_PRINT',
    EMAIL = 'EMAIL',
    ERDS = 'ERDS',
    QERDS = 'QERDS',
}

export enum PolicyStatement {
    TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
    PRIVACY_STATEMENT = 'PRIVACY_STATEMENT',
    PRACTICE_STATEMENT = 'PRACTICE_STATEMENT',
}
