import { ClusterOutlined } from '@ant-design/icons';
import { ApplicationModule, UserRole } from '@eservices/shared/constant';
import { Routes } from '../../constants';
import { BasicRoute, MainRoute } from '../../types';
import WorkflowDetailCreate from './WorkflowDetailCreate';
import WorkflowDetailEdit from './WorkflowDetailEdit';
import WorkflowDetailView from './WorkflowDetailView';
import WorkflowsOverviewEdit from './WorkflowsOverviewEdit';
import WorkflowsOverviewView from './WorkflowsOverviewView';

const createWorkflowDetailPage = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.CREATE_WORKFLOW,
    component: <WorkflowDetailCreate companyId={companyId} />,
});

const editWorkflowDetailPage = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.EDIT_WORKFLOW,
    component: <WorkflowDetailEdit companyId={companyId} />,
});

const viewWorkflowDetailPage = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.VIEW_WORKFLOW,
    component: <WorkflowDetailView companyId={companyId} />,
});

const editWorkflowsOverviewPageThatCanCreateWorkflow = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.WORKFLOWS,
    component: <WorkflowsOverviewEdit canCreateWorkflow companyId={companyId} />,
});

const viewWorkflowsOverviewPage = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.WORKFLOWS,
    component: <WorkflowsOverviewView companyId={companyId} />,
});

export const createWorkflowRouter = (userRole: UserRole, companyId: string): MainRoute => {
    const workflowRoutes = {
        [UserRole.SUPER_USER]: [
            createWorkflowDetailPage(companyId),
            editWorkflowDetailPage(companyId),
            editWorkflowsOverviewPageThatCanCreateWorkflow(companyId),
        ],
        [UserRole.CLIENT_ADMIN]: [
            createWorkflowDetailPage(companyId),
            editWorkflowDetailPage(companyId),
            editWorkflowsOverviewPageThatCanCreateWorkflow(companyId),
        ],
        [UserRole.CLIENT_DEPARTMENT_ADMIN]: [
            createWorkflowDetailPage(companyId),
            editWorkflowDetailPage(companyId),
            editWorkflowsOverviewPageThatCanCreateWorkflow(companyId),
        ],
        [UserRole.CLIENT_OPERATOR]: [viewWorkflowDetailPage(companyId), viewWorkflowsOverviewPage(companyId)],
        [UserRole.CLIENT_VIEWER]: [viewWorkflowDetailPage(companyId), viewWorkflowsOverviewPage(companyId)],
    };

    return {
        path: Routes.WORKFLOWS,
        title: 'workflow',
        icon: <ClusterOutlined />,
        subRoutes: workflowRoutes[userRole],
        module: ApplicationModule.EASYPOST_CONNECT,
    };
};
