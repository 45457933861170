import { EditOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { Colors } from '@eservices/ui-constants/colors';
import { Card, Skeleton } from 'antd';
import React from 'react';
import styled from 'styled-components';
import FailedToLoadData from '../../../components/FailedToLoadData';
import { useGetSampleTemplates } from '../../../hooks/apiHooks';
import { useTranslation } from '../../../hooks/translationHook';
import SampleEmailTemplate, { cardHeight, cardWidth } from './SampleEmailTemplate';

const Container = styled.div`
    margin: 5% 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px 20px;
`;

const CustomTemplate = styled(Card)`
    width: ${cardWidth};
    height: ${cardHeight};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.QUINARY_TRANSPARENT};
    outline: ${Colors.QUINARY} dashed 1px;
    font-size: 13px;
    color: ${Colors.PRIMARY};
    text-align: center;
`;

const EditIcon = styled(EditOutlined)`
    font-size: 40px;
    margin: 5% 0;
`;

interface EmailTemplateSamplesSelectorProps {
    onChangeTemplate: (template: API.EmailDesign) => void;
    onCustomTemplate: () => void;
}

const EmailTemplateSamplesSelector: React.FC<EmailTemplateSamplesSelectorProps> = ({
    onChangeTemplate,
    onCustomTemplate,
}) => {
    const { t } = useTranslation('emailTemplateEditor');
    const { data = [], isLoading, isError } = useGetSampleTemplates();

    if (isLoading) return <Skeleton active />;
    if (isError) return <FailedToLoadData />;

    return (
        <Container>
            {data.map(({ id, name, design }) => (
                <SampleEmailTemplate key={id} name={name} design={design} onSelect={() => onChangeTemplate(design)} />
            ))}
            <CustomTemplate hoverable onClick={() => onCustomTemplate()}>
                <p>
                    <EditIcon />
                </p>
                <p>{t('create_custom_template')}</p>
            </CustomTemplate>
        </Container>
    );
};

export default EmailTemplateSamplesSelector;
