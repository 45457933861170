import { ConsumptionAnnouncementType } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Col, Form, FormInstance, Row } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';
import { FormItem } from '../../components/FormItem';
import { Input } from '../../components/Input';
import { useTranslation } from '../../hooks/translationHook';
import Card from '../Card';
import { Checkbox } from '../Checkbox';
import CheckSquareOutlined from '../CheckSquareOutlined';
import { Header3 } from '../Headers';
import { Paragraph } from '../Paragraph';

const EligibleContainer = styled.div`
    margin-top: 30px;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    text-align: right;
    span {
        padding: 5px;
    }
`;

const Container = styled(Card)`
    max-width: 600px;
`;

interface DepartmentDetailsFormProps {
    isReadOnly: boolean;
    form: FormInstance<API.Department>;
}

const DepartmentForm: FC<DepartmentDetailsFormProps> = ({ isReadOnly, form }) => {
    const { t, tCommon, tValidation } = useTranslation('department');

    return (
        <Container>
            <Header3>{t('configuration')}</Header3>
            <Paragraph>{t('configuration_description')}</Paragraph>
            <Form
                form={form}
                initialValues={{
                    isEligibleForRPRegisteredMail: false,
                    hasSeparateInvoicing: false,
                    consumptionAnnouncementType: ConsumptionAnnouncementType.NONE,
                }}
                layout="vertical"
                name="department"
                requiredMark={false}
            >
                <FormItem name="isEligibleForRPRegisteredMail" hidden />
                <FormItem name="consumptionAnnouncementType" hidden />
                <Row>
                    <Col span={24}>
                        <FormItem
                            label={tCommon('name')}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: tValidation('required.department_name'),
                                },
                            ]}
                        >
                            <Input disabled={isReadOnly} type="text" aria-label="department name input" />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={11}>
                        <FormItem
                            label={tCommon('country_code')}
                            name="countryCode"
                            rules={[
                                {
                                    required: true,
                                    message: tValidation('required.country_code'),
                                },
                            ]}
                        >
                            <Input disabled={isReadOnly} type="text" aria-label="department country code input" />
                        </FormItem>
                    </Col>
                    <Col offset={2} span={11}>
                        <FormItem
                            label={tCommon('city')}
                            name="city"
                            rules={[
                                {
                                    required: true,
                                    message: tValidation('required.city'),
                                },
                            ]}
                        >
                            <Input disabled={isReadOnly} type="text" aria-label="department city input" />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormItem
                            label={tCommon('street')}
                            name="street"
                            rules={[
                                {
                                    required: true,
                                    message: tValidation('required.street'),
                                },
                            ]}
                        >
                            <Input disabled={isReadOnly} type="text" aria-label="department street input" />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={11}>
                        <FormItem
                            label={tCommon('postal_code')}
                            name="postalCode"
                            rules={[
                                {
                                    required: true,
                                    message: tValidation('required.postal_code'),
                                },
                            ]}
                        >
                            <Input disabled={isReadOnly} type="text" aria-label="department postal code input" />
                        </FormItem>
                    </Col>
                    <Col offset={2} span={11}>
                        <FormItem
                            label={tCommon('number_short')}
                            name="streetNr"
                            rules={[
                                {
                                    required: true,
                                    message: tValidation('required.street_number'),
                                },
                            ]}
                        >
                            <Input
                                disabled={isReadOnly}
                                type="text"
                                aria-label="department number input"
                                minLength={1}
                                maxLength={10}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormItem
                            label={tCommon('phone_number')}
                            name="phoneNumber"
                            rules={[
                                {
                                    required: true,
                                    message: tValidation('required.phone_number'),
                                },
                            ]}
                        >
                            <Input disabled={isReadOnly} type="tel" aria-label="department phone number input" />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <FormItem
                            label={tCommon('email')}
                            name="emailAddress"
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    message: tValidation('valid.email'),
                                },
                            ]}
                        >
                            <Input disabled={isReadOnly} type="email" aria-label="department email input" />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={11}>
                        <FormItem label={tCommon('identification_number')} name="identificationNumber">
                            <Input
                                disabled={isReadOnly}
                                type="text"
                                aria-label="department identification number input"
                            />
                        </FormItem>
                    </Col>
                    <Col offset={2} span={11}>
                        <FormItem
                            label={tCommon('erp_id')}
                            name="erpId"
                            rules={[
                                {
                                    required: true,
                                    message: tValidation('required.erpId'),
                                },
                            ]}
                        >
                            <Input disabled={isReadOnly} type="text" aria-label="department erp id input" />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={11}>
                        <FormItem name="hasSeparateInvoicing" valuePropName="checked">
                            <Checkbox disabled={isReadOnly} aria-label="department has separate invoice input">
                                {tCommon('has_separate_invoicing')}
                            </Checkbox>
                        </FormItem>
                    </Col>
                    {form.getFieldValue('isEligibleForRPRegisteredMail') && (
                        <Col offset={2} span={11}>
                            <EligibleContainer>
                                <CheckSquareOutlined />
                                <span>{t('eligible_rp_registered_mail')}</span>
                            </EligibleContainer>
                        </Col>
                    )}
                </Row>
            </Form>
        </Container>
    );
};

export default DepartmentForm;
