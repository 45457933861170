import { Language } from '@eservices/shared/constant';
import { Space } from 'antd';
import React from 'react';
import { useTranslation } from '../hooks/translationHook';
import styled from 'styled-components';
import EnFlag from '../assets/images/england.svg';
import FrFlag from '../assets/images/france.svg';
import BeFlag from '../assets/images/netherlands.svg';

const flagsPerLanguage = {
    [Language.EN]: EnFlag,
    [Language.NL]: BeFlag,
    [Language.FR]: FrFlag,
};

const LanguageIcon = styled.img`
    width: 27px;
    height: 17.7px;
`;

interface FlagProps {
    country: Language;
}

const Flag: React.FC<FlagProps> = ({ country }) => {
    const { tCommon } = useTranslation();

    return (
        <Space>
            <LanguageIcon src={flagsPerLanguage[country]} alt={`${country} flag`} />
            {tCommon(Language[country].toLocaleLowerCase())}
        </Space>
    );
};

export default Flag;
