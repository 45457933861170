import { WorkflowInputSourceType } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SubscriptionModal from '../../components/subscription/SubscriptionModal';
import WorkflowDetailNew from '../../components/workflow/WorkflowDetailNew';
import { Routes, SubscriptionContext } from '../../constants';
import { useCreateWorkflow } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import ResourceDetailLayout from '../../layouts/ResourceDetailLayout';
import { constructWorkflow, FormFieldsWorkflow } from './formHelper';

const loadingMessageKey = 'loadingMessageKey';

interface WorkflowDetailCreateProps {
    companyId: string;
}

const WorkflowDetailCreate: React.FC<WorkflowDetailCreateProps> = ({ companyId }) => {
    const history = useHistory();
    const { t } = useTranslation('workflow');
    const [form] = useForm<FormFieldsWorkflow>();
    const mutation = useCreateWorkflow(companyId);
    const [errors, setErrors] = useState([]);

    const onDone = async () => {
        try {
            const fields = await form.validateFields();
            let inputSource: API.WorkflowInputSource;
            switch (fields.inputSource.type) {
                case WorkflowInputSourceType.PDF:
                    inputSource = {
                        type: WorkflowInputSourceType.PDF,
                        channelIdentifiers: [],
                        splitIdentifiers: [],
                        ignoreIdentifiers: [],
                        extraInfoIdentifiers: [],
                    };
                    break;
                default:
                    break;
            }

            const updatedFields: API.WorkflowConfiguration = {
                ...fields,
                inputSource,
            };

            const workflow = constructWorkflow(updatedFields, [], inputSource, undefined, undefined);
            void message.loading({ key: loadingMessageKey, content: t('creating_new_workflow_loading') }, 0);
            mutation.mutate(workflow, {
                onSuccess: (data) => {
                    const workflow = data;
                    history.push(
                        Routes.EDIT_WORKFLOW.replace(':departmentId', workflow.departmentId).replace(
                            ':id',
                            workflow.id,
                        ),
                    );
                    void message.success(t('creating_new_workflow_success', { name: form.getFieldValue('name') }), 3);
                },
                onError: () => {
                    void message.error(t('creating_new_workflow_error'), 3);
                },
                onSettled: () => {
                    void message.destroy(loadingMessageKey);
                },
            });
        } catch (result) {
            const { errorFields } = result;
            if (errorFields) {
                const errors = errorFields
                    .map(({ errors }) => errors)
                    .reduce((e1: Array<string>, e2: Array<string>) => e1.concat(e2), []);

                setErrors(errors);
            } else {
                console.error(result);
            }
        }
    };

    const detail = <WorkflowDetailNew companyId={companyId} form={form} onDone={onDone} />;

    return (
        <>
            <ResourceDetailLayout title={t('create_workflow_title')} detail={detail} errors={errors} />
            <SubscriptionModal companyId={companyId} context={SubscriptionContext.WORKFLOW} />
        </>
    );
};

export default WorkflowDetailCreate;
