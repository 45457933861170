import { InfoCircleOutlined } from '@ant-design/icons';
import {
    DeliveryType,
    PrintAttachmentPosition,
    PrintColor,
    PrintSide,
    WorkflowEnvelopeType,
} from '@eservices/shared/constant';
import { Colors } from '@eservices/ui-constants/colors';
import { Badge, FormInstance, Radio, Tooltip } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import BlackAndWhite from '../../../assets/images/black_white.svg';
import Color from '../../../assets/images/color.svg';
import Recto from '../../../assets/images/recto.svg';
import RectoVerso from '../../../assets/images/recto_verso.svg';
import USLeft5gr from '../../../assets/images/us_left_5gr.svg';
import USRight5gr from '../../../assets/images/us_right_5gr.svg';
import { Input, InputNumberWithLeftAddon, Select, TextArea } from '../../../components/Input';
import Upload from '../../../components/Upload';
import { useTranslation } from '../../../hooks/translationHook';
import { FormItem } from '../../FormItem';
import PDFPreview from '../PDFPreview';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-flow: row wrap;
    justify-content: flex-start;
    gap: 4% 10%;
`;

const Box = styled.div`
    width: 35%;
    max-width: 500px;
    min-width: 250px;
`;

const AttachmentPrintBox = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
`;

const DownloadLink = styled.a`
    display: inline-block;
    margin-top: 5px;
`;

interface PrintConfigurationProps {
    isReadOnly: boolean;
    companyId: string;
    form: FormInstance;
}

const PrintConfiguration: React.FC<PrintConfigurationProps> = ({ isReadOnly, companyId, form }) => {
    const { t, tCommon, tValidation } = useTranslation('easy_print_task');
    const [hasAttachment, setHasAttachment] = useState(form.getFieldValue(['attachment', 'url']) !== undefined);
    const envelopeSupplierExampleURL = form.getFieldValue(['resources', 'envelope', 'supplierExample', 'url']);

    const deliveryTypeOptions = [
        { label: <Badge color={Colors.NON_PRIOR} text={t('non_prior')} />, value: DeliveryType.NON_PRIOR },
        { label: <Badge color={Colors.PRIOR} text={t('prior')} />, value: DeliveryType.PRIOR },
        { label: <Badge color={Colors.REGISTERED} text={t('registered')} />, value: DeliveryType.REGISTERED },
    ];

    const printAttachmentOptions = [
        {
            label: (
                <Tooltip
                    title={t('attachment_print_position_end_of_document_hint')}
                    key={PrintAttachmentPosition.END_OF_DOCUMENT}
                >
                    {t('attachment_print_position_end_of_document')}
                </Tooltip>
            ),
            value: PrintAttachmentPosition.END_OF_DOCUMENT,
        },
        {
            label: (
                <Tooltip
                    title={t('attachment_print_position_every_page_hint')}
                    key={PrintAttachmentPosition.VERSO_ALL_PAGES}
                >
                    {t('attachment_print_position_every_page')}
                </Tooltip>
            ),
            value: PrintAttachmentPosition.VERSO_ALL_PAGES,
        },
    ];

    const envelopeTypeOptions = [
        {
            label: (
                <>
                    <img src={USRight5gr} alt="us right 5gr" />
                    {t('us_right_5gr')}
                </>
            ),
            value: WorkflowEnvelopeType.US_RIGHT_5GR,
        },
        {
            label: (
                <>
                    <img src={USLeft5gr} alt="us left 5gr" />
                    {t('us_left_5gr')}
                </>
            ),
            value: WorkflowEnvelopeType.US_LEFT_5GR,
        },
    ];

    const sideTypeOptions = [
        {
            label: (
                <>
                    <img src={Recto} alt="recto" />
                    {t('recto')}
                </>
            ),
            value: PrintSide.RECTO,
        },
        {
            label: (
                <>
                    <img src={RectoVerso} alt="recto verso" />
                    {t('recto_verso')}
                </>
            ),
            value: PrintSide.RECTO_VERSO,
        },
    ];

    const printColorOptions = [
        {
            label: (
                <>
                    <img src={BlackAndWhite} alt="black and white" />
                    {t('black_white')}
                </>
            ),
            value: PrintColor.BW,
        },
        {
            label: (
                <>
                    <img src={Color} alt="color" />
                    {t('color')}
                </>
            ),
            value: PrintColor.COLORS,
        },
    ];

    return (
        <Container>
            <Box>
                <FormItem
                    label={t('name')}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.name'),
                        },
                    ]}
                >
                    <Input disabled={isReadOnly} type="text" aria-label="name input" />
                </FormItem>
                <FormItem label={tCommon('description')} name="description">
                    <TextArea disabled={isReadOnly} rows={5} aria-label="description input" />
                </FormItem>
            </Box>
            <Box>
                <FormItem
                    label={t('time_to_wait')}
                    name="timeToWait"
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.time_to_wait'),
                        },
                        {
                            type: 'number',
                            min: 1,
                            message: tValidation('min.time_to_wait'),
                        },
                        {
                            type: 'number',
                            max: 30,
                            message: tValidation('max.time_to_wait'),
                        },
                    ]}
                    tooltip={{ title: 'Tooltip with customize icon', icon: <InfoCircleOutlined /> }}
                >
                    <InputNumberWithLeftAddon
                        disabled={isReadOnly}
                        addonBefore={t('days')}
                        min={1}
                        max={30}
                        aria-label="time to wait input"
                    />
                </FormItem>
                <FormItem shouldUpdate label={t('logo')}>
                    {() => (
                        <Upload
                            accept="image/*,application/postscript"
                            disabled={isReadOnly}
                            isImageUpload
                            companyId={companyId}
                            previewUrl={form.getFieldValue(['resources', 'envelope', 'logo', 'url'])}
                            onFilenameChange={(filename: string) =>
                                form.setFields([
                                    {
                                        name: ['resources', 'envelope', 'logo', 'filename'],
                                        value: filename,
                                    },
                                ])
                            }
                            onDownloadUrlChange={(url: string) =>
                                form.setFields([
                                    {
                                        name: ['resources', 'envelope', 'logo', 'url'],
                                        value: url,
                                    },
                                ])
                            }
                        />
                    )}
                </FormItem>
            </Box>
            <Box>
                <FormItem
                    label={t('delivery_type')}
                    name="deliveryType"
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.delivery_type'),
                        },
                    ]}
                >
                    <Select disabled={isReadOnly} options={deliveryTypeOptions} />
                </FormItem>
                <FormItem
                    label={t('envelop_type')}
                    name={['resources', 'envelope', 'type']}
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.envelop_type'),
                        },
                    ]}
                >
                    <Select disabled={isReadOnly} options={envelopeTypeOptions} />
                </FormItem>
                <FormItem
                    label={t('print_side')}
                    name={['print', 'printSide']}
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.print_side'),
                        },
                    ]}
                >
                    <Select disabled={isReadOnly} options={sideTypeOptions} />
                </FormItem>
                <FormItem
                    label={t('print_color')}
                    name={['print', 'printColor']}
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.print_color'),
                        },
                    ]}
                >
                    <Select disabled={isReadOnly} options={printColorOptions} />
                </FormItem>
            </Box>
            <Box>
                <FormItem shouldUpdate label={t('attachment')}>
                    {() => (
                        <Upload
                            accept=".pdf"
                            disabled={isReadOnly}
                            isImageUpload={false}
                            companyId={companyId}
                            hasFile={form.getFieldValue(['attachment', 'url']) !== undefined}
                            onFilenameChange={(filename: string) => {
                                setHasAttachment(filename !== undefined);
                                form.setFields([
                                    {
                                        name: ['attachment', 'filename'],
                                        value: filename,
                                    },
                                ]);
                            }}
                            onDownloadUrlChange={(url: string) =>
                                form.setFields([
                                    {
                                        name: ['attachment', 'url'],
                                        value: url,
                                    },
                                ])
                            }
                        />
                    )}
                </FormItem>
                {hasAttachment && (
                    <AttachmentPrintBox>
                        <FormItem
                            name={['attachment', 'position']}
                            rules={[
                                {
                                    required: true,
                                    message: tValidation('required.attachment_print_option'),
                                },
                            ]}
                        >
                            <Radio.Group
                                disabled={isReadOnly}
                                options={printAttachmentOptions}
                                size="small"
                                optionType="button"
                                buttonStyle="solid"
                            />
                        </FormItem>
                    </AttachmentPrintBox>
                )}
                {envelopeSupplierExampleURL && (
                    <FormItem label={t('envelope_supplier_example')}>
                        <PDFPreview pdfUrl={envelopeSupplierExampleURL} />
                        <DownloadLink target="_blank" href={envelopeSupplierExampleURL} download>
                            {t('download_example')}
                        </DownloadLink>
                    </FormItem>
                )}
            </Box>
        </Container>
    );
};

export default PrintConfiguration;
