import { Divider as DividerAntd } from 'antd';
import styled from 'styled-components';
import Scissors from '../../assets/images/scissors.svg';

const Divider = styled(DividerAntd)`
    width: 2400px;
    margin-top: -30px;
`;

const Image = styled.img`
    max-height: 15px;
`;

interface PrintableVignetteProps {
    printRef: React.RefObject<HTMLDivElement>;
    vignette: React.ReactNode;
}

const PrintableVignette: React.FC<PrintableVignetteProps> = ({ printRef, vignette }) => {
    const Cutlane = () => (
        <Divider orientation="left">
            <Image src={Scissors} />
        </Divider>
    );
    return (
        <div ref={printRef}>
            {vignette}
            <Cutlane />
            {vignette}
            <Cutlane />
            {vignette}
            <Cutlane />
            {vignette}
        </div>
    );
};

export default PrintableVignette;
