import Icon from '@ant-design/icons';
import { Colors } from '@eservices/ui-constants/colors';
import { Card } from 'antd';
import React, { ComponentType, SVGProps, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

const Container = styled(Card)`
    width: 170px;
    padding: 20px;
    border-radius: 7px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .ant-card-body {
        padding: 1px;
    }

    &:hover {
        background-color: ${Colors.PRIMARY};
        color: ${Colors.WHITE};
    }
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

const Description = styled(ReactMarkdown)`
    font-size: 14px;
`;

const StyledIcon = styled(Icon)<StyledIconProps>`
    font-size: 16px;
    color: ${({ isHovering }) => (isHovering ? Colors.WHITE : Colors.PRIMARY)};
`;
interface StyledIconProps {
    isHovering: boolean;
}

interface SplitChoiceProps {
    onClick: () => void;
    icon: ComponentType<SVGProps<SVGSVGElement>>;
    description: string;
    isReadOnly: boolean;
}

const SplitChoice: React.FC<SplitChoiceProps> = ({ onClick, icon, description, isReadOnly }) => {
    const [isHovering, setIsHovering] = useState(false);

    return (
        <Container
            hoverable
            onClick={!isReadOnly && onClick}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <InnerContainer>
                <StyledIcon isHovering={isHovering} component={icon} />
                <Description>{description}</Description>
            </InnerContainer>
        </Container>
    );
};

export default SplitChoice;
