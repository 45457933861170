import React from 'react';
import { NavLink as AntNavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from '@eservices/ui-constants/colors';
import { useTranslation } from '../hooks/translationHook';
import { MainRoute } from '../types';

const NavLink = styled(AntNavLink)`
    display: block;
    padding: 10px 0px;
    cursor: pointer;
    font-size: 16px;
    color: ${Colors.GRAY_SECONDARY};
    text-align: center;
    border-left: 2px solid ${Colors.DARK_PRIMARY};

    :hover {
        background-color: ${Colors.SENARY};
        color: ${Colors.WHITE};
        border-left: 2px solid ${Colors.SENARY};

        .anticon {
            color: ${Colors.WHITE};
        }
    }

    &.${(props) => props.activeClassName} {
        background-color: ${Colors.WHITE};
        color: ${Colors.GRAY_PRIMARY};
        border-left: 2px solid ${Colors.TERTIARY};

        .anticon {
            color: ${Colors.GRAY_PRIMARY};
        }
    }

    .anticon {
        color: ${Colors.PRIMARY};
    }
`;

const IconContainer = styled.span`
    font-size: 24px;
`;

const Title = styled.span`
    font-size: 10px;
    white-space: initial;
`;

interface NavBarItemProps {
    route: MainRoute;
}

const NavBarItem: React.FC<NavBarItemProps> = ({ route: { path, icon, title } }) => {
    const { tCommon } = useTranslation();
    return (
        <li>
            <NavLink role="navlink" aria-label={`Link to ${title}`} to={path} activeClassName="any">
                <IconContainer>{icon}</IconContainer>
                <br />
                <Title>{tCommon(title)}</Title>
            </NavLink>
        </li>
    );
};

export default NavBarItem;
