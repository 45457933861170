import { ParcelType } from '@eservices/shared/constant';
import { API, ParcelWithCustoms } from '@eservices/shared/type';
import { Tabs } from 'antd';
import { useTranslation } from '../../hooks/translationHook';
import styled from 'styled-components';
import DetailTabs from '../DetailTabs';
import ParcelDetailSubDetailCustoms from './ParcelDetailCustoms';
import ParcelDetailSubDetailBpostDetail from './ParcelDetailSubDetailBpostDetail';
import ParcelDetailSubDetailBpostOptions from './ParcelDetailSubDetailBpostOptions';
import ParcelDetailSubDetailDownloads from './ParcelDetailSubDetailDownloads';
import ParcelDetailSubDetailRecipient from './ParcelDetailSubDetailRecipient';
import ParcelDetailSubDetailSender from './ParcelDetailSubDetailSender';

const { TabPane } = Tabs;

const Parts = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
`;

interface ParcelDetailSubDetailProps {
    companyId: string;
    departmentId: string;
    parcel: API.Parcel;
    departments: API.DepartmentListItem[];
}

const ParcelDetailSubDetail: React.FC<ParcelDetailSubDetailProps> = ({
    companyId,
    departmentId,
    parcel,
    departments,
}) => {
    const { t, tCommon } = useTranslation('parcel_detail');

    const { type, warnings, errors } = parcel;
    const tabs = [];

    const detailTab = (
        <TabPane tab={tCommon('details')} key="1">
            <Parts>
                <ParcelDetailSubDetailRecipient parcel={parcel} />
                <ParcelDetailSubDetailSender departments={departments} parcel={parcel} />
                <ParcelDetailSubDetailBpostDetail parcel={parcel} />
                <ParcelDetailSubDetailBpostOptions parcel={parcel} />
            </Parts>
            <ParcelDetailSubDetailDownloads
                companyId={companyId}
                departmentId={departmentId}
                departments={departments}
                parcel={parcel}
            />
        </TabPane>
    );
    tabs.push(detailTab);

    if (type === ParcelType.PARCEL_WITH_CUSTOMS) {
        const customsTab = (
            <TabPane tab={t('customs')} key="2">
                <ParcelDetailSubDetailCustoms parcel={parcel as ParcelWithCustoms} />
            </TabPane>
        );

        tabs.push(customsTab);
    }

    return <DetailTabs tabs={tabs} warnings={warnings} errors={errors.map((e) => e.message)} />;
};

export default ParcelDetailSubDetail;
