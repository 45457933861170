import { API } from '@eservices/shared/type';
import { Input, Space } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../../../../hooks/translationHook';
import RegionButton from '../Common/RegionButton';

const StyledInput = styled(Input)`
    width: 150px;
    margin: 0 5px;
`;

interface TextSplitProps {
    isReadOnly: boolean;
    order: number;
    identifier: API.PDFSplitIdentifier;
}

const TextSplit: React.FC<TextSplitProps> = ({ isReadOnly, order, identifier }) => {
    const { t, tValidation } = useTranslation('workflow');

    return (
        <Space align="baseline">
            {t('split_overview_part1')}
            <RegionButton regionOfInterest={identifier.regionOfInterest} />
            <strong>{t('split_overview_text_part2')}</strong>
            <FormItem
                name={[order, 'value']}
                rules={[
                    {
                        required: true,
                        message: tValidation('required.text'),
                    },
                ]}
                noStyle
            >
                <StyledInput disabled={isReadOnly} placeholder={t('text_placeholder')} />
            </FormItem>
        </Space>
    );
};

export default TextSplit;
