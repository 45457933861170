import { API } from '@eservices/shared/type';
import { useForm } from 'antd/lib/form/Form';
import { useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import FailedToLoadData from '../../components/FailedToLoadData';
import {
    initialState as pdfIdentifiersReducerInitialState,
    workflowPDFInputSourceReducer,
} from '../../components/workflow/PDFInputSourceConfiguration/IdentifiersManager/workflowPDFInputSourceReducer';
import { taskReducer } from '../../components/workflow/TaskManager/taskReducer';
import { Routes } from '../../constants';
import { useGetDepartment, useGetWorkflow } from '../../hooks/apiHooks';
import { WatermarkUI } from '../../types';
import WorkflowDetailFetching from './WorkflowDetailFetching';

interface WorkflowDetailViewProps {
    companyId: string;
}

const WorkflowDetailView: React.FC<WorkflowDetailViewProps> = ({ companyId }) => {
    const history = useHistory();
    const [form] = useForm();
    const [{ tasks }, tasksDispatch] = useReducer(taskReducer, { tasks: [] });
    const [workflowPDFInputSource, workflowPDFInputSourceDispatch] = useReducer(
        workflowPDFInputSourceReducer,
        pdfIdentifiersReducerInitialState,
    );
    const [watermark, setWatermark] = useState<WatermarkUI>();
    const [sampleFile, setSampleFile] = useState<API.ConfigurationFile>();
    const { id: workflowId, departmentId }: { id: string; departmentId: string } = useParams();
    const { data: departmentDetails, isError, isLoading } = useGetDepartment(companyId, departmentId);

    const query = useGetWorkflow(companyId, departmentId, workflowId);

    const onBack = () => {
        history.push(Routes.WORKFLOWS);
    };

    if (isError) return <FailedToLoadData />;
    return (
        <WorkflowDetailFetching
            isReadOnly
            companyId={companyId}
            departmentDetails={departmentDetails}
            departmentDetailsLoading={isLoading}
            query={query}
            form={form}
            tasks={tasks}
            tasksDispatch={tasksDispatch}
            workflowPDFInputSource={workflowPDFInputSource}
            workflowPDFInputSourceDispatch={workflowPDFInputSourceDispatch}
            onBack={onBack}
            watermark={watermark}
            setWatermark={setWatermark}
            sampleFile={sampleFile}
            setSampleFile={setSampleFile}
        />
    );
};

export default WorkflowDetailView;
