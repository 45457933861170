import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../components/Buttons';
import { Routes } from '../../constants';
import { useTranslation } from '../../hooks/translationHook';
import JobsOverviewFetching from './JobsOverviewFetching';

interface JobsOverviewEditProps {
    companyId: string;
    canSubmitJob: boolean;
    canCancelJobs: boolean;
    canApproveJobs: boolean;
}

const JobsOverviewView: React.FC<JobsOverviewEditProps> = ({
    companyId,
    canSubmitJob,
    canCancelJobs,
    canApproveJobs,
}) => {
    const { t } = useTranslation('jobs_overview');
    const history = useHistory();

    const actions = (
        <PrimaryButton
            icon={<PlusOutlined />}
            aria-label="create job link"
            onClick={() => history.push(Routes.SUBMIT_JOB)}
        >
            {t('action_submit_job')}
        </PrimaryButton>
    );

    return (
        <JobsOverviewFetching
            companyId={companyId}
            actions={canSubmitJob && actions}
            canCancelJobs={canCancelJobs}
            canApproveJobs={canApproveJobs}
        />
    );
};

export default JobsOverviewView;
