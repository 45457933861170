import { API } from '@eservices/shared/type';
import { message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import styled from 'styled-components';
import { PrimaryButton } from '../../components/Buttons';
import Card from '../../components/Card';
import UserDetailsForm from '../../components/profile/UserDetailsForm';
import { useUpdateMyUser } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';

const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
`;

interface Props {
    user: API.User;
}
const UserDetailEdit: React.FC<Props> = ({ user }) => {
    const { t, tCommon } = useTranslation('profile');
    const [form] = useForm<API.UpdateUserProfile>();
    form.setFieldsValue({ ...user });
    const saveMutation = useUpdateMyUser();

    const saveDetails = async () => {
        const updatedUserDetails = await form.validateFields();
        saveMutation.mutate(updatedUserDetails, {
            onSuccess: async () => {
                await message.success(t('editing_profile_success', { username: form.getFieldValue('username') }), 3);
            },
            onError: async () => {
                await message.error(t('editing_profile_error'), 3);
            },
        });
    };

    const actions = (
        <PrimaryButton disabled={saveMutation.isLoading} onClick={saveDetails}>
            {tCommon('save')}
        </PrimaryButton>
    );
    return (
        <Card title={t('user')}>
            <UserDetailsForm form={form} />
            <Actions>{actions}</Actions>
        </Card>
    );
};

export default UserDetailEdit;
