import { Colors } from '@eservices/ui-constants/colors';
import React from 'react';
import { Link as defaultLink, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { Fonts } from '../constants';

interface ButtonLinkProps extends LinkProps {
    icon?: React.ReactNode;
}

const Link = styled(defaultLink)`
    display: inline-block;
    font-size: 14px;
    border: 2px solid ${Colors.PRIMARY};
    border-radius: 32px;
    margin-bottom: 2px;

    padding: 4px 16px 2px 16px;

    color: ${Colors.DARK_PRIMARY};

    &:hover {
        border: 2px solid ${Colors.PRIMARY};
        background-color: ${Colors.PRIMARY};
        color: ${Colors.GRAY_PRIMARY};
    }
`;

const TextContainer = styled.span`
    font-family: ${Fonts.HURME1};
    margin-left: 8px;
`;

export const ButtonLink: React.FC<ButtonLinkProps> = ({ icon, children, ...linkProps }) => {
    return (
        <Link {...linkProps}>
            {icon}
            <TextContainer>{children}</TextContainer>
        </Link>
    );
};
