import PageHeader from 'antd/lib/page-header';
import React from 'react';
import styled from 'styled-components';
import FailedToLoadData from '../components/FailedToLoadData';

const Filters = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2%;
`;

const StyledPageHeader = styled(PageHeader)`
    margin: 0 0 2% 0;
`;

const Content = styled.div`
    margin: 0 24px;
`;

interface ResourcesTableLayoutProps {
    isError: boolean;
    title: string;
    filter: React.ReactNode;
    detail: React.ReactNode;
    actions?: React.ReactNode;
}

const ResourcesTableLayout: React.FC<ResourcesTableLayoutProps> = ({ isError, title, filter, detail, actions }) => {
    if (isError) return <FailedToLoadData />;

    return (
        <>
            <StyledPageHeader title={title} extra={actions}></StyledPageHeader>
            <Content>
                <Filters>{filter}</Filters>
                {detail}
            </Content>
        </>
    );
};

export default ResourcesTableLayout;
