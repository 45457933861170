import { CalendarFilled } from '@ant-design/icons';
import { message, Skeleton, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../../components/Buttons';
import Card from '../../components/Card';
import CreateAnnouncementModal from '../../components/daily-announcement/CreateAnnouncementModal';
import FailedToLoadData from '../../components/FailedToLoadData';
import { useCreateDailyConsumptionAnnouncement, useGetDepartments } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import { toReadableDate } from '../../utils/helpers';

const loadingMessageKey = 'loadingMessageKey';

const currentDate = toReadableDate(new Date().toISOString());

const DateDetailsHeader = styled.div`
    text-align: right;

    span {
        padding: 2px;
    }
`;

interface DailyAnnouncementsOverviewFetchingProps {
    companyId: string;
    canCreateAnnouncement?: boolean;
}

const DailyAnnouncementsOverviewFetching: React.FC<DailyAnnouncementsOverviewFetchingProps> = ({
    companyId,
    canCreateAnnouncement,
}) => {
    const { t } = useTranslation('daily_announcements');
    const [form] = useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const {
        data: departments = [],
        isLoading: isLoadingDepartments,
        isError: isErrorDepartments,
    } = useGetDepartments(companyId);

    const createMutation = useCreateDailyConsumptionAnnouncement(companyId);

    const onSave = async () => {
        const { departmentId, ...dailyAnnouncement } = await form.validateFields();

        void message.loading({ key: loadingMessageKey, content: t('creating_daily_announcement_loading') }, 0);

        createMutation.mutate(
            { departmentId, dailyAnnouncement },
            {
                onSuccess: () => {
                    void message.success(t('creating_daily_announcement_success'), 3);
                },
                onError: () => {
                    void message.error(t('creating_daily_announcement_error'), 3);
                },
                onSettled: () => {
                    message.destroy(loadingMessageKey);
                },
            },
        );
    };

    if (isLoadingDepartments) return <Skeleton active />;
    if (isErrorDepartments) return <FailedToLoadData />;
    return (
        <Card
            title={t('title')}
            extra={
                <DateDetailsHeader>
                    <Space>
                        <div>
                            <CalendarFilled />
                            <span>{currentDate}</span>
                        </div>
                        {canCreateAnnouncement && (
                            <div>
                                <PrimaryButton onClick={() => setIsModalVisible(true)}>
                                    {t('create_announcement')}
                                </PrimaryButton>
                            </div>
                        )}
                    </Space>
                </DateDetailsHeader>
            }
        >
            {
                // TODO : DAILY ANNOUNCEMENTS TABLE
            }
            {
                <CreateAnnouncementModal
                    form={form}
                    isVisible={isModalVisible}
                    companyId={companyId}
                    onSave={onSave}
                    onClose={() => setIsModalVisible(false)}
                    departments={departments}
                />
            }
        </Card>
    );
};

export default DailyAnnouncementsOverviewFetching;
