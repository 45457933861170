import { AuditOutlined } from '@ant-design/icons';
import { ApplicationModule, UserRole } from '@eservices/shared/constant';
import { Routes } from '../../constants';
import { BasicRoute, MainRoute } from '../../types';
import RegisteredMailOverviewView from './RegisteredMailOverviewView';

const viewRegisteredMailOverviewPage = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.REGISTERED_MAIL,
    component: <RegisteredMailOverviewView companyId={companyId} />,
});

export const createRegisteredMailRouter = (userRole: UserRole, companyId: string): MainRoute => {
    const registeredMailRoutes = {
        [UserRole.SUPER_USER]: [viewRegisteredMailOverviewPage(companyId)],
        [UserRole.CLIENT_ADMIN]: [viewRegisteredMailOverviewPage(companyId)],
        [UserRole.CLIENT_DEPARTMENT_ADMIN]: [viewRegisteredMailOverviewPage(companyId)],
        [UserRole.CLIENT_OPERATOR]: [viewRegisteredMailOverviewPage(companyId)],
        [UserRole.CLIENT_VIEWER]: [viewRegisteredMailOverviewPage(companyId)],
    };

    return {
        path: Routes.REGISTERED_MAIL,
        title: 'registered mail',
        icon: <AuditOutlined />,
        subRoutes: registeredMailRoutes[userRole],
        module: ApplicationModule.EASYPOST_PORTAL,
    };
};
