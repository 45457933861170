import CheckCircleFilled from '@ant-design/icons/lib/icons/CheckCircleFilled';
import CloseCircleFilled from '@ant-design/icons/lib/icons/CloseCircleFilled';
import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined';
import EditFilled from '@ant-design/icons/lib/icons/EditFilled';
import { Colors } from '@eservices/ui-constants/colors';
import { List, Modal } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';
import PDFViewer from '../../components/PDFViewer';
import { useTranslation } from '../../hooks/translationHook';

const Wrapper = styled.div`
    position: relative;
    background-color: ${Colors.WHITE};
    padding: 0 2%;
    display: flex;
    justify-content: space-between;
`;

const StyledList = styled(List)`
    margin-right: 40px;
    h2 {
        margin-top: 8px;
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 30px;
    }
    .ant-list-item {
        padding: 17px 0;
    }
`;

const StyledListItemMeta = styled(List.Item.Meta)`
    align-items: center;
    .ant-list-item-meta-avatar {
        margin-right: 8px;
    }
    .ant-list-item-meta-title {
        margin-bottom: 0;
    }
`;

const StyledCloseCircleFilled = styled(CloseCircleFilled)`
    color: ${Colors.ERROR};
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 25px;

    a:first-of-type {
        margin-right: 10px;
    }
`;
const policies = [
    {
        name: 'Terms & Conditions',
        id: 'terms-and-conditions-qerds',
        signed: false,
    },
    {
        name: 'Privacy statement',
        id: 'privacy-statement-qerds',
        signed: false,
    },
    {
        name: 'Practice Statement',
        id: 'practice-statement-qerds',
        signed: false,
    },
];

interface PoliciesPopUpProps {
    visible: boolean;
}

const PoliciesPopUp: React.FC<PoliciesPopUpProps> = ({ visible }) => {
    const { t, tCommon } = useTranslation('policies');
    const [indexPolicyDisplayed, setIndexPolicyDisplayed] = useState(0);
    const [statePolicies, setStatePolicies] = useState(policies);
    const [hasRead, setHasRead] = useState(false);
    const [show, setShow] = useState(visible);

    const PoliciesToSign = policies.map((policy) => {
        return (
            <List.Item key={policy.id}>
                <StyledListItemMeta
                    title={t(policy.id)}
                    avatar={policy.signed ? <CheckCircleFilled /> : <StyledCloseCircleFilled />}
                />
            </List.Item>
        );
    });

    const handleReachBottom = () => {
        setHasRead(true);
    };

    const handleConsent = () => {
        const clone = statePolicies;

        clone[indexPolicyDisplayed].signed = true;

        if (indexPolicyDisplayed + 1 < clone.length) {
            setStatePolicies(clone);
            setIndexPolicyDisplayed(indexPolicyDisplayed + 1);
            setHasRead(false);
        } else {
            setShow(false);
        }
    };

    return (
        <Modal centered title={tCommon('policies')} visible={show} closable={false} footer={null} width={1400}>
            <Wrapper>
                <StyledList>
                    <div>
                        <h2>{t('consent_required_title')}</h2>
                        <p>{t('consent_required_message')}</p>
                    </div>
                    {PoliciesToSign}
                </StyledList>
                <div>
                    <PDFViewer
                        handleReachBottom={handleReachBottom}
                        scrollThroughDocument
                        pdfUrl={`/assets/policies/${policies[indexPolicyDisplayed].id}.pdf`}
                    />
                    <ButtonWrapper>
                        <SecondaryButton
                            href={`/assets/policies/${policies[indexPolicyDisplayed].id}.pdf`}
                            download
                            icon={<DownloadOutlined />}
                        >
                            {t('download_document')}
                        </SecondaryButton>
                        <PrimaryButton icon={<EditFilled />} onClick={handleConsent} disabled={!hasRead}>
                            {t('sign_document')}
                        </PrimaryButton>
                    </ButtonWrapper>
                </div>
            </Wrapper>
        </Modal>
    );
};

export default PoliciesPopUp;
