import { DownloadOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { PrimaryButton } from '../../../components/Buttons';
import DescriptionItem from '../../../components/DescriptionItem';
import DownloadLink from '../../../components/DownloadLink';
import { useTranslation } from '../../../hooks/translationHook';

const StyledPrimaryButton = styled(PrimaryButton)`
    margin-top: 40px;
`;

const DownloadContainer = styled.div`
    text-align: center;
`;

interface QerdsTaskDetailProps {
    companyId: string;
    task: API.QERDSTaskDetails;
    taskConfiguration: API.TaskQERDS;
}

const QerdsTaskDetail: React.FC<QerdsTaskDetailProps> = ({ companyId, task }) => {
    const { t, tCommon } = useTranslation('qerds_task');

    const NA = <>{tCommon('na')}</>;

    const evidenceReport = task.evidenceReportLocation ? (
        <DownloadLink
            companyId={companyId}
            source={API.FileSource.SENDING_EVENT}
            location={task.evidenceReportLocation}
            content={<a download>{t('evidence_report')}</a>}
        />
    ) : (
        NA
    );

    return (
        <>
            <Row>
                <Col span={24}>
                    <DescriptionItem title={t('recipient_email_address')} content={task.recipientInfo.emailAddress} />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem title={t('recipient_first_name')} content={task.recipientInfo.firstName ?? NA} />
                </Col>
                <Col span={12}>
                    <DescriptionItem title={t('recipient_last_name')} content={task.recipientInfo.lastName ?? NA} />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem title={t('recipient_birth_date')} content={task.recipientInfo.birthDate ?? NA} />
                </Col>
                <Col span={12}>
                    <DescriptionItem
                        title={t('recipient_company_identification_number')}
                        content={task.recipientInfo.companyIdentificationNumber ?? NA}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem title={t('evidence_report')} content={evidenceReport} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem title={t('document_hash_before_sign')} content={task.documentHash} />
                </Col>
            </Row>
            <Row>
                <Col span={12} offset={6}>
                    <DownloadContainer>
                        <DownloadLink
                            companyId={companyId}
                            source={API.FileSource.SENDING_EVENT}
                            location={task.location}
                            content={
                                <StyledPrimaryButton icon={<DownloadOutlined />}>
                                    {tCommon('download_document')}
                                </StyledPrimaryButton>
                            }
                        />
                    </DownloadContainer>
                </Col>
            </Row>
        </>
    );
};

export default QerdsTaskDetail;
