// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.pro.ts`.

export const environment = {
    production: false,
    // Harcoded values to replace (left in this state to avoid build failure)
    awsRegion: 'eu-west-1',
    userPoolId: 'eu-west-1_zYSAtonrC',
    userPoolClientId: '1cbdgmpcv96h2vqnd7ofkjslla',
    apiBaseURL: 'http://localhost:8080',
    pillar: 'LOCAL',
    host: 'localhost',
    locize: {
        version: 'latest',
        enableAPI: true,
    },
    emailDomain: 'dev.ses.easypost.eu',
};
