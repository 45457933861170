import { API } from '@eservices/shared/type';
import { Timeline } from 'antd';
import { bpostStatusIcons } from '../../constants';
import { useTranslation } from '../../hooks/translationHook';
import { toReadableDateTime } from '../../utils/helpers';
import styled from 'styled-components';
import { Colors } from '@eservices/ui-constants/colors';

const Icon = styled.img`
    width: 30%;
    margin-bottom: 3%;
`;

const Timestamp = styled.span`
    color: ${Colors.GRAY_SEPTENARY};
`;

const IconStatusContainer = styled.div`
    text-align: center;
`;

const Status = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

interface ParcelDetailTimeLineProps {
    parcel: API.Parcel;
}

const ParcelDetailTimeline: React.FC<ParcelDetailTimeLineProps> = ({
    parcel: { barCode, statusHistory, recipient },
}) => {
    const { t, i18n } = useTranslation('parcel_detail');

    const statusHistoryList = Object.entries(statusHistory).map(([k, v]) => ({ status: k, date: v }));
    statusHistoryList.sort((a, b) => (a.date > b.date ? 1 : a.date < b.date ? -1 : 0));
    const lastKnownStatus = [...statusHistoryList].reverse().find((status) => status.date !== undefined);

    return (
        <Status>
            <div>
                <Timeline pending={!(statusHistory.PARCEL_DELIVERED || statusHistory.BACK_TO_SENDER_PARCEL_DELIVERED)}>
                    {statusHistoryList.map(({ status, date }) => (
                        <Timeline.Item>
                            <>
                                {t(status.toLowerCase())} <br />
                                <Timestamp>{toReadableDateTime(date)}</Timestamp>
                            </>
                        </Timeline.Item>
                    ))}
                </Timeline>
            </div>
            <IconStatusContainer>
                <div>
                    <Icon
                        src={bpostStatusIcons[lastKnownStatus.status]}
                        alt={t(lastKnownStatus.status.toLowerCase())}
                    />
                </div>
                {statusHistory.PARCEL_SORTED && (
                    <a
                        target="_blank"
                        href={`https://track.bpost.cloud/btr/web/#/search?lang=${i18n.language}&itemCode=${barCode}&postalCode=${recipient.address.postalCode}`}
                    >
                        {t('track_and_trace')}
                    </a>
                )}
            </IconStatusContainer>
        </Status>
    );
};

export default ParcelDetailTimeline;
