import DepartmentsOverviewFetching from './DepartmentsOverviewFetching';

interface DepartmentsOverviewViewProps {
    companyId: string;
}

const DepartmentsOverviewView: React.FC<DepartmentsOverviewViewProps> = ({ companyId }) => (
    <DepartmentsOverviewFetching companyId={companyId} isReadOnly />
);

export default DepartmentsOverviewView;
