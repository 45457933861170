import { CompressOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Button, InputNumber, Space } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { pdfjs } from 'react-pdf';
import styled from 'styled-components';
import { useTranslation } from '../hooks/translationHook';
import { noop } from '../utils/helpers';
import PDFAllPages from './PDFAllPages';
import PDFOnePage from './PDFOnePage';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Container = styled.div`
    width: 210mm;
`;

const TopButtons = styled.div`
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    button {
        margin-right: 10px;
    }
`;

const normalRatio = (height: number, width: number) => Math.floor((width / height) * 1000);

const A4_RATIO = normalRatio(842, 595);

export interface Rectangle {
    x: number;
    y: number;
    width: number;
    height: number;
    visible: boolean;
    focus: boolean;
}

interface RectanglePDFEditorProps {
    pdfUrl: string;
    onSizeChange?: (width: number, height: number) => void;
    onScaleChange?: (scale: number) => void;
    onNonA4Ratio?: () => void;
    extra?: React.ReactNode;
    scrollThroughDocument?: boolean;
    handleReachBottom?: () => void;
}

const defaultScale = 1.25;
const defaultPageNumber = 1;
const defaultNumberPages = 0;

const PDFViewer: React.FC<RectanglePDFEditorProps> = ({
    pdfUrl,
    onSizeChange = () => ({}),
    onScaleChange = () => ({}),
    onNonA4Ratio = () => ({}),
    extra,
    scrollThroughDocument = false,
    handleReachBottom = noop,
    children,
}) => {
    const { tCommon } = useTranslation('task');
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [canvasWidth, setCanvasWidth] = useState(0);
    const [scale, setScale] = useState(defaultScale);
    const [pageNumber, setPageNumber] = useState(defaultPageNumber);
    const [numberPages, setNumberPages] = useState(defaultNumberPages);
    const pageContainerRef = useRef<HTMLDivElement>();
    const myRefs: HTMLDivElement[] = [];
    const pageRefs = useRef(myRefs);

    const canvasPDF = useCallback((node) => {
        if (node !== null) {
            setCanvasHeight(node.getBoundingClientRect().height);
            setCanvasWidth(node.getBoundingClientRect().width);
        }
    }, []);

    useEffect(() => {
        setPageNumber(defaultPageNumber);
        setNumberPages(defaultPageNumber);
        setScale(defaultScale);
    }, [pdfUrl]);

    useEffect(() => {
        onScaleChange(scale);
    }, [scale]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumberPages(numPages);
    };

    const onPageLoadSuccess = ({ height, width }) => {
        if (A4_RATIO - normalRatio(height, width) !== 0) {
            onNonA4Ratio();
        }
    };

    const onRenderSuccess = () => {
        onSizeChange(canvasWidth, canvasHeight);
    };

    const updatePageNumberOnScroll = () => {
        const singlePage = pageRefs.current[0];
        const pageContainer = pageContainerRef.current;

        const heightPage = singlePage.offsetHeight + 2;

        const newPageNumber = Math.floor(pageContainer.scrollTop / heightPage) + 1;

        setPageNumber(newPageNumber);
        if (pageNumber === numberPages) {
            handleReachBottom();
        }
    };

    const changePageNumber = (number) => {
        if (number && scrollThroughDocument) {
            const pageToScroll = pageRefs.current[number - 1];
            pageToScroll.scrollIntoView();
        }
        if (!scrollThroughDocument) {
            setPageNumber(number);
        }
    };

    const constructPagesRefs = (el, index) => {
        pageRefs.current[index] = el;
    };

    return (
        <Container>
            <TopButtons>
                <div>
                    <Button shape="circle" icon={<CompressOutlined />} onClick={() => setScale(defaultScale)} />
                    <Button shape="circle" icon={<ZoomOutOutlined />} onClick={() => setScale(scale - 0.25)} />
                    <Button shape="circle" icon={<ZoomInOutlined />} onClick={() => setScale(scale + 0.25)} />
                </div>
                <Space>
                    {tCommon('Page')}
                    <InputNumber
                        style={{ width: '60px' }}
                        min={1}
                        max={numberPages}
                        keyboard
                        value={pageNumber}
                        size="small"
                        onChange={(number) => changePageNumber(number)}
                    />
                    / {numberPages}
                </Space>
                {extra}
            </TopButtons>
            {scrollThroughDocument ? (
                <PDFAllPages
                    canvasWidth={canvasWidth}
                    canvasHeight={canvasHeight}
                    pageContainerRef={pageContainerRef}
                    updatePageNumberOnScroll={updatePageNumberOnScroll}
                    pdfUrl={pdfUrl}
                    onDocumentLoadSuccess={onDocumentLoadSuccess}
                    scale={scale}
                    numberPages={numberPages}
                    constructPagesRefs={constructPagesRefs}
                />
            ) : (
                <PDFOnePage
                    canvasWidth={canvasWidth}
                    canvasHeight={canvasHeight}
                    pdfUrl={pdfUrl}
                    onDocumentLoadSuccess={onDocumentLoadSuccess}
                    scale={scale}
                    pageNumber={pageNumber}
                    canvasPDF={canvasPDF}
                    onRenderSuccess={onRenderSuccess}
                    onPageLoadSuccess={onPageLoadSuccess}
                    children={children}
                />
            )}
        </Container>
    );
};

export default PDFViewer;
