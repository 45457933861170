import { Form } from 'antd';
import styled from 'styled-components';
import { Fonts } from '../constants';

export const FormItem = styled(Form.Item)`
    label {
        text-transform: capitalize;
        font-family: ${Fonts.ARIAL_REGULAR}};
    }
`;

export const FormItemBold = styled(Form.Item)`
    label {
        text-transform: capitalize;
        font-family: ${Fonts.ARIAL_BOLD};
    }
`;
