import { WorkflowInputSourceType, WorkflowTriggerType } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { FormInstance } from 'antd/lib/form/Form';
import set from 'date-fns/set';
import { useEffect } from 'react';
import { UseQueryResult } from 'react-query';
import { WorkflowPDFInputSourceActions } from '../../components/workflow/PDFInputSourceConfiguration/IdentifiersManager/workflowPDFInputSourceReducer';
import { TasksActions } from '../../components/workflow/TaskManager/taskReducer';
import WorkflowDetailEdit from '../../components/workflow/WorkflowDetailEdit';
import ResourceDetailLayout from '../../layouts/ResourceDetailLayout';
import { WatermarkUI } from '../../types';

interface WorkflowDetailFetchingProps {
    isReadOnly: boolean;
    companyId: string;
    departmentDetails: API.Department;
    departmentDetailsLoading: boolean;
    query: UseQueryResult<API.WorkflowConfiguration, Error>;
    form: FormInstance;
    tasks: API.TaskConfiguration[];
    tasksDispatch: React.Dispatch<TasksActions>;
    workflowPDFInputSource: API.WorkflowPDFInputSource;
    workflowPDFInputSourceDispatch: React.Dispatch<WorkflowPDFInputSourceActions>;
    setWatermark: React.Dispatch<React.SetStateAction<WatermarkUI>>;
    watermark: WatermarkUI;
    actions?: React.ReactNode;
    errors?: string[];
    onBack: () => void;
    sampleFile: API.ConfigurationFile;
    setSampleFile: React.Dispatch<React.SetStateAction<API.ConfigurationFile>>;
}

const WorkflowDetailFetching: React.FC<WorkflowDetailFetchingProps> = ({
    isReadOnly,
    companyId,
    departmentDetails,
    departmentDetailsLoading,
    query,
    form,
    tasks,
    tasksDispatch,
    workflowPDFInputSource,
    workflowPDFInputSourceDispatch,
    setWatermark,
    watermark,
    actions,
    errors,
    onBack,
    sampleFile,
    setSampleFile,
}) => {
    useEffect(() => {
        if (query.data) {
            const workflow = query.data;
            if (workflow.trigger.type === WorkflowTriggerType.SCHEDULED) {
                const { tasks: _tasks, trigger, ...fields } = workflow;
                const { hours, minutes } = trigger.scheduleTimeUTC;
                form.setFieldsValue({ ...fields, scheduleTime: set(new Date(), { hours, minutes }) });
            } else {
                const { tasks: _tasks, ...fields } = workflow;
                form.setFieldsValue(fields);
            }
            tasksDispatch({ type: 'empty' });
            tasksDispatch({ type: 'insertList', payload: workflow.tasks });

            setSampleFile(workflow.inputSource.sampleFile);

            if (workflow.watermark?.image.url) {
                const imageElement = new Image();
                imageElement.src = workflow.watermark.image.url;
                imageElement.onload = function () {
                    setWatermark({
                        ...workflow.watermark,
                        imageElement,
                    });
                };
            }

            switch (workflow.inputSource.type) {
                case WorkflowInputSourceType.PDF:
                    workflowPDFInputSourceDispatch({ type: 'setFullState', payload: { state: workflow.inputSource } });
                    break;
                default:
                    break;
            }
        }
    }, [query.data]);

    const detail = (
        <WorkflowDetailEdit
            departmentDetails={departmentDetails}
            isLoading={query.isLoading && departmentDetailsLoading}
            isReadOnly={isReadOnly}
            companyId={companyId}
            workflow={query.data}
            form={form}
            tasks={tasks}
            tasksDispatch={tasksDispatch}
            workflowPDFInputSource={workflowPDFInputSource}
            workflowPDFInputSourceDispatch={workflowPDFInputSourceDispatch}
            setWatermark={setWatermark}
            watermark={watermark}
            sampleFile={sampleFile}
            setSampleFile={setSampleFile}
        />
    );

    return (
        <ResourceDetailLayout
            isLoading={query.isLoading}
            isError={query.isError}
            title={query.data?.name}
            errors={errors}
            detail={detail}
            actions={actions}
            onBack={onBack}
        />
    );
};

export default WorkflowDetailFetching;
