import { MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ParcelCustomsShipmentType } from '@eservices/shared/constant';
import { Alert, Button, Divider, Form, Popover, Space } from 'antd';
import ReactMarkdown from 'react-markdown';
import { Fonts } from '../../constants';
import { useBpostCountries } from '../../hooks/bpostCountriesHook';
import { useTranslation } from '../../hooks/translationHook';
import styled from 'styled-components';
import { FormItem } from '../FormItem';
import { Header3 } from '../Headers';
import { Input, InputNumber, Select } from '../Input';

const PopOverDescription = styled.div`
    width: 300px;
`;

const PopOverTitle = styled.div`
    font-family: ${Fonts.ARIAL_BOLD};
    margin: 2% 0;
    font-size: 14px;
`;

const AlertStyled = styled(Alert)`
    margin-bottom: 4%;
`;

const StyledInput = styled(Input)`
    min-width: 220px !important;
`;

const StyledSelect = styled(Select)`
    min-width: 220px !important;
`;

const Numbers = styled.div`
    display: flex;
    gap: 5%;
`;

const StyledInputNumber = styled(InputNumber)`
    width: 110px !important;
`;

const NumberOfItems = styled(InputNumber)`
    width: 80px !important;
`;

const Number = styled.div`
    width: 160px;
`;

const Fields = styled.div`
    display: flex;
    justify-content: space-between;
`;

const DeleteAction = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0 0 2% 0;
`;

interface EditRecipientCustomsPartProps {}

const EditRecipientCustomsPart: React.FC<EditRecipientCustomsPartProps> = () => {
    const { t, tCommon, tValidation } = useTranslation('create_parcels');
    const { isLoadingCountries, activeCountries } = useBpostCountries();

    const shipmentOptions = [
        {
            label: <>{t('shipment_type_documents')}</>,
            value: ParcelCustomsShipmentType.DOCUMENTS,
        },
        {
            label: <>{t('shipment_type_gift')}</>,
            value: ParcelCustomsShipmentType.GIFT,
        },
        {
            label: <>{t('shipment_type_goods')}</>,
            value: ParcelCustomsShipmentType.GOODS,
        },
        {
            label: <>{t('shipment_type_sample')}</>,
            value: ParcelCustomsShipmentType.SAMPLE,
        },
        {
            label: <>{t('shipment_type_other')}</>,
            value: ParcelCustomsShipmentType.OTHER,
        },
    ];

    const allCountryOptions = activeCountries.map((country) => ({
        label: country.name,
        value: country.code,
    }));

    return (
        <>
            <AlertStyled message={t('customs_message')} type="info" />
            <Header3>{t('customs_declaration_form')}</Header3>
            <Fields>
                <FormItem
                    label={t('content_description')}
                    name={['customsDetails', 'contentDescription']}
                    rules={[
                        { required: true, message: tValidation('required.item_description') },
                        { min: 3, message: tValidation('custom.min_3') },
                        { max: 50, message: tValidation('custom.max_50') },
                    ]}
                >
                    <StyledInput type="text" aria-label="content Description input" />
                </FormItem>
                <FormItem
                    label={t('shipment_type')}
                    name={['customsDetails', 'shipmentType']}
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.shipment_type'),
                        },
                    ]}
                >
                    <StyledSelect options={shipmentOptions} />
                </FormItem>
            </Fields>
            <Header3>{tCommon('items')}</Header3>
            <Form.List name={['customsDetails', 'contents']}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <div key={key}>
                                <Form.Item
                                    {...restField}
                                    label={t('item_description')}
                                    name={[name, 'itemDescription']}
                                    rules={[
                                        { required: true, message: tValidation('required.item_description') },
                                        { max: 30, message: tValidation('custom.max_30') },
                                    ]}
                                >
                                    <StyledInput type="text" aria-label="item description" />
                                </Form.Item>
                                <Numbers>
                                    <Number>
                                        <Form.Item
                                            {...restField}
                                            label={t('number_of_items')}
                                            name={[name, 'numberOfItems']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: tValidation('required.number_of_items'),
                                                },
                                            ]}
                                        >
                                            <NumberOfItems min={1} max={999999} aria-label="number of items" />
                                        </Form.Item>
                                    </Number>
                                    <Number>
                                        <Form.Item
                                            {...restField}
                                            label={t('netto_weight')}
                                            name={[name, 'nettoWeightInGrams']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: tValidation('required.netto_weight'),
                                                },
                                            ]}
                                        >
                                            <StyledInputNumber
                                                min={1}
                                                max={30000}
                                                aria-label="netto weight"
                                                addonAfter="gr"
                                                controls={false}
                                            />
                                        </Form.Item>
                                    </Number>
                                    <Number>
                                        <Form.Item
                                            {...restField}
                                            label={t('value_of_item')}
                                            name={[name, 'valueOfItemInEuro']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: tValidation('required.value_of_item'),
                                                },
                                            ]}
                                        >
                                            <StyledInputNumber
                                                min={1}
                                                aria-label="value of item"
                                                addonBefore="€"
                                                controls={false}
                                            />
                                        </Form.Item>
                                    </Number>
                                </Numbers>
                                <Fields>
                                    <Form.Item
                                        {...restField}
                                        label={t('origin_of_goods')}
                                        name={[name, 'originOfGoods']}
                                        rules={[
                                            {
                                                required: true,
                                                message: tValidation('required.origin_of_goods'),
                                            },
                                        ]}
                                    >
                                        <StyledSelect
                                            options={allCountryOptions}
                                            aria-label="origin of goods"
                                            filterOption
                                            showSearch
                                            loading={isLoadingCountries}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        label={
                                            <Space>
                                                {t('hs_tariff_code')}
                                                <Popover
                                                    content={
                                                        <PopOverDescription>
                                                            <ReactMarkdown>
                                                                {t('hs_tariff_code_description')}
                                                            </ReactMarkdown>
                                                        </PopOverDescription>
                                                    }
                                                    title={
                                                        <PopOverTitle>
                                                            {t('hs_tariff_code_title_description')}
                                                        </PopOverTitle>
                                                    }
                                                >
                                                    <QuestionCircleOutlined />
                                                </Popover>
                                            </Space>
                                        }
                                        name={[name, 'hsTariffCode']}
                                        rules={[
                                            {
                                                pattern: new RegExp('^[0-9]{6,9}$'),
                                                message: tValidation('custom.digits_6_to_9'),
                                            },
                                        ]}
                                    >
                                        <StyledInput type="text" aria-label="hs tariff code" />
                                    </Form.Item>
                                </Fields>
                                <DeleteAction>
                                    {name > 0 && <MinusCircleOutlined onClick={() => remove(name)} />}
                                </DeleteAction>
                                <Divider />
                            </div>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                {t('add_item')}
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </>
    );
};

export default EditRecipientCustomsPart;
