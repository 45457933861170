import { UserOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { Avatar, Button, Divider, List, Skeleton, Space } from 'antd';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { useSearchSendingEvents } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { Header2, Header3 } from '../Headers';
import { Modal } from '../Modal';
import { Paragraph } from '../Paragraph';
import SendingEventDetail from './SendingEventDetail';
import SendingEventPDFPreview from './SendingEventPreview';
const PAGE_SIZE = 20;

const ScrollableContainer = styled.div`
    height: 26vh;
    width: 100%;
    min-width: 480px;
    overflow: auto;
    margin: 5% 0 0 0;
`;

const Container = styled.div`
    display: flex;
    margin: 0%;
    justify-content: space-between;
    gap: 3%;
`;

const scrollableContainer = 'scrollableContainer';

interface ApprovalModalProps {
    companyId: string;
    job: API.JobListItem;
    onApproveJob: (jobId: string, departmentId: string) => void;
    onRejectJob: (jobId: string, departmentId: string) => void;
    isReadOnly: boolean;
}

const ApprovalModal: React.FC<ApprovalModalProps> = ({ companyId, job, onApproveJob, onRejectJob, isReadOnly }) => {
    const { t, tCommon } = useTranslation('job');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { mutateAsync: searchSendingEventsMutation, isLoading } = useSearchSendingEvents(companyId);
    const [currentPage, setCurrentPage] = useState(1);
    const [sendingEvents, setSendingEvents] = useState<API.SendingEventListItem[]>([]);
    const [totalItems, setTotalItems] = useState<number>();
    const [selectedSendingEventId, setSelectedSendingEventId] = useState<string>();

    const showModal = async () => {
        await search();
        setIsModalVisible(true);
    };

    const calculateUntil = () => format(addDays(new Date(job.startDate), 1), "yyyy-MM-dd'T23:59:59.999Z'");

    const nextScrollSearch = async () => {
        const nextPage = currentPage + 1;
        setCurrentPage(nextPage);
        await search();
    };

    const search = async () => {
        await searchSendingEventsMutation(
            {
                jobId: job.id,
                pagination: { page: currentPage, pageSize: PAGE_SIZE },
                range: {
                    from: job.startDate,
                    until: calculateUntil(),
                },
            },
            {
                onSuccess: (data) => {
                    setSendingEvents([...sendingEvents, ...data.items]);
                    if (!selectedSendingEventId) setSelectedSendingEventId(data.items[0].id);
                    setTotalItems(data.pagination.totalItems);
                },
            },
        );
    };

    const handleApprove = () => {
        onApproveJob(job.id, job.departmentId);
        setIsModalVisible(false);
        setSendingEvents([]);
    };

    const handleReject = () => {
        onRejectJob(job.id, job.departmentId);
        setIsModalVisible(false);
        setSendingEvents([]);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSendingEvents([]);
    };

    const Footer = (
        <Space size="large">
            <SecondaryButton shape="default" disabled={isReadOnly} onClick={handleCancel}>
                {tCommon('cancel')}
            </SecondaryButton>
            <Space>
                <PrimaryButton shape="default" disabled={isReadOnly} onClick={handleReject} danger>
                    {tCommon('reject')}
                </PrimaryButton>
                <PrimaryButton shape="default" disabled={isReadOnly} onClick={handleApprove}>
                    {tCommon('approve')}
                </PrimaryButton>
            </Space>
        </Space>
    );

    return (
        <>
            <PrimaryButton shape="default" disabled={isReadOnly} onClick={() => showModal()}>
                {t('approve_or_reject')}
            </PrimaryButton>
            <Modal
                centered
                width="1350px"
                title={t('sendings_overview_for_approval_modal_title')}
                visible={isModalVisible}
                onOk={handleApprove}
                onCancel={handleCancel}
                footer={Footer}
            >
                <Container>
                    <div>
                        <Space direction="vertical">
                            <div>
                                <Header3>{t('sendings_overview_for_approval_title')}</Header3>
                                <Paragraph>{t('sendings_overview_for_approval_description')}</Paragraph>
                                <ScrollableContainer id={scrollableContainer}>
                                    <InfiniteScroll
                                        dataLength={sendingEvents.length}
                                        next={nextScrollSearch}
                                        hasMore={sendingEvents.length < totalItems}
                                        loader={<Skeleton avatar paragraph={{ rows: 1 }} active={isLoading} />}
                                        scrollableTarget={scrollableContainer}
                                    >
                                        <List
                                            loading={isLoading}
                                            size="small"
                                            itemLayout="horizontal"
                                            dataSource={sendingEvents}
                                            renderItem={(sendingEvent) => {
                                                const { id, recipientInfo } = sendingEvent;
                                                return (
                                                    <List.Item
                                                        key={id}
                                                        actions={[
                                                            <Button
                                                                type="link"
                                                                onClick={() => setSelectedSendingEventId(id)}
                                                            >
                                                                {tCommon('review')}
                                                            </Button>,
                                                        ]}
                                                    >
                                                        <List.Item.Meta
                                                            avatar={<Avatar size="large" icon={<UserOutlined />} />}
                                                            title={recipientInfo[0].value}
                                                        />
                                                    </List.Item>
                                                );
                                            }}
                                        />
                                    </InfiniteScroll>
                                </ScrollableContainer>
                            </div>
                            <Divider />
                            <div>
                                <Header2>Details</Header2>
                                {selectedSendingEventId && (
                                    <SendingEventDetail companyId={companyId} job={job} seId={selectedSendingEventId} />
                                )}
                            </div>
                        </Space>
                    </div>
                    {selectedSendingEventId && (
                        <SendingEventPDFPreview companyId={companyId} job={job} seId={selectedSendingEventId} />
                    )}
                </Container>
            </Modal>
        </>
    );
};

export default ApprovalModal;
