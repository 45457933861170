import { UsergroupAddOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { useTranslation } from '../../hooks/translationHook';
import { SecondaryButton } from '../Buttons';
import { FormItem } from '../FormItem';
import { Header3 } from '../Headers';
import Upload from '../Upload';
import { InputType } from './Recipients';

const Action = styled.div`
    text-align: center;
    margin: 0 0 5% 0;
`;

const UploadBox = styled.div`
    width: 100px;
    margin: 0 auto;
`;

const Info = styled.p`
    max-width: 400px;
    margin-bottom: 6%;
`;

interface InputChoiceProps {
    onChangeInput: (input: InputType) => void;
    companyId: string;
    setUploadedFileKey: (uploadedFileKey: string) => void;
    uploadedFileKey: string;
}

const InputChoice: React.FC<InputChoiceProps> = ({ onChangeInput, companyId, setUploadedFileKey, uploadedFileKey }) => {
    const { t, tCommon } = useTranslation('create_parcels');

    return (
        <div>
            <Header3>{tCommon('manual')}</Header3>
            <Info>
                <ReactMarkdown>{t('input_manual_description')}</ReactMarkdown>
            </Info>
            <Action>
                <SecondaryButton
                    disabled={uploadedFileKey !== undefined}
                    icon={<UsergroupAddOutlined />}
                    onClick={() => onChangeInput(InputType.MANUAL)}
                >
                    {t('add_recipients')}
                </SecondaryButton>
            </Action>
            <Divider>{tCommon('or')}</Divider>
            <Header3>{tCommon('file')}</Header3>
            <Info>
                <ReactMarkdown>{t('input_file_description')}</ReactMarkdown>
            </Info>
            <UploadBox>
                <FormItem>
                    <Upload
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        disabled={false}
                        isImageUpload={false}
                        companyId={companyId}
                        hasFile={false}
                        onFilenameChange={(fileName: string) => {
                            setUploadedFileKey(fileName);
                        }}
                    />
                </FormItem>
            </UploadBox>
        </div>
    );
};

export default InputChoice;
