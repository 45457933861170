import { Colors } from '@eservices/ui-constants/colors';
import { Space, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Fonts } from '../../../constants';
import { useTranslation } from '../../../hooks/translationHook';

const InfoWrapper = styled.div`
    text-align: center;
    cursor: pointer;
    border-radius: 15px;
    padding: 30px 0;
    height: 255px;
    border: 1px solid ${Colors.GRAY_QUATERNARY};
    background-color: ${Colors.GRAY_TERTIARY};
    transition: 0.3s ease-in-out;
    &:hover {
        border: 1px solid ${Colors.PRIMARY};
        background-color: ${Colors.GRAY_QUATERNARY};
    }
`;

const Title = styled(Space)<TitleProps>`
    font-size: 16px;
    margin: 0 0 10px 0;
    font-family: ${Fonts.HURME4};
    color: ${Colors.GRAY_PRIMARY};
    text-transform: capitalize;
    width: 100%;
    display: flex;
    justify-content: center;
`;
interface TitleProps {
    color: string;
}

const IconWrapper = styled.div`
    margin-right: 5px;
`;

const Amount = styled.div<AmountProps>`
    font-size: 45px;
    font-family: ${Fonts.HURME4};
    font-weight: 600;
    color: ${({ color }) => color};
`;

const StyledSpin = styled(Spin)<AmountProps>`
    width: 100%;
    margin-top: 35px;
    .ant-spin-dot-item {
        background-color: ${({ color }) => color};
    }
`;

interface AmountProps {
    color: string;
}

interface StatusTileProps {
    visual: string;
    amount: number;
    icon: React.ReactNode;
    color: string;
    pathname: string;
    search: string;
    status: string;
    isDetailsLoading: boolean;
}

const StatusTileProps: React.FC<StatusTileProps> = ({
    visual,
    amount,
    icon,
    color,
    pathname,
    search,
    status,
    isDetailsLoading,
}) => {
    const { t } = useTranslation('dashboard');
    const history = useHistory();

    const redirectToFullDetails = () => {
        history.push({
            pathname,
            search,
        });
    };

    return (
        <InfoWrapper onClick={() => redirectToFullDetails()}>
            <Title color={color}>
                <IconWrapper color={color}>{icon}</IconWrapper>
                {t(status.toLocaleLowerCase())}
            </Title>
            <img src={visual} alt={t(status.toLocaleLowerCase())} />
            {isDetailsLoading ? <StyledSpin color={color} /> : <Amount color={color}>{amount}</Amount>}
        </InfoWrapper>
    );
};

export default StatusTileProps;
