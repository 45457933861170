import { ShoppingCartOutlined } from '@ant-design/icons';
import { ApplicationModule, UserRole } from '@eservices/shared/constant';
import { Routes } from '../../constants';
import { BasicRoute, MainRoute } from '../../types';
import OrdersOverviewView from './OrdersOverviewView';

const viewOrdersOverviewPage = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.ORDERS,
    component: <OrdersOverviewView companyId={companyId} />,
});

export const createOrdersRouter = (userRole: UserRole, companyId: string): MainRoute => {
    const ordersRoutes = {
        [UserRole.SUPER_USER]: [viewOrdersOverviewPage(companyId)],
        [UserRole.CLIENT_ADMIN]: [viewOrdersOverviewPage(companyId)],
        [UserRole.CLIENT_DEPARTMENT_ADMIN]: [viewOrdersOverviewPage(companyId)],
        [UserRole.CLIENT_OPERATOR]: [viewOrdersOverviewPage(companyId)],
    };

    return {
        path: Routes.ORDERS,
        title: 'orders',
        icon: <ShoppingCartOutlined />,
        subRoutes: ordersRoutes[userRole],
        module: ApplicationModule.EASYPOST_PORTAL,
    };
};
