import { API } from '@eservices/shared/type';
import { List, Skeleton } from 'antd';
import { useListOrders } from '../../hooks/apiHooks';
import OrderDetails from './OrderDetails';

interface OrderHistoryPanelProps {
    companyId: string;
    departmentId: string;
}

const OrderHistoryPanel: React.FC<OrderHistoryPanelProps> = ({ companyId, departmentId }) => {
    const { isSuccess, data: orders } = useListOrders(companyId, departmentId);

    if (isSuccess) {
        return (
            <List>
                {orders.map((order: API.Order) => {
                    return <OrderDetails key={order.id} order={order} />;
                })}
            </List>
        );
    }
    return <Skeleton />;
};

export default OrderHistoryPanel;
