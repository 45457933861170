import { JobStatus } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Routes } from '../../../constants';
import { useTranslation } from '../../../hooks/translationHook';
import Layout from './Layout';
import { JobActionRequired, JobErrorTile, JobProcessingTile, JobSuccessTile } from './statuses';
import StatusTile from './StatusTile';
import QueryString from 'query-string';

const statuses = [JobSuccessTile, JobProcessingTile, JobActionRequired, JobErrorTile];

interface JobInfoProps {
    statusCounters: API.DashboardOperatorJobsDetails;
    isDetailsLoading: boolean;
    rangeDate: string;
    departmentId: string;
}

const defaultStatusCounter: API.DashboardOperatorJobsDetails = {
    OK: 0,
    PROCESSING: 0,
    ERROR: 0,
    ACTION_REQUIRED: 0,
    WARNING: 0,
};

const JobInfo: React.FC<JobInfoProps> = ({
    statusCounters = defaultStatusCounter,
    isDetailsLoading,
    rangeDate,
    departmentId,
}) => {
    const { t } = useTranslation('dashboard');

    const baseRoute = Routes.JOBS;
    const totalAmount =
        statusCounters.OK + statusCounters.PROCESSING + statusCounters.ACTION_REQUIRED + statusCounters.ERROR;

    const amountPerStatus = {
        [JobStatus.OK]: statusCounters.OK,
        [JobStatus.PROCESSING]: statusCounters.PROCESSING,
        [JobStatus.ACTION_REQUIRED]: statusCounters.ACTION_REQUIRED,
        [JobStatus.ERROR]: statusCounters.ERROR,
    };

    return (
        <Layout
            title={t('title_jobs')}
            amount={totalAmount}
            baseRoute={baseRoute}
            rangeDate={rangeDate}
            departmentId={departmentId}
            isDetailsLoading={isDetailsLoading}
            totalWarning={statusCounters.WARNING}
        >
            {statuses.map(({ status, ...props }, i) => (
                <StatusTile
                    isDetailsLoading={isDetailsLoading}
                    amount={amountPerStatus[status]}
                    pathname={baseRoute}
                    search={QueryString.stringify({
                        status,
                        rangeDate,
                        departmentId,
                    })}
                    status={statuses[i].status}
                    {...props}
                    key={i}
                />
            ))}
        </Layout>
    );
};

export default JobInfo;
