import ThunderboltOutlined from '@ant-design/icons/lib/icons/ThunderboltOutlined';
import YuqueOutlined from '@ant-design/icons/lib/icons/YuqueOutlined';
import { WorkflowType } from '@eservices/shared/constant';
import { FormInstance } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../../hooks/translationHook';
import { FormItem } from '../../FormItem';
import { Header2 } from '../../Headers';
import { Paragraph } from '../../Paragraph';
import WizardChoiceWithIcon from '../../WizardChoiceWithIcon';

const Choices = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 30px;
    margin: 5% 0 0 0;
`;

interface WorkflowTypeQuestionProps {
    form: FormInstance;
}

const WorkflowTypeQuestion: React.FC<WorkflowTypeQuestionProps> = ({ form }) => {
    const { t, tValidation } = useTranslation('workflow');
    const [workflowType, setWorkflowType] = useState(form.getFieldValue('type'));

    const triggerTypes = [
        {
            type: WorkflowType.BASIC,
            title: t('basic_title'),
            description: t('basic_description'),
            cover: <YuqueOutlined />,
            enabled: true,
        },
        {
            type: WorkflowType.ADVANCED,
            title: t('advanced_title'),
            description: t('advanced_description'),
            cover: <ThunderboltOutlined />,
            enabled: false,
        },
    ];

    return (
        <>
            <Header2>{t('workflow_type_title')}</Header2>
            <Paragraph>{t('workflow_type_description')}</Paragraph>
            <FormItem
                name="type"
                rules={[
                    {
                        required: true,
                        message: tValidation('required.type'),
                    },
                ]}
                hidden
            />
            <Choices>
                {triggerTypes.map(({ type, enabled, cover, title, description }) => (
                    <div key={type}>
                        <WizardChoiceWithIcon
                            onClick={() => {
                                setWorkflowType(type);
                                form.setFieldsValue({
                                    type,
                                });
                            }}
                            enabled={enabled}
                            selected={workflowType === type}
                            icon={cover}
                            title={title}
                            description={description}
                        />
                    </div>
                ))}
            </Choices>
        </>
    );
};

export default WorkflowTypeQuestion;
