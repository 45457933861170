import { BorderOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { Col, Row } from 'antd';
import { useTranslation } from '../../hooks/translationHook';
import DescriptionItem from '../DescriptionItem';
import { Header3 } from '../Headers';

interface ParcelDetailSubDetailBpostOptionsProps {
    parcel: API.Parcel;
}

const ParcelDetailSubDetailBpostOptions: React.FC<ParcelDetailSubDetailBpostOptionsProps> = ({ parcel }) => {
    const { t, tCommon } = useTranslation('parcel_detail');

    const { bpostOptions } = parcel;

    return (
        <div>
            <Header3>{tCommon('options')}</Header3>
            <Row>
                <Col span={12}>
                    <DescriptionItem
                        title={t('insurance')}
                        content={bpostOptions.hasInsurance ? <CheckSquareOutlined /> : <BorderOutlined />}
                    />
                </Col>
                <Col span={12}>
                    <DescriptionItem
                        title={t('signature')}
                        content={bpostOptions.hasSignature ? <CheckSquareOutlined /> : <BorderOutlined />}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem
                        title={t('needs_mail_announcement')}
                        content={bpostOptions.needsMailAnnouncement ? <CheckSquareOutlined /> : <BorderOutlined />}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default ParcelDetailSubDetailBpostOptions;
