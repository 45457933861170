import { API } from '@eservices/shared/type';
import { Form, FormInstance } from 'antd';
import { useEffect } from 'react';
import { useGetDepartment } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import styled from 'styled-components';
import DepartmentSelector from '../DepartmentSelector';
import { FormItem } from '../FormItem';
import { Header2 } from '../Headers';
import { Input } from '../Input';
import Card from '../Card';

const StyledCard = styled(Card)`
    min-width: 400px;
`;

const AddressPart = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 0 3%;
`;

const StreetNumber = styled(FormItem)`
    width: 20%;
`;

const Box = styled(FormItem)`
    width: 20%;
`;

const Street = styled(FormItem)`
    width: 60%;
`;

const City = styled(FormItem)`
    width: 70%;
`;

const PostalCode = styled(FormItem)`
    width: 30%;
`;

interface SenderProps {
    company: API.Company;
    form: FormInstance<API.ParcelSender>;
    departmentId: string;
    onChangeDepartment: (departmentId: string) => void;
    senderAddressDirtyFlag: boolean;
}

const Sender: React.FC<SenderProps> = ({ company, form, departmentId, onChangeDepartment, senderAddressDirtyFlag }) => {
    const { tCommon, tValidation } = useTranslation('create_parcels');
    const { data: department } = useGetDepartment(company.id, departmentId, {
        enabled: Boolean(departmentId),
        staleTime: 60000,
    });

    useEffect(() => {
        if (!senderAddressDirtyFlag) {
            form.setFieldsValue({
                address: department?.headAddress,
                phoneNumber: department?.phoneNumber,
                emailAddress: department?.emailAddress,
            });
        }
    }, [department]);

    return (
        <StyledCard>
            <Header2>{tCommon('sender')}</Header2>
            <Form
                form={form}
                layout="vertical"
                name="sender"
                requiredMark={false}
                initialValues={{ departmentId: departmentId, companyName: company.name }}
            >
                <FormItem name="companyName" hidden />
                <FormItem name="phoneNumber" hidden />
                <FormItem name="emailAddress" hidden />
                <FormItem
                    label={tCommon('department')}
                    name="departmentId"
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.department_name'),
                        },
                    ]}
                >
                    <DepartmentSelector companyId={company.id} onChange={onChangeDepartment} />
                </FormItem>

                <FormItem
                    label={tCommon('name')}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.name'),
                        },
                        { min: 3, message: tValidation('custom.min_3') },
                    ]}
                >
                    <Input type="text" aria-label="name input" minLength={1} maxLength={40} />
                </FormItem>

                <AddressPart>
                    <Street
                        label={tCommon('street')}
                        name={['address', 'street']}
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.street'),
                            },
                        ]}
                    >
                        <Input type="text" aria-label="street input" minLength={1} maxLength={40} />
                    </Street>
                    <StreetNumber
                        label={tCommon('number_short')}
                        name={['address', 'streetNr']}
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.street_number'),
                            },
                        ]}
                    >
                        <Input type="text" aria-label="number input" minLength={1} maxLength={8} />
                    </StreetNumber>
                    <Box label={tCommon('box')} name={['address', 'box']}>
                        <Input type="text" aria-label="box input" minLength={1} maxLength={8} />
                    </Box>
                </AddressPart>
                <AddressPart>
                    <City
                        label={tCommon('city')}
                        name={['address', 'city']}
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.city'),
                            },
                        ]}
                    >
                        <Input type="text" aria-label="city input" minLength={1} maxLength={40} />
                    </City>
                    <PostalCode
                        label={tCommon('postal_code')}
                        name={['address', 'postalCode']}
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.postal_code'),
                            },
                        ]}
                    >
                        <Input type="text" aria-label="postal code input" minLength={1} maxLength={8} />
                    </PostalCode>
                </AddressPart>
                <FormItem
                    label={tCommon('country_code')}
                    name={['address', 'countryCode']}
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.country_code'),
                        },
                    ]}
                >
                    <Input type="text" aria-label="countryCode input" />
                </FormItem>
            </Form>
        </StyledCard>
    );
};

export default Sender;
