import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { ChannelProperties, TaskType } from '@eservices/shared/constant';
import { PDFChannelIdentifier } from '@eservices/shared/type';
import { Colors } from '@eservices/ui-constants/colors';
import { Space } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../../../../hooks/translationHook';
import { Header4 } from '../../../../Headers';
import TaskTypePropertyButton from './TaskTypePropertyButton';

const Content = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
`;

const Identifiers = styled.div`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    flex-direction: column;
`;

interface TaskTypeEditorProps {
    isReadOnly: boolean;
    taskType: TaskType;
    channelIdentifiers: PDFChannelIdentifier[];
    onChannelPropertyClick: (type: ChannelProperties) => void;
    onClearClick: (type: ChannelProperties) => void;
}

const QerdsTaskTypeEditor: React.FC<TaskTypeEditorProps> = ({
    isReadOnly,
    taskType,
    onChannelPropertyClick,
    onClearClick,
    channelIdentifiers,
}) => {
    const { t, tCommon } = useTranslation('workflow');

    const emailIdentifier = channelIdentifiers.find(
        (identifier) => identifier.kind === ChannelProperties.EMAIL_ADDRESS,
    );

    const birthDateIdentifier = channelIdentifiers.find(
        (identifier) => identifier.kind === ChannelProperties.BIRTH_DATE,
    );

    const companyVatIdentifier = channelIdentifiers.find(
        (identifier) => identifier.kind === ChannelProperties.COMPANY_VAT,
    );

    const firstNameIdentifier = channelIdentifiers.find(
        (identifier) => identifier.kind === ChannelProperties.FIRST_NAME,
    );

    const lastNameIdentifier = channelIdentifiers.find((identifier) => identifier.kind === ChannelProperties.LAST_NAME);

    const hasValidQerdsTaskProperties =
        emailIdentifier && (companyVatIdentifier || (firstNameIdentifier && lastNameIdentifier));

    return (
        <div>
            <Space align="start">
                <Header4>{tCommon(taskType.toLowerCase())}</Header4>
                {hasValidQerdsTaskProperties ? (
                    <CheckCircleFilled />
                ) : (
                    <CloseCircleOutlined style={{ color: Colors.ALERT }} />
                )}
            </Space>
            <Identifiers>
                <Space align="baseline">
                    {t('qerds_email_part1')}
                    <Content>
                        <TaskTypePropertyButton
                            type={ChannelProperties.EMAIL_ADDRESS}
                            isReadOnly={isReadOnly}
                            identifier={emailIdentifier}
                            onChannelPropertyClick={onChannelPropertyClick}
                            onClearClick={onClearClick}
                        />
                    </Content>
                </Space>
                <Space align="baseline" wrap>
                    {t('qerds_company_vat_part1')}
                    <Content>
                        <TaskTypePropertyButton
                            type={ChannelProperties.COMPANY_VAT}
                            isReadOnly={isReadOnly}
                            identifier={companyVatIdentifier}
                            onChannelPropertyClick={onChannelPropertyClick}
                            onClearClick={onClearClick}
                        />
                    </Content>
                    {t('qerds_and_or')}
                    <Content>
                        <TaskTypePropertyButton
                            type={ChannelProperties.FIRST_NAME}
                            isReadOnly={isReadOnly}
                            identifier={firstNameIdentifier}
                            onChannelPropertyClick={onChannelPropertyClick}
                            onClearClick={onClearClick}
                        />
                    </Content>
                    <Content>
                        <TaskTypePropertyButton
                            type={ChannelProperties.LAST_NAME}
                            isReadOnly={isReadOnly}
                            identifier={lastNameIdentifier}
                            onChannelPropertyClick={onChannelPropertyClick}
                            onClearClick={onClearClick}
                        />
                    </Content>
                </Space>
                <Space align="baseline">
                    {t('qerds_birth_date_part1')}
                    <Content>
                        <TaskTypePropertyButton
                            type={ChannelProperties.BIRTH_DATE}
                            isReadOnly={isReadOnly}
                            identifier={birthDateIdentifier}
                            onChannelPropertyClick={onChannelPropertyClick}
                            onClearClick={onClearClick}
                        />
                    </Content>
                </Space>
            </Identifiers>
        </div>
    );
};

export default QerdsTaskTypeEditor;
