import styled from 'styled-components';
import { Fonts } from '../../constants';

export const ImageContainer = styled.div`
    text-align: center;
    padding-bottom: 10px;
`;

export const VignetteTypeContainer = styled.div`
    margin-left: 20px;
    min-width: 300px;
    border: 3px solid black;
    border-collapse: collapse;
`;

export const VignetteTypeCheckContainer = styled.div`
    padding: 5px;
    height: 100px;
    display: flex;
    align-items: center;
`;

export const CheckboxContainer = styled.div`
    width: 32px;
    height: 32px;
    margin-left: 10px;
`;

export const VignetteTypeText = styled.div`
    margin-left: 20px;
    font-size: 28px;
`;

export const DatePartContainer = styled.div`
    padding: 5px;
    margin-left: 10px;
`;

export const DateLineContainer = styled.div`
    display: flex;
`;

export const DateLabelContainer = styled.div`
    width: 60px;
`;

export const ColonContainer = styled.div`
    margin-top: 10px;
    margin-left: 5px;
`;

export const Label = styled.div`
    font-family: ${Fonts.HURME4_LIGHT};
`;

export const TitleLabel = styled.div`
    font-family: ${Fonts.HURME4};
`;

export const CheckedBox = (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
        <path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24zm-6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z" />
    </svg>
);

export const Box = (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
        <path d="M22 2v20h-20v-20h20zm2-2h-24v24h24v-24z" />
    </svg>
);
