import { BookOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../components/Buttons';
import { Routes } from '../../constants';
import { useCreateUser } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import { translateFieldErrors } from '../../utils/helpers';
import UserDetailCreateFetching from './UserDetailCreateFetching';

const loadingMessageKey = 'loadingMessageKey';

interface UserDetailCreateProps {
    companyId: string;
}

const UserDetailCreate: React.FC<UserDetailCreateProps> = ({ companyId }) => {
    const history = useHistory();
    const { t, tCommon, tError } = useTranslation('user');
    const [form] = useForm<API.CreateUser>();
    const createUserMutation = useCreateUser(companyId);

    const onClickSaveUser = async () => {
        const newUser = await form.validateFields();

        void message.loading({ key: loadingMessageKey, content: t('creating_new_user_loading') }, 0);

        createUserMutation.mutate(newUser, {
            onSuccess: async () => {
                history.push(Routes.USERS);
                await message.success(t('creating_new_user_success', { username: form.getFieldValue('username') }), 3);
            },
            onError: (err) => {
                if (err.response?.data.fieldErrors)
                    form.setFields(translateFieldErrors(tError, err.response.data.fieldErrors));
            },
            onSettled: () => {
                message.destroy(loadingMessageKey);
            },
        });
    };

    const actions = (
        <>
            <PrimaryButton
                icon={<BookOutlined />}
                aria-label="submit new user button"
                disabled={createUserMutation.isLoading}
                htmlType="submit"
                onClick={onClickSaveUser}
            >
                {tCommon('save')}
            </PrimaryButton>
        </>
    );

    return (
        <UserDetailCreateFetching companyId={companyId} form={form} actions={actions} onBack={() => history.goBack()} />
    );
};

export default UserDetailCreate;
