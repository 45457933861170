import React, { useEffect } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthenticatedApp from './AuthenticatedApp';
import { useAuth } from './context/auth-context';
import GlobalStyle from './GlobalStyles';
import UnauthenticatedApp from './UnauthenticatedApp';

const App = () => {
    const { onLoad, isAuthenticated } = useAuth();
    const asyncOnLoadUser = useAsyncCallback(onLoad);

    useEffect(() => {
        asyncOnLoadUser.execute();
    }, []);

    if (asyncOnLoadUser.loading) return <></>;

    return (
        <>
            <GlobalStyle />
            <Router>{isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}</Router>
        </>
    );
};
export default App;
