import { SendOutlined } from '@ant-design/icons';
import { Alert, Form, notification } from 'antd';
import { useEffect } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { PrimaryButton } from '../../components/Buttons';
import { Input } from '../../components/Input';
import { FormItemBold } from '../../components/FormItem';
import { Notification, notificationStyle } from '../../components/Notification';
import { useAuth } from '../../context/auth-context';
import { useTranslation } from '../../hooks/translationHook';
import { Routes } from '../../constants';
import Layout from '../../layouts/LoginLayout';

const SubmitContainer = styled.div`
    float: right;
`;

const ForgotPasswordForm = () => {
    const history = useHistory();
    const { forgotPassword } = useAuth();
    const [form] = Form.useForm();
    const { t, tCommon, tValidation, tError } = useTranslation('forgot_password');

    const { execute, status, loading, error } = useAsyncCallback(
        async ({ username }) => await forgotPassword(username),
    );

    useEffect(() => {
        if (status === 'success') {
            history.push(Routes.FORGOT_PASSWORD_SUBMIT_FORM);

            notification.success({
                message: <Notification aria-label="notification">{t('notification_message')}</Notification>,
                style: notificationStyle,
            });
        }
    }, [status]);

    return (
        <Layout title={t('title')} description={t('description')}>
            <Form form={form} layout="vertical" name="login" onFinish={execute} requiredMark={false}>
                {error && (
                    <FormItemBold>
                        <Alert
                            aria-label="error"
                            message={tError(error.name)}
                            description={error.message}
                            type="error"
                        />
                    </FormItemBold>
                )}
                <FormItemBold
                    label={tCommon('username')}
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.username'),
                        },
                    ]}
                >
                    <Input aria-label="username input" />
                </FormItemBold>
                <FormItemBold>
                    <SubmitContainer>
                        <PrimaryButton
                            aria-label="submit button"
                            icon={<SendOutlined />}
                            disabled={loading}
                            htmlType="submit"
                        >
                            {t('send_reset_instruction')}
                        </PrimaryButton>
                    </SubmitContainer>
                </FormItemBold>
            </Form>
        </Layout>
    );
};

export default ForgotPasswordForm;
