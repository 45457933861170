import { ShoppingCartOutlined } from '@ant-design/icons';
import { CountryCode, ProductsCatalog } from '@eservices/shared/constant';
import { Product } from '@eservices/shared/type';
import { Col, Form, Row, Space } from 'antd';
import styled from 'styled-components';
import { useTranslation } from '../../hooks/translationHook';
import { SecondaryButton } from '../Buttons';
import { InputNumber } from '../Input';
import ProductCard from './ProductCard';

export const InputQuantity = styled(InputNumber)`
    width: 69px;
    height: 32px;
`;

const CatalogueContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
`;

interface CatalogueProps {
    countryCode: CountryCode;
    addProductToCart: (productName: string) => void;
}

const Catalogue: React.FC<CatalogueProps> = ({ countryCode, addProductToCart }) => {
    const { t } = useTranslation('order');

    const productList = Object.values(ProductsCatalog)
        .filter((product) => product.countryCode === countryCode)
        .map((product: Product) => (
            <ProductCard
                product={product}
                actions={[
                    <Space size="large">
                        <Form.Item name={product.code} initialValue={1} noStyle>
                            <InputQuantity
                                key={`input_${product.code}`}
                                min={1}
                                max={product.maxAmount}
                                type="number"
                                step={1}
                                aria-label={`input quantity ${product.nameKey}`}
                            />
                        </Form.Item>

                        <SecondaryButton
                            key={`add_button_${product.code}`}
                            onClick={() => addProductToCart(product.code)}
                            aria-label={`add button ${product.nameKey}`}
                        >
                            {
                                <Space>
                                    <ShoppingCartOutlined /> {t('add_product')}
                                </Space>
                            }
                        </SecondaryButton>
                    </Space>,
                ]}
            />
        ));

    return <CatalogueContainer>{productList}</CatalogueContainer>;
};

export default Catalogue;
