export enum Colors {
    PRIMARY = '#FFCB04',
    SECONDARY = '#1890FF',
    TERTIARY = '#F6A800',
    QUATERNARY = '#fcca19',
    QUINARY = '#fee37d',
    QUINARY_TRANSPARENT = '#fee37d1f',
    SENARY = '#fcca19',
    ALERT = '#FF4D4F',
    VALIDATION = '#52D49E',
    VALIDATED = VALIDATION,
    GRAY_PRIMARY = '#404041',
    GRAY_SECONDARY = '#9a9a9a',
    GRAY_TERTIARY = '#f8f8f8',
    GRAY_QUATERNARY = '#EFEFEF',
    GRAY_QUINARY = '#FFFFFF',
    GRAY_SENARY = '#a7a7a7',
    GRAY_SEPTENARY = '#b6b6b6',
    GRAY_OCTENARY = '#f0f0f0',
    DARK_PRIMARY = '#2d2d2d',
    DARK_SECONDARY = '#404041',
    DARK_TERTIARY = '#3E3D40',
    HIGHLIGHT = '#fafafa',
    BLUE_HIGHLIGHT = '#2faade',
    WHITE = '#FFFFFF',
    CLIENT_DEPARTMENT_ADMIN = '#FF9877',
    CLIENT_OPERATOR = '#D3AA93',
    CLIENT_VIEWER = '#7357C2',
    SUPER_USER = '#FFCB04',
    PRIOR = '#ff4d4f',
    NON_PRIOR = '#c094db',
    REGISTERED = '#2d2d2d',
    GOOD = '#a6deb2',
    BAD = '#F09595',
    ERROR = '#FF858F',
    NEUTRAL = '#a7a7a7',
    WARNING = '#FFA875',
    ACTION_REQUIRED = '#FFB674',
    WAITING = '#a7a7a7',
    PROCESSING = '#85E1FF',
    NOT_STARTED = DARK_PRIMARY,
    PENDING = PROCESSING,
    OK = '#a6deb2',
    CANCELLED = '#a7a7a7',
}
