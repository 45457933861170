import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined';
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined';
import FilePdfOutlined from '@ant-design/icons/lib/icons/FilePdfOutlined';
import UploadOutlined from '@ant-design/icons/lib/icons/UploadOutlined';
import { PolicyService, PolicyStatement } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Form, Input, message, PageHeader, Skeleton, Table, Tree } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { DataNode } from 'antd/lib/tree';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { PrimaryButton } from '../../components/Buttons';
import DownloadLink from '../../components/DownloadLink';
import FailedToLoadData from '../../components/FailedToLoadData';
import PDFViewer from '../../components/PDFViewer';
import Upload from '../../components/Upload';
import { useGetPolicyVersionsByServiceAndStatement, useSubmitPolicy } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import { toReadableDate } from '../../utils/helpers';

const loadingMessageKey = 'loadingMessageKey';

const Container = styled.div`
    display: grid;
    grid-template-areas: 'a b c';
    gap: 25px;
    grid-template-columns: 1fr auto 1fr;
    padding: 0 2% 0 2%;
    box-sizing: content-box;
`;

const PDFWrapper = styled.div`
    grid-area: b;
`;

const Versions = styled.div`
    grid-area: c;
`;

const PreviousVersions = styled.div`
    margin-bottom: 20px;
    max-height: 35vh;
    overflow-y: auto;
`;

const StyledFormItem = styled(FormItem)`
    margin-top: 20px;
    margin-bottom: 0;
`;

const StyledTree = styled(Tree)`
    .ant-tree-node-selected {
        background-color: transparent !important;
        font-weight: 800;
    }
    .ant-tree-switcher:not(.ant-tree-switcher-noop) + .ant-tree-node-content-wrapper {
        cursor: default;
        &:hover {
            background-color: transparent !important;
        }
    }
`;

const StyledTable = styled(Table)`
    margin-bottom: 20px;
    .ant-table-cell {
        padding: 12px;
    }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    margin-top: 50px;
`;

const policiesTree = [
    {
        service: PolicyService.QERDS,
        statements: [
            PolicyStatement.TERMS_AND_CONDITIONS,
            PolicyStatement.PRACTICE_STATEMENT,
            PolicyStatement.PRIVACY_STATEMENT,
        ],
    },
];

interface Document {
    service: PolicyService;
    statement: PolicyStatement;
}

const defaultNode: DataNode = {
    key: `${policiesTree[0].service}_${policiesTree[0].statements[0]}`,
};

interface PoliciesFetchingProps {
    companyId: string;
    isEditable: boolean;
}

const PoliciesFetching: React.FC<PoliciesFetchingProps> = ({ companyId, isEditable }) => {
    const { t, tValidation, tCommon } = useTranslation('policies');
    const [form] = useForm();
    const { policyId }: { policyId: string } = useParams();
    const [documentUrl, setDocumentUrl] = useState<string>();
    const [filename, setFilename] = useState<string>();
    const [currentService, setCurrentService] = useState<PolicyService>(policiesTree[0].service);
    const [currentStatement, setCurrentStatement] = useState<PolicyStatement>(policiesTree[0].statements[0]);
    const [hasFile, setHasFile] = useState(false);
    const [policyVersions, setPolicyVersions] = useState<API.Policy[]>([]);
    const submitPolicyMutation = useSubmitPolicy(companyId);
    const retrievebyServiceAndStatement = useGetPolicyVersionsByServiceAndStatement(companyId);

    useEffect(() => {
        switchStatementContext(defaultNode);
    }, []);

    useEffect(() => {
        retrievebyServiceAndStatement.mutate(
            {
                service: currentService,
                statement: currentStatement,
            },
            {
                onSuccess: (policyVersions) => {
                    setPolicyVersions(policyVersions);
                },
            },
        );
    }, [currentService, currentStatement]);

    const mappings: { [key: string]: Document } = {};

    policiesTree.forEach((service) =>
        service.statements.forEach((statement) => {
            mappings[`${service.service}_${statement}`] = {
                service: service.service,
                statement,
            };
        }),
    );

    const treeDocuments: DataNode[] = policiesTree.map((service) => {
        const policiesOfService = service.statements.map((statement) => ({
            title: t(statement.toLocaleLowerCase()),
            key: `${service.service}_${statement}`,
            icon: <FilePdfOutlined />,
        }));

        return {
            title: t(service.service.toLocaleLowerCase()),
            key: service.service,
            children: policiesOfService,
            selectable: false,
        };
    });

    const columns: ColumnsType = [
        { title: 'version Name', dataIndex: 'version', key: 'versionName' },
        {
            title: 'release Date',
            dataIndex: 'lastModifiedAt',
            key: 'releaseDate',
            render: (date: string) => toReadableDate(date),
        },
        { title: 'publishedBy', dataIndex: 'lastModifiedBy', key: 'publishedBy' },
        {
            title: 'document Url',
            dataIndex: 'location',
            key: 'documentUrl',
            render: (location: string) => (
                <DownloadLink
                    companyId={companyId}
                    source={API.FileSource.POLICY}
                    location={location}
                    content={<DownloadOutlined />}
                />
            ),
        },
    ];

    const confirmUpload = () => {
        void message.loading({ key: loadingMessageKey, content: t('updating_policy_loadidng') }, 0);

        submitPolicyMutation.mutate(
            {
                service: currentService,
                statement: currentStatement,
                submitPolicy: {
                    filename,
                    version: form.getFieldValue('version'),
                },
            },
            {
                onSuccess: () => {
                    void message.success(t('updating_policy_success'), 3);
                    setHasFile(false);
                },
                onError: () => {
                    void message.error(t('updating_policy_error'), 3);
                },
                onSettled: () => {
                    message.destroy(loadingMessageKey);
                },
            },
        );
    };

    const switchStatementContext = (node: DataNode) => {
        const mapping = mappings[node.key];
        if (mapping) {
            const { service, statement } = mapping;
            setCurrentService(service);
            setCurrentStatement(statement);
            setDocumentUrl(`${service}_${statement}`);
        }
    };

    const extra = !isEditable && (
        <PrimaryButton href={`/assets/policies/${policyId}.pdf`} download icon={<DownloadOutlined />}>
            {t('download_document')}
        </PrimaryButton>
    );

    return (
        <>
            <PageHeader title={tCommon('policies')} extra={extra} />
            <Container>
                <StyledTree
                    defaultExpandAll
                    defaultSelectedKeys={[defaultNode.key]}
                    onSelect={(_key, selectedStatement) => switchStatementContext(selectedStatement.node)}
                    switcherIcon={<DownOutlined />}
                    showIcon
                    treeData={treeDocuments}
                />
                <PDFWrapper>
                    <PDFViewer scrollThroughDocument pdfUrl={`/assets/policies/${documentUrl}.pdf`} />
                </PDFWrapper>
                {isEditable && (
                    <Versions>
                        <h2>{t('versions')}</h2>
                        <PreviousVersions>
                            {retrievebyServiceAndStatement.isError && <FailedToLoadData />}
                            {retrievebyServiceAndStatement.isLoading && <Skeleton active />}
                            {retrievebyServiceAndStatement.isSuccess && (
                                <StyledTable
                                    pagination={false}
                                    showHeader={false}
                                    columns={columns}
                                    dataSource={policyVersions}
                                />
                            )}
                        </PreviousVersions>
                        <Upload
                            accept=".pdf"
                            disabled={false}
                            isImageUpload={false}
                            companyId={companyId}
                            hasFile={hasFile}
                            onOriginalFilenameChange={(fileName) => {
                                if (fileName) {
                                    setHasFile(true);
                                } else {
                                    setHasFile(false);
                                }
                            }}
                            onFilenameChange={(filename) => setFilename(filename)}
                        />
                        {hasFile && (
                            <Form form={form} name="version">
                                <StyledFormItem
                                    label={t('version')}
                                    name={'version'}
                                    rules={[
                                        {
                                            required: true,
                                            message: tValidation('required.version'),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t('version_placeholder')}
                                        type="text"
                                        aria-label="version"
                                        minLength={1}
                                        maxLength={40}
                                    />
                                </StyledFormItem>
                            </Form>
                        )}
                        <StyledPrimaryButton
                            disabled={!hasFile}
                            onClick={() => confirmUpload()}
                            icon={<UploadOutlined />}
                        >
                            {t('confirm_document_update')}
                        </StyledPrimaryButton>
                    </Versions>
                )}
            </Container>
        </>
    );
};

export default PoliciesFetching;
