import { Skeleton } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import FailedToLoadData from '../../components/FailedToLoadData';
import SubscriptionModal from '../../components/subscription/SubscriptionModal';
import UserDetail from '../../components/user/UserDetail';
import { SubscriptionContext } from '../../constants';
import { useGetDepartments, useGetMyUser } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import ResourceDetailLayout from '../../layouts/ResourceDetailLayout';

interface UserDetailCreateFetchingProps {
    companyId: string;
    form: FormInstance;
    actions: React.ReactNode;
    onBack: () => void;
}

const UserDetailCreateFetching: React.FC<UserDetailCreateFetchingProps> = ({ companyId, actions, form, onBack }) => {
    const { t } = useTranslation('user');

    const { data: author, isLoading: isAuthorLoading, isError: isAuthorError } = useGetMyUser();
    const {
        data: departments = [],
        isLoading: isDepartmentsLoading,
        isError: isDepartmentsError,
    } = useGetDepartments(companyId);

    if (isAuthorLoading || isDepartmentsLoading) return <Skeleton />;

    if (isAuthorError || isDepartmentsError) return <FailedToLoadData />;

    const detail = <UserDetail form={form} departments={departments} authorRole={author.role} />;

    return (
        <>
            <ResourceDetailLayout title={t('register_user_title')} detail={detail} actions={actions} onBack={onBack} />
            <SubscriptionModal companyId={companyId} context={SubscriptionContext.USER} />
        </>
    );
};

export default UserDetailCreateFetching;
