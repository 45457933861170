import styled from 'styled-components';
import { Header1 } from '../components/Headers';
import { Colors } from '@eservices/ui-constants/colors';

interface LoginProps {
    title: string;
    description?: string;
}

const Container = styled.div`
    height: 100%;
    width: 25%;
    min-width: 500px;
    float: right;
    -webkit-box-shadow: 5px 5px 15px 5px rgba(76, 76, 76, 0.11);
    box-shadow: 5px 5px 15px 5px rgba(76, 76, 76, 0.11);
`;

const Form = styled.div`
    margin: 43% 10% 0 10%;
    max-width: 500px;
`;

const Underline = styled.div`
    width: 25%;
    max-width: 80px;
    border-bottom: 3px solid ${Colors.TERTIARY};
    padding-top: 10px;
`;

const TitleContainer = styled.div`
    margin: 0 0 7% 0;
`;

const Description = styled.div`
    font-size: 14px;
    color: ${Colors.GRAY_SECONDARY};
    padding: 0 0 15px 0;
`;

const Layout: React.FC<LoginProps> = ({ title, description, children }) => {
    return (
        <Container>
            <Form>
                <TitleContainer>
                    <Header1 role="heading" aria-label={title}>
                        {title}
                        <Underline />
                    </Header1>
                </TitleContainer>
                <Description>{description}</Description>
                {children}
            </Form>
        </Container>
    );
};

export default Layout;
