import { SendOutlined } from '@ant-design/icons';
import { ApplicationModule } from '@eservices/shared/constant';
import { Colors } from '@eservices/ui-constants/colors';
import { RadioChangeEvent } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as EasyPostPlatform } from '../assets/images/EasyPostPlatform.svg';
import { localStorageModuleKey, Routes } from '../constants';
import { Radio } from './Radio';

const Button = styled(Radio.Button)`
    width: 22px;
    text-align: center;
`;

const Container = styled.div`
    background-color: ${Colors.WHITE};
    width: 52px;
    padding: 5px 4px;
    border-radius: 7px;

    .ant-radio-button-wrapper {
        padding: 0;
        border: 0;
        height: 26px;
        font-size: 14px;
        line-height: 27px;
    }

    .ant-radio-button-wrapper::before {
        width: 0;
    }
`;

interface ModuleSwitchProps {
    module: ApplicationModule;
}

const ModuleSwitch: React.FC<ModuleSwitchProps> = ({ module }) => {
    const history = useHistory();

    const changeModule = (e: RadioChangeEvent) => {
        const module = e.target.value;
        localStorage.setItem(localStorageModuleKey, module);
        history.push(Routes.ROOT);
    };

    return (
        <Container>
            <Radio.Group value={module} buttonStyle="solid" onChange={changeModule}>
                <Button value={ApplicationModule.EASYPOST_CONNECT}>
                    <SendOutlined />
                </Button>
                <Button value={ApplicationModule.EASYPOST_PORTAL}>
                    <EasyPostPlatform />
                </Button>
            </Radio.Group>
        </Container>
    );
};

export default ModuleSwitch;
