import { DropboxOutlined } from '@ant-design/icons';
import { ApplicationModule, UserRole } from '@eservices/shared/constant';
import { Routes } from '../../constants';
import { BasicRoute, MainRoute } from '../../types';
import ParcelCreate from './ParcelCreate';
import ParcelOverviewView from './ParcelsOverviewView';

const viewParcelOverviewPageThatCanCreateParcels = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.PARCELS,
    component: <ParcelOverviewView companyId={companyId} canCreateParcelLabel />,
});

const viewParcelOverviewPage = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.PARCELS,
    component: <ParcelOverviewView companyId={companyId} />,
});

const createParcelPage = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.CREATE_PARCEL,
    component: <ParcelCreate companyId={companyId} />,
});

export const createParcelRouter = (userRole: UserRole, companyId: string): MainRoute => {
    const parcelsRoutes = {
        [UserRole.SUPER_USER]: [viewParcelOverviewPageThatCanCreateParcels(companyId), createParcelPage(companyId)],
        [UserRole.CLIENT_ADMIN]: [viewParcelOverviewPageThatCanCreateParcels(companyId), createParcelPage(companyId)],
        [UserRole.CLIENT_DEPARTMENT_ADMIN]: [
            viewParcelOverviewPageThatCanCreateParcels(companyId),
            createParcelPage(companyId),
        ],
        [UserRole.CLIENT_OPERATOR]: [
            viewParcelOverviewPageThatCanCreateParcels(companyId),
            createParcelPage(companyId),
        ],
        [UserRole.CLIENT_VIEWER]: [viewParcelOverviewPage(companyId)],
    };

    return {
        path: Routes.PARCELS,
        title: 'parcels',
        icon: <DropboxOutlined />,
        subRoutes: parcelsRoutes[userRole],
        module: ApplicationModule.EASYPOST_PORTAL,
    };
};
