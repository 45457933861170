import { TaskType } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../../hooks/translationHook';
import Card from '../../Card';
import { Header2 } from '../../Headers';
import { Paragraph } from '../../Paragraph';
import TaskDetail from './TaskDetail';
import TaskIntro from './TaskIntro';
import TaskPicker from './TaskPicker';
import { TasksActions } from './taskReducer';
import TasksOverview from './TasksOverview';

const Container = styled.div`
    width: 100%;
    display: flex;
    gap: 13%;
    padding: 20px 0 0 0;
`;

const TaskOverviewContainer = styled.div`
    position: relative;
`;

const TaskDetailContainer = styled.div`
    max-width: 900px;
    width: 100%;
`;

interface TaskManagerProps {
    companyId: string;
    departmentDetails: API.Department;
    isReadOnly: boolean;
    workflow: API.WorkflowConfiguration;
    tasks: Array<API.TaskConfiguration>;
    tasksDispatch: React.Dispatch<TasksActions>;
    isLoading: boolean;
}

const TaskManager: React.FC<TaskManagerProps> = ({
    companyId,
    departmentDetails,
    isReadOnly,
    workflow,
    tasks,
    tasksDispatch,
    isLoading,
}) => {
    const { t } = useTranslation('workflow');
    const [selectedTask, setSelectedTask] = useState<{ order: number; task: API.TaskConfiguration }>();
    const [selectedTaskType, setSelectedTaskType] = useState<TaskType>();

    const clearTaskEditing = () => {
        setSelectedTask(undefined);
        setSelectedTaskType(undefined);
    };

    const onSave = (order: number, task: API.TaskConfiguration) => {
        tasksDispatch({ type: 'update', payload: { index: order, task } });
        clearTaskEditing();
    };

    const onDelete = (order: number) => {
        tasksDispatch({ type: 'delete', payload: order });
        clearTaskEditing();
    };

    let taskOverviewDetail;
    if (tasks.length === 0)
        taskOverviewDetail = (
            <Card loading={isLoading}>
                <TaskIntro />
            </Card>
        );
    else {
        taskOverviewDetail = (
            <TasksOverview
                enabled={selectedTask === undefined && selectedTaskType === undefined}
                onTaskSelected={(order, task) => {
                    clearTaskEditing();
                    setSelectedTask({ task, order });
                }}
                tasks={tasks}
                isReadOnly={isReadOnly}
                companyId={companyId}
                workflow={workflow}
                tasksDispatch={tasksDispatch}
                selectedTaskOrder={selectedTask?.order}
            />
        );
    }
    return (
        <Container>
            <TaskOverviewContainer>
                <Header2>{t('manage_tasks_title')}</Header2>
                <Paragraph>{t('manage_tasks_description')}</Paragraph>
                {taskOverviewDetail}
            </TaskOverviewContainer>
            <TaskDetailContainer>
                {selectedTask?.task && (
                    <TaskDetail
                        departmentDetails={departmentDetails}
                        taskType={selectedTask.task.taskType}
                        task={selectedTask.task}
                        onCancel={() => clearTaskEditing()}
                        isReadOnly={isReadOnly}
                        companyId={companyId}
                        workflow={workflow}
                        order={selectedTask.order}
                        onOk={onSave}
                        onDelete={onDelete}
                    />
                )}

                {selectedTaskType && (
                    <TaskDetail
                        departmentDetails={departmentDetails}
                        taskType={selectedTaskType}
                        onCancel={() => clearTaskEditing()}
                        isReadOnly={isReadOnly}
                        companyId={companyId}
                        workflow={workflow}
                        order={tasks.length}
                        onOk={onSave}
                        onDelete={onDelete}
                    />
                )}

                {!selectedTask && !selectedTaskType && !isReadOnly && (
                    <TaskPicker
                        companyId={companyId}
                        onTaskTypeSelected={(taskType: TaskType) => {
                            setSelectedTaskType(taskType);
                        }}
                    />
                )}
            </TaskDetailContainer>
        </Container>
    );
};

export default TaskManager;
