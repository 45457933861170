import { PartialRecord } from '.';
import {
    ApplicationModule,
    ApprovalStatus,
    ChannelProperties,
    CompanyProcessingType,
    CompanyVolume,
    ConsumptionAnnouncementType,
    CountryCode,
    CSVQuoteStyle,
    DeliveryType,
    EmailTaskStatus,
    ERDSTaskStatus,
    IgnoreIdentifierKind,
    JobSource,
    JobStatus,
    Language,
    MultiTaskRecipientIdentifierKind,
    ParcelCustomsShipmentType,
    ParcelStatus,
    ParcelType,
    PolicyService,
    PolicyStatement,
    PrintAttachmentPosition,
    PrintColor,
    PrintSide,
    PrintTaskMailFormat,
    QERDSApiKeyStatus,
    QERDSTaskStatus,
    RecipientIdentifierKind,
    SendingEventBusinessStatus,
    SendingEventTechnicalStatus,
    SplitIdentifierKind,
    SubscriptionSupportType,
    SubscriptionType,
    TaskBusinessStatus,
    TaskTechnicalStatus,
    TaskType,
    TaskValidationStatus,
    UserRole,
    WatermarkLocation,
    WorkflowEnvelopeType,
    WorkflowInputSourceType,
    WorkflowPaperType,
    WorkflowTriggerType,
    WorkflowType,
} from '../constant';
import { OrderStatus, ProductCode, RegisteredMailStatus } from '../constant/portal';

export interface BaseModel {
    createdAt: string;
    lastModifiedAt: string;
    lastModifiedBy: string;
}

interface BaseIdModel extends BaseModel {
    id: string;
}

export interface ExtraInfo {
    name: string;
    value: string;
}

export interface BaseTaskDetails {
    id: string;
    startDate?: string; // Set when the processing of the sending events actually starts
    endDate?: string;
    warnings: string[];
    extraInfo: ExtraInfo[];
    errors: string[];
    recipient: string;
    documentHash?: string;
    order: number;
    submitAttempts: number;
    status: TaskTechnicalStatus;
    businessStatus: TaskBusinessStatus;
}

export interface Watermark {
    image: ConfigurationFile;
    location: WatermarkLocation;
    width: number;
    height: number;
    x?: number;
    y?: number;
}

export interface TaskRetraction {
    userId: string;
    username: string;
    timestamp: string;
}

export type ERDSTaskDetails = BaseTaskDetails & {
    taskType: TaskType.ERDS;
    location: string;
    statusHistory?: Partial<Record<ERDSTaskStatus, string>>;
    submissionReportLocation?: string;
    deliveryReportLocation?: string;
};

export type QERDSTaskDetails = BaseTaskDetails & {
    taskType: TaskType.QERDS;
    location: string;
    recipientInfo: QERDSRecipient;
    statusHistory?: Partial<Record<QERDSTaskStatus, string>>;
    evidenceReportLocation?: string;
    externalId?: string; // ID of the QERDS sending as known in supplier API
    retraction?: TaskRetraction;
};

export type EmailTaskDetails = BaseTaskDetails & {
    taskType: TaskType.EMAIL;
    location: string;
    statusHistory?: Partial<Record<EmailTaskStatus, string>>;
};

export type PrintTaskDetails = BaseTaskDetails & {
    taskType: TaskType.EASY_PRINT;
    departmentAddress: PhysicalAddress;
    location: string; // Keeps track of the location on s3 of the individual file extracted
    statusHistory?: { [key: string]: string };
    format?: PrintTaskMailFormat;
    addressRawData: string;
    countryClassification?: string;
    totalNumberOfPages?: number;
    totalNumberOfPrints?: number;
    metadata?: string;
    mailID?: string;
    barcode?: string;
    frontPage?: boolean;
    epodLocation?: string;
    hasRegisteredMailRPFlag?: boolean;
};

export type TaskDetails = PrintTaskDetails | EmailTaskDetails | ERDSTaskDetails | QERDSTaskDetails;

export interface JobRequest {
    companyId: string;
    configurationId: string;
    jobName: string;
    originalFilename: string;
    objectKey: string;
    isResend: boolean;
    source: JobSource.WEB_APP | JobSource.MAIL;
    username: string;
}

export interface JobContinuationRequest {
    taskToken: string;
    approval: Approval;
}

export interface Approval {
    status: ApprovalStatus;
    userId?: string;
    username?: string;
    timestamp?: string;
}

export type SendingEventApproval = Required<Omit<Approval, 'status'>>;

export interface Job {
    id: string;
    name: string;
    companyId: string;
    departmentId: string;
    preprocessingExecutionId: string;
    orchestrationExecutionId?: string;
    taskToken?: string;
    startDate: string;
    warnings: string[];
    errors: string[];
    config: WorkflowConfiguration;
    location: string;
    originalFilename: string;
    status: JobStatus;
    executionDate: string; // Moment when the preprocesing will be effectively triggered for the job/file.
    author?: string; // Username of the Job
    approval?: Approval;
    isResend: boolean;
    ttl: number;
    sendingEvents: { ok: number; warning: number; total: number };
}

export interface SendingEvent {
    id: string;
    companyId: string;
    departmentId: string;
    jobId: string;
    jobName: string;
    executionId?: string;
    startDate: string;
    endDate?: string;
    status: SendingEventTechnicalStatus;
    businessStatus: SendingEventBusinessStatus;
    config: WorkflowConfiguration;
    taskDetails: Array<TaskDetails>;
    warnings: string[];
    errors: string[];
    approval?: SendingEventApproval;
    ttl: number;
}

export interface Time {
    hours: number;
    minutes: number;
}

export interface PhysicalAddress {
    street: string;
    streetNr: string;
    postalCode: string;
    box?: string;
    city: string;
    countryCode: string;
}

export interface Subscription {
    subscriptionType: SubscriptionType;
    maxAmountOfDepartments: number;
    maxAmountOfWorkflows: number;
    maxAmountOfUsers: number;
    availableWorkflowTypes: WorkflowType[];
    availableTaskTypes: TaskType[];
    supportType: SubscriptionSupportType;
}

export interface SubscriptionDetails {
    companyId: string;
    usersCount: number;
    departmentsCount: number;
    workflowsCount: number;
    subscriptionType: SubscriptionType;
}

export type PartialSubscriptionDetails = Partial<SubscriptionDetails> & { companyId: string };

export interface Company extends BaseIdModel {
    erpId: string;
    identificationNumber?: string;
    name: string;
    headAddress: PhysicalAddress;
    volume: CompanyVolume;
    processingType: CompanyProcessingType;
    emailAddress?: string;
    phoneNumber?: string;
    isActive: boolean;
}

export interface Department extends BaseIdModel {
    companyId: string;
    erpId: string;
    hasSeparateInvoicing: boolean;
    identificationNumber?: string;
    name: string;
    headAddress: PhysicalAddress;
    emailAddress?: string;
    phoneNumber?: string;
    isEligibleForRPRegisteredMail: boolean;
    consumptionAnnouncementType: ConsumptionAnnouncementType;
    isActive: boolean;
}

export interface User extends BaseIdModel {
    companyId: string;
    username: string;
    role: UserRole;
    departments: Array<string>;
    firstName: string;
    lastName: string;
    preferredLanguage: Language;
    emailAddress: string;
    hasMFALoginEnabled: boolean;
    hasQERDSMandate: boolean;
    modulesAccess: ApplicationModule[];
}

export interface Product {
    code: ProductCode;
    nameKey: string;
    imageFilename: string;
    maxAmount: number;
    countryCode: CountryCode;
}

export interface OrderItem {
    code: ProductCode;
    amount: number;
}

export interface Order {
    id: string;
    companyId: string;
    departmentId: string;
    status: OrderStatus;
    createdAt: string;
    lastModifiedAt: string;
    lastModifiedBy: string;
    items: Array<OrderItem>;
    author: string;
}

export interface RegisteredMail {
    id: string;
    companyId: string;
    departmentId: string;
    statusHistory: PartialRecord<RegisteredMailStatus, string>; // TODO rework to Partial<Record<RegisteredMailStatus, string>> + adapt API model
    primaryBarCode: string;
    scannedAt: string;
    bpostStampDate?: string;
    secondaryBarCode?: string;
    ePodLocation?: string;
}

export interface RegionOfInterest {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
}

export interface ColumnNameIdentifier {
    columnName: string;
}

export interface PDFExtraInfoIdentifier {
    name: string;
    regionOfInterest: RegionOfInterest;
}

export interface PDFIgnoreIdentifier {
    kind: IgnoreIdentifierKind;
    value?: string;
    regionOfInterest: RegionOfInterest;
}

export interface PDFSplitIdentifier {
    kind: SplitIdentifierKind;
    splitOnChange: boolean;
    value?: string;
    regionOfInterest?: RegionOfInterest;
}

export interface ConfigurationFile {
    filename: string;
    url?: string;
}

export interface PDFMultiTaskRecipientIdentifier {
    kind: MultiTaskRecipientIdentifierKind;
    regionOfInterest: RegionOfInterest;
    value?: string;
}

export interface PDFRecipientIdentifier {
    kind: RecipientIdentifierKind;
    regionOfInterest: RegionOfInterest;
}

export interface PDFChannelIdentifier {
    kind: ChannelProperties;
    regionOfInterest: RegionOfInterest;
}

export enum CSVColumnType {
    STRING = 'STRING',
    CURRENCY = 'CURRENCY',
}

export interface CSVColumnDescription {
    label: string;
    hasMultipleValue: boolean;
    type: CSVColumnType;
}

export interface CSVColumnValue extends CSVColumnDescription {
    value: string | string[];
}

export interface WorkflowResourceBase {
    logo?: ConfigurationFile;
    supplierExample?: ConfigurationFile;
}

export type WorkflowResourcePaper = WorkflowResourceBase & {
    type: WorkflowPaperType;
};

export type WorkflowResourceEnvelope = WorkflowResourceBase & {
    type: WorkflowEnvelopeType;
};

export interface TaskBase {
    id: string;
    name: string;
    description?: string;
    validationStatus: TaskValidationStatus;
    validationErrors: CustomError[];
    modelVersion: string;
    // Maximum duration until the task can end up in a successful state
    maxDurationInSeconds: number;
    taskType: TaskType;
    supplierId: string;
    htmlTemplate?: ConfigurationFile;
    attachment?: ConfigurationFile;
}

export interface EmailConfiguration {
    sender: {
        emailAddress: string;
        displayName: string;
    };
    subject: string;
    bodyTemplate: ConfigurationFile;
    bodyJsonTemplate: ConfigurationFile;
}

export type TaskEmail = TaskBase &
    EmailConfiguration & {
        taskType: TaskType.EMAIL;
    };

export type TaskERDS = TaskBase &
    EmailConfiguration & {
        taskType: TaskType.ERDS;
        mergeDocuments: boolean;
        defaultLanguage: Language; // In case the language of recipient is not extracted from the data file
    };

export type TaskQERDS = TaskBase & {
    taskType: TaskType.QERDS;
};

export type TaskEasyPrint = TaskBase & {
    supplierId: string;
    taskType: TaskType.EASY_PRINT;
    mergeDocuments: boolean;
    resources: {
        paper: WorkflowResourcePaper;
        envelope: WorkflowResourceEnvelope;
    };
    print: {
        addFrontPage: boolean;
        printColor: PrintColor;
        printSide: PrintSide;
    };
    deliveryType: DeliveryType;
    attachment?: PrintAttachment;
};

export interface PrintAttachment extends ConfigurationFile {
    position: PrintAttachmentPosition;
}

export type TaskConfiguration = TaskEasyPrint | TaskERDS | TaskEmail | TaskQERDS;

export type WorkflowScheduledTrigger = {
    type: WorkflowTriggerType.SCHEDULED;
    scheduleTimeUTC: Time;
};

export type WorkflowFileDropTrigger = {
    type: WorkflowTriggerType.FILE_DROP;
    secondsToWait: number;
};

type WorkflowTrigger = WorkflowScheduledTrigger | WorkflowFileDropTrigger;

interface FileBasedSource {
    sampleFile?: ConfigurationFile;
}

export type WorkflowPDFInputSource = FileBasedSource & {
    type: WorkflowInputSourceType.PDF;
    channelIdentifiers: PDFChannelIdentifier[];
    splitIdentifiers: PDFSplitIdentifier[];
    ignoreIdentifiers: PDFIgnoreIdentifier[];
    extraInfoIdentifiers: PDFExtraInfoIdentifier[];
};

export interface CSVChannelIdentifier {
    kind: ChannelProperties;
    columns: Pick<CSVColumnDescription, 'label'>[];
}

export interface CSVExtraInfoIdentifier {
    column: Pick<CSVColumnDescription, 'label'>;
}

export type WorkflowCSVInputSource = FileBasedSource & {
    type: WorkflowInputSourceType.CSV;
    containsHeader: boolean;
    separator: string;
    multipleValueSeparator: string;
    quoteStyle: CSVQuoteStyle;
    columnDescriptions: CSVColumnDescription[];
    channelIdentifiers: CSVChannelIdentifier[];
    extraInfoIdentifiers: CSVExtraInfoIdentifier[];
};

export type WorkflowExcelInputSource = FileBasedSource & {
    type: WorkflowInputSourceType.EXCEL;
};

export type WorkflowInputSource = WorkflowPDFInputSource | WorkflowCSVInputSource | WorkflowExcelInputSource;

export interface WorkflowConfiguration extends BaseIdModel {
    companyId: string;
    departmentId: string;
    name: string;
    description?: string;
    enabled: boolean;
    // Not mandatory field - only used for clients using sFTP
    sFTPFolderName?: string;
    type: WorkflowType;
    validated: boolean;
    version: number;
    modelVersion: string;
    tasks: Array<TaskConfiguration>;
    watermark?: Watermark;
    trigger: WorkflowTrigger;
    inputSource: WorkflowInputSource;
}

export interface SystemParameters extends BaseIdModel {
    value: unknown;
}

export interface CustomError {
    code: string;
    message: string;
    timestamp: string;
}

export interface ParcelSender {
    departmentId: string;
    companyName: string;
    name: string;
    address: PhysicalAddress;
    phoneNumber?: string;
    emailAddress?: string;
}

export interface ParcelRecipient {
    id?: string;
    companyName?: string;
    name: string;
    address: PhysicalAddress;
    email?: string;
}

export interface ParcelBpostOptions {
    hasSignature: boolean;
    hasInsurance: boolean;
    needsMailAnnouncement: boolean;
}

export interface ParcelCustomsContentDetail {
    itemDescription: string;
    numberOfItems: number;
    nettoWeightInGrams: string;
    valueOfItemInEuro: string;
    originOfGoods: string;
    hsTariffCode: string;
}

export interface ParcelItemDetail {
    weightInGrams: string;
    type: string;
    options?: unknown;
}

export interface ParcelBase extends BaseIdModel {
    order?: string;
    name: string;
    companyId: string;
    departmentId: string;
    statusHistory: Partial<Record<ParcelStatus, string>>;
    barCode?: string;
    parcelGroupId: string;
    reference?: string;
    sender: ParcelSender;
    recipient: ParcelRecipient;
    bpostOptions: ParcelBpostOptions;
    trackAndTraceStatus?: string;
    itemDetail?: ParcelItemDetail;
    warnings: string[];
    errors: CustomError[];
    ttl: number;
}

export interface ParcelWithCustoms extends ParcelBase {
    type: ParcelType.PARCEL_WITH_CUSTOMS;
    customsDetails: {
        shipmentType: ParcelCustomsShipmentType;
        contentDescription: string;
        contents: ParcelCustomsContentDetail[];
    };
}

export interface ParcelWithoutCustoms extends ParcelBase {
    type: ParcelType.PARCEL_WITHOUT_CUSTOMS;
}

export type Parcel = ParcelWithCustoms | ParcelWithoutCustoms;

export interface ParcelContact extends BaseIdModel {
    companyId: string;
    departmentId: string;
    companyName?: string;
    name: string;
    address: PhysicalAddress;
    email?: string;
    lastUsedAt: string;
}

export interface BasicDailyConsumptionAnnouncement {
    companyId: string;
    departmentId: string;
    announcementDate: string;
    amountPrior: number;
    amountNonPrior: number;
    amountRegistered: number;
    amountParcels: number;
    consumptionAnnouncementType: ConsumptionAnnouncementType.BASIC;
    ttl: number;
}

export interface AdvancedDailyConsumptionProductAndQuantity {
    productCode: string;
    quantity: number;
}

export interface AdvancedDailyConsumptionAnnouncement {
    companyId: string;
    departmentId: string;
    announcementDate: string;
    NonPrior: AdvancedDailyConsumptionProductAndQuantity[];
    Prior: AdvancedDailyConsumptionProductAndQuantity[];
    Registered: AdvancedDailyConsumptionProductAndQuantity[];
    Parcels: AdvancedDailyConsumptionProductAndQuantity[];
    ttl: number;
    consumptionAnnouncementType: ConsumptionAnnouncementType.ADVANCED;
}

export interface PostalProductsCatalog {
    NonPrior: string[];
    Prior: string[];
    Registered: string[];
    Parcels: string[];
}

export type DailyConsumptionAnnouncement = BasicDailyConsumptionAnnouncement | AdvancedDailyConsumptionAnnouncement;

export interface QERDSApiKey extends BaseModel {
    companyId: string;
    userId: string;
    emailAddress: string;
    value: string;
    status: QERDSApiKeyStatus;
}

export interface QERDSRecipient {
    companyIdentificationNumber?: string;
    emailAddress: string;
    firstName?: string;
    lastName?: string;
    birthDate?: string;
}

export interface QERDSEmailFeedback {
    timestamp: string;
    status: EmailTaskStatus;
}

export interface QERDSMandateHolderNotification {
    companyId: string;
    userId: string;
    lastEmailSentAt: string;
    lastEmailFeedback?: QERDSEmailFeedback;
}

export interface Policy extends BaseModel {
    service: PolicyService;
    statement: PolicyStatement;
    version: string;
    id: string;
    location: string;
}

export interface PolicyVersion {
    id: string;
    location: string;
}

export type StatementVersion = PartialRecord<PolicyStatement, PolicyVersion>;

export interface PoliciesInventory {
    inventory: Record<PolicyService, StatementVersion>;
}
