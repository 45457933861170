import { PlusCircleOutlined } from '@ant-design/icons';
import { SplitIdentifierKind } from '@eservices/shared/constant';
import { PDFSplitIdentifier } from '@eservices/shared/type';
import { Form, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { SecondaryButton } from '../../../../../components/Buttons';
import { Header3 } from '../../../../../components/Headers';
import { useTranslation } from '../../../../../hooks/translationHook';
import DeleteButton from '../Common/DeleteButton';
import EmptyTextSplit from './EmptyTextSplit';
import RegexSplit from './RegexSplit';
import TextSplit from './TextSplit';

const Title = styled.div`
    margin: 20px 0px 40px 0px;
    text-align: center;
`;

const OrBlock = styled.div`
    margin: 20px 0;
    font-style: italic;
`;

interface SplitOverviewProps {
    isReadOnly: boolean;
    splitIdentifiers: PDFSplitIdentifier[];
    onAddNewSplitChoice: () => void;
    onUpdateSplitIdentifier: (order: number, value: string) => void;
    onDeleteSplitIdentifier: (order: number) => void;
}

const SplitOverview: React.FC<SplitOverviewProps> = ({
    isReadOnly,
    onAddNewSplitChoice,
    splitIdentifiers,
    onUpdateSplitIdentifier,
    onDeleteSplitIdentifier,
}) => {
    const { t, tCommon } = useTranslation('workflow');

    const fields = splitIdentifiers.map((identifier, index) => ({
        name: [index, 'value'],
        value: identifier.value,
    }));

    const onFormValuesChange = (changedValues: unknown) => {
        const order = Number(Object.keys(changedValues)[0]);
        const value = changedValues[order].value;
        onUpdateSplitIdentifier(order, value);
    };

    return (
        <div>
            <Title>
                <Header3>{t('split_overview_title')}</Header3>
            </Title>
            <Form fields={fields} onValuesChange={onFormValuesChange}>
                {splitIdentifiers.map((identifier, index) => {
                    let component: React.ReactNode;
                    switch (identifier.kind) {
                        case SplitIdentifierKind.EMPTY_TEXT:
                            component = <EmptyTextSplit identifier={identifier} />;
                            break;
                        case SplitIdentifierKind.TEXT:
                            component = <TextSplit isReadOnly={isReadOnly} order={index} identifier={identifier} />;
                            break;
                        case SplitIdentifierKind.REGEX:
                            component = <RegexSplit isReadOnly={isReadOnly} order={index} identifier={identifier} />;
                            break;
                        default:
                            break;
                    }
                    return (
                        <>
                            <Space align="baseline" wrap>
                                {component}
                                <DeleteButton disabled={isReadOnly} onClick={() => onDeleteSplitIdentifier(index)} />
                            </Space>
                            <OrBlock>{tCommon('or')}</OrBlock>
                        </>
                    );
                })}
            </Form>

            <SecondaryButton
                disabled={isReadOnly}
                type="dashed"
                icon={<PlusCircleOutlined />}
                shape="default"
                onClick={onAddNewSplitChoice}
            >
                {t('add_new_split_option')}
            </SecondaryButton>
        </div>
    );
};

export default SplitOverview;
