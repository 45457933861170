import { Alert, Form } from 'antd';
import { useEffect } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useHistory } from 'react-router-dom';
import { FormItem, FormItemBold } from '../../components/FormItem';
import PinCode from '../../components/login/PinCode';
import { Routes } from '../../constants';
import { useAuth } from '../../context/auth-context';
import { useTranslation } from '../../hooks/translationHook';
import Layout from '../../layouts/LoginLayout';

interface ConfirmFormProps {
    confirmationCode: string;
}

const ConfirmMFACodeForm: React.FC = () => {
    const history = useHistory();
    const { user, confirmSignIn } = useAuth();
    const [form] = Form.useForm<ConfirmFormProps>();
    const { t, tValidation, tError } = useTranslation('confirm_mfa');

    const {
        execute: submitConfirmationCode,
        loading,
        error,
    } = useAsyncCallback(async (confirmationCode: string) => {
        await confirmSignIn(confirmationCode);
        history.push(Routes.ROOT);
    });

    useEffect(() => {
        if (!user) {
            history.push(Routes.ROOT);
        }
    }, [user]);

    useEffect(() => {
        if (error) {
            form.resetFields();
        }
    }, [error]);

    const onCodeFilled = async () => {
        const { confirmationCode } = await form.validateFields();
        await submitConfirmationCode(confirmationCode);
    };

    return (
        <Layout title={t('title')}>
            <Form form={form} layout="vertical" name="confirmSignIn" requiredMark={false} autoComplete="off">
                {error && (
                    <FormItem>
                        <Alert message={tError(error.name)} description={error.message} type="error" />
                    </FormItem>
                )}
                <FormItemBold
                    label={t('mfa_confirmation_code')}
                    name="confirmationCode"
                    rules={[
                        { required: true, message: tValidation('required.mfa_confirmation_code') },
                        {
                            pattern: new RegExp('^[0-9]{6}$'),
                            message: tValidation('format.confirmation_code'),
                            validateTrigger: 'onClick',
                        },
                    ]}
                >
                    <PinCode
                        onCodeFilled={!loading && onCodeFilled}
                        aria-label="mfa confirmation code input"
                        length={6}
                    />
                </FormItemBold>
            </Form>
        </Layout>
    );
};

export default ConfirmMFACodeForm;
