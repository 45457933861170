import { API } from '@eservices/shared/common';
import { VignetteType } from '@eservices/shared/constant';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import DepartmentSelector from '../../components/DepartmentSelector';
import VignetteCard from '../../components/vignette/VignetteCard';
import { useGetDepartments } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import ResourceDetailLayout from '../../layouts/ResourceDetailLayout';

const Vignettes = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
`;

interface VignettesOverviewViewProps {
    companyId: string;
}

const VignettesOverviewView: React.FC<VignettesOverviewViewProps> = ({ companyId }) => {
    const { t } = useTranslation('vignettes');
    const { isLoading, data: departments } = useGetDepartments(companyId);
    const [selectedDepartment, setSelectedDepartment] = useState<API.DepartmentListItem>();

    useEffect(() => {
        if (departments?.length > 0) {
            setSelectedDepartment(departments[0]);
        }
    }, [departments]);

    const onChangeDepartment = (departmentId: string) => {
        setSelectedDepartment(departments.find((department) => department.id === departmentId));
    };

    const detail = (
        <Vignettes>
            <VignetteCard
                companyId={companyId}
                type={VignetteType.PRIOR}
                department={selectedDepartment}
                isLoading={isLoading}
            />
            <VignetteCard
                companyId={companyId}
                type={VignetteType.NON_PRIOR}
                department={selectedDepartment}
                isLoading={isLoading}
            />
            <VignetteCard
                companyId={companyId}
                type={VignetteType.REGISTERED}
                department={selectedDepartment}
                isLoading={isLoading}
            />
        </Vignettes>
    );

    const actions = (
        <>
            {departments?.length === 1 ? (
                selectedDepartment && selectedDepartment.name
            ) : (
                <DepartmentSelector
                    companyId={companyId}
                    onChange={onChangeDepartment}
                    value={selectedDepartment?.id}
                />
            )}
        </>
    );

    return <ResourceDetailLayout title={t('title')} detail={detail} actions={actions} isLoading={isLoading} />;
};

export default VignettesOverviewView;
