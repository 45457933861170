import { PageHeader } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Filters from '../../components/dashboard/common/Filters';
import JobInfo from '../../components/dashboard/operator/JobInfo';
import SendingInfo from '../../components/dashboard/operator/SendingInfo';
import FailedToLoadData from '../../components/FailedToLoadData';
import { useGetDashboardOperatorDetails } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import { getRangeDates } from '../../utils/helpers';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
`;

const StyledPageHeader = styled(PageHeader)`
    border: 1px solid rgb(235, 237, 240);
    margin: 0 0 2% 0;
`;

const JobContainer = styled.div`
    width: 52%;
`;

const SendingContainer = styled.div`
    width: 39%;
`;

interface DashboardOperatorFetchingProps {
    companyId: string;
}

const DashboardOperatorFetching: React.FC<DashboardOperatorFetchingProps> = ({ companyId }) => {
    const { t } = useTranslation('dashboard');
    const [form] = useForm();
    const detailsStatusesMutation = useGetDashboardOperatorDetails(companyId);
    const [rangeDate, setRangeDate] = useState<string>();
    const [departmentId, setDepartmentId] = useState<string>();

    useEffect(() => {
        updateFilters('today');
    }, []);

    const fetchDetails = (startDate: string, endDate: string, departmentId?: string) => {
        detailsStatusesMutation.mutate({
            range: {
                from: startDate,
                until: endDate,
            },
            departmentId,
        });
    };

    const updateFilters = (rangeDate: string, departmentId?: string): void => {
        const { startDate, endDate } = getRangeDates(rangeDate);

        setRangeDate(rangeDate);
        setDepartmentId(departmentId);

        fetchDetails(startDate.toISOString(), endDate.toISOString(), departmentId);
    };

    if (detailsStatusesMutation.isError) return <FailedToLoadData />;
    return (
        <>
            <StyledPageHeader
                title={t('welcome_message')}
                extra={<Filters updateFilters={updateFilters} form={form} companyId={companyId} />}
            />

            <Wrapper>
                <JobContainer>
                    <JobInfo
                        isDetailsLoading={detailsStatusesMutation.isLoading}
                        statusCounters={detailsStatusesMutation.data?.jobs}
                        rangeDate={rangeDate}
                        departmentId={departmentId}
                    />
                </JobContainer>
                <SendingContainer>
                    <SendingInfo
                        isDetailsLoading={detailsStatusesMutation.isLoading}
                        statusCounters={detailsStatusesMutation.data?.sendings}
                        formFilters={form}
                        rangeDate={rangeDate}
                        departmentId={departmentId}
                    />
                </SendingContainer>
            </Wrapper>
        </>
    );
};

export default DashboardOperatorFetching;
