import { DownloadOutlined } from '@ant-design/icons';
import { PrintColor, PrintSide, WorkflowEnvelopeType } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Badge, Col, Divider, Row, Space } from 'antd';
import { PrimaryButton } from '../../../components/Buttons';
import DescriptionItem from '../../../components/DescriptionItem';
import DownloadLink from '../../../components/DownloadLink';
import { Colors } from '@eservices/ui-constants/colors';
import { useTranslation } from '../../../hooks/translationHook';
import styled from 'styled-components';
import BlackAndWhite from '../../../assets/images/black_white.svg';
import Color from '../../../assets/images/color.svg';
import Recto from '../../../assets/images/recto.svg';
import RectoVerso from '../../../assets/images/recto_verso.svg';
import USLeft5gr from '../../../assets/images/us_left_5gr.svg';
import USRight5gr from '../../../assets/images/us_right_5gr.svg';

const StyledPrimaryButton = styled(PrimaryButton)`
    margin-top: 40px;
`;

const DownloadContainer = styled.div`
    text-align: center;
`;

interface EasyPrintTaskDetailProps {
    companyId: string;
    task: API.PrintTaskDetails;
    taskConfiguration: API.TaskEasyPrint;
}

const EasyPrintTaskDetail: React.FC<EasyPrintTaskDetailProps> = ({ companyId, task, taskConfiguration }) => {
    const { t, tCommon } = useTranslation('easy_print_task');

    const NA = <>{tCommon('na')}</>;

    const DeliveryValue = (
        <Badge
            color={Colors[taskConfiguration.deliveryType]}
            text={
                task.hasRegisteredMailRPFlag
                    ? `${t(taskConfiguration.deliveryType.toLocaleLowerCase())} (RP)`
                    : t(taskConfiguration.deliveryType.toLocaleLowerCase())
            }
        />
    );

    const EnvelopeValue =
        taskConfiguration.resources.envelope.type === WorkflowEnvelopeType.US_RIGHT_5GR ? (
            <Space align="baseline">
                {t('us_right_5gr')}
                <img src={USRight5gr} alt="us right 5gr" />
            </Space>
        ) : (
            <Space align="baseline">
                <img src={USLeft5gr} alt="us left 5gr" />
                {t('us_left_5gr')}
            </Space>
        );

    const PrintSideValue =
        taskConfiguration.print.printSide === PrintSide.RECTO ? (
            <Space align="baseline">
                {t('recto')}
                <img src={Recto} alt="recto" />
            </Space>
        ) : (
            <Space align="baseline">
                <img src={RectoVerso} alt="recto verso" />
                {t('recto_verso')}
            </Space>
        );

    const PrintColorValue =
        taskConfiguration.print.printColor === PrintColor.BW ? (
            <Space align="baseline">
                {t('black_white')}
                <img src={BlackAndWhite} alt="black and white" />
            </Space>
        ) : (
            <Space align="baseline">
                {t('color')}
                <img src={Color} alt="color" />
            </Space>
        );

    const Logo = styled.img`
        width: 80px;
    `;
    const LogoValue = taskConfiguration.resources.envelope?.logo?.url ? (
        <Logo src={taskConfiguration.resources.envelope?.logo?.url} alt="logo" />
    ) : (
        NA
    );

    const AttachmentValue = taskConfiguration?.attachment?.url ? (
        <a target="_blank" href={taskConfiguration.attachment.url} download>
            {t('attachment')}
        </a>
    ) : (
        NA
    );

    const EnvelopeLayoutValue = taskConfiguration.resources.envelope.supplierExample?.url ? (
        <a target="_blank" href={taskConfiguration.resources.envelope.supplierExample.url}>
            {t('envelope_layout')}
        </a>
    ) : (
        NA
    );

    const BarCodeValue = task.epodLocation ? (
        <DownloadLink
            companyId={companyId}
            location={task.epodLocation}
            source={API.FileSource.REGISTERED_MAIL}
            content={<a download>{task.barcode}</a>}
        />
    ) : (
        <>{task.barcode}</>
    );

    return (
        <>
            <Row>
                <Col span={24}>
                    <DescriptionItem title={t('recipient')} content={task.recipient} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem title={t('delivery_type')} content={DeliveryValue} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem title={t('address')} content={task.addressRawData} />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem title={t('mail_id')} content={task.mailID} />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem title={t('barcode')} content={BarCodeValue} />
                </Col>
                <Col span={12}>
                    <DescriptionItem title={t('format')} content={task.format} />
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={12}>
                    <DescriptionItem title={t('print_side')} content={PrintSideValue} />
                </Col>
                <Col span={12}>
                    <DescriptionItem title={t('number_of_pages')} content={task.totalNumberOfPages} />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem title={t('print_color')} content={PrintColorValue} />
                </Col>
                <Col span={12}>
                    <DescriptionItem title={t('number_of_prints')} content={task.totalNumberOfPrints} />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem title={t('attachment')} content={AttachmentValue} />
                </Col>
                <Col span={12}>
                    <DescriptionItem title={t('add_front_page')} content={task.frontPage ? 'Yes' : 'No'} />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem title={t('envelop_type')} content={EnvelopeValue} />
                </Col>
                <Col span={12}>
                    <DescriptionItem title={t('logo')} content={LogoValue} />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem title={t('envelope_layout')} content={EnvelopeLayoutValue} />
                </Col>
            </Row>
            <Row>
                <Col span={12} offset={6}>
                    <DownloadContainer>
                        <DownloadLink
                            companyId={companyId}
                            source={API.FileSource.SENDING_EVENT}
                            location={task.location}
                            content={
                                <StyledPrimaryButton icon={<DownloadOutlined />}>
                                    {tCommon('download_document')}
                                </StyledPrimaryButton>
                            }
                        />
                    </DownloadContainer>
                </Col>
            </Row>
        </>
    );
};

export default EasyPrintTaskDetail;
