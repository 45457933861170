import Icon from '@ant-design/icons';
import { Colors } from '@eservices/ui-constants/colors';
import { Divider } from 'antd';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { Header3 } from '../../../../Headers';

const Container = styled.div`
    max-width: 900px;
`;

const Text = styled.div`
    width: 70%;
`;

const IconContainer = styled.div`
    width: 30%;
`;

const Description = styled(ReactMarkdown)`
    font-size: 14px;
    color: ${Colors.GRAY_SECONDARY};
    padding: 0 0 15px 0;
`;

const StyledIcon = styled(Icon)`
    font-size: 90px;
    color: ${Colors.PRIMARY};
    width: 100%;
    height: 100px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px 0px 0px 0px;
`;

const HeaderContainer = styled.div`
    margin: 0 0 5% 0;
`;

interface CollectDataProps {
    title: string;
    description: string;
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

const TabContent: React.FC<CollectDataProps> = ({ title, description, icon, children }) => {
    return (
        <Container>
            <HeaderContainer>
                <Header>
                    <Text>
                        <Header3>{title}</Header3>
                        <Description>{description}</Description>
                    </Text>
                    <IconContainer>
                        <StyledIcon component={icon} />
                    </IconContainer>
                </Header>
            </HeaderContainer>
            <Divider />
            {children}
        </Container>
    );
};

export default TabContent;
