import { CloseOutlined } from '@ant-design/icons';
import { ConsumptionAnnouncementType } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Form, FormInstance } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import styled from 'styled-components';
import { useGetDailyAnnouncement } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import { PrimaryButton, SecondaryButton } from '../Buttons';
import DepartmentSelector from '../DepartmentSelector';
import { FormItemBold } from '../FormItem';
import { Modal } from '../Modal';
import AdvancedAnnoucement from './AdvancedAnnoucement';
import BasicAnnoucement from './BasicAnnoucement';

const currentDate = format(new Date(), 'yyyy-MM-dd');
const MAX_INPUT = 10000;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const renderDepartmentLabel = (department: API.DepartmentListItem) => {
    return `${department.erpId} - ${department.name}`;
};

const isButtonDisabled = () => {
    const brusselsHours = utcToZonedTime(new Date().toISOString(), 'Europe/Brussels').getHours();
    return !(brusselsHours > 0 && brusselsHours < 18);
};

interface CreateAnnouncementModalProps {
    isVisible: boolean;
    form: FormInstance;
    companyId: string;
    onSave: () => void;
    onClose: () => void;
    departments: API.DepartmentListItem[];
}

const CreateAnnouncementModal: React.FC<CreateAnnouncementModalProps> = ({
    isVisible,
    form,
    companyId,
    onSave,
    onClose,
    departments,
}) => {
    const { t, tCommon, tValidation } = useTranslation('daily_announcements');
    const mutation = useGetDailyAnnouncement(companyId);

    const onChangeDepartment = (departmentId: string) => {
        if (departmentId) {
            const department = departments.find((dpt) => dpt.id === departmentId);

            form.setFieldsValue({ consumptionAnnouncementType: department.consumptionAnnouncementType });

            mutation.mutate(
                { departmentId: department.id, selectedDate: currentDate },
                {
                    onSuccess: (data) => {
                        form.setFieldsValue(data);
                    },
                },
            );
        }
    };

    return (
        <Modal
            title={t('create_announcement')}
            visible={isVisible}
            closable={true}
            forceRender={true}
            onCancel={onClose}
            footer={
                <ButtonsContainer>
                    <SecondaryButton aria-label="cancel" icon={<CloseOutlined />} onClick={onClose}>
                        {tCommon('cancel')}
                    </SecondaryButton>
                    <PrimaryButton
                        aria-label="create announcement"
                        onClick={() => onSave()}
                        disabled={isButtonDisabled()}
                    >
                        {t('create_announcement')}
                    </PrimaryButton>
                </ButtonsContainer>
            }
        >
            <Form form={form} layout="vertical" name={'create_announcement'} requiredMark={false}>
                <FormItemBold
                    label={tCommon('department')}
                    name="departmentId"
                    initialValue={departments[0].id}
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.department'),
                        },
                    ]}
                >
                    <DepartmentSelector
                        companyId={companyId}
                        renderLabel={renderDepartmentLabel}
                        onChange={onChangeDepartment}
                    />
                </FormItemBold>
                <FormItem
                    name="consumptionAnnouncementType"
                    initialValue={departments[0].consumptionAnnouncementType}
                    hidden
                />

                {form.getFieldValue('consumptionAnnouncementType') === ConsumptionAnnouncementType.BASIC && (
                    <BasicAnnoucement maxInput={MAX_INPUT} />
                )}
                {form.getFieldValue('consumptionAnnouncementType') === ConsumptionAnnouncementType.ADVANCED && (
                    <AdvancedAnnoucement companyId={companyId} maxInput={MAX_INPUT} />
                )}
                {form.getFieldValue('consumptionAnnouncementType') === ConsumptionAnnouncementType.NONE && (
                    <div>{t('no_access_to_daily_annoucement')}</div>
                )}
            </Form>
        </Modal>
    );
};

export default CreateAnnouncementModal;
