import { Language, PolicyService, PolicyStatement } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import mime from 'mime';
import QueryString from 'query-string';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SendingEventIdProps, TaskActionRequest } from '../types';
import { useAxios } from './axiosHook';

export enum QueryKey {
    USER = 'user',
    DEPARTMENT = 'department',
    COMPANY = 'company',
    FILE_UPLOAD = 'file-upload',
    WORKFLOW = 'workflow',
    JOB = 'job',
    SENDING_EVENT = 'sending-event',
    ORDER = 'order',
    PARCEL = 'parcel',
    SUBSCRIPTION = 'subscription',
    EMAIL_TEMPLATE = 'email-template',
    DAILY_ANNOUNCEMENT = 'daily-announcement',
    DAILY_ANNOUNCEMENT_CATALOG = 'daily-announcement-catalog',
    DASHBOARD = 'dashboard',
    QERDS_API_KEY = 'qerds-api-key',
    POLICY = 'policy',
}

export enum AxiosType {
    DEFAULT = 'DEFAULT',
    WITHOUT_INTERCEPTOR = 'WITHOUT_INTERCEPTOR',
}

const useRequests = <T>(type: AxiosType = AxiosType.DEFAULT) => {
    const [axios, axiosWithoutInterceptor] = useAxios();

    let axiosInstance: AxiosInstance;

    switch (type) {
        case AxiosType.DEFAULT:
            axiosInstance = axios;
            break;
        case AxiosType.WITHOUT_INTERCEPTOR:
            axiosInstance = axiosWithoutInterceptor;
            break;
        default:
            axiosInstance = axios;
            break;
    }

    return {
        get: async (url: string): Promise<T> => (await axiosInstance.get(url)).data,
        post: async <A>(url: string, body?: A): Promise<T> =>
            (await axiosInstance.post<A, AxiosResponse<T>>(url, body)).data,
        put: async (url: string, body: T, config?: AxiosRequestConfig): Promise<T> =>
            (await axiosInstance.put(url, body, config)).data,
        delete: async (url: string): Promise<T> => (await axiosInstance.delete(url)).data,
    };
};

export const useGetBpostCountries = (lang: Language) => {
    const { get } = useRequests<API.BpostCountries>(AxiosType.WITHOUT_INTERCEPTOR);
    return useQuery<API.BpostCountries, AxiosError>(
        ['BPOST_COUNTRIES'],
        () => get(`/assets/countries/bpostCountries${lang.toUpperCase()}.json`),
        {
            staleTime: 1000 * 60 * 60,
            cacheTime: 1000 * 60 * 60,
        },
    );
};

export const useGetMyUser = () => {
    const { get } = useRequests<API.User>();
    return useQuery<API.User, AxiosError>([QueryKey.USER], () => get('/user/me'));
};

export const useUpdateMyUser = () => {
    const queryClient = useQueryClient();
    const { put } = useRequests<API.UpdateUserProfile>();
    return useMutation<API.UpdateUserProfile, AxiosError, API.UpdateUserProfile, unknown>(
        (user: API.UpdateUserProfile) => put('/user/me', user),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(QueryKey.USER);
            },
        },
    );
};

export const useSetupMFALoginForMyUser = () => {
    const queryClient = useQueryClient();
    const { post } = useRequests<never>();
    return useMutation<never, AxiosError, boolean>(
        [QueryKey.USER],
        (isEnabled: boolean) => post('/user/me/setup-mfa-login', { isEnabled }),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(QueryKey.USER);
            },
        },
    );
};

export const useGetUser = (companyId: string, userId: string) => {
    const { get } = useRequests<API.User>();
    return useQuery<API.User, AxiosError>([QueryKey.USER, { companyId, userId }], () =>
        get(`/company/${companyId}/users/${userId}`),
    );
};

export const useGetUsers = (companyId: string) => {
    const { get } = useRequests<API.User[]>();
    return useQuery<API.User[], AxiosError>([QueryKey.USER, { companyId }], () => get(`/company/${companyId}/users`));
};

export const useCreateUser = (companyId: string) => {
    const queryClient = useQueryClient();
    const { post } = useRequests<API.CreateUser>();
    return useMutation<API.CreateUser, AxiosError, API.CreateUser, unknown>(
        (user: API.CreateUser) => post(`/company/${companyId}/users`, user),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(QueryKey.USER);
            },
        },
    );
};

export const useUpdateUser = (companyId: string, userId: string) => {
    const queryClient = useQueryClient();
    const { put } = useRequests<API.EditUser>();
    return useMutation<API.EditUser, AxiosError, API.EditUser, unknown>(
        (user: API.EditUser) => put(`/company/${companyId}/users/${userId}`, user),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(QueryKey.USER);
            },
        },
    );
};

export const useUserResetPassword = (companyId: string, userId: string) => {
    const queryClient = useQueryClient();
    const { post } = useRequests<never>();
    return useMutation<never, AxiosError>(() => post(`company/${companyId}/users/${userId}/reset-password`), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(QueryKey.USER);
        },
    });
};

export const useGetDepartments = (companyId: string) => {
    const { get } = useRequests<API.DepartmentListItem[]>();
    return useQuery<API.DepartmentListItem[], AxiosError>([QueryKey.DEPARTMENT, { companyId }], () =>
        get(`/company/${companyId}/departments`),
    );
};

export const useGetDepartment = (companyId: string, departmentId: string, options?: unknown) => {
    const { get } = useRequests<API.Department>();
    return useQuery<API.Department, AxiosError>(
        [QueryKey.DEPARTMENT, { companyId, departmentId }],
        () => get(`/company/${companyId}/departments/${departmentId}`),
        options,
    );
};

export const useCreateDepartment = (companyId: string) => {
    const queryClient = useQueryClient();
    const { post } = useRequests<API.CreateDepartment>();
    return useMutation<API.CreateDepartment, AxiosError, API.CreateDepartment, unknown>(
        (department: API.CreateDepartment) => post(`/company/${companyId}/departments`, department),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(QueryKey.DEPARTMENT);
            },
        },
    );
};

export const useUpdateDepartment = (companyId: string, departmentId: string) => {
    const queryClient = useQueryClient();
    const { put } = useRequests<API.EditDepartment>();
    return useMutation<API.EditDepartment, AxiosError, API.EditDepartment, unknown>(
        (department: API.EditDepartment) => put(`/company/${companyId}/departments/${departmentId}`, department),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(QueryKey.DEPARTMENT);
            },
        },
    );
};

export const useCreateWorkflow = (companyId: string) => {
    const queryClient = useQueryClient();
    const { post } = useRequests<API.WorkflowConfiguration>();
    return useMutation<API.WorkflowConfiguration, AxiosError, API.WorkflowConfiguration, unknown>(
        (workflow: API.WorkflowConfiguration) =>
            post(`/company/${companyId}/department/${workflow.departmentId}/workflows`, workflow),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(QueryKey.WORKFLOW);
            },
        },
    );
};

export const useGetWorkflows = (companyId: string) => {
    const { get } = useRequests<API.WorkflowConfigurationListItem[]>();
    return useQuery<API.WorkflowConfigurationListItem[], AxiosError>([QueryKey.WORKFLOW, { companyId }], () =>
        get(`/company/${companyId}/workflows`),
    );
};

export const useGetWorkflow = (companyId: string, departmentId: string, workflowId: string) => {
    const { get } = useRequests<API.WorkflowConfiguration>();
    return useQuery<API.WorkflowConfiguration, AxiosError>(
        [QueryKey.WORKFLOW, { companyId, departmentId, workflowId }],
        () => get(`/company/${companyId}/department/${departmentId}/workflows/${workflowId}`),
    );
};

export const useUpdateWorkflow = (companyId: string, workflowId: string) => {
    const queryClient = useQueryClient();
    const { put } = useRequests<API.WorkflowConfiguration>();
    return useMutation<API.WorkflowConfiguration, AxiosError, API.WorkflowConfiguration, unknown>(
        (workflow: API.WorkflowConfiguration) =>
            put(`/company/${companyId}/department/${workflow.departmentId}/workflows/${workflowId}`, workflow),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(QueryKey.WORKFLOW);
            },
        },
    );
};

export const useCloneWorkflow = (companyId: string) => {
    const queryClient = useQueryClient();
    const { post } = useRequests<API.WorkflowConfiguration>();
    return useMutation<
        API.WorkflowConfiguration,
        AxiosError,
        { departmentId: string; workflowId: string; targetDepartmentId: string },
        unknown
    >(
        ({ departmentId, workflowId, targetDepartmentId }) =>
            post(
                `/company/${companyId}/department/${departmentId}/workflows/${workflowId}/clone/${targetDepartmentId}`,
            ),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(QueryKey.WORKFLOW);
            },
        },
    );
};

export const useGetCompanies = () => {
    const { get } = useRequests<API.CompanyListItem[]>();
    return useQuery<API.CompanyListItem[], AxiosError>([QueryKey.COMPANY], () => get(`/companies`));
};

export const useGetUploadUrl = (companyId: string) => {
    const { get } = useRequests<API.FileUploadLink>();
    return useMutation<API.FileUploadLink, AxiosError, string, unknown>((filename: string) =>
        get(`/company/${companyId}/tmp-file-upload-url?${QueryString.stringify({ filename })}`),
    );
};

export const useGetFile = <T>() => {
    const { get } = useRequests<T>(AxiosType.WITHOUT_INTERCEPTOR);
    return useMutation<T, AxiosError, string, unknown>((url: string) => get(url));
};

export const usePutFile = () => {
    const { put } = useRequests<File>(AxiosType.WITHOUT_INTERCEPTOR);
    return useMutation<File, AxiosError, { url: string; file: File }, unknown>(({ url, file }) =>
        put(url, file, {
            headers: { 'Content-Type': mime.getType(file.name) },
            timeout: 0,
        }),
    );
};

export const useGetFileUrl = (companyId: string) => {
    const { get } = useRequests<string>();
    return useMutation<string, AxiosError, string, unknown>((filename: string) =>
        get(`/company/${companyId}/tmp-file-download-url?${QueryString.stringify({ filename })}`),
    );
};

export const useGetDownloadUrl = (companyId: string) => {
    const { get } = useRequests<string>();
    return useMutation<string, AxiosError, { location: string; source: API.FileSource }, unknown>(
        ({ location, source }) =>
            get(`/company/${companyId}/file-download-url?${QueryString.stringify({ location, source })}`),
    );
};

export const useGetCompany = (companyId: string) => {
    const { get } = useRequests<API.Company>();
    return useQuery<API.Company, AxiosError>([QueryKey.COMPANY, { companyId }], () => get(`/companies/${companyId}`));
};

export const useGetCompanySubscriptionDetails = (companyId: string) => {
    const { get } = useRequests<API.CompanySubscriptionDetails>();
    return useQuery<API.CompanySubscriptionDetails, AxiosError>([QueryKey.SUBSCRIPTION, { companyId }], () =>
        get(`/companies/${companyId}/subscription-details`),
    );
};

export const useCreateCompany = () => {
    const queryClient = useQueryClient();
    const { post } = useRequests<API.CreateCompany>();
    return useMutation<API.CreateCompany, AxiosError, API.CreateCompany, unknown>(
        (company: API.CreateCompany) => post(`/companies`, company),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(QueryKey.COMPANY);
            },
        },
    );
};

export const useUpdateCompany = (companyId: string) => {
    const queryClient = useQueryClient();
    const { put } = useRequests<API.EditCompany>();
    return useMutation<API.EditCompany, AxiosError, API.EditCompany, unknown>(
        (company: API.EditCompany) => put(`/companies/${companyId}`, company),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(QueryKey.COMPANY);
            },
        },
    );
};

export const useSearchJobs = (companyId: string) => {
    const { post } = useRequests<API.JobList>();
    return useMutation<API.JobList, AxiosError, API.ESJobFilter, unknown>((esJobFilter: API.ESJobFilter) =>
        post(`/company/${companyId}/jobs?`, esJobFilter),
    );
};

export const useSubmitJob = (companyId: string) => {
    const queryClient = useQueryClient();
    const { post } = useRequests<unknown>();
    return useMutation<
        unknown,
        AxiosError,
        { workflowId: string; departmentId: string; submitJob: API.SubmitJob },
        unknown
    >(
        ({ workflowId, departmentId, submitJob }) =>
            post(`/company/${companyId}/department/${departmentId}/workflows/${workflowId}/submit-job`, submitJob),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(QueryKey.JOB);
            },
        },
    );
};

export const usePerformJobAction = (companyId: string) => {
    const { post } = useRequests<unknown>();
    return useMutation<
        unknown,
        AxiosError,
        { jobId: string; departmentId: string; action: API.JobActionType },
        unknown
    >(({ jobId, departmentId, action }) =>
        post(
            `/company/${companyId}/department/${departmentId}/job/${jobId}/perform-action?${QueryString.stringify({
                actionType: action,
            })}`,
        ),
    );
};

export const useSearchSendingEvents = (companyId: string) => {
    const { post } = useRequests<API.SendingEventList>();

    return useMutation<API.SendingEventList, AxiosError, API.SendingEventFilter, unknown>(
        (sendingEventFilter: API.SendingEventFilter) =>
            post(`/company/${companyId}/sending-events`, sendingEventFilter),
    );
};

export const useGetSendingEvent = (companyId: string, departmentId: string, jobId: string, seId: string) => {
    const { get } = useRequests<API.SendingEvent>();
    return useQuery<API.SendingEvent, AxiosError>(
        [QueryKey.SENDING_EVENT, { companyId, departmentId, jobId, seId }],
        () => get(`/company/${companyId}/department/${departmentId}/job/${jobId}/sending-events/${seId}`),
    );
};

export const useListOrders = (companyId: string, departmentId: string) => {
    const { get } = useRequests<API.Order[]>();
    return useQuery<API.Order[], AxiosError>([QueryKey.ORDER, { companyId, departmentId }], () =>
        get(`/company/${companyId}/department/${departmentId}/orders`),
    );
};

export const useCreateOrder = (companyId: string, departmentId: string) => {
    const queryClient = useQueryClient();
    const { post } = useRequests<API.Order>();
    return useMutation<API.Order, AxiosError, API.Order, unknown>(
        (order: API.Order) => post(`/company/${companyId}/department/${departmentId}/orders`, order),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(QueryKey.ORDER);
            },
        },
    );
};

export const useSearchRegisteredMail = (companyId: string) => {
    const { post } = useRequests<API.RegisteredMailList>();
    return useMutation<API.RegisteredMailList, AxiosError, API.RegisteredMailFilter, unknown>(
        (esRegisteredMailFilter: API.RegisteredMailFilter) =>
            post(`/company/${companyId}/registered-mail`, esRegisteredMailFilter),
    );
};

export const useSearchParcels = (companyId: string) => {
    const { post } = useRequests<API.ParcelList>();
    return useMutation<API.ParcelList, AxiosError, API.ParcelFilter, unknown>((parcelFilter: API.ParcelFilter) =>
        post(`/company/${companyId}/parcels`, parcelFilter),
    );
};

export const useGetParcel = (companyId: string, departmentId: string, id: string) => {
    const { get } = useRequests<API.Parcel>();
    return useQuery<API.Parcel, AxiosError>([QueryKey.PARCEL, { companyId, departmentId, id }], () =>
        get(`/company/${companyId}/department/${departmentId}/parcels/${id.split('#').join('/')}`),
    );
};

export const useSearchParcelContacts = (companyId: string, options?: unknown) => {
    const { post } = useRequests<API.ParcelContactList>();
    return useMutation<API.ParcelContactList, AxiosError, API.ParcelContactsFilter, unknown>(
        (parcelContactsFilter: API.ParcelContactsFilter) =>
            post(`/company/${companyId}/parcels/contacts`, parcelContactsFilter),
        options,
    );
};

export const useCreateParcelRequests = (companyId: string) => {
    const { post } = useRequests<API.SimpleResponse>();
    return useMutation<
        API.SimpleResponse,
        AxiosError,
        { departmentId: string; parcelRequests: API.ParcelRequests },
        unknown
    >(({ departmentId, parcelRequests }) =>
        post(`/company/${companyId}/department/${departmentId}/parcels`, parcelRequests),
    );
};

export const useGetSampleTemplates = () => {
    const { get } = useRequests<API.EmailTemplate[]>();
    return useQuery<API.EmailTemplate[], AxiosError>([QueryKey.EMAIL_TEMPLATE, 'allTemplates'], () =>
        get('/emailTemplates/templates'),
    );
};

export const useGetTemplatePreviewUrl = (body: object) => {
    const { post } = useRequests<API.EmailTemplatePicturePreview>();
    return useQuery<API.EmailTemplatePicturePreview, AxiosError>([QueryKey.EMAIL_TEMPLATE, body], () =>
        post('/emailTemplates/templatePreviewUrl', body),
    );
};

export const useGetDailyAnnouncement = (companyId: string) => {
    const { get } = useRequests<API.BasicDailyConsumptionAnnouncement>();
    return useMutation<
        API.BasicDailyConsumptionAnnouncement,
        AxiosError,
        { departmentId: string; selectedDate: string },
        unknown
    >(async ({ departmentId, selectedDate }) =>
        get(`/company/${companyId}/department/${departmentId}/daily-announcements/${selectedDate}`),
    );
};

export const useGetMonthTotalDailyAnnouncements = (companyId: string) => {
    const { get } = useRequests<API.MonthDailyConsumptionAnnouncement>();
    return useMutation<
        API.MonthDailyConsumptionAnnouncement,
        AxiosError,
        { departmentId: string; date: string },
        unknown
    >(({ departmentId, date }) =>
        get(
            `/company/${companyId}/department/${departmentId}/daily-announcements?${QueryString.stringify({
                date,
            })}`,
        ),
    );
};

export const useCreateDailyConsumptionAnnouncement = (companyId: string) => {
    const queryClient = useQueryClient();
    const { post } = useRequests<API.CreateDailyConsumptionAnnouncement>();

    return useMutation<
        API.CreateDailyConsumptionAnnouncement,
        AxiosError,
        { departmentId: string; dailyAnnouncement: API.CreateDailyConsumptionAnnouncement },
        unknown
    >(
        ({ departmentId, dailyAnnouncement }) =>
            post(`/company/${companyId}/department/${departmentId}/daily-announcements`, dailyAnnouncement),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(QueryKey.DAILY_ANNOUNCEMENT);
            },
        },
    );
};

export const useGetPostalProductsCatalog = (companyId: string) => {
    const { get } = useRequests<API.PostalProductCatalog>();
    return useQuery<API.PostalProductCatalog, AxiosError>([QueryKey.DAILY_ANNOUNCEMENT_CATALOG], () =>
        get(`/company/${companyId}/postal-products-catalog`),
    );
};

export const useGetDashboardOperatorDetails = (companyId: string) => {
    const { post } = useRequests<API.DashboardOperatorDetails>();
    return useMutation<API.DashboardOperatorDetails, AxiosError, API.DashboardFilter, unknown>(
        (dashboardFilter: API.DashboardFilter) => post(`/company/${companyId}/dashboard/operator`, dashboardFilter),
    );
};

export const useGetQERDSApiKeyDetails = (companyId: string) => {
    const { get } = useRequests<API.QERDSApiKeyDetails>();
    return useQuery<API.QERDSApiKeyDetails, AxiosError>([QueryKey.QERDS_API_KEY], () =>
        get(`/company/${companyId}/qerds/api-key-details`),
    );
};

export const useRegisterQERDSAPIKey = (companyId: string) => {
    const queryClient = useQueryClient();
    const { post } = useRequests<API.RegisterQERDSApiKey>();
    return useMutation<API.RegisterQERDSApiKey, AxiosError, API.RegisterQERDSApiKey>(
        (qerdsAPIKey) => post(`/company/${companyId}/qerds/register-api-key`, qerdsAPIKey),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(QueryKey.QERDS_API_KEY);
            },
        },
    );
};

export const useRevokeQERDSAPIKey = (companyId: string) => {
    const queryClient = useQueryClient();
    const { post } = useRequests<void>();
    return useMutation<void, AxiosError, void>(() => post(`/company/${companyId}/qerds/revoke-api-key`), {
        onSuccess: async () => {
            await queryClient.invalidateQueries(QueryKey.QERDS_API_KEY);
        },
    });
};

export const useTaskActionRequest = ({ companyId, departmentId, jobId, sendingEventId }: SendingEventIdProps) => {
    const queryClient = useQueryClient();
    const { post } = useRequests<void>();
    return useMutation<void, AxiosError, TaskActionRequest>(
        ({ taskId, actionType }) =>
            post(
                `/company/${companyId}/department/${departmentId}/job/${jobId}/sending-event/${sendingEventId}/task/${taskId}/${actionType}`,
            ),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(QueryKey.SENDING_EVENT);
            },
        },
    );
};

export const useSubmitPolicy = (companyId: string) => {
    const queryClient = useQueryClient();
    const { post } = useRequests<unknown>();
    return useMutation<
        unknown,
        AxiosError,
        { service: PolicyService; statement: PolicyStatement; submitPolicy: API.SubmitPolicy },
        unknown
    >(
        ({ service, statement, submitPolicy }) =>
            post(`/companyId/${companyId}/service/${service}/statement/${statement}/submit-policy`, submitPolicy),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(QueryKey.POLICY);
            },
        },
    );
};

export const useGetPolicyVersionsByServiceAndStatement = (companyId: string) => {
    const { get } = useRequests<API.Policy[]>();
    return useMutation<API.Policy[], AxiosError, { service: PolicyService; statement: PolicyStatement }, unknown>(
        ({ service, statement }) =>
            get(`/companyId/${companyId}/service/${service}/statement/${statement}/policy-versions`),
    );
};
