import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import styled from 'styled-components';
import DepartmentSelector from '../../DepartmentSelector';
import { FormItem } from '../../FormItem';
import DateSelector from './DateSelector';

const SearchForm = styled(Form)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .ant-form-item {
        margin-left: 25px;
        margin-bottom: 0 !important;
    }
`;

interface FilterFormProps {
    rangeDate: string;
    departmentId: string;
}
interface FiltersProps {
    form: FormInstance<FilterFormProps>;
    companyId: string;
    updateFilters: (rangeDate: string, departmentId?: string) => void;
}

const Filters: React.FC<FiltersProps> = ({ form, companyId, updateFilters }) => {
    const onValuesChange = (_changedValues, { rangeDate, departmentId }) => {
        updateFilters(rangeDate, departmentId);
    };

    return (
        <SearchForm onValuesChange={onValuesChange} form={form} name="filtersForm">
            <FormItem name="departmentId">
                <DepartmentSelector allowClear companyId={companyId} />
            </FormItem>
            <FormItem name="rangeDate" initialValue="today">
                <DateSelector />
            </FormItem>
        </SearchForm>
    );
};

export default Filters;
