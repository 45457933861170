import { API } from '@eservices/shared/type';
import { Col, Row } from 'antd';
import { useBpostCountries } from '../../hooks/bpostCountriesHook';
import { useTranslation } from '../../hooks/translationHook';
import DescriptionItem from '../DescriptionItem';
import { Header3 } from '../Headers';

interface ParcelDetailSubDetailSenderProps {
    parcel: API.Parcel;
    departments: API.DepartmentListItem[];
}

const ParcelDetailSubDetailSender: React.FC<ParcelDetailSubDetailSenderProps> = ({ parcel, departments }) => {
    const { tCommon } = useTranslation('parcel_detail');
    const { findCountryName } = useBpostCountries();

    const { sender } = parcel;

    const department = departments.find(({ id }) => id === sender.departmentId);

    return (
        <div>
            <Header3>{tCommon('sender')}</Header3>
            <Row>
                <Col span={24}>
                    <DescriptionItem title={tCommon('department')} content={department.name} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem
                        title={tCommon('address')}
                        content={
                            <div>
                                {sender.address.street} {sender.address.streetNr} {sender.address.box},
                                {sender.address.postalCode} {sender.address.city},{' '}
                                {findCountryName(sender.address.countryCode)}
                            </div>
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem title={tCommon('email')} content={sender.emailAddress} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem title={tCommon('phone_number')} content={sender.phoneNumber} />
                </Col>
            </Row>
        </div>
    );
};

export default ParcelDetailSubDetailSender;
