import { BookOutlined, CloseOutlined } from '@ant-design/icons';
import { TaskType } from '@eservices/shared/constant';
import { Space } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../../hooks/translationHook';
import { PrimaryButton, SecondaryButton } from '../../Buttons';
import TaskInfo from '../TaskManager/TaskInfo';

const ActionButtons = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 1%;
`;

interface TaskActionsProps {
    onSave: () => void;
    onCancel: () => void;
    allowedToSave: boolean;
    isLoading?: boolean;
    taskType: TaskType;
    isReadOnly: boolean;
}

const TaskActions: React.FC<TaskActionsProps> = ({
    onSave,
    onCancel,
    allowedToSave,
    isLoading = false,
    taskType,
    isReadOnly,
}) => {
    const { tCommon } = useTranslation();

    return (
        <ActionButtons>
            <Space>
                <TaskInfo taskType={taskType} />
                <SecondaryButton shape="default" aria-label="cancel button" icon={<CloseOutlined />} onClick={onCancel}>
                    {tCommon('cancel')}
                </SecondaryButton>
                <PrimaryButton
                    shape="default"
                    icon={<BookOutlined />}
                    aria-label="save button"
                    disabled={(!allowedToSave && isLoading) || isReadOnly}
                    htmlType="submit"
                    onClick={onSave}
                    loading={isLoading}
                >
                    {isLoading}
                    {tCommon('save')}
                </PrimaryButton>
            </Space>
        </ActionButtons>
    );
};

export default TaskActions;
