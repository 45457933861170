import { ApplicationModule } from '@eservices/shared/constant';
import { Colors } from '@eservices/ui-constants/colors';
import React from 'react';
import { Link as AntLink } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../assets/images/logo.svg';
import ModuleSwitch from '../components/ModuleSwitch';
import NavBarItem from '../components/NavBarItem';
import { Routes } from '../constants';
import { MainRoute } from '../types';

const Link = styled(AntLink)`
    text-align: center;
    display: block;
`;

const LogoImage = styled.img`
    margin: 18px 20px;
    width: 50px;
    display: block;
    text-align: center;
`;

const NavBar = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

const Navigation = styled.div`
    grid-area: navigation;
`;

const NavigationContainer = styled.nav`
    padding-top: 4vh;
`;

const ContextSwitchContainer = styled.div`
    margin: 40px 18px;
`;

const Container = styled.div`
    grid-area: sidebar;
    display: grid;
    grid-template-rows: 1.9fr 0.1fr;
    grid-template-areas:
        'navigation'
        'footer';
    background-color: ${Colors.DARK_SECONDARY};
`;

interface SidebarLayoutProps {
    routes: MainRoute[];
    module: ApplicationModule;
    availableModules: ApplicationModule[];
}

const SidebarLayout: React.FC<SidebarLayoutProps> = ({ routes, module, availableModules }) => {
    return (
        <Container>
            <Navigation>
                <div>
                    <Link aria-label="logo link" to={Routes.ROOT}>
                        <LogoImage alt="logo" src={Logo} />
                    </Link>
                    {availableModules.length > 1 && (
                        <ContextSwitchContainer>
                            <ModuleSwitch module={module} />
                        </ContextSwitchContainer>
                    )}
                </div>
                <NavigationContainer>
                    <NavBar>
                        {routes
                            .filter(
                                (route) =>
                                    route.subRoutes.length > 0 &&
                                    (route.module === module || route.module === ApplicationModule.OMNI),
                            )
                            .map((route) => (
                                <NavBarItem key={route.title} route={route} />
                            ))}
                    </NavBar>
                </NavigationContainer>
            </Navigation>
        </Container>
    );
};

export default SidebarLayout;
