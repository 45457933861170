import { StopOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';
import Card from '../../components/Card';
import { Header3 } from '../../components/Headers';
import { Routes } from '../../constants';
import { useAuth } from '../../context/auth-context';
import { useSetupMFALoginForMyUser } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import SetupTOTPFetching from './SetupTOTPFetching';

const Container = styled.div`
    display: flex;
    flex-flow: column wrap;
    min-height: 300px;
    justify-content: space-between;
`;

const Actions = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    gap: 10px;
`;

interface MFASettingsProp {
    hasMFALoginEnabled: boolean;
}

const MFASettings: React.FC<MFASettingsProp> = ({ hasMFALoginEnabled }) => {
    const { t, tCommon } = useTranslation('profile');
    const history = useHistory();
    const { signOut } = useAuth();
    const [showSetupTOTPForm, setShowSetupTOTPForm] = useState(false);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const setupMFAMutation = useSetupMFALoginForMyUser();

    const labelsSuffix = hasMFALoginEnabled ? 'already_enabled' : 'initial';
    const onClickConfirm = async () => {
        setPopoverVisible(false);
        setupMFAMutation.mutate(false, {
            onSuccess: async () => {
                await signOut();
                history.push(Routes.ROOT);
            },
        });
    };

    const content = () => {
        return showSetupTOTPForm ? (
            <SetupTOTPFetching />
        ) : (
            <>
                <div>
                    <Header3>{t(`mfa_setup_${labelsSuffix}`)}</Header3>
                    <ReactMarkdown>{t(`mfa_description_${labelsSuffix}`)}</ReactMarkdown>
                </div>
                <Actions>
                    {hasMFALoginEnabled && (
                        <Popover
                            title={tCommon('confirm_action')}
                            visible={popoverVisible}
                            onVisibleChange={(visible) => setPopoverVisible(visible)}
                            content={
                                <>
                                    <p>{t('confirm_mfa_disable')}</p>
                                    <Actions>
                                        <SecondaryButton onClick={() => setPopoverVisible(false)}>
                                            {tCommon('cancel')}
                                        </SecondaryButton>
                                        <PrimaryButton onClick={onClickConfirm}>{tCommon('confirm')}</PrimaryButton>
                                    </Actions>
                                </>
                            }
                            trigger="click"
                        >
                            <SecondaryButton danger disabled={setupMFAMutation.isLoading} icon={<StopOutlined />}>
                                {t('disable_mfa')}
                            </SecondaryButton>
                        </Popover>
                    )}
                    <PrimaryButton onClick={() => setShowSetupTOTPForm(true)}>
                        {t(`mfa_device_setup_${labelsSuffix}`)}
                    </PrimaryButton>
                </Actions>
            </>
        );
    };

    return (
        <Card title={t('mfa_settings')}>
            <Container>{content()}</Container>
        </Card>
    );
};

export default MFASettings;
