import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../components/Buttons';
import { Routes } from '../../constants';
import { useTranslation } from '../../hooks/translationHook';
import ParcelsOverviewViewFetching from './ParcelsOverviewFetching';

interface ParcelsOverviewViewProps {
    companyId: string;
    canCreateParcelLabel?: boolean;
}

const ParcelOverviewView: React.FC<ParcelsOverviewViewProps> = ({ companyId, canCreateParcelLabel }) => {
    const { t } = useTranslation('parcels_overview');
    const history = useHistory();

    const actions = (
        <PrimaryButton
            icon={<PlusOutlined />}
            aria-label="create parcel button"
            onClick={() => history.push(Routes.CREATE_PARCEL)}
        >
            {t('action_add')}
        </PrimaryButton>
    );

    return <ParcelsOverviewViewFetching companyId={companyId} actions={canCreateParcelLabel && actions} />;
};

export default ParcelOverviewView;
