import { ApplicationModule, UserRole } from '../constant';

export interface Requirements {
    authorizedRoles: UserRole[];
    companyId?: string;
    departmentId?: string;
    modulesAccess?: ApplicationModule;
}

export interface Pagination {
    page: number;
    pageSize: number;
}

export interface Range {
    from: string;
    until: string;
}

export type PartialRecord<K extends keyof never, T> = Partial<Record<K, T>>;

export type ItemsWithPagination<T> = {
    items: Array<T>;
    pagination: {
        page: number;
        pageItems: number;
        totalItems: number;
    };
};
