import Axios, { AxiosInstance } from 'axios';
import { createContext, useMemo } from 'react';
import { environment } from '../environments/environment';
import { useAuth } from './auth-context';

export const AxiosContext = createContext<[AxiosInstance, AxiosInstance]>(undefined);

const baseConfig = {
    timeout: 15000,
    headers: {
        'Content-Type': 'application/json',
    },
};

export const AxiosProvider: React.FC = ({ children }) => {
    const { retrieveAccessToken } = useAuth();

    const axios = useMemo(() => {
        const axios = Axios.create({ baseURL: environment.apiBaseURL, ...baseConfig });

        axios.interceptors.request.use(async (config) => {
            const token = await retrieveAccessToken();
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        });
        return axios;
    }, []);

    const axiosWithoutInterceptor = useMemo(() => {
        const axios = Axios.create(baseConfig);
        return axios;
    }, []);

    return <AxiosContext.Provider value={[axios, axiosWithoutInterceptor]}>{children}</AxiosContext.Provider>;
};
