import { AlertFilled, CheckCircleFilled, FireFilled, Loading3QuartersOutlined } from '@ant-design/icons';
import { JobStatus, SendingEventBusinessStatus } from '@eservices/shared/constant';
import { Colors } from '@eservices/ui-constants/colors';
import dashboardActionRequiredPicture from '../../../assets/images/dashboard_action_required.svg';
import dashboardErrorsPicture from '../../../assets/images/dashboard_errors.svg';
import dashboardProcessingPicture from '../../../assets/images/dashboard_processing.svg';
import dashboardSuccessPicture from '../../../assets/images/dashboard_success.svg';
interface StatusTileBase {
    icon: React.ReactNode;
    visual: string;
    color: Colors;
}

interface JobStatusTile extends StatusTileBase {
    status: JobStatus;
}
interface SendingEventStatusTile extends StatusTileBase {
    status: SendingEventBusinessStatus;
}

//BASE
const successTileBase: StatusTileBase = {
    icon: <CheckCircleFilled />,
    visual: dashboardSuccessPicture,
    color: Colors.OK,
};
const processingTileBase: StatusTileBase = {
    icon: <Loading3QuartersOutlined />,
    visual: dashboardProcessingPicture,
    color: Colors.PROCESSING,
};
const actionRequiredTileBase: StatusTileBase = {
    icon: <AlertFilled />,
    visual: dashboardActionRequiredPicture,
    color: Colors.ACTION_REQUIRED,
};
const errorTileBase: StatusTileBase = {
    icon: <FireFilled />,
    visual: dashboardErrorsPicture,
    color: Colors.ERROR,
};

//JOBS
export const JobSuccessTile: JobStatusTile = {
    ...successTileBase,
    status: JobStatus.OK,
};
export const JobProcessingTile: JobStatusTile = {
    ...processingTileBase,
    status: JobStatus.PROCESSING,
};
export const JobActionRequired: JobStatusTile = {
    ...actionRequiredTileBase,
    status: JobStatus.ACTION_REQUIRED,
};
export const JobErrorTile: JobStatusTile = {
    ...errorTileBase,
    status: JobStatus.ERROR,
};

//SENDINGS
export const SendingEventSuccessTile: SendingEventStatusTile = {
    ...successTileBase,
    status: SendingEventBusinessStatus.SUCCESS,
};
export const SendingEventProcessingTile: SendingEventStatusTile = {
    ...processingTileBase,
    status: SendingEventBusinessStatus.IN_PROGRESS,
};
export const SendingEventErrorTile: SendingEventStatusTile = {
    ...errorTileBase,
    status: SendingEventBusinessStatus.FAILURE,
};
