import { SendOutlined } from '@ant-design/icons';
import { ApplicationModule, UserRole } from '@eservices/shared/constant';
import { Routes } from '../../constants';
import { BasicRoute, MainRoute } from '../../types';
import SendingEventsOverviewView from './SendingEventsOverviewView';

const viewSendingEventsOverviewPage = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.SENDING_EVENTS,
    component: <SendingEventsOverviewView companyId={companyId} />,
});

export const createSendingEventsRouter = (userRole: UserRole, companyId: string): MainRoute => {
    const sendingEventRoutes = {
        [UserRole.SUPER_USER]: [viewSendingEventsOverviewPage(companyId)],
        [UserRole.CLIENT_ADMIN]: [viewSendingEventsOverviewPage(companyId)],
        [UserRole.CLIENT_DEPARTMENT_ADMIN]: [viewSendingEventsOverviewPage(companyId)],
        [UserRole.CLIENT_OPERATOR]: [viewSendingEventsOverviewPage(companyId)],
        [UserRole.CLIENT_VIEWER]: [viewSendingEventsOverviewPage(companyId)],
    };

    return {
        path: Routes.SENDING_EVENTS,
        title: 'Sendings',
        icon: <SendOutlined />,
        subRoutes: sendingEventRoutes[userRole],
        module: ApplicationModule.EASYPOST_CONNECT,
    };
};
