import { useGetFileUrl, useGetUploadUrl, usePutFile } from './apiHooks';

const memeType = {
    json: 'application/json',
    html: 'text/html',
};

export function useUploadBlob(companyId: string) {
    const { mutateAsync: getUploadUrlMutationAsync } = useGetUploadUrl(companyId);
    const { mutateAsync: uploadMutationAsync } = usePutFile();
    const { mutateAsync: getDownloadUrlMutationAsync } = useGetFileUrl(companyId);

    const uploadBlob = async (filename: string, blobData: string) => {
        const [_name, extension] = filename.split('.');
        const blob = new Blob([blobData], { type: memeType[extension] });

        const fileUploadLink = await getUploadUrlMutationAsync(filename);
        await uploadMutationAsync({ url: fileUploadLink.url, file: new File([blob], filename) });
        const downloadUrl = await getDownloadUrlMutationAsync(fileUploadLink.filename);

        return { filename: fileUploadLink.filename, url: downloadUrl };
    };

    return { uploadBlob };
}
