import { Tag as AntTag } from 'antd';
import styled from 'styled-components';

const borderRadius = '7px';

interface TagProps {
    textcolor?: string;
}

export const Tag = styled(AntTag)<TagProps>`
    color: ${(props) => props.textcolor};
    border-color: ${(props) => props.textcolor};
    border-radius: ${borderRadius};
`;
