import { API } from '.';
import { ParcelCustomsShipmentType, ParcelType } from '../constant';

export interface RecipientWithParcelInfoBase extends API.ParcelRecipient {
    parcelName: string;
    bpostOptions: API.ParcelBpostOptions;
    hasError: boolean;
}

export interface RecipientWithParcelInfoWithCustoms extends RecipientWithParcelInfoBase {
    type: ParcelType.PARCEL_WITH_CUSTOMS;
    customsDetails: {
        shipmentType: ParcelCustomsShipmentType;
        contentDescription: string;
        contents: API.ParcelCustomsContentDetail[];
    };
}

export interface RecipientWithParcelInfoWithoutCustoms extends RecipientWithParcelInfoBase {
    type: ParcelType.PARCEL_WITHOUT_CUSTOMS;
}

export type RecipientWithParcelInfo = RecipientWithParcelInfoWithCustoms | RecipientWithParcelInfoWithoutCustoms;
