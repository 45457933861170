import { Fonts } from '../constants';
import styled from 'styled-components';
import { Colors } from '@eservices/ui-constants/colors';

export const Paragraph = styled.p`
    font-size: 14px;
    color: ${Colors.GRAY_SENARY};
`;

export const DescriptionParagraph = styled.p`
    font-family: ${Fonts.ARIAL_REGULAR};
    font-size: 14px;
    line-height: 21px;
    color: ${Colors.DARK_SECONDARY};
`;
