import { WorkflowInputSourceType } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { FormInstance } from 'antd';
import { WatermarkUI } from '../../types';
import { FormItem } from '../FormItem';
import PDFInputSourceConfiguration from './PDFInputSourceConfiguration';
import { WorkflowPDFInputSourceActions } from './PDFInputSourceConfiguration/IdentifiersManager/workflowPDFInputSourceReducer';

interface InputSourceConfigurationProps {
    isReadOnly: boolean;
    companyId: string;
    inputSourceType: WorkflowInputSourceType;
    tasks: Array<API.TaskConfiguration>;
    workflowPDFInputSource: API.WorkflowPDFInputSource;
    workflowPDFInputSourceDispatch: React.Dispatch<WorkflowPDFInputSourceActions>;
    setWatermark: React.Dispatch<React.SetStateAction<WatermarkUI>>;
    watermark: WatermarkUI;
    form: FormInstance;
    sampleFile: API.ConfigurationFile;
    setSampleFile: React.Dispatch<React.SetStateAction<API.ConfigurationFile>>;
}

const InputSourceConfiguration: React.FC<InputSourceConfigurationProps> = ({
    companyId,
    tasks,
    isReadOnly,
    inputSourceType,
    workflowPDFInputSource,
    workflowPDFInputSourceDispatch,
    setWatermark,
    watermark,
    form,
    sampleFile,
    setSampleFile,
}) => {
    let detail: React.ReactNode;

    switch (inputSourceType) {
        case WorkflowInputSourceType.PDF:
            detail = (
                <PDFInputSourceConfiguration
                    companyId={companyId}
                    isReadOnly={isReadOnly}
                    tasks={tasks}
                    workflowPDFInputSource={workflowPDFInputSource}
                    workflowPDFInputSourceDispatch={workflowPDFInputSourceDispatch}
                    setWatermark={setWatermark}
                    watermark={watermark}
                    form={form}
                    sampleFile={sampleFile}
                    setSampleFile={setSampleFile}
                />
            );
            break;
        default:
            detail = <div>Not Supported</div>;
            break;
    }

    return (
        <div>
            <FormItem name={['inputSource', 'type']} initialValue={WorkflowInputSourceType.PDF} hidden />
            {detail}
        </div>
    );
};

export default InputSourceConfiguration;
