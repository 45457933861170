import { EmailTaskStatus } from '@eservices/shared/common';

export type MailTags = { [key: string]: string[] };
export interface MailDetails {
    tags: MailTags;
}

export interface Bounce {
    bounceType: string;
    bounceSubType: string;
}

export interface Complaint {
    complaintSubType: string;
}
export interface Reject {
    reason: string;
}
export interface Failure {
    errorMessage: string;
}
// doc: https://docs.aws.amazon.com/ses/latest/dg/event-publishing-retrieving-sns-contents.html

export interface BaseStatusFeedback {
    eventType: EmailTaskStatus;
    mail: MailDetails;
    timestamp: string;
}

export type BounceFeedback = BaseStatusFeedback & {
    eventType: EmailTaskStatus.Bounce;
    bounce: Bounce;
};

export type ComplaintFeedback = BaseStatusFeedback & {
    eventType: EmailTaskStatus.Complaint;
    complaint: Complaint;
};

export type RejectFeedback = BaseStatusFeedback & {
    eventType: EmailTaskStatus.Reject;
    reject: Reject;
};

export type FailureFeedback = BaseStatusFeedback & {
    eventType: EmailTaskStatus.RenderingFailure;
    failure: Failure;
};

export type SendFeedBack = BaseStatusFeedback & {
    eventType: EmailTaskStatus.Send;
};

export type OpenFeedBack = BaseStatusFeedback & {
    eventType: EmailTaskStatus.Open;
};

export type ClickFeedBack = BaseStatusFeedback & {
    eventType: EmailTaskStatus.Click;
};

export type DeliveryDelayFeedBack = BaseStatusFeedback & {
    eventType: EmailTaskStatus.DeliveryDelay;
};

export type DeliveryFeedBack = BaseStatusFeedback & {
    eventType: EmailTaskStatus.Delivery;
};

export type EmailStatusFeedback =
    | BounceFeedback
    | ComplaintFeedback
    | RejectFeedback
    | FailureFeedback
    | OpenFeedBack
    | DeliveryDelayFeedBack
    | SendFeedBack
    | ClickFeedBack
    | DeliveryFeedBack;
