import { BookOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PrimaryButton } from '../../components/Buttons';
import FailedToLoadData from '../../components/FailedToLoadData';
import {
    initialState as pdfIdentifiersReducerInitialState,
    workflowPDFInputSourceReducer,
} from '../../components/workflow/PDFInputSourceConfiguration/IdentifiersManager/workflowPDFInputSourceReducer';
import { taskReducer } from '../../components/workflow/TaskManager/taskReducer';
import { Routes } from '../../constants';
import { useGetDepartment, useGetWorkflow, useUpdateWorkflow } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import { WatermarkUI } from '../../types';
import { translateFieldErrors } from '../../utils/helpers';
import { constructWorkflow } from './formHelper';
import WorkflowDetailFetching from './WorkflowDetailFetching';

const loadingMessageKey = 'loadingMessageKey';

interface WorkflowDetailEditProps {
    companyId: string;
}

const WorkflowDetailEdit: React.FC<WorkflowDetailEditProps> = ({ companyId }) => {
    const history = useHistory();
    const { t, tError, tCommon, tValidation } = useTranslation('workflow');
    const [form] = useForm();
    const [{ tasks }, tasksDispatch] = useReducer(taskReducer, { tasks: [] });
    const [workflowPDFInputSource, workflowPDFInputSourceDispatch] = useReducer(
        workflowPDFInputSourceReducer,
        pdfIdentifiersReducerInitialState,
    );
    const [watermark, setWatermark] = useState<WatermarkUI>();
    const [sampleFile, setSampleFile] = useState<API.ConfigurationFile>();
    const [errors, setErrors] = useState([]);
    const { id: workflowId, departmentId }: { id: string; departmentId: string } = useParams();
    const { data: departmentDetails, isError, isLoading } = useGetDepartment(companyId, departmentId);
    const query = useGetWorkflow(companyId, departmentId, workflowId);
    const submit = useUpdateWorkflow(companyId, workflowId);

    const onBack = () => {
        history.push(Routes.WORKFLOWS);
    };

    const onClickSaveWorkflow = async () => {
        const fields = await form.validateFields();
        const workflow = constructWorkflow(fields, tasks, workflowPDFInputSource, sampleFile, watermark, query.data);

        setErrors([]);
        void message.loading({ key: loadingMessageKey, content: t('editing_workflow_loading') }, 0);
        submit.mutate(workflow, {
            onSuccess: () => {
                history.push(Routes.WORKFLOWS);
                void message.success(t('editing_workflow_success', { name: form.getFieldValue('name') }), 3);
            },
            onError: (error) => {
                const errorResponse = error?.response;
                if (errorResponse) {
                    if (errorResponse.data?.fieldErrors) {
                        form.setFields(translateFieldErrors(tError, errorResponse.data.fieldErrors));
                    }
                    const error = errorResponse.data?.error;
                    if (error) {
                        setErrors([error]);
                    }
                    void message.error(t('editing_workflow_error'), 3);
                }
            },
            onSettled: () => {
                void message.destroy(loadingMessageKey);
            },
        });
    };

    const actions = (
        <PrimaryButton
            icon={<BookOutlined />}
            aria-label="save button"
            disabled={query.isLoading || submit.isLoading}
            htmlType="submit"
            onClick={onClickSaveWorkflow}
        >
            {tCommon('save')}
        </PrimaryButton>
    );

    if (isError) return <FailedToLoadData />;
    return (
        <WorkflowDetailFetching
            isReadOnly={false}
            companyId={companyId}
            departmentDetails={departmentDetails}
            departmentDetailsLoading={isLoading}
            query={query}
            form={form}
            tasks={tasks}
            tasksDispatch={tasksDispatch}
            workflowPDFInputSource={workflowPDFInputSource}
            workflowPDFInputSourceDispatch={workflowPDFInputSourceDispatch}
            watermark={watermark}
            setWatermark={setWatermark}
            errors={errors}
            actions={actions}
            onBack={onBack}
            sampleFile={sampleFile}
            setSampleFile={setSampleFile}
        />
    );
};

export default WorkflowDetailEdit;
