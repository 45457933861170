import { Colors } from '@eservices/ui-constants/colors';
import Avatar from 'antd/lib/avatar';
import React from 'react';
import styled from 'styled-components';
import flow from '../../../assets/images/flow.svg';
import { Fonts } from '../../../constants';
import { useTranslation } from '../../../hooks/translationHook';
import { Header3 } from '../../Headers';
import { Paragraph } from '../../Paragraph';

const IntroText = styled.div`
    text-align: center;
`;

const Image = styled.img`
    margin: 20px auto 24px;
    display: block;
    height: 104px;
`;

const Path = styled.div`
    margin: 15% 0 9% 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: ${Colors.GRAY_SECONDARY};
`;

const Number = styled(Avatar)`
    border: 1px solid ${Colors.GRAY_SECONDARY};
    background-color: ${Colors.WHITE};
    color: ${Colors.GRAY_SECONDARY};
    display: block;
`;

const PathItem = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
`;

const PathItemText = styled.div`
    font-family: ${Fonts.HURME1};
`;

interface TaskIntroProps {}

const TaskIntro: React.FC<TaskIntroProps> = () => {
    const { t } = useTranslation('workflow');

    return (
        <div>
            <IntroText>
                <Header3>{t('no_tasks_defined')}</Header3>
                <Paragraph>{t('no_tasks_defined_description')}</Paragraph>
                <Image alt="Simple workflow illustration" src={flow}></Image>
            </IntroText>
            <Path>
                <PathItem>
                    <Number>1</Number> <PathItemText>{t('no_tasks_defined_step_1')}</PathItemText>
                </PathItem>
                <PathItem>
                    <Number>2</Number> <PathItemText>{t('no_tasks_defined_step_2')}</PathItemText>
                </PathItem>
                <PathItem>
                    <Number>3</Number> <PathItemText>{t('no_tasks_defined_step_3')}</PathItemText>
                </PathItem>
            </Path>
        </div>
    );
};

export default TaskIntro;
