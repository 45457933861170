import { UserAddOutlined } from '@ant-design/icons';
import { ParcelCustomsShipmentType, ParcelType } from '@eservices/shared/constant';
import { ParcelRecipient, RecipientWithParcelInfo } from '@eservices/shared/type';
import { Alert, Button, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import { useBpostCountries } from '../../hooks/bpostCountriesHook';
import { useTranslation } from '../../hooks/translationHook';
import styled from 'styled-components';
import { FormItem } from '../FormItem';
import { Input, Select } from '../Input';
import { Modal } from '../Modal';

const AddressPart = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 0 5%;
`;

const StreetNumber = styled(FormItem)`
    width: 20%;
`;

const Box = styled(FormItem)`
    width: 20%;
`;

const Street = styled(FormItem)`
    width: 60%;
`;

const City = styled(FormItem)`
    width: 70%;
`;

const PostalCode = styled(FormItem)`
    width: 30%;
`;

const StyledSelect = styled(Select)`
    min-width: 220px !important;
`;

const defaultPostalCodeFormat = '9999';

const defaultFormValues: ParcelRecipient = {
    name: '',
    address: {
        city: '',
        postalCode: '',
        street: '',
        streetNr: '',
        box: '',
        countryCode: 'BE',
    },
};

interface AddContactProps {
    onSave: (recipient: RecipientWithParcelInfo) => void;
}

const AddContact: React.FC<AddContactProps> = ({ onSave }) => {
    const { t, tCommon, tValidation } = useTranslation('create_parcels');
    const [form] = useForm<ParcelRecipient>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { activeCountries, isLoadingCountries, findPostalCodeFormat, requiresCustoms } = useBpostCountries();
    const [countryCode, setCountryCode] = useState(defaultFormValues.address.countryCode);
    const [postalCodeFormat, setPostalCodeFormat] = useState(defaultPostalCodeFormat);

    const showModal = () => {
        form.resetFields();
        setIsModalVisible(true);
        setCountryCode(defaultFormValues.address.countryCode);
        setPostalCodeFormat(defaultPostalCodeFormat);
    };

    const handleOk = async () => {
        const contact = await form.validateFields();
        const type = requiresCustoms(contact.address.countryCode)
            ? ParcelType.PARCEL_WITH_CUSTOMS
            : ParcelType.PARCEL_WITHOUT_CUSTOMS;
        const defaultData = {
            ...contact,
            parcelName: t('parcel_for', { parcelName: contact.companyName || contact.name }),
            bpostOptions: {
                hasInsurance: false,
                hasSignature: false,
                needsMailAnnouncement: false,
            },
        };

        switch (type) {
            case ParcelType.PARCEL_WITH_CUSTOMS:
                onSave({
                    ...defaultData,
                    customsDetails: {
                        shipmentType: ParcelCustomsShipmentType.OTHER,
                        contentDescription: '',
                        contents: [],
                    },
                    type,
                    hasError: true,
                });
                break;
            case ParcelType.PARCEL_WITHOUT_CUSTOMS:
                onSave({
                    ...defaultData,
                    type,
                    hasError: false,
                });
                break;
        }
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onCountryChange = (value: string) => {
        setCountryCode(value);
        setPostalCodeFormat(findPostalCodeFormat(value));
    };

    const allCountryOptions = activeCountries.map((country) => ({
        label: country.name,
        value: country.code,
    }));

    return (
        <>
            <Button type="text" icon={<UserAddOutlined />} onClick={showModal}>
                {t('create_new_contact')}
            </Button>
            <Modal
                title={t('create-new-contact')}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={tCommon('save')}
                maskClosable={false}
                destroyOnClose
            >
                <Form
                    initialValues={defaultFormValues}
                    form={form}
                    layout="vertical"
                    name="recipient"
                    requiredMark={false}
                >
                    <FormItem
                        label={tCommon('company')}
                        name="companyName"
                        rules={[{ min: 3, message: tValidation('custom.min_3') }]}
                    >
                        <Input type="text" aria-label="company name input" />
                    </FormItem>
                    <FormItem
                        label={tCommon('name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.name'),
                            },
                            { min: 3, message: tValidation('custom.min_3') },
                        ]}
                    >
                        <Input type="text" aria-label="name input" minLength={1} maxLength={40} />
                    </FormItem>
                    <FormItem
                        label={tCommon('email')}
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.email'),
                            },
                            { type: 'email' },
                        ]}
                    >
                        <Input type="email" aria-label="mail input" />
                    </FormItem>
                    <AddressPart>
                        <Street
                            label={tCommon('street')}
                            name={['address', 'street']}
                            rules={[
                                {
                                    required: true,
                                    message: tValidation('required.street'),
                                },
                            ]}
                        >
                            <Input type="text" aria-label="street input" minLength={1} maxLength={40} />
                        </Street>
                        <StreetNumber
                            label={tCommon('number_short')}
                            name={['address', 'streetNr']}
                            rules={[
                                {
                                    required: true,
                                    message: tValidation('required.street_number'),
                                },
                            ]}
                        >
                            <Input type="text" aria-label="number input" minLength={1} maxLength={8} />
                        </StreetNumber>
                        <Box label={tCommon('box')} name={['address', 'box']}>
                            <Input type="text" aria-label="box input" minLength={1} maxLength={8} />
                        </Box>
                    </AddressPart>
                    <AddressPart>
                        <City
                            label={tCommon('city')}
                            name={['address', 'city']}
                            rules={[
                                {
                                    required: true,
                                    message: tValidation('required.city'),
                                },
                            ]}
                        >
                            <Input type="text" aria-label="city input" minLength={1} maxLength={40} />
                        </City>
                        <PostalCode
                            label={tCommon('postal_code')}
                            name={['address', 'postalCode']}
                            rules={[
                                {
                                    required: true,
                                    message: tValidation('required.postal_code'),
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                aria-label="postal code input"
                                placeholder={postalCodeFormat}
                                minLength={1}
                                maxLength={8}
                            />
                        </PostalCode>
                    </AddressPart>
                    <Form.Item
                        label={tCommon('country')}
                        name={['address', 'countryCode']}
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.country'),
                            },
                        ]}
                    >
                        <StyledSelect
                            options={allCountryOptions}
                            aria-label="country code"
                            filterOption
                            showSearch
                            loading={isLoadingCountries}
                            onChange={onCountryChange}
                        />
                    </Form.Item>
                </Form>
                {requiresCustoms(countryCode) && <Alert message={t('customs_message')} type="info" />}
            </Modal>
        </>
    );
};

export default AddContact;
