import { IgnoreIdentifierKind } from '@eservices/shared/constant';
import { Space } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as EmptySpaceIcon } from '../../../../../assets/images/empty_space.svg';
import { ReactComponent as RegexIcon } from '../../../../../assets/images/regex.svg';
import { ReactComponent as TextIcon } from '../../../../../assets/images/text.svg';
import { useTranslation } from '../../../../../hooks/translationHook';
import { Header3 } from '../../../../Headers';
import IgnoreChoice from './IgnoreChoice';

const Choices = styled.div`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-evenly;
`;

const Container = styled.div`
    text-align: center;
`;

interface IgnoreChoicesProps {
    isReadOnly: boolean;
    onIgnoreChoiceSelected: (kind: IgnoreIdentifierKind) => void;
}

const IgnoreChoices: React.FC<IgnoreChoicesProps> = ({ isReadOnly, onIgnoreChoiceSelected }) => {
    const { t } = useTranslation('workflow');

    return (
        <Container>
            <Space direction="vertical">
                <Header3>{t('choose_ignore_option')}</Header3>
                <Choices>
                    <IgnoreChoice
                        isReadOnly={isReadOnly}
                        onClick={() => onIgnoreChoiceSelected(IgnoreIdentifierKind.TEXT)}
                        icon={TextIcon}
                        description={t('markdown_ignore_on_text')}
                    />
                    <IgnoreChoice
                        isReadOnly={isReadOnly}
                        onClick={() => onIgnoreChoiceSelected(IgnoreIdentifierKind.REGEX)}
                        icon={RegexIcon}
                        description={t('markdown_ignore_on_regex')}
                    />
                    <IgnoreChoice
                        isReadOnly={isReadOnly}
                        onClick={() => onIgnoreChoiceSelected(IgnoreIdentifierKind.EMPTY_TEXT)}
                        icon={EmptySpaceIcon}
                        description={t('markdown_ignore_on_empty')}
                    />
                </Choices>
            </Space>
        </Container>
    );
};

export default IgnoreChoices;
