import { RecipientWithParcelInfo } from '@eservices/shared/type';
import { useState } from 'react';
import { useTranslation } from '../../hooks/translationHook';
import { RecipientsActions } from '../../screens/parcels/recipientReducer';
import { Header2 } from '../Headers';
import InputChoice from './InputChoice';
import ManualInput from './ManualInput';

export enum InputType {
    MANUAL = 'MANUAL',
    FILE = 'FILE',
}

interface RecipientsProps {
    companyId: string;
    recipients: Array<RecipientWithParcelInfo>;
    recipientsDispatch: React.Dispatch<RecipientsActions>;
    setUploadedFileKey: (uploadedFileKey: string) => void;
    uploadedFileKey: string;
}

const Recipients: React.FC<RecipientsProps> = ({
    companyId,
    recipients,
    recipientsDispatch,
    setUploadedFileKey,
    uploadedFileKey,
}) => {
    const { t, tCommon } = useTranslation('create_parcels');
    const [inputType, setInputType] = useState<InputType>();

    const content = () => {
        switch (inputType) {
            case InputType.MANUAL:
                return (
                    <ManualInput
                        companyId={companyId}
                        recipients={recipients}
                        recipientsDispatch={recipientsDispatch}
                    />
                );
            default:
                return (
                    <InputChoice
                        companyId={companyId}
                        setUploadedFileKey={setUploadedFileKey}
                        uploadedFileKey={uploadedFileKey}
                        onChangeInput={setInputType}
                    />
                );
        }
    };

    return (
        <>
            <Header2>{tCommon('recipients')}</Header2>
            {content()}
        </>
    );
};

export default Recipients;
