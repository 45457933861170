import { LoadingOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { Space, Timeline } from 'antd';
import { useTranslation } from '../../../hooks/translationHook';
import { toReadableDateTime } from '../../../utils/helpers';

interface EmailProgressTaskProps {
    task: API.EmailTaskDetails;
    taskConfiguration: API.TaskEmail;
}

const EmailTaskProgress: React.FC<EmailProgressTaskProps> = ({ task }) => {
    const { t, tCommon } = useTranslation('email_task');

    const [send, delivery, open, click, bounce, complaint, reject, renderingFailure, deliveryDelay] =
        task.statusHistory;

    const failureStates = [bounce, complaint, reject, renderingFailure, deliveryDelay];
    const happyPath = !failureStates.some((failureState) => Boolean(failureState.date));

    const getColor = (endDate: string): string => {
        if (endDate) return 'green';
        else return 'gray';
    };

    const showLoadingIcon = (endDate: string, previousTaskEndDate: string): boolean => {
        if (endDate || task.endDate) return false;
        if (previousTaskEndDate) return true;
    };

    return (
        <Timeline>
            <Timeline.Item color={getColor(send.date)}>
                <Space>
                    {t(send.value.toLocaleLowerCase())} {send.date && toReadableDateTime(send.date)}
                </Space>
            </Timeline.Item>
            {happyPath ? (
                <>
                    <Timeline.Item
                        color={getColor(delivery.date)}
                        dot={showLoadingIcon(delivery.date, delivery.date) && <LoadingOutlined spin />}
                    >
                        <Space>
                            {t(delivery.value.toLocaleLowerCase())} {delivery.date && toReadableDateTime(delivery.date)}
                        </Space>
                    </Timeline.Item>
                    <Timeline.Item
                        color={getColor(open.date)}
                        dot={showLoadingIcon(open.date, open.date) && <LoadingOutlined spin />}
                    >
                        <Space>
                            {t(open.value.toLocaleLowerCase())} {open.date && toReadableDateTime(open.date)}
                        </Space>
                    </Timeline.Item>
                </>
            ) : (
                <>
                    {failureStates
                        .filter((failureState) => Boolean(failureState.date))
                        .map((failureState) => {
                            return (
                                <Timeline.Item color={'red'}>
                                    <Space>
                                        {t(failureState.value.toLocaleLowerCase())}
                                        {failureState.date && toReadableDateTime(failureState.date)}
                                    </Space>
                                </Timeline.Item>
                            );
                        })}
                </>
            )}
        </Timeline>
    );
};

export default EmailTaskProgress;
