import { CopyOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { Badge, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import Table from '../../components/Table';
import { Routes } from '../../constants';
import { useGetDepartments } from '../../hooks/apiHooks';
import { getPaginationDetails } from '../../utils/helpers';
import { useTranslation } from '../../hooks/translationHook';
import { IconButton } from '../Buttons';
import ChannelIndicator from '../ChannelIndicator';
import TriggerIndicator from '../TriggerIndicator';
import WorkflowTypeIndicator from '../WorkflowTypeIndicator';
import CloneWorkflowModal from './CloneWorkflowModal';
import { Colors } from '@eservices/ui-constants/colors';

interface WorkflowsTableProps {
    companyId: string;
    workflows: API.WorkflowConfigurationListItem[];
    isLoading: boolean;
    isReadOnly?: boolean;
    isCloning?: boolean;
    cloneWorkflow?: (departmentId: string, workflowId: string, targetDepartmentId: string) => void;
}

const WorkflowsTable: React.FC<WorkflowsTableProps> = ({
    companyId,
    workflows = [],
    isLoading,
    isReadOnly,
    isCloning,
    cloneWorkflow,
}) => {
    const { t, tCommon } = useTranslation('workflow_overview');
    const [modalVisible, setModalVisible] = useState(false);
    const [workflowIdToClone, setWorkflowIdToClone] = useState<string>();
    const [departmentIdToClone, setDepartmentIdToClone] = useState<string>();
    const { isLoading: isLoadingDepartments, data: departments = [] } = useGetDepartments(companyId);

    const paginationConfig = getPaginationDetails(workflows.length, {});

    const showCloneWorkflowModal = (workflowId: string, departmentId: string) => {
        setWorkflowIdToClone(workflowId);
        setDepartmentIdToClone(departmentId);
        setModalVisible(true);
    };

    const columns: ColumnsType<API.WorkflowConfigurationListItem> = [
        {
            title: tCommon('name'),
            dataIndex: 'name',
        },
        {
            title: tCommon('department'),
            render: (record: API.WorkflowConfigurationListItem) =>
                departments.find((department) => department.id === record.departmentId)?.name,
        },
        {
            title: tCommon('type'),
            align: 'right',
            dataIndex: 'type',
            width: 50,
            render: (type) => <WorkflowTypeIndicator type={type} />,
        },
        {
            title: tCommon('trigger'),
            align: 'center',
            dataIndex: 'trigger',
            render: (trigger) => <TriggerIndicator type={trigger} />,
        },
        {
            title: tCommon('tasks'),
            align: 'right',
            dataIndex: 'tasks',
            width: 30,
            render: (tasks: API.TaskConfigurationListItem[]) => {
                return (
                    <Space>
                        {tasks.map((task) => (
                            <ChannelIndicator key={task.id} type={task.taskType} />
                        ))}
                    </Space>
                );
            },
        },
        {
            title: tCommon('enabled'),
            align: 'center',
            dataIndex: 'enabled',
            width: 120,
            render: (enabled) => (
                <Badge status={enabled ? 'processing' : 'warning'} color={enabled ? Colors.GOOD : Colors.NEUTRAL} />
            ),
        },
        {
            title: tCommon('validated'),
            align: 'center',
            dataIndex: 'validated',
            width: 10,
            render: (valid) => <Badge color={valid ? Colors.GOOD : Colors.BAD} />,
        },
    ];

    if (isReadOnly) {
        columns.push({
            width: 10,
            align: 'right',
            render: ({ id, departmentId }: API.WorkflowConfigurationListItem) => (
                <IconButton
                    icon={<EyeOutlined />}
                    href={Routes.VIEW_WORKFLOW.replace(':departmentId', departmentId).replace(':id', id)}
                />
            ),
        });
    } else {
        columns.push({
            width: 10,
            align: 'right',
            render: ({ id, departmentId }: API.WorkflowConfigurationListItem) => (
                <Space>
                    <IconButton
                        icon={<EditOutlined />}
                        href={Routes.EDIT_WORKFLOW.replace(':departmentId', departmentId).replace(':id', id)}
                    />
                    <IconButton icon={<CopyOutlined />} onClick={() => showCloneWorkflowModal(id, departmentId)} />
                </Space>
            ),
        });
    }

    return (
        <>
            <Table
                loading={isLoading}
                rowKey="id"
                columns={columns}
                dataSource={workflows}
                pagination={paginationConfig}
            />
            {modalVisible && (
                <CloneWorkflowModal
                    departmentId={departmentIdToClone}
                    workflowId={workflowIdToClone}
                    departments={departments}
                    isLoading={isLoadingDepartments}
                    disableSaveButton={isCloning}
                    onSave={cloneWorkflow}
                    onCancel={() => setModalVisible(false)}
                />
            )}
        </>
    );
};

export default WorkflowsTable;
