import PoliciesFetching from './PoliciesFetching';

interface PoliciesViewProps {
    companyId: string;
    isEditable: boolean;
}

const PoliciesView: React.FC<PoliciesViewProps> = ({ companyId, isEditable }) => {
    return <PoliciesFetching companyId={companyId} isEditable={isEditable} />;
};

export default PoliciesView;
