import Icon from '@ant-design/icons';
import { Colors } from '@eservices/ui-constants/colors';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { ReactComponent as MeasureIcon } from '../../../assets/images/measure.svg';
import { Fonts } from '../../../constants';
import { useTranslation } from '../../../hooks/translationHook';

const Overlay = styled.div`
    position: absolute;
    background: black;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    z-index: 99999;
`;

const Content = styled.div`
    color: white;
    z-index: 999999;
    text-align: center;
    width: 80%;
    margin: 20% auto 0 auto;
`;

const Title = styled.div`
    font-family: ${Fonts.HURME4_SEMI_BOLD};
    font-size: 25px;
    color: ${Colors.WHITE};
    margin-bottom: 2%;
    word-wrap: break-word;
`;

const StyledIcon = styled(Icon)`
    font-size: 150px;
    margin: 20% 0;
`;

interface DrawingOverlayProps {}

const DrawingOverlay: React.FC<DrawingOverlayProps> = ({}) => {
    const { t } = useTranslation('task');

    return (
        <Overlay>
            <Content>
                <Title>{t(`start_draw_title`)}</Title>
                <ReactMarkdown>{t(`start_draw_subtitle`)}</ReactMarkdown>
                <StyledIcon component={MeasureIcon} />
            </Content>
        </Overlay>
    );
};

export default DrawingOverlay;
