/**
 * Remove all non alphanumerical characters and takes the non accented version of the characters (accented or diachritic chars)
 * Makes use of ES6 decomposition of Unicode characters from String method normalize
 * cfr https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
 * @param input string to normalize
 * @returns
 */
export const normalizeString = (input: string): string => {
    /*
     * Decompose Unicode characters
     *  and then expurge all the non alphanumeric characters (with the \W word matcher + exclude underscore)
     */
    return input.normalize('NFKD').replace(/[^a-zA-Z0-9]/g, '');
};
