import { Link, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import BackgroundLogo from './assets/images/background-login.svg';
import Logo from './assets/images/login-logo.svg';
import { Routes } from './constants';
import CompleteNewPasswordForm from './screens/login/CompleteNewPasswordForm';
import ConfirmMFACodeForm from './screens/login/ConfirmMFACodeForm';
import ForgotPasswordForm from './screens/login/ForgotPasswordForm';
import ForgotPasswordSubmitForm from './screens/login/ForgotPasswordSubmitForm';
import SignInForm from './screens/login/SignInForm';

const StyledLogo = styled.div`
    position: absolute;
    left: 60px;
    top: 60px;
`;

const Container = styled.div`
    background: url('${BackgroundLogo}') no-repeat top left;
    background-size: cover;
    background-position: right 60px;
    background-repeat: no-repeat;
    height: 100vh;
`;

const UnauthenticatedApp = () => (
    <Container>
        <StyledLogo>
            <Link aria-label="logo link" to={Routes.ROOT}>
                <img alt="logo" src={Logo} />
            </Link>
        </StyledLogo>
        <Switch>
            <Route path={Routes.CONFIRM_PASSWORD}>
                <CompleteNewPasswordForm />
            </Route>
            <Route path={Routes.CONFIRM_MFA_CODE}>
                <ConfirmMFACodeForm />
            </Route>
            <Route path={Routes.FORGOT_PASSWORD_FORM}>
                <ForgotPasswordForm />
            </Route>
            <Route path={Routes.FORGOT_PASSWORD_SUBMIT_FORM}>
                <ForgotPasswordSubmitForm />
            </Route>
            <Route path="*">
                <SignInForm />
            </Route>
        </Switch>
    </Container>
);

export default UnauthenticatedApp;
