import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { QRCodeSVG } from 'qrcode.react';
import styled from 'styled-components';
import { useTranslation } from '../../hooks/translationHook';
import { FormItem } from '../FormItem';
import PinCode from '../login/PinCode';

const QRCodeBox = styled.div`
    margin: auto;
`;

const Paragraph = styled.p`
    overflow-wrap: anywhere;
`;

interface SetupTOTPFormProps {
    secretKey: string;
    form: FormInstance;
    onCodeFilled?: () => void;
}

const SetupTOTPForm: React.FC<SetupTOTPFormProps> = ({ form, secretKey, onCodeFilled }) => {
    const { t, tValidation } = useTranslation('setup_totp');
    const qrCodeValue = `otpauth://totp/EasyPost.Connect?secret=${secretKey}`;

    return (
        <>
            <QRCodeBox>
                <QRCodeSVG value={qrCodeValue} />
            </QRCodeBox>
            <Paragraph>
                {t('software_totp_secret_key', {
                    secretKey,
                })}
            </Paragraph>
            <Form form={form} layout="vertical" name="softwareTotp" requiredMark={false}>
                <FormItem
                    label={t('confirmation_code_totp_application')}
                    name={'confirmationCode'}
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.confirmation_code'),
                        },
                        {
                            pattern: new RegExp('^[0-9]{6}$'),
                            message: tValidation('format.confirmation_code'),
                            validateTrigger: 'onClick',
                        },
                    ]}
                >
                    <PinCode length={6} onCodeFilled={onCodeFilled} aria-label="totp confirmation code input" />
                </FormItem>
            </Form>
        </>
    );
};

export default SetupTOTPForm;
