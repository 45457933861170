import { DownOutlined } from '@ant-design/icons';
import { UserRole } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Colors } from '@eservices/ui-constants/colors';
import { Badge, Dropdown, Menu } from 'antd';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Company } from '../AuthenticatedApp';
import { Fonts, Routes } from '../constants';
import { useAuth } from '../context/auth-context';
import { useTranslation } from '../hooks/translationHook';
import UserAvatar from './UserAvatar';

const UserDetails = styled.div`
    grid-area: userDetails;
    text-align: right;
    margin-right: 5%;
`;

const Name = styled.span`
    margin-left: 18px;
`;

const Info = styled.div`
    height: 31px;
    display: inline-block;
    margin-right: 15px;
    padding: 5px 15px;
    border-right: 1px solid ${Colors.GRAY_QUATERNARY};
    font-family: ${Fonts.HURME4};
    font-size: 14px;
    letter-spacing: 1.33px;
    color: ${Colors.GRAY_PRIMARY};
    text-transform: UPPERCASE;
`;

const Profile = styled.div`
    display: inline-block;
    cursor: pointer;
`;

const DetailsIcon = styled(DownOutlined)`
    font-size: 10px;
    margin-left: 5px;
`;

interface HeaderProps {
    user: API.User;
    companyName?: string;
    onChangeCompany: React.Dispatch<React.SetStateAction<Company>>;
}

const Header: React.FC<HeaderProps> = ({ user, companyName, onChangeCompany }) => {
    const history = useHistory();
    const { role, firstName, lastName } = user;
    const { t, tCommon } = useTranslation('header');
    const { signOut } = useAuth();

    const menu = (
        <Menu>
            <Menu.Item key="0">
                <Link to={Routes.PROFILE}>{t('your_profile')}</Link>
            </Menu.Item>
            {role === UserRole.SUPER_USER && (
                <Menu.Item key="00" onClick={() => onChangeCompany(undefined)}>
                    {t('change_company')}
                </Menu.Item>
            )}
            <Menu.Divider />
            <Menu.Item key="1">{t('help')}</Menu.Item>
            <Menu.Divider />
            <Menu.Item
                key="3"
                onClick={async () => {
                    await signOut();
                    history.push(Routes.ROOT);
                }}
            >
                {t('sign Out')}
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <UserDetails>
                {companyName && <Info aria-label="user role">{companyName}</Info>}
                <Info aria-label="user role">{tCommon(role)}</Info>
                <Dropdown overlay={menu} placement="bottomRight" arrow trigger={['click']}>
                    <Profile>
                        <Badge aria-label="badge count" size="small">
                            <UserAvatar withTooltip={false} user={user} />
                        </Badge>
                        <Name aria-label="name">
                            {firstName} {lastName}
                            <DetailsIcon />
                        </Name>
                    </Profile>
                </Dropdown>
            </UserDetails>
        </>
    );
};

export default Header;
