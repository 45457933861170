import InfoCircleOutlined from '@ant-design/icons/lib/icons/InfoCircleOutlined';
import { Colors } from '@eservices/ui-constants/colors';
import { InputNumber, Popover, Skeleton, Tabs } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React from 'react';
import styled from 'styled-components';
import { useGetPostalProductsCatalog } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import FailedToLoadData from '../FailedToLoadData';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
    overflow: visible !important;
`;

const StyledTabPane = styled(TabPane)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    .ant-form-item-label {
        overflow: visible !important;
    }
`;

const StyledFormItem = styled(FormItem)`
    margin-bottom: 15px;
`;

const ProductName = styled.div`
    span {
        margin-right: 5px;
    }
    .anticon {
        transition: 0.3s ease-in-out;
        &:hover {
            color: ${Colors.PRIMARY};
        }
    }
`;

interface AdvancedAnnoucementProps {
    maxInput: number;
    companyId: string;
}

const AdvancedAnnoucement: React.FC<AdvancedAnnoucementProps> = ({ maxInput, companyId }) => {
    const { t } = useTranslation('daily_announcements');
    const { data: postalProductsCatalog, isLoading, isError } = useGetPostalProductsCatalog(companyId);

    const createFormItems = (inputs, category) => {
        return inputs.map((productCode, index) => {
            return (
                <React.Fragment key={productCode}>
                    <FormItem name={[category, index, 'productCode']} initialValue={productCode} hidden />
                    <StyledFormItem
                        name={[category, index, 'quantity']}
                        label={
                            <ProductName>
                                <span>{productCode}</span>
                                <Popover
                                    placement="rightTop"
                                    overlayStyle={{
                                        width: '200px',
                                    }}
                                    content={t(`${category}_${productCode}_description`)}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            </ProductName>
                        }
                        initialValue={0}
                        labelAlign="left"
                    >
                        <InputNumber min={0} max={maxInput} />
                    </StyledFormItem>
                </React.Fragment>
            );
        });
    };

    if (isLoading) return <Skeleton active />;
    if (isError) return <FailedToLoadData />;
    return (
        <>
            <StyledTabs defaultActiveKey="Prior">
                <StyledTabPane forceRender={true} tab={t('post_type_prior')} key="Prior">
                    {createFormItems(postalProductsCatalog.Prior, 'Prior')}
                </StyledTabPane>
                <StyledTabPane forceRender={true} tab={t('post_type_non_prior')} key="NonPrior">
                    {createFormItems(postalProductsCatalog.NonPrior, 'NonPrior')}
                </StyledTabPane>
                <StyledTabPane forceRender={true} tab={t('post_type_registered')} key="Registered">
                    {createFormItems(postalProductsCatalog.Registered, 'Registered')}
                </StyledTabPane>
                <StyledTabPane forceRender={true} tab={t('post_type_parcel')} key="Parcels">
                    {createFormItems(postalProductsCatalog.Parcels, 'Parcels')}
                </StyledTabPane>
            </StyledTabs>
        </>
    );
};

export default AdvancedAnnoucement;
