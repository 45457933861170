import { API } from '@eservices/shared/type';
import { Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from '../../hooks/translationHook';
import ChannelIndicator from '../ChannelIndicator';
import Table from '../Table';
import TriggerIndicator from '../TriggerIndicator';
import WorkflowTypeIndicator from '../WorkflowTypeIndicator';

interface WorkflowTableProps {
    workflows: API.WorkflowConfigurationListItem[];
    departments: API.DepartmentListItem[];
    isLoading: boolean;
    selectedWorkflow: API.WorkflowConfigurationListItem;
    setSelectedWorkflow: React.Dispatch<React.SetStateAction<API.WorkflowConfigurationListItem>>;
}

const WorkflowsTable: React.FC<WorkflowTableProps> = ({
    workflows = [],
    departments = [],
    isLoading,
    selectedWorkflow,
    setSelectedWorkflow,
}) => {
    const { t, tCommon } = useTranslation('job');

    const columns: ColumnsType<API.WorkflowConfiguration> = [
        {
            title: tCommon('name'),
            dataIndex: 'name',
        },
        {
            title: tCommon('department'),
            render: (record: API.WorkflowConfigurationListItem) =>
                departments.find((department) => department.id === record.departmentId)?.name,
        },
        {
            title: tCommon('type'),
            align: 'right',
            dataIndex: 'type',
            render: (type) => <WorkflowTypeIndicator type={type} />,
        },
        {
            title: tCommon('trigger'),
            align: 'center',
            dataIndex: 'trigger',
            width: 10,
            render: (trigger) => <TriggerIndicator type={trigger} />,
        },
        {
            title: t('channels'),
            align: 'right',
            width: 10,
            render: (record: API.WorkflowConfiguration) => (
                <Space>
                    {record.tasks.map((task) => (
                        <ChannelIndicator key={task.id} type={task.taskType} />
                    ))}
                </Space>
            ),
        },
    ];

    return (
        <Table
            loading={isLoading}
            rowKey="id"
            columns={columns}
            dataSource={workflows}
            pagination={false}
            rowSelection={{
                columnWidth: 0,
                renderCell: () => '',
                type: 'radio',
                selectedRowKeys: [selectedWorkflow?.id],
            }}
            onRow={(record: API.WorkflowConfigurationListItem) => ({
                onClick: () => setSelectedWorkflow(record),
            })}
        />
    );
};

export default WorkflowsTable;
