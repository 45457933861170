import { LineChartOutlined } from '@ant-design/icons';
import { ApplicationModule, UserRole } from '@eservices/shared/constant';
import { Routes } from '../../constants';
import { BasicRoute, MainRoute } from '../../types';
import DashboardOperatorView from './DashboardOperatorView';

const viewDashboardOperator = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.DASHBOARD,
    component: <DashboardOperatorView companyId={companyId} />,
});

export const createDashboardOperatorRouter = (userRole: UserRole, companyId: string): MainRoute => {
    const dashboardOperatorRoutes = {
        [UserRole.SUPER_USER]: [viewDashboardOperator(companyId)],
        [UserRole.CLIENT_ADMIN]: [viewDashboardOperator(companyId)],
        [UserRole.CLIENT_DEPARTMENT_ADMIN]: [viewDashboardOperator(companyId)],
        [UserRole.CLIENT_OPERATOR]: [viewDashboardOperator(companyId)],
        [UserRole.CLIENT_VIEWER]: [viewDashboardOperator(companyId)],
    };

    return {
        path: Routes.DASHBOARD,
        title: 'dashboard',
        icon: <LineChartOutlined />,
        subRoutes: dashboardOperatorRoutes[userRole],
        module: ApplicationModule.EASYPOST_CONNECT,
    };
};
