import { ClearOutlined, CloseOutlined, SendOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { message, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';
import { Header3 } from '../../components/Headers';
import JobUpload from '../../components/job/JobUpload';
import WorkflowsTable from '../../components/job/WorkflowsTable';
import { Routes } from '../../constants';
import { useGetDepartments, useGetWorkflows, useSubmitJob } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import ResourceDetailLayout from '../../layouts/ResourceDetailLayout';
import styled from 'styled-components';
import Card from '../../components/Card';

const Container = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
`;

const Table = styled(Card)`
    min-width: 400px;
    max-width: 900px;
    width: 55%;
`;

const Upload = styled(Card)`
    width: 400px;
`;

export interface JobSubmit {
    jobName: string;
    filename: string;
    originalFilename: string;
}

interface JobSubmissionProps {
    companyId: string;
}

const JobSubmission: React.FC<JobSubmissionProps> = ({ companyId }) => {
    const history = useHistory();
    const { t, tCommon } = useTranslation('job');
    const [form] = useForm();
    const { isLoading, data: workflows = [] } = useGetWorkflows(companyId);
    const { data: departments = [] } = useGetDepartments(companyId);

    const [isUploading, setIsUploading] = useState(false);
    const [availableWorkflows, setAvailableWorkflows] = useState<Array<API.WorkflowConfigurationListItem>>([]);
    const [selectedWorkflow, setSelectedWorkflow] = useState<API.WorkflowConfigurationListItem>(undefined);
    const mutation = useSubmitJob(companyId);

    useEffect(() => {
        const filteredWorkflows = workflows.filter((wf) => wf.enabled && wf.validated);
        if (filteredWorkflows.length > 0) {
            setAvailableWorkflows(filteredWorkflows);
            setSelectedWorkflow(filteredWorkflows[0]);
        }
    }, [JSON.stringify(workflows)]);

    const onClickBack = () => {
        history.goBack();
    };

    const onClickCancel = () => {
        form.resetFields();
    };

    useEffect(() => {
        if (mutation.isSuccess) {
            message.success(t('submit_job_success', { name: form.getFieldValue('name') }), 3);
            history.push(Routes.JOBS);
        }
    }, [mutation.isSuccess]);

    useEffect(() => {
        if (mutation.isError) {
            message.error(t('submit_job_error'), 3);
        }
    }, [mutation.isError]);

    const onClickSaveJob = async () => {
        const fields = await form.validateFields();

        mutation.mutate({
            workflowId: selectedWorkflow.id,
            departmentId: selectedWorkflow.departmentId,
            submitJob: { ...fields },
        });
    };

    const detail = (
        <Container>
            <Table title={t('submit_title')}>
                <WorkflowsTable
                    workflows={availableWorkflows}
                    departments={departments}
                    isLoading={isLoading}
                    selectedWorkflow={selectedWorkflow}
                    setSelectedWorkflow={setSelectedWorkflow}
                />
            </Table>
            <Upload>
                <Spin spinning={mutation.isLoading} tip={t('submitting')}>
                    <JobUpload
                        companyId={companyId}
                        form={form}
                        selectedWorkflow={selectedWorkflow}
                        onIsUploading={setIsUploading}
                    />
                </Spin>
            </Upload>
        </Container>
    );

    const actions = (
        <>
            <SecondaryButton aria-label="clear" icon={<ClearOutlined />} onClick={onClickCancel}>
                {tCommon('clear')}
            </SecondaryButton>
            <PrimaryButton
                disabled={mutation.isLoading || isUploading}
                icon={<SendOutlined />}
                aria-label="submit new job button"
                htmlType="submit"
                onClick={() => onClickSaveJob()}
            >
                {t('submit_job')}
            </PrimaryButton>
        </>
    );

    return (
        <ResourceDetailLayout title={t('create_job_title')} detail={detail} actions={actions} onBack={onClickBack} />
    );
};

export default JobSubmission;
