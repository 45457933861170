import { Modal as AntModal } from 'antd';
import styled from 'styled-components';

export const Modal = AntModal;

export const ModalSubtileForm = styled(Modal)`
    .ant-modal-body {
        padding: 24px 24px 10px 24px;
    }
`;
