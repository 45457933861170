import React, { ReactElement } from 'react';

interface ConditionalWrapProps {
    condition: boolean;
    wrap: (content: React.ReactNode) => ReactElement<unknown, any>;
    children: ReactElement<unknown, any>;
}

const ConditionalWrap: React.FC<ConditionalWrapProps> = ({ condition, wrap, children }) =>
    condition ? wrap(children) : <>{children}</>;

export default ConditionalWrap;
