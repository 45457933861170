import { API } from '@eservices/shared/type';
import { Input, Space } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../../../../hooks/translationHook';
import RegionButton from '../Common/RegionButton';

const StyledInput = styled(Input)`
    width: 150px;
    margin: 0 5px;
`;

interface RegexIgnoreProps {
    isReadOnly: boolean;
    order: number;
    identifier: API.PDFIgnoreIdentifier;
}

const RegexIgnore: React.FC<RegexIgnoreProps> = ({ isReadOnly, order, identifier }) => {
    const { t, tValidation } = useTranslation('workflow');

    return (
        <Space align="baseline">
            {t('ignore_overview_part1')}
            <RegionButton regionOfInterest={identifier.regionOfInterest} />
            <strong>{t('ignore_overview_regex_part2')}</strong>
            <FormItem
                name={[order, 'value']}
                rules={[
                    {
                        required: true,
                        message: tValidation('required.regex'),
                    },
                ]}
                noStyle
            >
                <StyledInput disabled={isReadOnly} placeholder={t('regex_placeholder')} />
            </FormItem>
        </Space>
    );
};

export default RegexIgnore;
