import { DeleteOutlined } from '@ant-design/icons';
import { Avatar, Tooltip } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Colors } from '@eservices/ui-constants/colors';
import { generateInitials } from '../utils/helpers';

interface AlertAvatarProps {
    onMouseLeave: () => void;
    onClick: () => void;
}

const AlertAvatar = styled(Avatar)<AlertAvatarProps>`
    border: 1px solid ${Colors.ALERT};
    color: ${Colors.ALERT};
    background-color: ${Colors.WHITE};
    cursor: pointer;
`;

interface StyledAvatarProps {
    color: string;
    onMouseEnter: () => void;
}

const StyledAvatar = styled(Avatar)<StyledAvatarProps>`
    background-color: ${Colors.WHITE};
    color: ${({ color }) => color};
    border: 1px solid ${Colors.GRAY_QUATERNARY} !important;
    cursor: pointer;
`;

interface UserIconProps {
    id: string | number;
    name: string;
    color: string;
    onRemove?: (id: string | number) => void;
}

const AvatarWithDelete: React.FC<UserIconProps> = ({ id, name, color, onRemove }) => {
    const [isHover, setIsHover] = useState(false);

    const handleHover = () => {
        setIsHover(true);
    };

    const handleHoverOut = () => {
        setIsHover(false);
    };

    const handleClose = () => {
        onRemove(id);
    };

    return (
        <Tooltip title={name}>
            {isHover ? (
                <AlertAvatar
                    icon={<DeleteOutlined />}
                    style={{ color: Colors.ALERT }}
                    onMouseLeave={handleHoverOut}
                    onClick={handleClose}
                />
            ) : (
                <StyledAvatar color={color} onMouseEnter={handleHover}>
                    {generateInitials(name)}
                </StyledAvatar>
            )}
        </Tooltip>
    );
};

export default AvatarWithDelete;
