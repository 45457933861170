import { PrimaryButton } from '../../components/Buttons';
import { useTranslation } from '../../hooks/translationHook';
import RegisteredMailOverviewFetching from './RegisteredMailOverviewFetching';

interface RegisteredMailOverviewViewProps {
    companyId: string;
}

const RegisteredMailOverviewView: React.FC<RegisteredMailOverviewViewProps> = ({ companyId }) => {
    const { t } = useTranslation('registered_mail_overview');

    return <RegisteredMailOverviewFetching companyId={companyId} />;
};

export default RegisteredMailOverviewView;
