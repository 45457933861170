import { BookOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../components/Buttons';
import CompanyDetail from '../../components/company/CompanyDetail';
import { Routes } from '../../constants';
import { useCreateCompany } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import ResourceDetailLayout from '../../layouts/ResourceDetailLayout';

const loadingMessageKey = 'loadingMessageKey';

const CompanyDetailCreate: React.FC = () => {
    const history = useHistory();
    const { t, tCommon } = useTranslation('company');
    const [form] = useForm();
    const mutation = useCreateCompany();

    useEffect(() => {
        if (mutation.isLoading) {
            message.loading({ key: loadingMessageKey, content: t('creating_new_company_loading') }, 0);
        } else {
            message.destroy(loadingMessageKey);
        }
    }, [mutation.isLoading]);

    useEffect(() => {
        if (mutation.isSuccess) {
            history.push(Routes.COMPANIES);
            message.success(t('creating_new_company_success', { name: form.getFieldValue('name') }), 3);
        }
    }, [mutation.isSuccess]);

    useEffect(() => {
        if (mutation.isError) {
            message.error(t('creating_new_company_error'), 3);
        }
    }, [mutation.isError]);

    const onBackClick = () => {
        history.goBack();
    };

    const onClickSaveCompany = async () => {
        const { countryCode, city, street, postalCode, streetNr, ...otherFormValues } = await form.validateFields();
        const newCompany: API.CreateCompany = {
            ...otherFormValues,
            headAddress: { countryCode, city, street, postalCode, streetNr },
        };
        mutation.mutate(newCompany);
    };

    const detail = <CompanyDetail isReadOnly={false} form={form} />;

    const actions = (
        <>
            <PrimaryButton
                icon={<BookOutlined />}
                aria-label="submit new company button"
                disabled={mutation.isLoading}
                htmlType="submit"
                onClick={onClickSaveCompany}
            >
                {tCommon('save')}
            </PrimaryButton>
        </>
    );

    return (
        <ResourceDetailLayout
            title={t('create_company_title')}
            detail={detail}
            actions={actions}
            onBack={onBackClick}
        />
    );
};

export default CompanyDetailCreate;
