import { API } from '@eservices/shared/type';
import { Form, FormInstance, Space } from 'antd';
import { useTranslation } from '../../hooks/translationHook';
import styled from 'styled-components';
import { FormItem } from '../FormItem';
import { Input } from '../Input';
import { Paragraph } from '../Paragraph';
import Upload from '../Upload';
import WorkflowSmallDetail from '../workflow/WorkflowSmallDetail';

const FullWithSpace = styled(Space)`
    width: 100%;
    margin: 0 auto;
    margin-bottom: 5%;

    .ant-form-item {
        margin-bottom: 5px;
    }
`;

interface JobUploadProps {
    companyId: string;
    form: FormInstance;
    selectedWorkflow: API.WorkflowConfigurationListItem;
    onIsUploading: (flag: boolean) => void;
}

const JobUpload: React.FC<JobUploadProps> = ({ companyId, form, selectedWorkflow, onIsUploading }) => {
    const { t, tValidation } = useTranslation('job');

    if (!selectedWorkflow) return <Paragraph>{t('workflow_selected_none')}</Paragraph>;

    return (
        <Form
            form={form}
            initialValues={{ jobName: '', originalFilename: '', filename: '' }}
            requiredMark={false}
            layout="vertical"
        >
            <FullWithSpace direction="vertical">
                <FormItem
                    label={t('name')}
                    name="jobName"
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.job_name'),
                        },
                    ]}
                >
                    <Input type="text" aria-label="job name input" />
                </FormItem>
                <FormItem
                    name="filename"
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.file_upload'),
                        },
                    ]}
                >
                    <Upload
                        accept=".pdf,zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                        disabled={false}
                        isImageUpload={false}
                        companyId={companyId}
                        hasFile={false}
                        onOriginalFilenameChange={(value) => form.setFieldsValue({ originalFilename: value })}
                        onFilenameChange={(value) => form.setFieldsValue({ filename: value })}
                        onIsUploading={onIsUploading}
                    />
                </FormItem>
                <FormItem hidden name="originalFilename" />
                <FormItem hidden name="filename" />
                <WorkflowSmallDetail companyId={companyId} {...selectedWorkflow} />
            </FullWithSpace>
        </Form>
    );
};

export default JobUpload;
