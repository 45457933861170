import { CopyOutlined, FileOutlined } from '@ant-design/icons';
import { WatermarkLocation } from '@eservices/shared/constant';
import { Radio, RadioChangeEvent, Space } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { SecondaryButton } from '../../../../../components/Buttons';
import { useTranslation } from '../../../../../hooks/translationHook';
import { WatermarkUI } from '../../../../../types';
import Upload from '../../../../Upload';

const Container = styled.div`
    width: 95%;
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 auto;
`;

const Preview = styled.div`
    max-width: 150px;
    position: relative;
`;

const RotateIcon = styled.span`
    svg {
        transform: rotate(180deg);
    }
`;

const RedrawButton = styled(SecondaryButton)``;

interface WatermarkEditorProps {
    companyId: string;
    isReadOnly: boolean;
    watermark: WatermarkUI;
    onAddWatermark: (watermark: WatermarkUI) => void;
    onDeleteWatermark: () => void;
    onRedrawWatermark: () => void;
    onUpdateWatermark: (watermark: WatermarkUI) => void;
}

const WatermarkEditor: React.FC<WatermarkEditorProps> = ({
    companyId,
    isReadOnly,
    watermark,
    onAddWatermark,
    onUpdateWatermark,
    onRedrawWatermark,
    onDeleteWatermark,
}) => {
    const { t } = useTranslation('task');
    const [filename, setFilename] = useState<string>();

    const locationOptions = [
        {
            label: (
                <Space>
                    <RotateIcon>
                        <FileOutlined />
                    </RotateIcon>
                    {t('first_page')}
                </Space>
            ),
            value: WatermarkLocation.FIRST_PAGE,
        },
        {
            label: (
                <Space>
                    <CopyOutlined />
                    {t('all_pages')}
                </Space>
            ),
            value: WatermarkLocation.ALL_PAGES,
        },
    ];

    const onFilenameChange = (filename: string) => {
        setFilename(filename);
    };

    const onDownloadUrlChange = (url: string) => {
        if (url) {
            const imageElement = new Image();
            imageElement.src = url;
            imageElement.onload = function () {
                const width = imageElement.naturalWidth;
                const height = imageElement.naturalHeight;

                onAddWatermark({
                    image: {
                        filename,
                        url,
                    },
                    width,
                    height,
                    imageElement,
                    location: watermark?.location || WatermarkLocation.ALL_PAGES,
                });
            };
        } else {
            onDeleteWatermark();
        }
    };

    const onChangeWatermarkLocation = (e: RadioChangeEvent) => {
        onUpdateWatermark({ ...watermark, location: e.target.value as WatermarkLocation });
    };

    return (
        <Container>
            <InnerContainer>
                <Preview>
                    <Upload
                        accept="image/*,application/postscript"
                        disabled={isReadOnly}
                        isImageUpload
                        companyId={companyId}
                        previewUrl={watermark?.image?.url}
                        onFilenameChange={onFilenameChange}
                        onDownloadUrlChange={onDownloadUrlChange}
                    />
                </Preview>
                {watermark && (
                    <>
                        <Radio.Group
                            disabled={isReadOnly}
                            options={locationOptions}
                            optionType="button"
                            buttonStyle="solid"
                            onChange={onChangeWatermarkLocation}
                            value={watermark.location}
                        />
                        <RedrawButton disabled={isReadOnly} shape="default" type="dashed" onClick={onRedrawWatermark}>
                            {t('redraw_watermark')}
                        </RedrawButton>
                    </>
                )}
            </InnerContainer>
        </Container>
    );
};

export default WatermarkEditor;
