import { useTranslation } from '../hooks/translationHook';
import CustomError from './CustomError';

const FailedToLoadData: React.FC = () => {
    const { tError } = useTranslation();

    return <CustomError status="warning" title={tError('data_could_not_be_fetched')} />;
};

export default FailedToLoadData;
