import { PlusOutlined } from '@ant-design/icons';
import MailOutlined from '@ant-design/icons/lib/icons/MailOutlined';
import PrinterOutlined from '@ant-design/icons/lib/icons/PrinterOutlined';
import { Subscriptions, TaskType } from '@eservices/shared/constant';
import { Skeleton } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { iconsPerTask } from '../../../constants';
import { useGetCompanySubscriptionDetails } from '../../../hooks/apiHooks';
import { useTranslation } from '../../../hooks/translationHook';
import FailedToLoadData from '../../FailedToLoadData';
import { Header2 } from '../../Headers';
import { Paragraph } from '../../Paragraph';
import WizardChoiceWithIcon from '../../WizardChoiceWithIcon';

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 20%;
`;

const Choices = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 30px;
    margin: 5% 0 0 0;
    flex-direction: column;
`;

const IconWrapper = styled.div`
    font-size: 25px;
`;

const IntroImage = styled.img`
    width: 35%;
    max-width: 400px;
    display: block;
    float: right;
`;

const AddNewTask = styled.div`
    max-width: 600px;
`;

interface TaskPickerProps {
    companyId: string;
    onTaskTypeSelected: (taskType: TaskType) => void;
}

const TaskPicker: React.FC<TaskPickerProps> = ({ companyId, onTaskTypeSelected }) => {
    const { t } = useTranslation('workflow');
    const { isError, isLoading, data: subscriptionDetails } = useGetCompanySubscriptionDetails(companyId);

    if (isLoading) return <Skeleton />;
    if (isError) return <FailedToLoadData />;

    const taskTypes = [
        {
            type: TaskType.EASY_PRINT,
            title: t('print_title'),
            description: t('print_description'),
            cover: iconsPerTask.EASY_PRINT || <PrinterOutlined />,
        },
        {
            type: TaskType.ERDS,
            title: t('erds_title'),
            description: t('erds_description'),
            cover: <IconWrapper>{iconsPerTask.ERDS} </IconWrapper>,
            comingSoon: true,
        },
        {
            type: TaskType.EMAIL,
            title: t('email'),
            description: t('email_description'),
            cover: iconsPerTask.EMAIL || <MailOutlined />,
        },
        {
            type: TaskType.QERDS,
            title: t('qerds'),
            description: t('qerds_description'),
            cover: <IconWrapper>{iconsPerTask.QERDS} </IconWrapper>,
        },
    ];

    const availableTaskTypes = Subscriptions[subscriptionDetails.subscriptionType].availableTaskTypes;

    const activeTaskTypes = taskTypes.map((taskType) => ({
        ...taskType,
        enabled: availableTaskTypes.includes(taskType.type),
    }));

    return (
        <Container>
            <AddNewTask>
                <div>
                    <Header2>
                        <PlusOutlined /> {t('add_new_task_title')}
                    </Header2>
                    <Paragraph>{t('add_new_task_description')}</Paragraph>
                </div>
                <Choices>
                    {activeTaskTypes.map(({ type, enabled, cover, title, description }) => (
                        <WizardChoiceWithIcon
                            size={taskTypes.length > 2 ? 'small' : 'default'}
                            key={type}
                            onClick={() => {
                                onTaskTypeSelected(type);
                            }}
                            enabled={enabled}
                            icon={cover}
                            title={title}
                            description={description}
                        />
                    ))}
                </Choices>
            </AddNewTask>
            <IntroImage src="https://www.hubmasters.net/hubfs/identify%20website%20problems.svg" />
        </Container>
    );
};

export default TaskPicker;
