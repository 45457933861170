import { List } from 'antd';
import Card from '../../components/Card';
import { useGetDepartments } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';

interface DepartmentsListFetchingProps {
    companyId: string;
}

const DepartmentsListFetching: React.FC<DepartmentsListFetchingProps> = ({ companyId }) => {
    const { tCommon } = useTranslation('profile');

    const { data: myDepartments = [], isLoading } = useGetDepartments(companyId);

    return (
        <Card title={tCommon('departments')}>
            <List
                dataSource={myDepartments}
                loading={isLoading}
                size="small"
                itemLayout="horizontal"
                renderItem={(department) => {
                    return (
                        <List.Item key={department.id}>
                            <List.Item.Meta title={department.name} />
                        </List.Item>
                    );
                }}
            ></List>
        </Card>
    );
};

export default DepartmentsListFetching;
