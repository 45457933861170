import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import styled from 'styled-components';
import { useTranslation } from '../../hooks/translationHook';
import { FormItem as BaseFormItem } from '../FormItem';
import { Password } from '../Input';

const Box = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
`;
const FormItem = styled(BaseFormItem)`
    width: 48%;
    min-width: 200px;
`;

interface ChangePasswordFormProps {
    form: FormInstance;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ form }) => {
    const { t, tValidation, tError } = useTranslation('change_password');

    return (
        <Form
            form={form}
            name="changePassword"
            initialValues={{ oldPassword: '', newPassword: '' }}
            requiredMark={false}
            layout="vertical"
        >
            <FormItem
                label={t('old_password')}
                name="oldPassword"
                rules={[
                    {
                        required: true,
                        message: tValidation('required.old_password'),
                    },
                ]}
            >
                <Password aria-label="user old password input" />
            </FormItem>
            <Box>
                <FormItem
                    label={t('new_password')}
                    name="newPassword"
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.new_password'),
                        },
                    ]}
                >
                    <Password aria-label="user new password input" />
                </FormItem>
                <FormItem
                    label={t('repeat_new_password')}
                    name="repeatNewPassword"
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.repeat_new_password'),
                        },
                        (form) => ({
                            validator(_, value) {
                                if (!value || form.getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(tError('new_password_doesnt_match')));
                            },
                        }),
                    ]}
                >
                    <Password aria-label="user new password confirm input" />
                </FormItem>
            </Box>
        </Form>
    );
};

export default ChangePasswordForm;
