import WorkflowsOverviewFetching from './WorkflowsOverviewFetching';

interface WorkflowsOverviewViewProps {
    companyId: string;
}

const WorkflowsOverviewView: React.FC<WorkflowsOverviewViewProps> = ({ companyId }) => (
    <WorkflowsOverviewFetching companyId={companyId} isReadOnly />
);

export default WorkflowsOverviewView;
