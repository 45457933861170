import { API } from '@eservices/shared/type';
import { Colors } from '@eservices/ui-constants/colors';
import { Form, Tabs as AntdTabs } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Card from '../../components/Card';
import DepartmentSelector from '../../components/DepartmentSelector';
import OrderHistoryPanel from '../../components/order/OrderHistoryPanel';
import StorePanel from '../../components/order/StorePanel';
import { useGetDepartments } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import ResourceDetailLayout from '../../layouts/ResourceDetailLayout';

const Tabs = styled(AntdTabs)`
    .ant-tabs-ink-bar {
        background: ${Colors.TERTIARY};
    }
`;

const Box = styled.div`
    width: 100%;
`;

export enum StoreTab {
    STORE = 'tab_store',
    HISTORY = 'tab_orders',
}

interface OrdersOverviewViewProps {
    companyId: string;
}

const OrdersOverviewView: React.FC<OrdersOverviewViewProps> = ({ companyId }) => {
    const history = useHistory();
    const { t } = useTranslation('order');
    const [form] = useForm();
    const { isLoading, isError, data: departments = [] } = useGetDepartments(companyId);
    const [activeTabKey, setActiveTabKey] = useState<StoreTab>(StoreTab.STORE);
    const [selectedDepartment, setSelectedDepartment] = useState<API.DepartmentListItem>();

    const onAfterSuccessfullOrder = () => {
        switchTab(StoreTab.HISTORY);
    };

    const switchTab = (tabKey: StoreTab) => {
        setActiveTabKey(tabKey);
    };

    const onChangeDepartment = (departmentId: string) => {
        setSelectedDepartment(departments.find((department) => department.id === departmentId));
    };

    useEffect(() => {
        if (departments?.length > 0) {
            setSelectedDepartment(departments[0]);
        }
    }, [departments]);

    const tabList = [
        {
            key: StoreTab.STORE,
            tab: t('tab_store'),
        },
        {
            key: StoreTab.HISTORY,
            tab: t('tab_orders'),
        },
    ];

    const actions = (
        <Box>
            {departments.length === 1 ? (
                selectedDepartment && selectedDepartment.name
            ) : (
                <DepartmentSelector
                    companyId={companyId}
                    onChange={onChangeDepartment}
                    value={selectedDepartment?.id}
                />
            )}
        </Box>
    );

    const contentList = {
        tab_store: (
            <>
                {selectedDepartment ? (
                    <StorePanel
                        form={form}
                        companyId={companyId}
                        departmentId={selectedDepartment.id}
                        onAfterSuccessfullOrder={onAfterSuccessfullOrder}
                    />
                ) : (
                    t('department_required')
                )}
            </>
        ),
        tab_orders: (
            <>
                {selectedDepartment ? (
                    <OrderHistoryPanel companyId={companyId} departmentId={selectedDepartment.id} />
                ) : (
                    t('department_required')
                )}
            </>
        ),
    };

    const detail = (
        <Form form={form} name="order_products">
            <Card tabList={tabList} activeTabKey={activeTabKey} onTabChange={switchTab}>
                {contentList[activeTabKey]}
            </Card>
        </Form>
    );

    return (
        <ResourceDetailLayout
            isLoading={isLoading}
            isError={isError}
            title={t('title')}
            detail={detail}
            actions={actions}
        />
    );
};

export default OrdersOverviewView;
