import { CloseOutlined, RotateRightOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { useForm } from 'antd/lib/form/Form';
import { useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryButton, SecondaryButton } from '../../components/Buttons';
import ParcelCreator from '../../components/parcel/ParcelCreator';
import { Routes } from '../../constants';
import { useCreateParcelRequests, useGetDepartments, useGetUploadUrl, usePutFile } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import ResourceDetailLayout from '../../layouts/ResourceDetailLayout';
import { ParcelSenderWithDepartment } from '../../types';
import { recipientsReducer } from './recipientReducer';
import { useGetCompany } from '../../hooks/apiHooks';

interface ParcelsCreateProps {
    companyId: string;
}

const ParcelCreate: React.FC<ParcelsCreateProps> = ({ companyId }) => {
    const history = useHistory();
    const { t } = useTranslation('parcels_create');
    const [senderForm] = useForm<ParcelSenderWithDepartment>();
    const [uploadedFileKey, setUploadedFileKey] = useState<string>();
    const { data: company, isLoading: isLoadingCompany } = useGetCompany(companyId);
    const { data: departments = [], isLoading: isLoadingDepartments } = useGetDepartments(companyId);
    const createParcelRequestsMutation = useCreateParcelRequests(companyId);
    const getUploadUrlMutation = useGetUploadUrl(companyId);
    const uploadMutation = usePutFile();
    const [{ recipients }, recipientsDispatch] = useReducer(recipientsReducer, {
        recipients: [],
    });
    const [errors, setErrors] = useState<string[]>([]);
    const [waiting, setIsWaiting] = useState(false);

    const uploadFileWithRecipients = (fileUploadLink: API.FileUploadLink) => {
        const { filename, url } = fileUploadLink;
        const ndJsonContent = recipients.map((recipients) => JSON.stringify(recipients)).join('\n');
        const blob = new Blob([ndJsonContent], { type: 'application/json' });
        uploadMutation.mutate(
            { url: url, file: new File([blob], filename) },
            {
                onSuccess: () => createParcelRequests(filename),
            },
        );
    };

    const createParcelRequests = (fileName: string) => {
        const { departmentId, companyName, name, address, emailAddress, phoneNumber } = senderForm.getFieldsValue();
        createParcelRequestsMutation.mutate(
            {
                departmentId,
                parcelRequests: {
                    fileName,
                    sender: {
                        departmentId,
                        companyName,
                        name,
                        address,
                        emailAddress,
                        phoneNumber,
                    },
                },
            },
            {
                onSuccess: async () => {
                    await new Promise((resolve) => setTimeout(resolve, 2000));
                    setIsWaiting(false);
                    history.push(Routes.PARCELS);
                },
                onError: () => {
                    setErrors([t('failed_requesting_parcel_labels')]);
                    setIsWaiting(false);
                },
            },
        );
    };

    const detail = (
        <ParcelCreator
            company={company}
            senderForm={senderForm}
            setUploadedFileKey={setUploadedFileKey}
            uploadedFileKey={uploadedFileKey}
            departments={departments}
            recipients={recipients}
            recipientsDispatch={recipientsDispatch}
        />
    );

    const onBackClick = () => {
        history.push(Routes.PARCELS);
    };

    const onClickCreate = async () => {
        const senderFormFields = await senderForm.validateFields();

        if (uploadedFileKey) {
            setIsWaiting(true);
            createParcelRequests(uploadedFileKey);
        } else {
            const recipientsWithErrors = recipients.filter((recipient) => recipient.hasError);

            if (recipientsWithErrors.length > 0) {
                const errorMessages = recipientsWithErrors.map((recipient) =>
                    t('error_found_for', { name: recipient.name }),
                );
                setErrors(errorMessages);
            } else if (recipients.length > 0) {
                setIsWaiting(true);
                const fileName = `${senderFormFields.name}-${new Date().toISOString()}.json`;
                getUploadUrlMutation.mutate(fileName, {
                    onSuccess: uploadFileWithRecipients,
                    onError: () => {
                        setErrors([t('failed_requesting_parcel_labels')]);
                        setIsWaiting(false);
                    },
                });
            }
        }
    };

    const actions = [
        <PrimaryButton aria-label="request label button" icon={<RotateRightOutlined />} onClick={onClickCreate}>
            {t('request_label')}
        </PrimaryButton>,
    ];

    return (
        <ResourceDetailLayout
            title={t('request_label_title')}
            detail={detail}
            actions={actions}
            isLoading={isLoadingDepartments || isLoadingCompany}
            errors={errors}
            isWaiting={waiting}
            onBack={onBackClick}
        />
    );
};

export default ParcelCreate;
