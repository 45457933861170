import { TaskType } from '@eservices/shared/constant';
import { Colors } from '@eservices/ui-constants/colors';
import { default as React } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { useTranslation } from '../../../hooks/translationHook';
import Collapse from '../../Collapse';
import { Header1 } from '../../Headers';

const Image = styled.img`
    margin: 5% auto;
    display: block;
    height: 150px;
`;

const Description = styled.div`
    position: relative;
`;

const Container = styled.div`
    padding; 5%;
`;

const CustomHeader1 = styled(Header1)`
    color: ${Colors.PRIMARY};
`;

const { Panel } = Collapse;

interface TaskDescriptionProps {
    taskType: TaskType;
}

const TaskDescription: React.FC<TaskDescriptionProps> = ({ taskType }) => {
    const { t, tCommon } = useTranslation('workflow');

    return (
        <Container>
            <CustomHeader1>{t(taskType)}</CustomHeader1>
            <Image
                alt="Simple workflow illustration"
                src="https://embed-ssl.wistia.com/deliveries/540a376c39bd51c384e5d200686d962a.jpg" //TODO get some nice visuals
            ></Image>
            <Description>
                <Collapse defaultActiveKey={['1']} ghost>
                    <Panel header={tCommon('description')} key="1">
                        <ReactMarkdown>{t(`${taskType.toLocaleLowerCase()}_task_description`)}</ReactMarkdown>
                    </Panel>
                    <Panel header={tCommon('use_cases')} key="2">
                        <ReactMarkdown>{t(`${taskType.toLocaleLowerCase()}_task_use_cases`)}</ReactMarkdown>
                    </Panel>
                </Collapse>
            </Description>
        </Container>
    );
};

export default TaskDescription;
