import {
    DeliveryType,
    EASYPRINT_DEFAULT_SUPPLIER,
    InputFileType,
    PrintAttachmentPosition,
    PrintColor,
    PrintSide,
    TaskType,
    TaskValidationStatus,
    WorkflowEnvelopeType,
    WorkflowPaperType,
} from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Tabs } from 'antd';
import Form, { useForm } from 'antd/lib/form/Form';
import React, { useEffect } from 'react';
import { FormItem } from '../../../components/FormItem';
import { useTranslation } from '../../../hooks/translationHook';
import { TaskDetailProps } from '../../../types';
import { getDaysFrom } from '../../../utils/helpers';
import TaskActions from '../common/TaskActions';
import TaskDangerousActions from '../common/TaskDangerousActions';
import { convertFormToEasyPrintTask, EasyPrintTaskFormFields } from './formHelpers';
import PrintConfiguration from './PrintConfiguration';

const { TabPane } = Tabs;

const tabConfiguration = 'configuration';

const defaultFormValues: EasyPrintTaskFormFields = {
    taskType: TaskType.EASY_PRINT,
    name: '',
    supplierId: EASYPRINT_DEFAULT_SUPPLIER,
    deliveryType: DeliveryType.NON_PRIOR,
    timeToWait: 1,
    mergeDocuments: false,
    validationStatus: TaskValidationStatus.PENDING,
    resources: {
        paper: {
            type: WorkflowPaperType.A4_5GR,
        },
        envelope: {
            type: WorkflowEnvelopeType.US_LEFT_5GR,
            supplierExample: {
                filename: undefined,
                url: undefined,
            },
        },
    },
    print: {
        addFrontPage: false,
        printSide: PrintSide.RECTO,
        printColor: PrintColor.BW,
    },
    attachment: {
        filename: undefined,
        url: undefined,
        position: PrintAttachmentPosition.END_OF_DOCUMENT,
    },
};

export interface FilesState {
    logo?: API.ConfigurationFile;
    attachment?: API.ConfigurationFile;
    sampleFile?: API.ConfigurationFile;
}

type EasyPrintTaskConfigurationProps = TaskDetailProps<API.TaskEasyPrint>;

const EasyPrintTaskConfiguration: React.FC<EasyPrintTaskConfigurationProps> = ({
    order,
    isReadOnly,
    companyId,
    task,
    onSave,
    onCancel,
    onError,
    onDelete,
    allowedToSave,
}) => {
    const { t } = useTranslation('workflow');
    const [form] = useForm<EasyPrintTaskFormFields>();

    useEffect(() => {
        if (task) {
            form.setFieldsValue({
                ...task,
                timeToWait: getDaysFrom(task.maxDurationInSeconds),
            });
        }
    }, []);

    const saveTask = async () => {
        try {
            const taskFields = await form.validateFields();
            const task = convertFormToEasyPrintTask(taskFields);
            onSave(order, task);
        } catch (e) {
            let errors = [];
            if (e?.errorFields) {
                errors = e?.errorFields.map(({ errors }) => errors.join(', '));
            } else {
                errors.push(e);
            }
            onError(errors);
        }
    };

    return (
        <Form
            form={form}
            initialValues={defaultFormValues}
            layout="vertical"
            name="easyPrintTaskConfiguration"
            requiredMark={false}
        >
            <FormItem name="validationStatus" hidden />
            <FormItem name="id" hidden />
            <FormItem name="taskType" hidden />
            <FormItem name="supplierId" hidden />
            <FormItem name={['resources', 'paper', 'type']} hidden />
            <FormItem name={['resources', 'envelope', 'type']} hidden />
            <FormItem name={['resources', 'envelope', 'supplierExample', 'filename']} hidden />
            <FormItem hidden name={['resources', 'envelope', 'logo', 'filename']} />
            <FormItem hidden name={['resources', 'envelope', 'logo', 'url']} />
            <FormItem hidden name={['attachment', 'url']} />
            <FormItem hidden name={['attachment', 'filename']} />
            <FormItem hidden name={['htmlTemplate', 'url']} />
            <FormItem hidden name={['htmlTemplate', 'filename']} />
            <FormItem hidden name="mergeDocuments" />
            <FormItem hidden name={['print', 'addFrontPage']} />
            <Tabs
                defaultActiveKey={tabConfiguration}
                tabBarExtraContent={
                    <TaskActions
                        onSave={saveTask}
                        onCancel={onCancel}
                        allowedToSave={allowedToSave}
                        taskType={TaskType.EASY_PRINT}
                        isReadOnly={isReadOnly}
                    />
                }
            >
                <TabPane tab={t(tabConfiguration)} key={tabConfiguration}>
                    <PrintConfiguration isReadOnly={isReadOnly} companyId={companyId} form={form} />
                </TabPane>
            </Tabs>
            <TaskDangerousActions canBeDeleted={task !== undefined && !isReadOnly} onDelete={onDelete} />
        </Form>
    );
};

export default EasyPrintTaskConfiguration;
