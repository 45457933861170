import { BookOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { ParcelStatus } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Badge, Space, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { bpostStatusIcons } from '../../constants';
import { useBpostCountries } from '../../hooks/bpostCountriesHook';
import { useTranslation } from '../../hooks/translationHook';
import { getPaginationDetails, toReadableDateTime } from '../../utils/helpers';
import styled from 'styled-components';
import { IconButton } from '../Buttons';
import DownloadLink from '../DownloadLink';
import Table from '../Table';
import ParcelDetail from './ParcelDetail';
import { Colors } from '@eservices/ui-constants/colors';

const Icon = styled.img`
    width: 70px;
`;

const Details = styled.div`
    color: ${Colors.GRAY_SEPTENARY};
    font-size: 12px;
`;

interface ParcelsTableProps {
    companyId: string;
    parcels: API.ParcelListItem[];
    departments: API.DepartmentListItem[];
    total: number;
    isLoading: boolean;
    onPaginationChange: (page: number, pageSize?: number) => void;
    pagination: { page: number; pageSize: number };
}

const ParcelsTable: React.FC<ParcelsTableProps> = ({
    companyId,
    parcels = [],
    total,
    isLoading,
    onPaginationChange,
    pagination,
    departments,
}) => {
    const { t, tCommon, i18n } = useTranslation('parcels_overview');
    const [selectedParcel, setSelectedParcel] = useState<API.ParcelListItem>();
    const { isLoadingCountries, findCountryName } = useBpostCountries();

    const paginationConfig = getPaginationDetails(total, {
        current: pagination.page,
        pageSize: pagination.pageSize,
        onChange: onPaginationChange,
        showSizeChanger: true,
    });

    const columns: ColumnsType<API.ParcelListItem> = [
        {
            title: tCommon('Parcel'),
            width: '20%',
            render: ({
                name,
                recipient: {
                    companyName,
                    name: recipientName,
                    address: { countryCode, city, postalCode, street, streetNr, box },
                },
            }: API.ParcelListItem) => (
                <>
                    <div>{name}</div>
                    <Details>
                        <div>
                            {companyName || recipientName}
                            <br />
                            {companyName && recipientName}
                        </div>
                        {street} {streetNr}
                        {box && ` ${box}`}, {postalCode} {city}, {findCountryName(countryCode)}
                    </Details>
                </>
            ),
        },
        {
            title: tCommon('department'),
            width: '10%',
            render: ({ departmentId }: API.ParcelListItem) =>
                departments.find((department) => department.id === departmentId)?.name,
        },
        {
            width: '10%',
            title: t('track_and_trace'),
            align: 'center',
            render: ({ barCode, statusHistory, recipient }: API.ParcelListItem) =>
                statusHistory.find((s) => s.value === ParcelStatus.PARCEL_SORTED).date && (
                    <a
                        target="_blank"
                        href={`https://track.bpost.cloud/btr/web/#/search?lang=${i18n.language}&itemCode=${barCode}&postalCode=${recipient.address.postalCode}`}
                    >
                        {t('link')}
                    </a>
                ),
        },
        {
            width: '10%',
            title: t('status'),
            align: 'center',
            render: ({ statusHistory }: API.ParcelListItem) => {
                const sortedStatuses = statusHistory.sort((a, b) => (a.date > b.date ? -1 : a.date < b.date ? 1 : 0));
                const lastKnownStatus = sortedStatuses[0];
                return (
                    <Tooltip
                        title={
                            <>
                                {t(lastKnownStatus.value)} - {toReadableDateTime(lastKnownStatus.date)}
                            </>
                        }
                    >
                        <Icon
                            src={bpostStatusIcons[lastKnownStatus.value]}
                            alt={t(lastKnownStatus.value.toLowerCase())}
                        />
                    </Tooltip>
                );
            },
        },
        {
            title: tCommon('create_date'),
            width: '10%',
            align: 'right',
            render: ({ createdAt }: API.ParcelListItem) => toReadableDateTime(createdAt),
        },
        {
            width: '5%',
            align: 'right',
            render: (parcelListItem: API.ParcelListItem) => {
                const { id, parcelGroupId, departmentId, statusHistory, errors } = parcelListItem;
                const hasLabel = Boolean(statusHistory.find(({ value }) => value === ParcelStatus.LABEL_CREATED).date);
                const hasMergedLabels = Boolean(
                    statusHistory.find(({ value }) => value === ParcelStatus.LABEL_MERGED).date,
                );

                return (
                    <Space>
                        {hasMergedLabels && (
                            <DownloadLink
                                companyId={companyId}
                                source={API.FileSource.PARCEL}
                                location={`${companyId}/${departmentId}/${parcelGroupId}/${parcelGroupId}-merge.pdf`}
                                content={<IconButton icon={<BookOutlined />} />}
                            />
                        )}
                        {hasLabel && (
                            <DownloadLink
                                companyId={companyId}
                                source={API.FileSource.PARCEL}
                                location={`${companyId}/${departmentId}/${parcelGroupId}/label-${id}.pdf`}
                                content={<IconButton icon={<DownloadOutlined />} />}
                            />
                        )}
                        <Badge count={errors.length}>
                            <IconButton icon={<EyeOutlined />} onClick={() => setSelectedParcel(parcelListItem)} />
                        </Badge>
                    </Space>
                );
            },
        },
    ];

    return (
        <>
            <Table
                loading={isLoading || isLoadingCountries}
                rowKey="id"
                columns={columns}
                dataSource={parcels}
                pagination={paginationConfig}
            />
            {selectedParcel && (
                <ParcelDetail
                    companyId={companyId}
                    departmentId={selectedParcel.departmentId}
                    id={selectedParcel.id}
                    handleOnClose={() => setSelectedParcel(undefined)}
                />
            )}
        </>
    );
};

export default ParcelsTable;
