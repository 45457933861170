import { Skeleton } from 'antd';
import { useGetDepartments, useGetParcel } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import DetailDrawer from '../DetailDrawer';
import FailedToLoadData from '../FailedToLoadData';
import ParcelDetailSubDetail from './ParcelDetailSubDetail';
import ParcelDetailTimeline from './ParcelDetailTimeline';

interface ParcelDetailProps {
    companyId: string;
    departmentId: string;
    id: string;
    handleOnClose: () => void;
}

const ParcelDetail: React.FC<ParcelDetailProps> = ({ companyId, departmentId, id, handleOnClose }) => {
    const { t } = useTranslation('parcel_detail');
    const {
        data: parcel,
        isLoading: isLoadingParcel,
        isError: isErrorParcel,
    } = useGetParcel(companyId, departmentId, id);
    const {
        data: departments,
        isLoading: isLoadingDepartments,
        isError: isErrorDepartments,
    } = useGetDepartments(companyId);

    if (isLoadingParcel || isLoadingDepartments)
        return <DetailDrawer title={t('title')} subtitle={id} onClose={handleOnClose} main={<Skeleton active />} />;
    if (isErrorParcel || isErrorDepartments)
        return <DetailDrawer title={t('title')} subtitle={id} onClose={handleOnClose} main={<FailedToLoadData />} />;

    return (
        <DetailDrawer
            title={parcel.name}
            subtitle={id}
            onClose={handleOnClose}
            main={<ParcelDetailTimeline parcel={parcel} />}
            detail={
                <ParcelDetailSubDetail
                    companyId={companyId}
                    departmentId={departmentId}
                    departments={departments}
                    parcel={parcel}
                />
            }
        />
    );
};

export default ParcelDetail;
