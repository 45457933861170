import { LoadingOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { Space, Timeline } from 'antd';
import { useTranslation } from '../../../hooks/translationHook';
import { toReadableDateTime } from '../../../utils/helpers';

interface ErdsProgressTaskProps {
    task: API.ERDSTaskDetails;
    taskConfiguration: API.TaskERDS;
}

const ErdsTaskProgress: React.FC<ErdsProgressTaskProps> = ({ task, taskConfiguration }) => {
    const { t, tCommon } = useTranslation('erds_task');

    const [
        received,
        signed,
        delivered,
        _submissionReportGenerated, // Not shown
        downloadStarted,
        downloadFailed /*TODO [ESERVICES-367]: Include this status in the timeline */,
        downloaded,
        refused,
    ] = task.statusHistory;

    const getColor = (endDate: string): string => {
        if (endDate) return 'green';
        else return 'gray';
    };

    const showLoadingIcon = (endDate: string, previousTaskEndDate: string): boolean => {
        if (endDate || task.endDate) return false;
        if (previousTaskEndDate) return true;
    };

    return (
        <Timeline>
            <Timeline.Item color={getColor(received.date)}>
                <Space>
                    {t(received.value.toLocaleLowerCase())} {received.date && toReadableDateTime(received.date)}
                </Space>
            </Timeline.Item>
            <Timeline.Item
                color={getColor(signed.date)}
                dot={showLoadingIcon(signed.date, received.date) && <LoadingOutlined spin />}
            >
                <Space>
                    {t(signed.value.toLocaleLowerCase())} {signed.date && toReadableDateTime(signed.date)}
                </Space>
            </Timeline.Item>
            <Timeline.Item
                color={getColor(delivered.date)}
                dot={showLoadingIcon(delivered.date, signed.date) && <LoadingOutlined spin />}
            >
                <Space>
                    {t(delivered.value.toLocaleLowerCase())} {delivered.date && toReadableDateTime(delivered.date)}
                </Space>
            </Timeline.Item>
            {refused.date ? (
                <Timeline.Item color={'red'}>
                    <Space>
                        {t(refused.value.toLocaleLowerCase())} {refused.date && toReadableDateTime(refused.date)}
                    </Space>
                </Timeline.Item>
            ) : (
                <>
                    <Timeline.Item
                        color={getColor(downloadStarted.date)}
                        dot={showLoadingIcon(downloadStarted.date, delivered.date) && <LoadingOutlined spin />}
                    >
                        <Space>
                            {t(downloadStarted.value.toLocaleLowerCase())}{' '}
                            {downloadStarted.date && toReadableDateTime(downloadStarted.date)}
                        </Space>
                    </Timeline.Item>
                    <Timeline.Item
                        color={getColor(downloaded.date)}
                        dot={showLoadingIcon(downloaded.date, downloadStarted.date) && <LoadingOutlined spin />}
                    >
                        <Space>
                            {t(downloaded.value.toLocaleLowerCase())}{' '}
                            {downloaded.date && toReadableDateTime(downloaded.date)}
                        </Space>
                    </Timeline.Item>
                </>
            )}
            <Timeline.Item color={task.endDate && downloaded.date ? 'green' : task.endDate ? 'red' : 'gray'}>
                <Space>
                    {tCommon('end')} {task.endDate && toReadableDateTime(task.endDate)}
                </Space>
            </Timeline.Item>
        </Timeline>
    );
};

export default ErdsTaskProgress;
