import { QERDS_DEFAULT_SUPPLIER, TaskType, TaskValidationStatus } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Tabs } from 'antd';
import Form, { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../../hooks/translationHook';
import { TaskDetailProps } from '../../../types';
import { getDaysFrom } from '../../../utils/helpers';
import { FormItem } from '../../FormItem';
import TaskActions from '../common/TaskActions';
import TaskDangerousActions from '../common/TaskDangerousActions';
import { convertFormToQerdsTask, QerdsTaskFormFields } from './formHelpers';
import SendingConfiguration from './SendingConfiguration';
const { TabPane } = Tabs;

const tabConfiguration = 'configuration';

const defaultFormValues: QerdsTaskFormFields = {
    taskType: TaskType.QERDS,
    name: '',
    supplierId: QERDS_DEFAULT_SUPPLIER,
    timeToWait: 1,
    validationStatus: TaskValidationStatus.PENDING,
};

interface QerdsTaskConfigurationProps extends TaskDetailProps<API.TaskQERDS> {
    workflow: API.WorkflowConfiguration;
}

const QerdsTaskConfiguration: React.FC<QerdsTaskConfigurationProps> = ({
    order,
    task,
    isReadOnly,
    onSave,
    onCancel,
    onError,
    onDelete,
    allowedToSave,
}) => {
    const { t } = useTranslation('workflow');
    const [isLoading, setIsLoading] = useState(false);
    const [form] = useForm<QerdsTaskFormFields>();

    useEffect(() => {
        if (task) {
            form.setFieldsValue({
                ...task,
                timeToWait: getDaysFrom(task.maxDurationInSeconds),
            });
        }
    }, []);

    const saveTask = async () => {
        try {
            setIsLoading(true);
            const taskFields = await form.validateFields();
            const task = convertFormToQerdsTask(taskFields);
            onSave(order, task);
        } catch (e) {
            let errors = [];
            if (e?.errorFields) {
                errors = e?.errorFields.map(({ errors }) => errors.join(', '));
            } else {
                errors.push(e.message);
            }
            onError(errors);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Form
            form={form}
            initialValues={defaultFormValues}
            layout="vertical"
            name="qerdsTaskConfiguration"
            requiredMark={false}
        >
            <FormItem name="validationStatus" hidden />
            <FormItem name="id" hidden />
            <FormItem name="supplierId" hidden />
            <FormItem name="taskType" hidden />
            <FormItem hidden name="mergeDocuments" />
            <FormItem hidden name={['attachment', 'url']} />
            <FormItem hidden name={['attachment', 'filename']} />
            <FormItem hidden name={['htmlTemplate', 'url']} />
            <FormItem hidden name={['htmlTemplate', 'filename']} />
            <Tabs
                defaultActiveKey={tabConfiguration}
                tabBarExtraContent={
                    <TaskActions
                        isLoading={isLoading}
                        onCancel={onCancel}
                        onSave={saveTask}
                        allowedToSave={allowedToSave && !isLoading}
                        taskType={TaskType.QERDS}
                        isReadOnly={isReadOnly}
                    />
                }
            >
                <TabPane tab={t(tabConfiguration)} key={tabConfiguration}>
                    <SendingConfiguration isReadOnly={isReadOnly} />
                </TabPane>
            </Tabs>
            <TaskDangerousActions canBeDeleted={task !== undefined && !isReadOnly} onDelete={onDelete} />
        </Form>
    );
};

export default QerdsTaskConfiguration;
