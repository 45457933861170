import { ChannelProperties, TaskType } from '@eservices/shared/constant';
import { API, RegionOfInterest } from '@eservices/shared/type';
import { Colors } from '@eservices/ui-constants/colors';
import { FormInstance } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { WatermarkUI } from '../../../types';
import { getEnvelopeWindowDimensions } from '../helpers/identifierRoisHelpers';
import DrawingOverlay from './DrawingOverlay';
import DrawingTool, { RegionOfInterestUI } from './DrawingTool/DrawingTool';
import { Rectangle, RectangleBoundaries } from './DrawingTool/RectanglePDFEditor';
import IdentifiersManager, { defaultActiveKey } from './IdentifiersManager';
import {
    WorkflowPDFInputSourceActions,
    WorkflowPDFInputSourceDrawingActions,
} from './IdentifiersManager/workflowPDFInputSourceReducer';

const Container = styled.div`
    display: flex;
    gap: 20px;
`;

const IdentifiersManagerContainer = styled.div`
    position: relative;
    min-width: 430px;
`;

const convertRegionOfInterestToRectangle = (regionOfInterest: RegionOfInterestUI): Rectangle => {
    return {
        highlighted: regionOfInterest.highlighted,
        x: regionOfInterest.x1,
        y: Math.min(regionOfInterest.y1, regionOfInterest.y2),
        width: Math.abs(regionOfInterest.x2 - regionOfInterest.x1),
        height: Math.min(regionOfInterest.y1, regionOfInterest.y2) - Math.max(regionOfInterest.y1, regionOfInterest.y2),
    };
};

const findEnvelopWindowDimensionForTasks = (tasks: Array<API.TaskConfiguration>) => {
    const printTasks = tasks.filter(({ taskType }) => taskType === TaskType.EASY_PRINT) as API.TaskEasyPrint[];
    if (printTasks.length > 0) {
        const dimensionsPerPrintTask = printTasks.map(({ deliveryType, resources }) =>
            getEnvelopeWindowDimensions(resources.envelope.type, deliveryType),
        );
        return dimensionsPerPrintTask.sort((a, b) => a.width + a.height - b.width + b.height)[0];
    }
};

export const HighlightRectangleContext = React.createContext(null);

interface PDFInputSourceConfigurationProps {
    companyId: string;
    isReadOnly: boolean;
    tasks: Array<API.TaskConfiguration>;
    workflowPDFInputSource: API.WorkflowPDFInputSource;
    workflowPDFInputSourceDispatch: React.Dispatch<WorkflowPDFInputSourceActions>;
    setWatermark: React.Dispatch<React.SetStateAction<WatermarkUI>>;
    watermark: WatermarkUI;
    form: FormInstance;
    sampleFile: API.ConfigurationFile;
    setSampleFile: React.Dispatch<React.SetStateAction<API.ConfigurationFile>>;
}

const PDFInputSourceConfiguration: React.FC<PDFInputSourceConfigurationProps> = ({
    companyId,
    isReadOnly,
    tasks,
    workflowPDFInputSource,
    workflowPDFInputSourceDispatch,
    setWatermark,
    watermark,
    form,
    sampleFile,
    setSampleFile,
}) => {
    const [action, setAction] = useState<WorkflowPDFInputSourceDrawingActions>();
    const [identifierContext, setIdentifierContext] = useState<string>(defaultActiveKey);
    const [highlightedRegionOfInterest, setHighlightedRegionOfInterest] = useState<RegionOfInterest>();
    const [showWatermark, setShowWatermark] = useState<boolean>();
    const [boundaries, setBoundaries] = useState<RectangleBoundaries>();

    useEffect(() => {
        setShowWatermark(identifierContext === 'watermark');
    }, [identifierContext]);

    useEffect(() => {
        if (action?.type === 'insertChannelIdentifier') {
            if (action.payload.kind === ChannelProperties.ADDRESS) {
                const envelopWindowDimension = findEnvelopWindowDimensionForTasks(tasks);
                setBoundaries({
                    maxWidth: envelopWindowDimension.width,
                    maxHeight: envelopWindowDimension.height,
                });
            }
        }
    }, [action]);

    const handleNewRegionOfInterest = (regionOfInterest: RegionOfInterest, rectangle: Rectangle) => {
        if (action) {
            switch (action.type) {
                case 'insertChannelIdentifier':
                    workflowPDFInputSourceDispatch({
                        ...action,
                        payload: {
                            ...action.payload,
                            regionOfInterest: regionOfInterest,
                        },
                    });
                    break;
                case 'insertSplitIdentifier':
                    workflowPDFInputSourceDispatch({
                        ...action,
                        payload: {
                            ...action.payload,
                            regionOfInterest: regionOfInterest,
                        },
                    });
                    break;
                case 'insertIgnoreIdentifier':
                    workflowPDFInputSourceDispatch({
                        ...action,
                        payload: {
                            ...action.payload,
                            regionOfInterest: regionOfInterest,
                        },
                    });
                    break;
                case 'insertExtraInfoIdentifier':
                    workflowPDFInputSourceDispatch({
                        ...action,
                        payload: {
                            ...action.payload,
                            regionOfInterest: regionOfInterest,
                        },
                    });
                    break;
                case 'insertWatermark': {
                    setWatermark({ ...watermark, ...rectangle, y: rectangle.y - rectangle.height });
                }
            }
            setAction(undefined);
            setBoundaries(undefined);
        }
    };

    const onChangeIdentifierContext = (newIdentifierContext: string) => setIdentifierContext(newIdentifierContext);

    const collectRectangles = () => {
        let rectangles: Rectangle[] = workflowPDFInputSource[identifierContext]?.map(({ regionOfInterest }) => {
            const rectangle = convertRegionOfInterestToRectangle(regionOfInterest);
            return regionOfInterest === highlightedRegionOfInterest ? { ...rectangle, highlighted: true } : rectangle;
        });

        const envelopWindowDimension = findEnvelopWindowDimensionForTasks(tasks);
        if (identifierContext === 'channelIdentifiers' && envelopWindowDimension) {
            const envelopeWindow = {
                height: envelopWindowDimension.height,
                width: envelopWindowDimension.width,
                x: envelopWindowDimension.leftX,
                y: envelopWindowDimension.topY,
                highlighted: false,
                stroke: Colors.DARK_PRIMARY,
                fill: 'rgba(0, 0, 0, 0)',
                lineDash: [6],
            };
            rectangles = [...rectangles, envelopeWindow];
        }

        return rectangles;
    };

    return (
        <HighlightRectangleContext.Provider value={setHighlightedRegionOfInterest}>
            <Container>
                <DrawingTool
                    companyId={companyId}
                    isReadOnly={isReadOnly}
                    rectangles={collectRectangles()}
                    onRegionOfInterestDrawn={handleNewRegionOfInterest}
                    needsMultiRecipientIdentifier={false}
                    form={form}
                    watermark={showWatermark && watermark}
                    sampleFile={sampleFile}
                    setSampleFile={setSampleFile}
                    boundaries={boundaries}
                    canDraw={Boolean(action)}
                />
                <IdentifiersManagerContainer>
                    {action && <DrawingOverlay />}
                    <IdentifiersManager
                        companyId={companyId}
                        tasks={tasks}
                        workflowPDFInputSource={workflowPDFInputSource}
                        workflowPDFInputSourceDispatch={workflowPDFInputSourceDispatch}
                        setWatermark={setWatermark}
                        watermark={watermark}
                        setAction={setAction}
                        onTabChange={onChangeIdentifierContext}
                        isReadOnly={isReadOnly}
                    />
                </IdentifiersManagerContainer>
            </Container>
        </HighlightRectangleContext.Provider>
    );
};

export default PDFInputSourceConfiguration;
