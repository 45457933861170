import { BookOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AlertButton, PrimaryButton } from '../../components/Buttons';
import { Routes } from '../../constants';
import { useUpdateUser, useUserResetPassword } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import { translateFieldErrors } from '../../utils/helpers';
import UserDetailFetching from './UserDetailFetching';

const loadingMessageKey = 'loadingMessageKey';

interface UserDetailEditProps {
    companyId: string;
}

const UserDetailEdit: React.FC<UserDetailEditProps> = ({ companyId }) => {
    const history = useHistory();
    const { t, tError, tCommon } = useTranslation('user');
    const [form] = useForm<API.EditUser>();
    const [errors, setErrors] = useState([]);
    const { id: userId }: { id: string } = useParams();

    const updateUserMutation = useUpdateUser(companyId, userId);
    const resetPasswordMutation = useUserResetPassword(companyId, userId);

    const onClickResetPassword = async () => {
        await message.loading({ key: loadingMessageKey, content: t('reset_password_loading') }, 0);

        resetPasswordMutation.mutate(null, {
            onSuccess: async () => {
                await message.success(t('reset_password_success', { username: form.getFieldValue('username') }), 3);
            },
            onError: async () => {
                await message.error(t('reset_password_error'), 3);
            },
            onSettled: () => {
                message.destroy(loadingMessageKey);
            },
        });
    };

    const onBackClick = () => {
        history.goBack();
    };

    const onClickSaveUser = async () => {
        const editUser = await form.validateFields();
        await message.loading({ key: loadingMessageKey, content: t('editing_user_loading') }, 0);

        updateUserMutation.mutate(editUser, {
            onSuccess: async () => {
                history.push(Routes.USERS);
                await message.success(t('editing_user_success', { username: form.getFieldValue('username') }), 3);
            },
            onError: async (err) => {
                const {
                    data: { fieldErrors, error },
                } = err.response;

                if (fieldErrors) {
                    form.setFields(translateFieldErrors(tError, fieldErrors));
                }
                if (error) {
                    setErrors([error]);
                }

                await message.error(t('editing_user_error'), 3);
            },
            onSettled: () => {
                message.destroy(loadingMessageKey);
            },
        });
    };

    const actions = [
        <AlertButton icon={<ExclamationCircleOutlined />} onClick={onClickResetPassword}>
            {t('send_reset_password')}
        </AlertButton>,
        <PrimaryButton
            icon={<BookOutlined />}
            aria-label="save button"
            disabled={updateUserMutation.isLoading}
            htmlType="submit"
            onClick={onClickSaveUser}
        >
            {tCommon('save')}
        </PrimaryButton>,
    ];

    return (
        <>
            <UserDetailFetching
                companyId={companyId}
                userId={userId}
                isUpdate
                form={form}
                errors={errors}
                actions={actions}
                onBack={onBackClick}
            />
        </>
    );
};

export default UserDetailEdit;
