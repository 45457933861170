import { BookOutlined } from '@ant-design/icons';
import { API } from '@eservices/shared/type';
import { Modal } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../components/Buttons';
import { Select } from '../../components/Input';
import { Routes } from '../../constants';
import { useGetCompanies } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import styled from 'styled-components';

const { Option } = Select;

const FullWidthSelect = styled(Select)`
    width: 100%;
`;

interface ChooseCompanyProps {
    onComplete: (company: API.CompanyListItem) => void;
}

const ChooseCompany: React.FC<ChooseCompanyProps> = ({ onComplete }) => {
    const history = useHistory();
    const { t, tCommon } = useTranslation('choose_company');
    const [company, setCompany] = useState(undefined);
    const { isLoading, data: companies = [] } = useGetCompanies();

    const handleOnOk = () => {
        history.push(Routes.ROOT);
        onComplete(company);
    };

    const onChange = (value: string) => {
        setCompany(companies.find((company) => company.id === value));
    };

    return (
        <Modal
            title={t('title')}
            visible
            onOk={handleOnOk}
            closable={false}
            footer={
                <>
                    <PrimaryButton icon={<BookOutlined />} aria-label="choose" onClick={handleOnOk} disabled={!company}>
                        {tCommon('choose')}
                    </PrimaryButton>
                </>
            }
        >
            <FullWidthSelect
                loading={isLoading}
                showSearch
                placeholder={t('search_placeholder')}
                optionFilterProp="children"
                onChange={onChange}
                filterOption
            >
                {companies.map(({ id, name }: API.CompanyListItem) => (
                    <Option key={id} value={id}>
                        {name}
                    </Option>
                ))}
            </FullWidthSelect>
        </Modal>
    );
};

export default ChooseCompany;
