import { API } from '@eservices/shared/type';
import { FormInstance } from 'antd';
import styled from 'styled-components';
import DepartmentForm from './DepartmentForm';
import DepartmentUsersEditor from './DepartmentUsersEditor';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 5%;
`;

interface DepartmentDetailProps {
    isUsersReadOnly: boolean;
    isDetailsReadOnly: boolean;
    form: FormInstance;
    companyId: string;
    users: API.User[];
    setUsers?: (users: API.User[]) => void;
}

const DepartmentDetail: React.FC<DepartmentDetailProps> = ({
    isUsersReadOnly,
    isDetailsReadOnly,
    form,
    companyId,
    users,
    setUsers,
}) => {
    return (
        <Container>
            <DepartmentForm isReadOnly={isDetailsReadOnly} form={form} />
            <DepartmentUsersEditor
                isReadOnly={isUsersReadOnly}
                companyId={companyId}
                users={users}
                setUsers={setUsers}
            />
        </Container>
    );
};

export default DepartmentDetail;
