import { ClockCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React from 'react';
import Countdown from 'react-countdown';
import { Colors } from '@eservices/ui-constants/colors';
import { useTranslation } from '../../hooks/translationHook';
import styled from 'styled-components';

const Clock = styled(ClockCircleOutlined)`
    font-size: 50px;
`;

const Container = styled.div`
    text-align: center;
    padding: 20px;
    color: ${Colors.GRAY_SENARY};
`;

interface TaskExpirationProps {
    timeToWait: number;
}

const TaskExpiration: React.FC<TaskExpirationProps> = ({ timeToWait }) => {
    const { t } = useTranslation('se_detail');

    return (
        <Container>
            <Countdown
                date={timeToWait}
                renderer={({ days, hours, minutes }) => (
                    <Space direction="vertical">
                        <Clock />
                        {days > 1 ? (
                            t('expires_days', { count: days })
                        ) : (
                            <span>{t('expires', { hours, minutes })}</span>
                        )}
                    </Space>
                )}
            />
        </Container>
    );
};

export default TaskExpiration;
