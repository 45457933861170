import { Space } from 'antd';
import React from 'react';
import { Colors } from '@eservices/ui-constants/colors';
import styled from 'styled-components';

const Description = styled(Space)`
    overflow-wrap: anywhere;
    color: ${Colors.GRAY_SEPTENARY};
    p {
        text-transform: capitalize;
        overflow-wrap: normal;
        display: inline-block;
        margin-right: 6px;
        color: ${Colors.DARK_PRIMARY};
    }
`;

interface DescriptionItemProps {
    title: string;
    content: React.ReactNode;
}

const DescriptionItem: React.FC<DescriptionItemProps> = ({ title, content }) => (
    <Description align="start">
        <p>{title}:</p>
        {content}
    </Description>
);

export default DescriptionItem;
