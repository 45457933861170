import { Language } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Form, FormInstance } from 'antd';
import styled from 'styled-components';
import { useTranslation } from '../../hooks/translationHook';
import Flag from '../Flag';
import { FormItem as BaseFormItem } from '../FormItem';
import { Input, Select } from '../Input';

interface UserDetailFormProps {
    form: FormInstance<API.UpdateUserProfile>;
}

const Box = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
`;
const FormItem = styled(BaseFormItem)`
    width: 48%;
    min-width: 200px;
`;

const UserDetailsForm: React.FC<UserDetailFormProps> = ({ form }) => {
    const { tCommon, tValidation } = useTranslation('profile');

    return (
        <Form form={form} requiredMark={false} layout="vertical">
            <FormItem hidden name="lastModifiedAt">
                <Input />
            </FormItem>
            <Box>
                <FormItem
                    label={tCommon('username')}
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.admin_username'),
                        },
                    ]}
                >
                    <Input disabled={true} type="text" aria-label="user username input" />
                </FormItem>
                <FormItem
                    label={tCommon('email')}
                    name="emailAddress"
                    rules={[
                        {
                            required: true,
                            type: 'email',
                            message: tValidation('valid.email'),
                        },
                    ]}
                >
                    <Input disabled={true} type="email" aria-label="user email input" />
                </FormItem>
            </Box>
            <Box>
                <FormItem
                    label={tCommon('firstname')}
                    name="firstName"
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.firstname'),
                        },
                    ]}
                >
                    <Input type="text" aria-label="user firstname input" />
                </FormItem>
                <FormItem
                    label={tCommon('lastname')}
                    name="lastName"
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.lastname'),
                        },
                    ]}
                >
                    <Input type="text" aria-label="user lastname input" />
                </FormItem>
            </Box>
            <Box>
                <FormItem label={tCommon('preferred_language')} name="preferredLanguage">
                    <Select
                        aria-label="user language select"
                        options={Object.keys(Language).map((language: Language) => ({
                            label: <Flag country={language} />,
                            value: Language[language],
                        }))}
                    />
                </FormItem>
            </Box>
        </Form>
    );
};

export default UserDetailsForm;
