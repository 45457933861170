import { Job as DomainJob, SendingEvent as DomainSendingEvent } from './domain-model';

export type SendingEvent = Omit<DomainSendingEvent, 'config'> & {
    workflowId: string;
};

export type Job = Omit<DomainJob, 'config'> & {
    workflowId: string;
};

export interface AggregationBucket {
    key: string;
    doc_count: number;
}
