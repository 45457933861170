import { BookOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { ParcelType, ParcelCustomsShipmentType } from '@eservices/shared/constant';
import { API, RecipientWithParcelInfo } from '@eservices/shared/type';
import { Avatar, Button, List, Skeleton } from 'antd';
import { useReducer, useState } from 'react';
import { Colors } from '@eservices/ui-constants/colors';
import { useSearchParcelContacts } from '../../hooks/apiHooks';
import { useBpostCountries } from '../../hooks/bpostCountriesHook';
import { useTranslation } from '../../hooks/translationHook';
import { addWildcards, generateInitials } from '../../utils/helpers';
import styled from 'styled-components';
import AvatarWithDelete from '../AvatarWithDelete';
import { IconButton, PrimaryButton, SecondaryButton } from '../Buttons';
import { Header3 } from '../Headers';
import { Input } from '../Input';
import { Modal } from '../Modal';
import { contactsReducer } from './contactReducer';
import InfiniteScroll from 'react-infinite-scroll-component';

const PAGE_SIZE = 20;

const Search = styled.div`
    margin: 0% 0 5% 0;
`;

const Selection = styled.div`
    margin-top: 5%;
    min-height: 40px;
`;

const SelectedContacts = styled.div`
    display: flex;
    gap: 2%;
    flex-flow: row wrap;
`;

const ScrollableContainer = styled.div`
    height: 400px;
    overflow: auto;
`;

interface AvatarStyledProps {
    selected: boolean;
}

const AvatarStyled = styled(Avatar)<AvatarStyledProps>`
    color: ${Colors.GRAY_SEPTENARY};
    background-color: ${Colors.WHITE};
    border: 1px solid ${({ selected }) => (selected ? Colors.PRIMARY : Colors.GRAY_QUATERNARY)};
`;

interface AddressBookProps {
    companyId: string;
    onSave: (recipient: RecipientWithParcelInfo[]) => void;
}

const AddressBook: React.FC<AddressBookProps> = ({ onSave, companyId }) => {
    const { mutateAsync: searchContacts, isLoading } = useSearchParcelContacts(companyId, {
        staleTime: 60000,
    });
    const [{ contacts }, contactsDispatch] = useReducer(contactsReducer, { contacts: [] });
    const { t, tCommon } = useTranslation('create_parcels');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { isLoadingCountries, findCountryName, requiresCustoms } = useBpostCountries();
    const [keyword, setKeyword] = useState<string>();
    const [currentPage, setCurrentPage] = useState(1);
    const [contactItems, setContactsItems] = useState<API.ParcelContactListItem[]>([]);
    const [totalItems, setTotalItems] = useState<number>();

    const showModal = async () => {
        await handleCleanSearch('*');
        contactsDispatch({ type: 'empty' });
        setIsModalVisible(true);
    };

    const handleOk = () => {
        onSave(
            contacts.map((contact) => {
                const type = requiresCustoms(contact.address.countryCode)
                    ? ParcelType.PARCEL_WITH_CUSTOMS
                    : ParcelType.PARCEL_WITHOUT_CUSTOMS;
                const defaultData = {
                    ...contact,
                    parcelName: t('parcel_for', { parcelName: contact.companyName || contact.name }),
                    bpostOptions: {
                        hasInsurance: false,
                        hasSignature: false,
                        needsMailAnnouncement: false,
                    },
                };

                switch (type) {
                    case ParcelType.PARCEL_WITH_CUSTOMS:
                        return {
                            ...defaultData,
                            customsDetails: {
                                shipmentType: ParcelCustomsShipmentType.OTHER,
                                contentDescription: '',
                                contents: [],
                            },
                            type,
                            hasError: true,
                        };
                    case ParcelType.PARCEL_WITHOUT_CUSTOMS:
                        return {
                            ...defaultData,
                            type,
                            hasError: false,
                        };
                }
            }),
        );
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleClose = () => {
        contactsDispatch({ type: 'empty' });
    };

    const handleAddContact = (contact: API.ParcelContactListItem) => {
        contactsDispatch({ type: 'insert', payload: contact });
    };

    const handleDeleteContact = (id: string) => {
        contactsDispatch({ type: 'delete', payload: id });
    };

    const handleOnSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
        await handleCleanSearch(addWildcards(e.target.value));
    };

    const handleCleanSearch = async (input: string) => {
        const initPage = 1;
        setKeyword(input);
        setCurrentPage(initPage);
        await searchContacts(
            {
                search: input,
                pagination: { page: initPage, pageSize: PAGE_SIZE },
            },
            {
                onSuccess: (data) => {
                    setContactsItems(data.items);
                    setTotalItems(data.pagination.totalItems);
                },
            },
        );
    };

    const nextScrollSearch = async () => {
        const nextPage = currentPage + 1;
        setCurrentPage(nextPage);
        await searchContacts(
            {
                search: keyword,
                pagination: { page: nextPage, pageSize: PAGE_SIZE },
            },
            {
                onSuccess: (data) => {
                    setContactsItems([...contactItems, ...data.items]);
                    setTotalItems(data.pagination.totalItems);
                },
            },
        );
    };

    const scrollableContainer = 'scrollableContainer';

    const availableContacts = () => (
        <div>
            <ScrollableContainer id={scrollableContainer}>
                <InfiniteScroll
                    dataLength={contactItems.length}
                    next={nextScrollSearch}
                    hasMore={contactItems.length < totalItems}
                    loader={<Skeleton avatar paragraph={{ rows: 1 }} active={isLoading} />}
                    scrollableTarget={scrollableContainer}
                >
                    <List
                        loading={isLoading || isLoadingCountries}
                        size="small"
                        itemLayout="horizontal"
                        dataSource={contactItems}
                        renderItem={(contact) => {
                            const {
                                id,
                                companyName,
                                name,
                                address: { city, countryCode, postalCode, street, streetNr, box },
                            } = contact;
                            const selected = contacts.includes(contact);
                            return (
                                <List.Item
                                    key={id}
                                    actions={[
                                        <IconButton
                                            hidden={selected}
                                            icon={<PlusOutlined />}
                                            onClick={() => handleAddContact(contact)}
                                        />,
                                    ]}
                                >
                                    <List.Item.Meta
                                        avatar={
                                            <AvatarStyled selected={selected}>
                                                {generateInitials(contact.companyName || contact.name)}
                                            </AvatarStyled>
                                        }
                                        title={companyName || name}
                                        description={
                                            <>
                                                {companyName && (
                                                    <>
                                                        <b> {name}</b>
                                                        <br />
                                                    </>
                                                )}
                                                {street}, {streetNr} {box}
                                                <br />
                                                {postalCode} {city} - {findCountryName(countryCode)}
                                            </>
                                        }
                                    />
                                </List.Item>
                            );
                        }}
                    />
                </InfiniteScroll>
            </ScrollableContainer>
        </div>
    );

    const selection = () => (
        <Selection>
            <Header3>{t('selection')}</Header3>
            <SelectedContacts>
                {contacts.map(({ id, name }, index) => (
                    <AvatarWithDelete
                        key={id}
                        id={id}
                        color={Colors.GRAY_SEPTENARY}
                        name={name}
                        onRemove={handleDeleteContact}
                    />
                ))}
            </SelectedContacts>
        </Selection>
    );

    return (
        <>
            <Button type="text" icon={<BookOutlined />} onClick={showModal}>
                {tCommon('address_book')}
            </Button>
            <Modal
                title={tCommon('address_book')}
                visible={isModalVisible}
                onCancel={handleCancel}
                afterClose={handleClose}
                destroyOnClose
                footer={
                    <>
                        <SecondaryButton aria-label="cancel" icon={<CloseOutlined />} onClick={handleCancel}>
                            {tCommon('cancel')}
                        </SecondaryButton>
                        <PrimaryButton icon={<BookOutlined />} aria-label="save" onClick={handleOk}>
                            {tCommon('save')}
                        </PrimaryButton>
                    </>
                }
            >
                <Search>
                    <Input
                        type="text"
                        aria-label="company name input"
                        placeholder="Enter name or address"
                        onChange={handleOnSearch}
                    />
                </Search>
                {availableContacts()}
                {selection()}
            </Modal>
        </>
    );
};

export default AddressBook;
