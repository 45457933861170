import { Radio as AntRadio } from 'antd';
import styled from 'styled-components';

const borderRadius = '7px';

export const Radio = styled(AntRadio)`
    .ant-radio-group {
        border-top-right-radius: ${borderRadius};
        border-bottom-right-radius: ${borderRadius};
        border-top-left-radius: ${borderRadius};
        border-bottom-left-radius: ${borderRadius};
    }
`;
