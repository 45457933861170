import { Colors } from '@eservices/ui-constants/colors';
import { createGlobalStyle } from 'styled-components';
import { Fonts } from './constants';

const GlobalStyle = createGlobalStyle`
    html, body {
        height: 100vh;
        background-color: ${Colors.WHITE};
    }

    @font-face {
        font-family: '${Fonts.HURME1}';
        src: url(./assets/fonts/${Fonts.HURME1}.otf);
    }
    @font-face {
        font-family: '${Fonts.HURME4}';
        src: url(./assets/fonts/${Fonts.HURME4}.otf);
    }
    @font-face {
        font-family: '${Fonts.HURME4_BOLD}';
        src: url(./assets/fonts/${Fonts.HURME4_BOLD}.otf);
    }
    @font-face {
        font-family: '${Fonts.HURME4_SEMI_BOLD}';
        src: url(./assets/fonts/${Fonts.HURME4_SEMI_BOLD}.otf);
    }
    @font-face {
        font-family: '${Fonts.HURME4}';
        src: url(./assets/fonts/${Fonts.HURME4}.otf);
    }
    @font-face {
        font-family: '${Fonts.HURME4_LIGHT}';
        src: url(./assets/fonts/${Fonts.HURME4_LIGHT}.otf);
    }
    @font-face {
        font-family: '${Fonts.ARIAL_REGULAR}';
        src: url(./assets/fonts/${Fonts.ARIAL_REGULAR}.ttf);
    }
    @font-face {
        font-family: '${Fonts.ARIAL_BOLD}';
        src: url(./assets/fonts/${Fonts.ARIAL_BOLD}.ttf);
    }
    * {
        font-family: '${Fonts.ARIAL_REGULAR}';
    }

    .anticon-check-circle {
        color: ${Colors.OK};
    }

    .anticon-alert {
        color: ${Colors.ACTION_REQUIRED};
    }

    .anticon-warning {
        color: ${Colors.WARNING};
    }

    .anticon-fire {
        color: ${Colors.ERROR};
    }

    .anticon-loading, .anticon-loading-3-quarters {
        color: ${Colors.PROCESSING}
    }

    b, strong {
        font-family: '${Fonts.ARIAL_BOLD}';
    }

    .ant-tag {
        margin-right: 0px;
    }

    .ant-popover-inner {
        border-radius: 7px;
    }
`;

export default GlobalStyle;
