import React from 'react';
import { Colors } from '@eservices/ui-constants/colors';
import styled from 'styled-components';

const Modal = styled.div`
     {
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.4);
    }
`;

const Content = styled.div`
    margin: 20% auto;
    text-align: center;
`;

const LoadingStyled = styled.div`
    display: inline-block;
    position: relative;
    width: 60px;
    height: 60px;

    div {
        position: absolute;
        border: 4px solid ${Colors.PRIMARY};
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    div:nth-child(2) {
        animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }
`;

const Loading: React.FC = () => (
    <Modal>
        <Content>
            <LoadingStyled>
                <div></div>
                <div></div>
            </LoadingStyled>
        </Content>
    </Modal>
);

export default Loading;
