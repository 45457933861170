import { TOptions, StringMap } from 'i18next';
import { useTranslation as useI18nextTranslation } from 'react-i18next';

type options = string | TOptions<StringMap>;

export function useTranslation(context?: string) {
    const { t: defaultT, i18n } = useI18nextTranslation(['glossary', 'common', 'error', 'validation']);

    function t(key: string, options?: options): string {
        return defaultT(`glossary:${context}.${key}`, options);
    }

    function tCommon(key: string, options?: options): string {
        return defaultT(`common:${key}`, options);
    }

    function tError(key: string, options?: options): string {
        return defaultT(`error:${key}`, options);
    }

    function tValidation(key: string, options?: options): string {
        return defaultT(`validation:${key}`, options);
    }

    return { t, tCommon, tError, tValidation, i18n };
}
