import { ApartmentOutlined } from '@ant-design/icons';
import { ApplicationModule, UserRole } from '@eservices/shared/constant';
import { Routes } from '../../constants';
import { BasicRoute, MainRoute } from '../../types';
import DepartmentDetailCreate from './DepartmentDetailCreate';
import DepartmentDetailEdit from './DepartmentDetailEdit';
import DepartmentDetailView from './DepartmentDetailView';
import DepartmentsOverviewEdit from './DepartmentsOverviewEdit';
import DepartmentsOverviewView from './DepartmentsOverviewView';

const editDepartmentOverviewPageThatCanCreateDepartment = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.DEPARTMENTS,
    component: <DepartmentsOverviewEdit canCreateDepartment companyId={companyId} />,
});

const editDepartmentOverviewPage = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.DEPARTMENTS,
    component: <DepartmentsOverviewEdit canCreateDepartment={false} companyId={companyId} />,
});

const viewDepartmentOverviewPage = (companyId: string): BasicRoute => ({
    exact: true,
    path: Routes.DEPARTMENTS,
    component: <DepartmentsOverviewView companyId={companyId} />,
});

const createDepartmentPage = (companyId: string): BasicRoute => ({
    path: Routes.CREATE_DEPARTMENT,
    component: <DepartmentDetailCreate companyId={companyId} />,
});

const editDepartmentPage = (companyId: string): BasicRoute => ({
    path: Routes.EDIT_DEPARTMENT,
    component: <DepartmentDetailEdit companyId={companyId} isDetailsReadOnly={false} isUsersReadOnly={false} />,
});

const editDepartmentUsersPage = (companyId: string): BasicRoute => ({
    path: Routes.EDIT_DEPARTMENT,
    component: <DepartmentDetailEdit companyId={companyId} isDetailsReadOnly={true} isUsersReadOnly={false} />,
});

const viewDepartmentPage = (companyId: string): BasicRoute => ({
    path: Routes.VIEW_DEPARTMENT,
    component: <DepartmentDetailView companyId={companyId} />,
});

export const createDepartmentsRouter = (userRole: UserRole, companyId: string): MainRoute => {
    const departmentRoutes = {
        [UserRole.SUPER_USER]: [
            editDepartmentOverviewPageThatCanCreateDepartment(companyId),
            createDepartmentPage(companyId),
            editDepartmentPage(companyId),
        ],
        [UserRole.CLIENT_ADMIN]: [editDepartmentOverviewPage(companyId), editDepartmentUsersPage(companyId)],
        [UserRole.CLIENT_DEPARTMENT_ADMIN]: [editDepartmentOverviewPage(companyId), editDepartmentUsersPage(companyId)],
        [UserRole.CLIENT_OPERATOR]: [viewDepartmentOverviewPage(companyId), viewDepartmentPage(companyId)],
        [UserRole.CLIENT_VIEWER]: [viewDepartmentOverviewPage(companyId), viewDepartmentPage(companyId)],
    };

    return {
        path: Routes.DEPARTMENTS,
        title: 'departments',
        icon: <ApartmentOutlined />,
        subRoutes: departmentRoutes[userRole],
        module: ApplicationModule.OMNI,
    };
};
