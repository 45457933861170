import { CompanyProcessingType, CompanyVolume } from '@eservices/shared/constant';
import { Col, Form, FormInstance, Row } from 'antd';
import { FC } from 'react';
import { FormItem } from '../../components/FormItem';
import { Input } from '../../components/Input';
import { Radio } from '../../components/Radio';
import { useTranslation } from '../../hooks/translationHook';

interface CopmanyDetailsFormProps {
    isReadOnly: boolean;
    form: FormInstance;
}

const CompanyForm: FC<CopmanyDetailsFormProps> = ({ isReadOnly, form }) => {
    const { tCommon, tValidation } = useTranslation();
    return (
        <Form form={form} layout="vertical" name="company" requiredMark={false}>
            <Row>
                <Col span={24}>
                    <FormItem
                        label={tCommon('name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.company_name'),
                            },
                        ]}
                    >
                        <Input disabled={isReadOnly} type="text" aria-label="company name input" />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    <FormItem
                        label={tCommon('country_code')}
                        name="countryCode"
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.country_code'),
                            },
                        ]}
                    >
                        <Input disabled={isReadOnly} type="text" aria-label="company country code input" />
                    </FormItem>
                </Col>
                <Col offset={2} span={11}>
                    <FormItem
                        label={tCommon('city')}
                        name="city"
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.city'),
                            },
                        ]}
                    >
                        <Input disabled={isReadOnly} type="text" aria-label="company city input" />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FormItem
                        label={tCommon('street')}
                        name="street"
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.street'),
                            },
                        ]}
                    >
                        <Input disabled={isReadOnly} type="text" aria-label="company street input" />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    <FormItem
                        label={tCommon('postal_code')}
                        name="postalCode"
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.postal_code'),
                            },
                        ]}
                    >
                        <Input disabled={isReadOnly} type="text" aria-label="company postal code input" />
                    </FormItem>
                </Col>
                <Col offset={2} span={11}>
                    <FormItem
                        label={tCommon('number_short')}
                        name="streetNr"
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.street_number'),
                            },
                        ]}
                    >
                        <Input
                            disabled={isReadOnly}
                            type="text"
                            aria-label="company number input"
                            minLength={1}
                            maxLength={10}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FormItem
                        label={tCommon('phone_number')}
                        name="phoneNumber"
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.phone_number'),
                            },
                        ]}
                    >
                        <Input disabled={isReadOnly} type="tel" aria-label="company phone number input" />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FormItem
                        label={tCommon('email')}
                        name="emailAddress"
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: tValidation('valid.email'),
                            },
                        ]}
                    >
                        <Input disabled={isReadOnly} type="email" aria-label="company email input" />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    <FormItem label={tCommon('identification_number')} name="identificationNumber">
                        <Input disabled={isReadOnly} type="text" aria-label="company identification number input" />
                    </FormItem>
                </Col>
                <Col offset={2} span={11}>
                    <FormItem
                        label={tCommon('erp_id')}
                        name="erpId"
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.erpId'),
                            },
                        ]}
                    >
                        <Input disabled={isReadOnly} type="text" aria-label="company erp id input" />
                    </FormItem>
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    <FormItem
                        label={tCommon('volume')}
                        name="volume"
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.volume'),
                            },
                        ]}
                    >
                        <Radio.Group optionType="button" buttonStyle="solid" size="small" disabled={isReadOnly}>
                            <Radio.Button aria-label="company volume small" value={CompanyVolume.SMALL}>
                                {tCommon('volume_small')}
                            </Radio.Button>
                            <Radio.Button aria-label="company volume large" value={CompanyVolume.LARGE}>
                                {tCommon('volume_large')}
                            </Radio.Button>
                        </Radio.Group>
                    </FormItem>
                </Col>
                <Col offset={2} span={11}>
                    <FormItem
                        label={tCommon('processing_type')}
                        name="processingType"
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.processing_type'),
                            },
                        ]}
                    >
                        <Radio.Group optionType="button" buttonStyle="solid" size="small" disabled={isReadOnly}>
                            <Radio.Button
                                aria-label="company processing type single"
                                value={CompanyProcessingType.SINGLE_FILE}
                            >
                                {tCommon('processing_type_single')}
                            </Radio.Button>
                            <Radio.Button
                                aria-label="company processing type batch"
                                value={CompanyProcessingType.BATCH_FILE}
                            >
                                {tCommon('processing_type_batch')}
                            </Radio.Button>
                        </Radio.Group>
                    </FormItem>
                </Col>
            </Row>
        </Form>
    );
};

export default CompanyForm;
