import { API } from '@eservices/shared/common';
import { ChannelProperties, WorkflowInputSourceType } from '@eservices/shared/constant';
import { WatermarkUI } from '../../../../types';

export type WorkflowPDFInputSourceDrawingActions =
    | {
          type: 'insertChannelIdentifier';
          payload: API.PDFChannelIdentifier;
      }
    | {
          type: 'insertSplitIdentifier';
          payload: API.PDFSplitIdentifier;
      }
    | {
          type: 'insertIgnoreIdentifier';
          payload: API.PDFIgnoreIdentifier;
      }
    | {
          type: 'insertExtraInfoIdentifier';
          payload: API.PDFExtraInfoIdentifier;
      }
    | {
          type: 'insertWatermark';
          payload: WatermarkUI;
      };

export type WorkflowPDFInputSourceActions =
    | WorkflowPDFInputSourceDrawingActions
    | { type: 'removeChannelIdentifier'; payload: ChannelProperties }
    | { type: 'updateSplitIdentifier'; payload: { order: number; value: string } }
    | { type: 'removeSplitIdentifier'; payload: { order: number } }
    | { type: 'updateIgnoreIdentifier'; payload: { order: number; value: string } }
    | { type: 'removeIgnoreIdentifier'; payload: { order: number } }
    | { type: 'updateExtraInfoIdentifier'; payload: { order: number; value: string } }
    | { type: 'removeExtraInfoIdentifier'; payload: { order: number } }
    | { type: 'setFullState'; payload: { state: API.WorkflowPDFInputSource } };

export const initialState: API.WorkflowPDFInputSource = {
    type: WorkflowInputSourceType.PDF,
    channelIdentifiers: [],
    splitIdentifiers: [],
    ignoreIdentifiers: [],
    extraInfoIdentifiers: [],
};

export function workflowPDFInputSourceReducer(
    state: API.WorkflowPDFInputSource,
    action: WorkflowPDFInputSourceActions,
): API.WorkflowPDFInputSource {
    switch (action.type) {
        case 'insertChannelIdentifier': {
            const newIdentifier = action.payload;
            const filteredChannelIdentifiers = state.channelIdentifiers.filter(
                (identifier) => identifier.kind !== newIdentifier.kind,
            );
            const channelIdentifiers = [...filteredChannelIdentifiers, newIdentifier];
            return { ...state, channelIdentifiers };
        }
        case 'removeChannelIdentifier': {
            const channelIdentifiers = state.channelIdentifiers.filter(
                (identifier) => identifier.kind !== action.payload,
            );
            return { ...state, channelIdentifiers };
        }
        case 'insertSplitIdentifier': {
            const newIdentifier = action.payload;
            const splitIdentifiers = [...state.splitIdentifiers, newIdentifier];
            return { ...state, splitIdentifiers };
        }
        case 'updateSplitIdentifier': {
            const order = action.payload.order;
            const value = action.payload.value;
            const updatedSplitIdentifier = { ...state.splitIdentifiers[order], value: value };

            return {
                ...state,
                splitIdentifiers: Object.assign([], state.splitIdentifiers, {
                    [order]: updatedSplitIdentifier,
                }),
            };
        }
        case 'removeSplitIdentifier': {
            const order = action.payload.order;
            return {
                ...state,
                splitIdentifiers: [
                    ...state.splitIdentifiers.slice(0, order),
                    ...state.splitIdentifiers.slice(order + 1),
                ],
            };
        }
        case 'insertIgnoreIdentifier': {
            const newIdentifier = action.payload;
            const ignoreIdentifiers = [...state.ignoreIdentifiers, newIdentifier];
            return { ...state, ignoreIdentifiers };
        }
        case 'updateIgnoreIdentifier': {
            const order = action.payload.order;
            const value = action.payload.value;
            const updatedIgnoreIdentifier = { ...state.ignoreIdentifiers[order], value: value };

            return {
                ...state,
                ignoreIdentifiers: Object.assign([], state.ignoreIdentifiers, {
                    [order]: updatedIgnoreIdentifier,
                }),
            };
        }
        case 'removeIgnoreIdentifier': {
            const order = action.payload.order;
            return {
                ...state,
                ignoreIdentifiers: [
                    ...state.ignoreIdentifiers.slice(0, order),
                    ...state.ignoreIdentifiers.slice(order + 1),
                ],
            };
        }
        case 'insertExtraInfoIdentifier': {
            const newIdentifier = action.payload;
            const extraInfoIdentifiers = [...state.extraInfoIdentifiers, newIdentifier];
            return { ...state, extraInfoIdentifiers };
        }
        case 'updateExtraInfoIdentifier': {
            const order = action.payload.order;
            const value = action.payload.value;
            const updatedExtraInfoIdentifier = { ...state.extraInfoIdentifiers[order], name: value };
            return {
                ...state,
                extraInfoIdentifiers: Object.assign([], state.extraInfoIdentifiers, {
                    [order]: updatedExtraInfoIdentifier,
                }),
            };
        }
        case 'removeExtraInfoIdentifier': {
            const order = action.payload.order;
            return {
                ...state,
                extraInfoIdentifiers: [
                    ...state.extraInfoIdentifiers.slice(0, order),
                    ...state.extraInfoIdentifiers.slice(order + 1),
                ],
            };
        }
        case 'setFullState': {
            return { ...action.payload.state };
        }
        default:
            throw new Error();
    }
}
