import { Subscriptions } from '@eservices/shared/constant';
import { SubscriptionContext } from '../../constants';
import { useGetCompanySubscriptionDetails } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import FailedToLoadData from '../FailedToLoadData';
import { Header2 } from '../Headers';
import { Paragraph } from '../Paragraph';
import CustomModal from './CustomModal';

interface SubscriptionModalProps {
    companyId: string;
    context: SubscriptionContext;
}

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({ companyId, context }) => {
    const { t } = useTranslation('subscription');
    const { isLoading, isError, data: subscriptionDetails } = useGetCompanySubscriptionDetails(companyId);

    const contextKey = context.toLowerCase();

    if (isLoading) return null;

    if (isError) {
        return (
            <CustomModal>
                <FailedToLoadData />
            </CustomModal>
        );
    }

    const showSubscriptionDetail =
        Subscriptions[subscriptionDetails.subscriptionType][`maxAmountOf${context}`] -
            subscriptionDetails[`${contextKey}Count`] <=
        0;

    if (showSubscriptionDetail) {
        return (
            <CustomModal>
                {t('subscription_restrictions_apply')}
                <Header2>
                    {t('subscription_type_title', { subscription: t(subscriptionDetails.subscriptionType) })}
                </Header2>
                <Paragraph>{t(`subscription_${contextKey}_description`)}</Paragraph>
            </CustomModal>
        );
    }

    return null;
};

export default SubscriptionModal;
