import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Colors } from '@eservices/ui-constants/colors';
import styled from 'styled-components';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Container = styled.div`
    box-sizing: content-box;
    border: 1px dotted ${Colors.GRAY_SEPTENARY};
`;
const PDFContainer = styled.div`
    overflow: auto;
    width: 100%;
`;

interface PDFPreviewProps {
    pdfUrl: string;
}

const defaultPageNumber = 1;

const PDFPreview: React.FC<PDFPreviewProps> = ({ pdfUrl }) => {
    const [canvasWidth, setCanvasWidth] = useState(0);
    const pdfContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (pdfContainerRef !== null) {
            setCanvasWidth(pdfContainerRef.current.getBoundingClientRect().width);
        }
    }, [pdfContainerRef]);

    return (
        <Container>
            <PDFContainer ref={pdfContainerRef}>
                <Document file={pdfUrl}>
                    <Page renderTextLayer={false} pageNumber={defaultPageNumber} width={canvasWidth} />
                </Document>
            </PDFContainer>
        </Container>
    );
};

export default PDFPreview;
