import styled from 'styled-components';
import TotalInfoTile from './TotalInfoTile';

const Statuses = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    margin: 20px 0 0 0;
    gap: 20px;
`;

interface LayoutProps {
    title: string;
    amount: number;
    baseRoute: string;
    rangeDate: string;
    departmentId: string;
    isDetailsLoading: boolean;
    totalWarning?: number;
}

const Layout: React.FC<LayoutProps> = ({
    title,
    amount,
    baseRoute,
    rangeDate,
    departmentId,
    children,
    isDetailsLoading,
    totalWarning,
}) => {
    return (
        <div>
            <TotalInfoTile
                titleSection={title}
                amount={amount}
                baseRoute={baseRoute}
                rangeDate={rangeDate}
                departmentId={departmentId}
                isDetailsLoading={isDetailsLoading}
                totalWarning={totalWarning}
            />
            <Statuses>{children}</Statuses>
        </div>
    );
};

export default Layout;
