import styled from 'styled-components';
import { Colors } from '@eservices/ui-constants/colors';

export const Notification = styled.span`
    font-size: 12px;
    color: ${Colors.GRAY_SECONDARY};
`;

export const notificationStyle = {
    width: '500px',
    color: Colors.GRAY_SECONDARY,
};
