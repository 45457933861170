import { Language, SendingEventBusinessStatus } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Divider, message, Result, Space, Steps } from 'antd';
import { formatDistance } from 'date-fns';
import { enGB, fr, nl } from 'date-fns/locale';
import { Status } from 'rc-steps/lib/interface';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TaskActionType } from '../../constants';
import { useTaskActionRequest } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import ChannelIndicator from '../ChannelIndicator';
import DescriptionItem from '../DescriptionItem';
import DetailDrawer from '../DetailDrawer';
import WorkflowFormDetail from '../workflow/WorkflowFormDetail';
import TaskActions from './TaskActions';
import TaskDetail from './TaskDetail';

const locales = {
    [Language.EN]: enGB,
    [Language.NL]: nl,
    [Language.FR]: fr,
};

const SpaceStyled = styled(Space)`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    p {
        margin: 0;
    }
`;

const loadingMessageKey = 'loadingMessageKey';

const ChannelIndicatorStyled = styled(ChannelIndicator)`
    font-size: 24px !important;
    color: green;
`;

const getStatus = (taskDetail: API.TaskDetails, nextTask: API.TaskDetails): Status => {
    if (taskDetail.endDate && (nextTask ? nextTask.startDate : taskDetail.endDate)) return 'finish';
    if (taskDetail.startDate) return 'process';
    if (!taskDetail.startDate) return 'wait';
};

interface SendingEventsDetailProps {
    companyId: string;
    sendingEvent: API.SendingEvent;
    handleOnClose: () => void;
}

const SendingEventsDetail: React.FC<SendingEventsDetailProps> = ({ companyId, sendingEvent, handleOnClose }) => {
    const { t, tCommon, i18n } = useTranslation('se_detail');
    const [taskIndex, setTaskIndex] = useState(0);
    const { departmentId, jobId, id: sendingEventId } = sendingEvent;
    const taskActionMutation = useTaskActionRequest({ companyId, departmentId, jobId, sendingEventId });

    useEffect(() => {
        const currentActiveTaskIndex = sendingEvent.taskDetails.findIndex(
            ({ startDate, endDate }) => startDate && !endDate,
        );

        if (currentActiveTaskIndex === -1) setTaskIndex(sendingEvent.taskDetails.length + 1);
        else setTaskIndex(currentActiveTaskIndex + 1);
    }, []);

    const onConfirmTaskAction = (taskId: string, actionType: TaskActionType) => {
        void message.loading({ key: loadingMessageKey, content: t(`${actionType}_requested`) }, 0);
        taskActionMutation.mutate(
            { taskId, actionType },
            {
                onSuccess: () => {
                    void message.success(t(`${actionType}_success`), 3);
                },
                onError: () => {
                    void message.error(t(`${actionType}_error`), 3);
                },
                onSettled: () => {
                    message.destroy(loadingMessageKey);
                    handleOnClose();
                },
            },
        );
    };

    const handleStepChange = (current) => {
        setTaskIndex(current);
    };

    const distanceOptions = { includeSeconds: true, locale: locales[i18n.language.toLocaleUpperCase()] };

    const timeline = (
        <Steps progressDot size="small" current={taskIndex} onChange={handleStepChange}>
            <Steps.Step title="Start" status="finish" />
            {sendingEvent.taskDetails.map((taskDetail, i) => {
                const taskConfig = sendingEvent.config.tasks.find(({ id }) => id === taskDetail.id);
                return (
                    <Steps.Step
                        key={taskDetail.id}
                        title={<ChannelIndicatorStyled type={taskConfig.taskType} />}
                        status={getStatus(taskDetail, sendingEvent.taskDetails[i + 1])}
                    />
                );
            })}
            <Steps.Step
                disabled={sendingEvent.endDate === undefined}
                title="End"
                status={sendingEvent.endDate ? 'finish' : 'wait'}
            />
        </Steps>
    );

    const workflowDetail = () => (
        <WorkflowFormDetail
            companyId={companyId}
            departmentId={sendingEvent.departmentId}
            workflowConfiguration={sendingEvent.config}
        />
    );

    const failureResult = () => (
        <Result
            status="error"
            title={t('result_title_error')}
            subTitle={t('result_sub_title_error', {
                duration: formatDistance(new Date(sendingEvent.startDate), new Date(), distanceOptions),
            })}
        />
    );

    const successResult = () => (
        <Result
            status="success"
            title={t('result_title')}
            subTitle={t('result_sub_title', {
                duration: formatDistance(
                    new Date(sendingEvent.startDate),
                    sendingEvent.endDate ? new Date(sendingEvent.endDate) : new Date(),
                    distanceOptions,
                ),
            })}
        />
    );

    const taskDetail = () => {
        const task = sendingEvent.taskDetails[taskIndex - 1];
        const taskConfiguration = sendingEvent.config.tasks.find(({ id }) => task.id === id);

        return <TaskDetail companyId={companyId} task={task} taskConfiguration={taskConfiguration} />;
    };

    const taskActions = () => {
        const task = sendingEvent.taskDetails[taskIndex - 1];
        if (!task || !task.startDate || task.endDate) {
            return undefined;
        }

        return (
            <TaskActions
                isLoading={taskActionMutation.isLoading}
                onConfirmTaskAction={onConfirmTaskAction}
                task={task}
            />
        );
    };

    const detail = () => {
        switch (taskIndex) {
            case 0:
                return workflowDetail();
            case sendingEvent.taskDetails.length + 1:
                return sendingEvent.businessStatus === SendingEventBusinessStatus.SUCCESS
                    ? successResult()
                    : failureResult();
            default:
                return taskDetail();
        }
    };

    const approverDetail = (approver: string) => (
        <DescriptionItem title={tCommon('approver')} content={approver}></DescriptionItem>
    );

    return (
        <DetailDrawer
            title={t('title')}
            subtitle={sendingEvent.id}
            onClose={handleOnClose}
            main={timeline}
            detail={
                <>
                    <SpaceStyled>
                        {sendingEvent.approval && approverDetail(sendingEvent.approval.username)}
                        {taskActions()}
                    </SpaceStyled>
                    <Divider />
                    {detail()}
                </>
            }
        />
    );
};

export default SendingEventsDetail;
