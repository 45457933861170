import { Result } from 'antd';
import React, { ErrorInfo } from 'react';
import { TFunction, withTranslation } from 'react-i18next';

//Must be a class component!!!
class ErrorBoundary extends React.Component<{ t: TFunction }, { hasError: boolean }> {
    constructor(props: { t: TFunction<'translation'> } | Readonly<{ t: TFunction<'translation'> }>) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: unknown) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        //TODO send these error message to the backend (ttl = 1day) ?
        console.log(error);
        console.log(errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <Result
                    status="error"
                    title={this.props.t('glossary:error.title')}
                    subTitle={this.props.t('glossary:error.sub_title')}
                />
            );
        }

        return this.props.children;
    }
}

export default withTranslation()(ErrorBoundary);
