import { BpostCountryZones } from '../constant';

export type BpostCountryZone = typeof BpostCountryZones[number];
export interface BpostCountry {
    code: string;
    name: string;
    zone: BpostCountryZone;
    postalCodeFormat: string | null;
    active: boolean;
}

export type BpostCountries = Array<BpostCountry>;
