import {
    CheckCircleFilled,
    FireFilled,
    Loading3QuartersOutlined,
    ReloadOutlined,
    SearchOutlined,
    EllipsisOutlined,
} from '@ant-design/icons';
import { SendingEventBusinessStatus } from '@eservices/shared/constant';
import { Colors } from '@eservices/ui-constants/colors';
import { Checkbox, Form, Input, Select, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { format, subMonths } from 'date-fns';
import add from 'date-fns/add';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SecondaryButton } from '../../components/Buttons';
import DatePicker from '../../components/DatePicker';
import SendingEventsTable from '../../components/sending-event/SendingEventTable';
import { TABLE_PAGE_SIZE } from '../../constants';
import { useGetDepartments, useGetWorkflows, useSearchSendingEvents } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import useQueryParams from '../../hooks/useQueryHook';
import ResourcesTableLayout from '../../layouts/ResourcesTableLayout';
import { formatSearchTerm, getRangeDates } from '../../utils/helpers';

const dateFormat = 'DD/MM/YYYY';

const defaultSearchValues = {
    range: [subMonths(new Date(), 1), new Date()],
};

const StyledInput = styled(Input)`
    min-width: 254px !important;
`;

const StyledSelect = styled(Select)`
    min-width: 254px !important;
`;

const SearchForm = styled(Form)`
    display: flex;
    justify-content: flex-start;
    align-items: start;
    width: 100%;

    .ant-form-item {
        margin-bottom: 0;
    }
`;

const ReloadContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1%;
`;

const defaultPagination = { page: 1, pageSize: TABLE_PAGE_SIZE };

interface SearchFormValues {
    search: string;
    workflowName: string;
    departmentId: string;
    containWarnings: boolean;
    businessStatus: SendingEventBusinessStatus;
    range: [Date, Date];
    jobName: string;
    jobId: string;
}
interface SendingEventsOverviewFetchingProps {
    companyId: string;
    actions?: React.ReactNode;
}

const SendingEventsOverviewFetching: React.FC<SendingEventsOverviewFetchingProps> = ({ companyId, actions }) => {
    const { t, tCommon, tValidation } = useTranslation('se_overview');
    const [form] = useForm<SearchFormValues>();
    const { data: departments = [], isLoading: isLoadingDepartments } = useGetDepartments(companyId);
    const { data: workflows = [], isLoading: isLoadingWorkflows } = useGetWorkflows(companyId);
    const mutation = useSearchSendingEvents(companyId);
    const params = useQueryParams();
    const [pagination, setPagination] = useState(defaultPagination);

    const workflowsOptions = workflows.map((workflow) => ({ label: workflow.name, value: workflow.id }));
    const departmentsOptions = departments.map((department) => ({ label: department.name, value: department.id }));

    useEffect(() => {
        const jobId = params.get('jobId');
        const startDateStr = params.get('startDate');
        const containWarnings = params.get('containWarnings');

        const businessStatusStr = params.get('businessStatus');
        const departmentId = params.get('departmentId');
        const rangeDate = params.get('rangeDate');

        if (jobId && startDateStr) {
            const startDate = new Date(startDateStr);
            form.setFieldsValue({
                jobId,
                range: [startDate, add(startDate, { days: 1 })],
                containWarnings: containWarnings === 'true' ? true : undefined,
            });
        } else if (rangeDate) {
            const startDate = getRangeDates(rangeDate).startDate;
            const endDate = getRangeDates(rangeDate).endDate;
            const businessStatus = SendingEventBusinessStatus[businessStatusStr];

            form.setFieldsValue({
                departmentId: departmentId,
                range: [startDate, endDate],
                businessStatus: businessStatus,
                containWarnings: containWarnings === 'true' ? true : undefined,
            });
        }

        onSearch();
    }, []);

    const businessStatusOptions = [
        {
            label: (
                <Space>
                    <EllipsisOutlined style={{ color: Colors.NOT_STARTED }} /> {tCommon('not_started')}
                </Space>
            ),
            value: SendingEventBusinessStatus.NOT_STARTED,
        },
        {
            label: (
                <Space>
                    <Loading3QuartersOutlined style={{ color: Colors.PROCESSING }} /> {tCommon('in_progress')}
                </Space>
            ),
            value: SendingEventBusinessStatus.IN_PROGRESS,
        },
        {
            label: (
                <Space>
                    <CheckCircleFilled style={{ color: Colors.GOOD }} /> {tCommon('success')}
                </Space>
            ),
            value: SendingEventBusinessStatus.SUCCESS,
        },
        {
            label: (
                <Space>
                    <FireFilled style={{ color: Colors.ERROR }} /> {tCommon('failure')}
                </Space>
            ),
            value: SendingEventBusinessStatus.FAILURE,
        },
    ];

    const onSearch = async (page = defaultPagination.page, pageSize = defaultPagination.pageSize) => {
        const { search = '', jobId, jobName, range, containWarnings, ...rest } = await form.validateFields();

        const filter = {
            containWarnings,
            pagination: {
                page,
                pageSize,
            },
            range: {
                from: format(range[0], "yyyy-MM-dd'T00:00:00.000Z'"),
                until: format(range[1], "yyyy-MM-dd'T23:59:59.999Z'"),
            },
            jobId: formatSearchTerm(false, jobId),
            jobName: formatSearchTerm(true, jobName),
            search: formatSearchTerm(true, search),
            ...rest,
        };
        setPagination({ page, pageSize });
        mutation.mutate(filter);
    };

    const handlePaginationChange = (page: number, pageSize: number) => {
        setPagination({ page, pageSize });
        onSearch(page, pageSize);
    };

    const Filter = (
        <SearchForm form={form} initialValues={defaultSearchValues} requiredMark={false} layout="vertical">
            <Space direction="vertical">
                <div>
                    <Space>
                        <FormItem name="search" rules={[{ min: 3, message: tValidation('custom.min_3') }]}>
                            <StyledInput placeholder={t('search_placeholder')} />
                        </FormItem>
                        <FormItem name="jobName" rules={[{ min: 3, message: tValidation('custom.min_3') }]}>
                            <StyledInput placeholder={t('job_placeholder')} />
                        </FormItem>
                        <FormItem name="businessStatus">
                            <StyledSelect
                                placeholder={tCommon('business_status')}
                                allowClear
                                options={businessStatusOptions}
                                onChange={() => onSearch()}
                            />
                        </FormItem>
                        <FormItem name="containWarnings" valuePropName="checked">
                            <Checkbox onChange={() => onSearch()}>{t('show_only_warnings')}</Checkbox>
                        </FormItem>
                    </Space>
                </div>
                <div>
                    <Space align="start">
                        <FormItem name="workflowId">
                            <StyledSelect
                                placeholder={tCommon('workflow')}
                                allowClear
                                showSearch
                                options={workflowsOptions}
                                loading={isLoadingWorkflows}
                                onChange={() => onSearch()}
                            />
                        </FormItem>
                        <FormItem name="jobId" rules={[{ min: 3, message: tValidation('custom.min_3') }]}>
                            <StyledInput placeholder={t('job_id_placeholder')} />
                        </FormItem>
                        <FormItem name="departmentId">
                            <StyledSelect
                                placeholder={tCommon('department')}
                                allowClear
                                showSearch
                                options={departmentsOptions}
                                loading={isLoadingDepartments}
                                onChange={() => onSearch()}
                            />
                        </FormItem>
                        <FormItem
                            name="range"
                            rules={[
                                {
                                    required: true,
                                    message: tValidation('required.range'),
                                },
                            ]}
                        >
                            <DatePicker.RangePicker format={dateFormat} onChange={() => onSearch()} />
                        </FormItem>
                        <SecondaryButton icon={<SearchOutlined />} aria-label="save" onClick={() => onSearch()}>
                            {tCommon('search')}
                        </SecondaryButton>
                    </Space>
                </div>
            </Space>
        </SearchForm>
    );

    const detail = (
        <>
            <ReloadContainer>
                <SecondaryButton icon={<ReloadOutlined />} aria-label="refresh" onClick={() => onSearch()}>
                    {tCommon('refresh')}
                </SecondaryButton>
            </ReloadContainer>
            <SendingEventsTable
                companyId={companyId}
                total={mutation.data?.pagination.totalItems}
                isLoading={mutation.isLoading}
                sendingEvents={mutation.data?.items}
                onPaginationChange={handlePaginationChange}
                departments={departments}
                workflows={workflows}
                pagination={pagination}
            />
        </>
    );

    return (
        <ResourcesTableLayout isError={false} title={t('title')} filter={Filter} detail={detail} actions={actions} />
    );
};

export default SendingEventsOverviewFetching;
