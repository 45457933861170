import { Amplify } from '@aws-amplify/core';
import { default as React, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { ReactQueryDevtools } from 'react-query/devtools';
import App from './App';
import Favicon from './assets/images/favicon.jpg';
import { AppProviders } from './context';
import { environment } from './environments/environment';
import ErrorBoundary from './ErrorBoundary';
import './i18n';
import Loading from './screens/utils/Loading';
import './theme.less';

const amplifyConfig = {
    Auth: {
        region: environment.awsRegion,
        userPoolId: environment.userPoolId,
        userPoolWebClientId: environment.userPoolClientId,
        authenticationFlowType: 'USER_PASSWORD_AUTH', // Required flow to allow the User Migration trigger to be invoked
    },
    Analytics: {
        disabled: true,
    },
};
Amplify.configure(amplifyConfig);

ReactDOM.render(
    <React.StrictMode>
        {environment.production ? (
            <Helmet>
                <title>EasyPost.Connect</title>
                <link rel="icon" type="image/x-icon" href={Favicon} />
            </Helmet>
        ) : (
            <Helmet>
                <meta name="robots" content="noindex" />
                <title>EasyPost.Connect [{environment.pillar}]</title>
                <link rel="icon" type="image/x-icon" href={Favicon} />
            </Helmet>
        )}

        <AppProviders>
            <Suspense fallback={<Loading />}>
                <ErrorBoundary>
                    <App />
                    <ReactQueryDevtools initialIsOpen={false} />
                </ErrorBoundary>
            </Suspense>
        </AppProviders>
    </React.StrictMode>,
    document.getElementById('root'),
);
