import { ApplicationModule, UserRole } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { EASYPOST_ROUTES, localStorageModuleKey, OMNI_ROUTES } from './constants';
import { useGetMyUser } from './hooks/apiHooks';
import { useTranslation } from './hooks/translationHook';
import MainContentLayout from './layouts/MainContentLayout';
import SidebarLayout from './layouts/SidebarLayout';
import { createRoutesFor } from './router';
import ChooseCompany from './screens/choose_company/ChooseCompany';
import FatalError from './screens/utils/FatalError';
import Loading from './screens/utils/Loading';
import { MainRoute } from './types';

const localStorageCompanyKey = 'company';

const Container = styled.div`
    display: grid;
    grid-template-columns: 85px 1.7fr;
    grid-template-areas: 'sidebar main';
`;
export interface Company {
    id: string;
    name?: string;
}

const AuthenticatedApp: React.FC = () => {
    const { i18n } = useTranslation();
    const [company, setCompany] = useState<Company>(undefined);
    const { isError, isSuccess, data: user, error } = useGetMyUser();
    const location = useLocation();
    const [module, setModule] = useState<ApplicationModule>();

    const setNewModule = (newModule: ApplicationModule) => {
        localStorage.setItem(localStorageModuleKey, newModule);
        setModule(newModule);
    };

    const restorePreviousContext = () => {
        const localStorageContext = localStorage.getItem(localStorageModuleKey);
        if (localStorageContext) {
            setModule(localStorageContext as ApplicationModule);
        } else {
            setModule(ApplicationModule.EASYPOST_CONNECT);
        }
    };

    useEffect(() => {
        const firstPathPart = location.pathname.split('/')[1];
        if (firstPathPart === '') {
            restorePreviousContext();
        } else {
            if (OMNI_ROUTES.includes(firstPathPart)) {
                restorePreviousContext();
            } else {
                if (EASYPOST_ROUTES.includes(firstPathPart)) {
                    setNewModule(ApplicationModule.EASYPOST_PORTAL);
                } else {
                    setNewModule(ApplicationModule.EASYPOST_CONNECT);
                }
            }
        }
    }, [location]);

    useEffect(() => {
        if (user) {
            if (user.role === UserRole.SUPER_USER) {
                setCompany(JSON.parse(localStorage.getItem(localStorageCompanyKey)));
            } else {
                setCompany({ id: user.companyId });
            }
            i18n.changeLanguage(user.preferredLanguage.toLowerCase());
            // If user got access revoked to the current module -> Take the first available
            if (module && !user.modulesAccess.includes(module)) {
                setNewModule(user.modulesAccess[0]);
            }
        }
    }, [user]);

    const handleCompanySelection = (company: API.CompanyListItem) => {
        setCompany(company);
        localStorage.setItem(localStorageCompanyKey, JSON.stringify(company));
    };

    if (isError) return <FatalError error={error} />;

    if (isSuccess && company) {
        const routes = createRoutesFor(user, company.id);
        return (
            <Container role="main">
                <SidebarLayout
                    routes={routes.filter((route: MainRoute) => route.title)}
                    module={module}
                    availableModules={user.modulesAccess}
                />
                <MainContentLayout
                    onChangeCompany={setCompany}
                    routes={routes}
                    user={user}
                    companyName={company.name}
                />
            </Container>
        );
    }

    if (isSuccess && !company && user.role === UserRole.SUPER_USER) {
        return <ChooseCompany onComplete={handleCompanySelection} />;
    }

    return <Loading />;
};

export default AuthenticatedApp;
