import { LoginOutlined } from '@ant-design/icons';
import { Alert, Form } from 'antd';
import { useEffect } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { PrimaryButton } from '../../components/Buttons';
import { FormItemBold } from '../../components/FormItem';
import { Input, Password } from '../../components/Input';
import { CognitoChallenges, CognitoExceptions, Fonts, Routes } from '../../constants';
import { useAuth } from '../../context/auth-context';
import { useTranslation } from '../../hooks/translationHook';
import Layout from '../../layouts/LoginLayout';

const ForgotPasswordContainer = styled.div`
    margin-top: -20px;
    a {
        font-family: '${Fonts.ARIAL_BOLD}';
    }
`;

const SubmitContainer = styled.div`
    float: right;
`;

const SignInForm = () => {
    const history = useHistory();
    const { user, signIn } = useAuth();
    const [form] = Form.useForm();
    const { t, tCommon, tValidation, tError } = useTranslation('sign_in');

    const { execute, status, loading, error } = useAsyncCallback(
        async ({ username, password }) => await signIn(username, password),
    );

    const parseExceptionMessage = (message: string) => {
        if (message.includes('INVALID_CREDENTIALS')) {
            return tError('invalid_credentials_exception');
        } else {
            return message;
        }
    };

    useEffect(() => {
        if (status === 'success') {
            switch (user.challengeName) {
                case CognitoChallenges.NEW_PASSWORD_REQUIRED:
                    history.push(Routes.CONFIRM_PASSWORD);
                    break;
                case CognitoChallenges.SOFTWARE_TOKEN_MFA:
                    history.push(Routes.CONFIRM_MFA_CODE);
                    break;
            }
        } else if (status === 'error') {
            if (error.name === CognitoExceptions.PASSWORD_RESET_REQUIRED) {
                history.push(Routes.FORGOT_PASSWORD_SUBMIT_FORM);
            }
            form.resetFields();
        }
    }, [status]);

    return (
        <Layout title={t('title')}>
            <Form form={form} layout="vertical" name="login" onFinish={execute} requiredMark={false}>
                {error && (
                    <FormItemBold>
                        <Alert
                            message={tError(error.name)}
                            description={parseExceptionMessage(error.message)}
                            type="error"
                        />
                    </FormItemBold>
                )}
                <FormItemBold
                    label={tCommon('username')}
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.username'),
                        },
                    ]}
                >
                    <Input aria-label="username input" />
                </FormItemBold>
                <FormItemBold
                    label={tCommon('password')}
                    name="password"
                    rules={[{ required: true, message: tValidation('required.password') }]}
                >
                    <Password aria-label="password input" />
                </FormItemBold>
                <FormItemBold>
                    <ForgotPasswordContainer>
                        <Link aria-label="forgot password link" to={Routes.FORGOT_PASSWORD_FORM}>
                            {t('forgot_password')}
                        </Link>
                    </ForgotPasswordContainer>
                </FormItemBold>
                <FormItemBold>
                    <SubmitContainer>
                        <PrimaryButton
                            aria-label="sign in button"
                            icon={<LoginOutlined />}
                            disabled={loading}
                            htmlType="submit"
                        >
                            {t('log_in')}
                        </PrimaryButton>
                    </SubmitContainer>
                </FormItemBold>
            </Form>
        </Layout>
    );
};

export default SignInForm;
