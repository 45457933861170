import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../components/Buttons';
import { Routes } from '../../constants';
import { useTranslation } from '../../hooks/translationHook';
import UsersOverviewFetching from './UsersOverviewFetching';

interface UsersOverviewEditProps {
    companyId: string;
}

const UsersOverviewEdit: React.FC<UsersOverviewEditProps> = ({ companyId }) => {
    const { t } = useTranslation('user_overview');
    const history = useHistory();

    const actions = (
        <PrimaryButton
            icon={<PlusOutlined />}
            aria-label="create user link"
            onClick={() => history.push(Routes.CREATE_USER)}
        >
            {t('action_add')}
        </PrimaryButton>
    );

    return <UsersOverviewFetching companyId={companyId} actions={actions} />;
};

export default UsersOverviewEdit;
