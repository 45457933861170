import { TaskType } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { getSecondsFrom } from '../../../utils/helpers';

export type ErdsTaskFormFields = Omit<
    API.TaskERDS,
    'maxDurationInSeconds' | 'bodyTemplate' | 'bodyJsonTemplate' | 'validationErrors'
> & {
    timeToWait: number;
};

export const convertFormToErdsTask = (
    form: ErdsTaskFormFields,
    bodyTemplate: API.ConfigurationFile,
    bodyJsonTemplate: API.ConfigurationFile,
): API.TaskERDS => {
    const { timeToWait, ...rest } = form;
    let { attachment, htmlTemplate } = form;

    if (!bodyTemplate.filename) {
        bodyTemplate = undefined;
    }

    if (!bodyJsonTemplate.filename) {
        bodyJsonTemplate = undefined;
    }

    if (!attachment.filename) {
        attachment = undefined;
    }

    if (!htmlTemplate.filename) {
        htmlTemplate = undefined;
    }

    return {
        ...rest,
        taskType: TaskType.ERDS,
        validationErrors: [],
        maxDurationInSeconds: getSecondsFrom(timeToWait),
        attachment,
        htmlTemplate,
        bodyTemplate,
        bodyJsonTemplate,
    };
};
