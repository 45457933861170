import { ClockCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { WorkflowTriggerType } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { Col, Row, Space } from 'antd';
import { useTranslation } from '../../hooks/translationHook';
import DescriptionItem from '../DescriptionItem';
import { Header3 } from '../Headers';
import WorkflowTypeIndicator from '../WorkflowTypeIndicator';

interface WorkflowFormDetailProps {
    companyId: string;
    departmentId: string;
    workflowConfiguration: API.WorkflowConfiguration;
}

const WorkflowFormDetail: React.FC<WorkflowFormDetailProps> = ({ workflowConfiguration }) => {
    const { t, tCommon } = useTranslation('workflow');

    const TriggerValue =
        workflowConfiguration.trigger.type === WorkflowTriggerType.FILE_DROP ? (
            <Space align="baseline">
                {t('file_drop')}
                <UploadOutlined />
            </Space>
        ) : (
            <Space align="baseline">
                {t('scheduled')}
                <ClockCircleOutlined />
            </Space>
        );

    return (
        <>
            <Header3>{t('workflow_details')}</Header3>
            <Row>
                <Col span={12}>
                    <DescriptionItem title={tCommon('name')} content={workflowConfiguration.name} />
                </Col>
                <Col span={12}>
                    <DescriptionItem title={tCommon('description')} content={workflowConfiguration.description} />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem title={tCommon('trigger')} content={TriggerValue} />
                </Col>
                <Col span={12}>
                    <DescriptionItem
                        title={tCommon('description')}
                        content={<WorkflowTypeIndicator type={workflowConfiguration.type} />}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <DescriptionItem title={t('sftp_folder_name')} content={workflowConfiguration.sFTPFolderName} />
                </Col>
            </Row>
        </>
    );
};

export default WorkflowFormDetail;
