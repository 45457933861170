import { API, RegionOfInterest } from '@eservices/shared/type';
import { Colors } from '@eservices/ui-constants/colors';
import { FormInstance, Slider, Space } from 'antd';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { useTranslation } from '../../../../hooks/translationHook';
import { WatermarkUI } from '../../../../types';
import { SecondaryButton } from '../../../Buttons';
import { FormItem } from '../../../FormItem';
import { Header3 } from '../../../Headers';
import Upload from '../../../Upload';
import RectanglePDFEditor, { Rectangle, RectangleBoundaries } from './RectanglePDFEditor';

export interface RegionOfInterestUI extends RegionOfInterest {
    highlighted?: boolean;
}

const SliderContainer = styled.div`
    width: 150px;
`;

const Container = styled.div`
    display: flex;
    height: 100%;
    gap: 20px;
    flex-direction: column;
`;

const UploadContainer = styled.div`
    display: flex;
    width: 100%;
    margin: 20% 0;
    min-width: 800px;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 10px;
`;

const Description = styled(ReactMarkdown)`
    font-size: 14px;
    color: ${Colors.GRAY_SECONDARY};
    padding: 0 0 15px 0;
`;

interface DrawingToolProps {
    companyId: string;
    isReadOnly: boolean;
    needsMultiRecipientIdentifier: boolean;
    form: FormInstance;
    rectangles: Rectangle[];
    onRegionOfInterestDrawn: (regionOfInterest: RegionOfInterest, rectangle: Rectangle) => void;
    watermark: WatermarkUI;
    sampleFile?: API.ConfigurationFile;
    setSampleFile: React.Dispatch<React.SetStateAction<API.ConfigurationFile>>;
    boundaries?: RectangleBoundaries;
    canDraw: boolean;
}

const DrawingTool: React.FC<DrawingToolProps> = ({
    companyId,
    isReadOnly,
    rectangles = [],
    form,
    onRegionOfInterestDrawn,
    watermark,
    sampleFile,
    setSampleFile,
    boundaries,
    canDraw,
}) => {
    const { t, tCommon } = useTranslation('task');
    const [tmpSample, setTmpSample] = useState<API.ConfigurationFile>();
    const [opacity, setOpacity] = useState(1);

    const onFilenameChange = (filename: string) => setSampleFile({ filename });
    const onDownloadUrlChange = (url: string) => setSampleFile({ ...sampleFile, url });

    const onRectangleDrawn = (rectangle: Rectangle): void => {
        const { x, y, width, height } = rectangle;
        const x1 = Math.min(x + width, x);
        const x2 = Math.max(x + width, x);
        const y1 = Math.max(y - height, y);
        const y2 = Math.min(y - height, y);

        onRegionOfInterestDrawn({ x1, x2, y1, y2 }, rectangle);
    };

    return (
        <Container>
            {sampleFile?.url ? (
                <RectanglePDFEditor
                    pdfUrl={sampleFile.url}
                    boundaries={boundaries}
                    onRectangleDrawn={onRectangleDrawn}
                    items={rectangles}
                    opacity={opacity}
                    extra={
                        <>
                            <Space>
                                <div>{t('opacity')}</div>
                                <SliderContainer>
                                    <Slider min={0} max={1} step={0.01} value={opacity} onChange={setOpacity} />
                                </SliderContainer>
                            </Space>
                            <SecondaryButton
                                disabled={isReadOnly}
                                onClick={() => {
                                    setTmpSample(sampleFile);
                                    setSampleFile(undefined);
                                }}
                            >
                                {t('change_sample')}
                            </SecondaryButton>
                        </>
                    }
                    watermark={watermark}
                    canDraw={canDraw}
                />
            ) : (
                <UploadContainer id="pdf_canvas_upload">
                    <Header3>{t('upload_sample_title')}</Header3>
                    <Description>{t('upload_sample_description')}</Description>
                    <FormItem shouldUpdate hidden={sampleFile?.url !== undefined}>
                        {() => (
                            <Upload
                                accept=".pdf"
                                disabled={isReadOnly}
                                isImageUpload={false}
                                companyId={companyId}
                                hasFile={form.getFieldValue(['sampleFile', 'url']) !== undefined}
                                onFilenameChange={onFilenameChange}
                                onDownloadUrlChange={onDownloadUrlChange}
                            />
                        )}
                    </FormItem>

                    {tmpSample && (
                        <SecondaryButton
                            disabled={isReadOnly}
                            onClick={() => {
                                setSampleFile(tmpSample);
                            }}
                        >
                            {tCommon('cancel')}
                        </SecondaryButton>
                    )}
                </UploadContainer>
            )}
        </Container>
    );
};

export default DrawingTool;
