import { SendingEventBusinessStatus } from '@eservices/shared/constant';
import { API } from '@eservices/shared/type';
import { FormInstance } from 'antd/lib/form/Form';
import { Routes } from '../../../constants';
import { useTranslation } from '../../../hooks/translationHook';
import Layout from './Layout';
import { SendingEventErrorTile, SendingEventProcessingTile, SendingEventSuccessTile } from './statuses';
import StatusTile from './StatusTile';
import QueryString from 'query-string';

const statuses = [SendingEventSuccessTile, SendingEventProcessingTile, SendingEventErrorTile];

interface SendingProps {
    statusCounters: API.DashboardOperatorSendingsDetails;
    formFilters: FormInstance;
    isDetailsLoading: boolean;
    rangeDate: string;
    departmentId: string;
}

const defaultStatusCounter: API.DashboardOperatorSendingsDetails = {
    FAILURE: 0,
    IN_PROGRESS: 0,
    SUCCESS: 0,
    WARNING: 0,
};

const SendingInfo: React.FC<SendingProps> = ({
    statusCounters = defaultStatusCounter,
    isDetailsLoading,
    rangeDate,
    departmentId,
}) => {
    const { t } = useTranslation('dashboard');

    const baseRoute = Routes.SENDING_EVENTS;
    const totalAmount = statusCounters.SUCCESS + statusCounters.IN_PROGRESS + statusCounters.FAILURE;

    const amountPerStatus: { [key: string]: number } = {
        [SendingEventBusinessStatus.SUCCESS]: statusCounters.SUCCESS,
        [SendingEventBusinessStatus.IN_PROGRESS]: statusCounters.IN_PROGRESS,
        [SendingEventBusinessStatus.FAILURE]: statusCounters.FAILURE,
    };

    return (
        <Layout
            title={t('title_sendings')}
            amount={totalAmount}
            baseRoute={baseRoute}
            rangeDate={rangeDate}
            departmentId={departmentId}
            isDetailsLoading={isDetailsLoading}
            totalWarning={statusCounters.WARNING}
        >
            {statuses.map(({ status, ...props }, i) => (
                <StatusTile
                    isDetailsLoading={isDetailsLoading}
                    amount={amountPerStatus[status]}
                    pathname={baseRoute}
                    search={QueryString.stringify({
                        businessStatus: status,
                        rangeDate,
                        departmentId,
                    })}
                    status={statuses[i].status}
                    {...props}
                    key={i}
                />
            ))}
        </Layout>
    );
};

export default SendingInfo;
