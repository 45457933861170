import { API, WorkflowInputSource } from '@eservices/shared/common';
import { DEFAULT_SENDER_MAIL_ADDRESS } from '@eservices/shared/constant';
import React from 'react';
import styled from 'styled-components';
import { Input, InputNumberWithLeftAddon, Select, TextArea } from '../../../components/Input';
import { useTranslation } from '../../../hooks/translationHook';
import { FormItem } from '../../FormItem';
import { FormInstance } from 'antd';
import { SubjectInputWithExtraInfo } from '../common/SubjectInputWithExtraInfo';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-flow: row wrap;
    justify-content: flex-start;
    gap: 4% 10%;
`;

const Box = styled.div`
    width: 35%;
    max-width: 500px;
    min-width: 250px;
`;

interface SendingConfigurationProps {
    inputSource: WorkflowInputSource;
    form: FormInstance;
    departmentDetails: API.Department;
    isReadOnly: boolean;
}

const SendingConfiguration: React.FC<SendingConfigurationProps> = ({
    form,
    inputSource,
    departmentDetails,
    isReadOnly,
}) => {
    const { t, tCommon, tValidation } = useTranslation('email_task');

    const emailOptions = [
        { label: DEFAULT_SENDER_MAIL_ADDRESS, value: DEFAULT_SENDER_MAIL_ADDRESS },
        { label: departmentDetails.emailAddress, value: departmentDetails.emailAddress },
    ];

    return (
        <Container>
            <Box>
                <FormItem
                    label={t('name')}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.name'),
                        },
                    ]}
                >
                    <Input disabled={isReadOnly} type="text" aria-label="name input" />
                </FormItem>
                <FormItem label={tCommon('description')} name="description">
                    <TextArea disabled={isReadOnly} rows={5} aria-label="description input" />
                </FormItem>
            </Box>
            <Box>
                <FormItem
                    label={t('time_to_wait')}
                    name="timeToWait"
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.time_to_wait'),
                        },
                        {
                            type: 'number',
                            min: 1,
                            message: tValidation('min.time_to_wait'),
                        },
                        {
                            type: 'number',
                            max: 30,
                            message: tValidation('max.time_to_wait'),
                        },
                    ]}
                >
                    <InputNumberWithLeftAddon
                        disabled={isReadOnly}
                        addonBefore={t('days')}
                        min={1}
                        max={30}
                        aria-label="time to wait input"
                    />
                </FormItem>
            </Box>
            <Box>
                <FormItem
                    label={tCommon('sender_email')}
                    name={['sender', 'emailAddress']}
                    rules={[
                        {
                            type: 'email',
                            required: true,
                            message: tValidation('required.email'),
                        },
                    ]}
                >
                    <Select options={emailOptions}></Select>
                </FormItem>
                <FormItem
                    label={t('display_name')}
                    name={['sender', 'displayName']}
                    rules={[
                        {
                            required: true,
                            message: tValidation('required.display_name'),
                        },
                    ]}
                >
                    <Input disabled={isReadOnly} type="text" aria-label="display name input" />
                </FormItem>
            </Box>
            <Box>
                <SubjectInputWithExtraInfo form={form} inputSource={inputSource} isReadOnly={isReadOnly} />
            </Box>
        </Container>
    );
};

export default SendingConfiguration;
