import { User } from '@eservices/shared/type';
import { Colors } from '@eservices/ui-constants/colors';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Company } from '../AuthenticatedApp';
import Header from '../components/Header';
import ScreenRouter from '../components/ScreenRouter';
import { Routes } from '../constants';
import { useTranslation } from '../hooks/translationHook';
import PoliciesPopUp from '../screens/policies/PoliciesPopUp';
import { MainRoute } from '../types';

const headerHeight = '60px';
const footerHeight = '40px';

const Container = styled.div`
    grid-area: main;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: ${headerHeight} 1.8fr ${footerHeight};
    grid-template-areas:
        'header'
        'content'
        'policies';
`;

const HeaderContainer = styled.header`
    grid-area: header;
    height: ${headerHeight};
    border-bottom: solid 1px ${Colors.GRAY_QUATERNARY};
    display: grid;
    grid-template-columns: 0.7fr minmax(400px, 1.4fr);
    grid-template-rows: 1fr;
    gap: 0px 0px;
    padding: 14px;
    grid-template-areas: 'breadcrumbs userDetails';
`;

const Content = styled.div`
    overflow: auto;
    grid-area: content;
    padding: 2% 2% 1% 2%;
    background-color: ${Colors.GRAY_QUINARY};
    height: calc(100vh - ${headerHeight} - ${footerHeight});
`;

const FooterContainer = styled.footer`
    grid-area: policies;
    height: ${footerHeight};
    padding-right: calc(2% + 24px + 17px);
    background-color: ${Colors.WHITE};
    display: flex;
    flex-direciton: column;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid ${Colors.GRAY_QUATERNARY};
    a {
        color: ${Colors.GRAY_PRIMARY};
        &:hover {
            color: ${Colors.PRIMARY};
        }
    }
`;
interface MainContentLayoutProps {
    user: User;
    routes: MainRoute[];
    companyName?: string;
    onChangeCompany: React.Dispatch<React.SetStateAction<Company>>;
}

const MainContentLayout: React.FC<MainContentLayoutProps> = ({ user, routes, companyName, onChangeCompany }) => {
    const { tCommon } = useTranslation();
    const location = useLocation();

    const lastDocumentsAllSigned = true; //Temp variable in waiting for backend

    const shouldDisplayContent = () => {
        return lastDocumentsAllSigned || location.pathname.includes(Routes.POLICIES + '/');
    };

    return (
        <Container>
            <HeaderContainer>
                <Header user={user} companyName={companyName} onChangeCompany={onChangeCompany} />
            </HeaderContainer>
            <Content>
                <ScreenRouter routes={routes} />
            </Content>
            <FooterContainer>
                <Link to={Routes.POLICIES}>{tCommon('policies')}</Link>
            </FooterContainer>
            <PoliciesPopUp visible={!shouldDisplayContent()} />
        </Container>
    );
};

export default MainContentLayout;
