import { SplitIdentifierKind } from '@eservices/shared/constant';
import { PDFSplitIdentifier } from '@eservices/shared/type';
import React, { useState } from 'react';
import { ReactComponent as SplitDocumentIcon } from '../../../../../assets/images/pdf_split.svg';
import { useTranslation } from '../../../../../hooks/translationHook';
import TabContent from '../Common/TabContent';
import { WorkflowPDFInputSourceActions, WorkflowPDFInputSourceDrawingActions } from '../workflowPDFInputSourceReducer';
import SplitChoices from './SplitChoices';
import SplitOverview from './SplitOverview';

interface SplittingProps {
    isReadOnly: boolean;
    splitIdentifiers: PDFSplitIdentifier[];
    workflowPDFInputSourceDispatch: React.Dispatch<WorkflowPDFInputSourceActions>;
    setAction: React.Dispatch<React.SetStateAction<WorkflowPDFInputSourceDrawingActions>>;
}

const Splitting: React.FC<SplittingProps> = ({
    isReadOnly,
    setAction,
    splitIdentifiers,
    workflowPDFInputSourceDispatch,
}) => {
    const { t } = useTranslation('workflow');
    const [isShowSplitChoicesVisible, setIsShowSplitChoicesVisible] = useState(false);

    const onSplitChoiceSelected = (kind: SplitIdentifierKind) => {
        setIsShowSplitChoicesVisible(false);
        setAction({
            type: 'insertSplitIdentifier',
            payload: {
                kind,
                splitOnChange: false,
                regionOfInterest: {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 0,
                },
            },
        });
    };

    const onUpdateSplitIdentifier = (order: number, value: string) => {
        workflowPDFInputSourceDispatch({
            type: 'updateSplitIdentifier',
            payload: {
                order,
                value,
            },
        });
    };

    const onDeleteSplitIdentifier = (order: number) => {
        workflowPDFInputSourceDispatch({
            type: 'removeSplitIdentifier',
            payload: { order },
        });
    };

    return (
        <TabContent
            title={t('split_document_title')}
            description={t('markdown_split_document_description')}
            icon={SplitDocumentIcon}
        >
            {isShowSplitChoicesVisible ? (
                <SplitChoices onSplitChoiceSelected={onSplitChoiceSelected} isReadOnly={isReadOnly} />
            ) : (
                <SplitOverview
                    isReadOnly={isReadOnly}
                    splitIdentifiers={splitIdentifiers}
                    onAddNewSplitChoice={() => setIsShowSplitChoicesVisible(true)}
                    onUpdateSplitIdentifier={onUpdateSplitIdentifier}
                    onDeleteSplitIdentifier={onDeleteSplitIdentifier}
                />
            )}
        </TabContent>
    );
};

export default Splitting;
