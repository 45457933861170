import { Colors } from '@eservices/ui-constants/colors';
import { Badge } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from '../hooks/translationHook';
import Card from './Card';
import ConditionalWrap from './ConditionalWrap';
import { Header4 } from './Headers';
import { Paragraph } from './Paragraph';

type Size = 'small' | 'default';

const Container = styled(Card)<ContainerProps>`
    width: 350px;
    padding: 1% 1% 1% 3%;

    cursor: ${({ enabled }) => (enabled ? 'pointer' : 'not-allowed')};
    .ant-card-body {
        padding: 1px;
    }

    &:hover {
        ${({ enabled }) => enabled && `border-color: ${Colors.PRIMARY};`};
    }
`;

interface ContainerProps {
    enabled: boolean;
}

const CardContent = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: center;
`;

const Extra = styled.div`
    margin: 5% 0 0 0;
`;

const sizeProps = {
    small: `font-size: 40px;
            padding: 2%;
            width: 100px;
    `,
    default: `font-size: 70px;
              padding: 5%;
              width: 140px;
    `,
};

const IconContainer = styled.div<IconContainerProps>`
    ${({ size }) => sizeProps[size]}
    color: ${Colors.GRAY_SEPTENARY};
    border-radius: 5px;
    text-align: center;
    line-height: 70px;
    ${({ selected }) => selected && `background-color: ${Colors.PRIMARY}; color: ${Colors.WHITE}`};

    &:hover {
        ${({ enabled }) => enabled && `background-color: ${Colors.PRIMARY}; color: ${Colors.WHITE}`};
    }
`;
interface IconContainerProps {
    selected: boolean;
    enabled: boolean;
    size: Size;
}

const TextContainer = styled.div`
    margin: 5px 0 0 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const StyledParagraph = styled(Paragraph)`
    font-size: 12px;
`;

interface StyledHeader4Props {
    selected: boolean;
}

const StyledHeader4 = styled(Header4)<StyledHeader4Props>`
    ${({ selected }) => selected && `color: ${Colors.PRIMARY}`};
`;

interface WizardChoiceWithIconProps {
    onClick: () => void;
    enabled?: boolean;
    comingSoon?: boolean;
    selected?: boolean;
    icon: React.ReactNode;
    title: React.ReactNode;
    description: React.ReactNode;
    extra?: React.ReactNode;
    size?: Size;
}

const WizardChoiceWithIcon: React.FC<WizardChoiceWithIconProps> = ({
    title,
    description,
    selected = false,
    enabled = true,
    comingSoon = false,
    onClick,
    icon,
    extra,
    size = 'default',
}) => {
    const { tCommon } = useTranslation();
    const [isHoveringOver, setIsHoveringOver] = useState(false);

    return (
        <ConditionalWrap
            condition={comingSoon}
            wrap={(content) => (
                <Badge.Ribbon text={tCommon('coming_soon')} color={Colors.GRAY_SECONDARY}>
                    {content}
                </Badge.Ribbon>
            )}
        >
            <ConditionalWrap
                condition={!enabled}
                wrap={(content) => (
                    <Badge.Ribbon text={tCommon('not_available')} color={Colors.GRAY_SECONDARY}>
                        {content}
                    </Badge.Ribbon>
                )}
            >
                <div>
                    <Container
                        onClick={enabled && onClick}
                        enabled={enabled}
                        onMouseEnter={() => setIsHoveringOver(true)}
                        onMouseLeave={() => setIsHoveringOver(false)}
                    >
                        <CardContent>
                            <TextContainer>
                                <StyledHeader4 selected={selected}>{title}</StyledHeader4>
                                <StyledParagraph>{description}</StyledParagraph>
                            </TextContainer>
                            <IconContainer size={size} selected={selected || isHoveringOver} enabled={enabled}>
                                {icon}
                            </IconContainer>
                        </CardContent>
                    </Container>
                    {selected && extra && <Extra>{extra}</Extra>}
                </div>
            </ConditionalWrap>
        </ConditionalWrap>
    );
};

export default WizardChoiceWithIcon;
