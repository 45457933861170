import { SearchOutlined } from '@ant-design/icons';
import { Form, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { format, subMonths } from 'date-fns';
import { useEffect, useState } from 'react';
import { SecondaryButton } from '../../components/Buttons';
import DatePicker from '../../components/DatePicker';
import { FormItem } from '../../components/FormItem';
import { Input, Select } from '../../components/Input';
import RegisteredMailTable from '../../components/registered-mail/RegisteredMailTable';
import { TABLE_PAGE_SIZE } from '../../constants';
import { useGetDepartments, useSearchRegisteredMail } from '../../hooks/apiHooks';
import { useTranslation } from '../../hooks/translationHook';
import ResourcesTableLayout from '../../layouts/ResourcesTableLayout';
import styled from 'styled-components';

const dateFormat = 'DD/MM/YYYY';

const defaultSearchValues = {
    range: [subMonths(new Date(), 1), new Date()],
};

const StyledSelect = styled(Select)`
    min-width: 254px !important;
`;

const StyledInput = styled(Input)`
    min-width: 254px !important;
`;

const SearchForm = styled(Form)`
    display: flex;
    justify-content: flex-start;
    align-items: start;
    width: 100%;

    .ant-form-item {
        margin-bottom: 0;
    }
`;

const defaultPagination = { page: 1, pageSize: TABLE_PAGE_SIZE };

interface SearchFormValues {
    primaryBarCode: string;
    userRole: string;
    range: [Date, Date];
}
interface RegisteredMailOverviewFetchingProps {
    companyId: string;
    actions?: React.ReactNode;
}

const RegisteredMailOverviewFetching: React.FC<RegisteredMailOverviewFetchingProps> = ({ companyId, actions }) => {
    const { t, tCommon, tValidation } = useTranslation('registered_mail_overview');
    const [form] = useForm<SearchFormValues>();
    const { isLoading: isLoadingDepartments, data: departments = [] } = useGetDepartments(companyId);
    const searchMutation = useSearchRegisteredMail(companyId);

    const [pagination, setPagination] = useState(defaultPagination);

    const departmentsOptions = departments.map((department) => ({ label: department.name, value: department.id }));

    useEffect(() => {
        onSearch();
    }, []);

    const onSearch = async (page = defaultPagination.page, pageSize = defaultPagination.pageSize) => {
        const { range, ...rest } = await form.validateFields();
        const filter = {
            fromScannedAt: format(range[0], 'yyyy-MM-dd') + 'T00:00:00.000Z',
            untilScannedAt: format(range[1], 'yyyy-MM-dd') + 'T23:59:59.999Z',
            pagination: {
                page,
                pageSize,
            },
            ...rest,
        };
        setPagination({ page, pageSize });
        searchMutation.mutate(filter);
    };

    const handlePaginationChange = (page: number, pageSize: number) => {
        setPagination({ page, pageSize });
        onSearch(page, pageSize);
    };

    const Filter = (
        <SearchForm form={form} initialValues={defaultSearchValues} requiredMark={false} layout="vertical">
            <Space align="start">
                <Space>
                    <FormItem name="barCode" rules={[{ min: 3, message: tValidation('custom.min_3') }]}>
                        <StyledInput placeholder={tCommon('barcode')} />
                    </FormItem>
                    <FormItem name="departmentId">
                        <StyledSelect
                            placeholder={tCommon('department')}
                            allowClear
                            showSearch
                            options={departmentsOptions}
                            loading={isLoadingDepartments}
                            onChange={() => onSearch()}
                        />
                    </FormItem>
                    <FormItem
                        name="range"
                        rules={[
                            {
                                required: true,
                                message: tValidation('required.range'),
                            },
                        ]}
                    >
                        <DatePicker.RangePicker format={dateFormat} onChange={() => onSearch()} />
                    </FormItem>
                    <SecondaryButton icon={<SearchOutlined />} aria-label="search" onClick={() => onSearch()}>
                        {tCommon('search')}
                    </SecondaryButton>
                </Space>
            </Space>
        </SearchForm>
    );
    const detail = (
        <>
            <RegisteredMailTable
                companyId={companyId}
                total={searchMutation.data?.pagination.totalItems}
                isLoading={searchMutation.isLoading || isLoadingDepartments}
                registeredMails={searchMutation.data?.items}
                departments={departments}
                onPaginationChange={handlePaginationChange}
                pagination={pagination}
            />
        </>
    );

    return (
        <ResourcesTableLayout isError={false} title={t('title')} filter={Filter} detail={detail} actions={actions} />
    );
};

export default RegisteredMailOverviewFetching;
