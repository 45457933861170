import { API } from '@eservices/shared/type';
import { Collapse as AntdCollapse } from 'antd';
import { Colors } from '@eservices/ui-constants/colors';
import { useTranslation } from '../../hooks/translationHook';
import styled from 'styled-components';
import Collapse from '../Collapse';
import OrderPanelHeader from './OrderPanelHeader';
import ProductItem from './ProductItem';

const { Panel } = AntdCollapse;

const StyledCollapse = styled(Collapse)`
    .ant-collapse-arrow {
        color: ${Colors.TERTIARY};
        border-color: ${Colors.TERTIARY};
    }
`;

interface OrderDetailsProps {
    order: API.Order;
}

const OrderDetails: React.FC<OrderDetailsProps> = ({ order }) => {
    const { t } = useTranslation('order');
    return (
        <StyledCollapse>
            <Panel
                header={
                    <OrderPanelHeader
                        creationDate={order.createdAt}
                        status={order.status}
                        amount={order.items.length}
                    />
                }
                key={order.id}
            >
                {order.items.map((orderItem) => (
                    <ProductItem
                        key={`order_${order.id}_${orderItem.code}`}
                        productCode={orderItem.code}
                        amount={orderItem.amount}
                    />
                ))}
            </Panel>
        </StyledCollapse>
    );
};

export default OrderDetails;
