import { InfoCircleTwoTone } from '@ant-design/icons';
import { TaskType } from '@eservices/shared/constant';
import { Drawer } from 'antd';
import { useTranslation } from '../../../hooks/translationHook';
import React, { useState } from 'react';
import { SecondaryButton } from '../../Buttons';
import TaskDescription from './TaskDescription';

interface TaskInfoProps {
    taskType: TaskType;
}

const TaskInfo: React.FC<TaskInfoProps> = ({ taskType }) => {
    const { tCommon } = useTranslation('workflow');
    const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

    return (
        <div>
            <SecondaryButton
                type="text"
                shape="default"
                icon={<InfoCircleTwoTone />}
                onClick={() => {
                    setIsDescriptionVisible(true);
                }}
            >
                {tCommon('info')}
            </SecondaryButton>
            <Drawer
                placement="right"
                onClose={() => setIsDescriptionVisible(false)}
                visible={isDescriptionVisible}
                width="500px"
            >
                <TaskDescription taskType={taskType} />
            </Drawer>
        </div>
    );
};

export default TaskInfo;
