import React from 'react';
import { ReactComponent as WatermarkIcon } from '../../../../../assets/images/watermark.svg';
import { useTranslation } from '../../../../../hooks/translationHook';
import { WatermarkUI } from '../../../../../types';
import TabContent from '../Common/TabContent';
import { WorkflowPDFInputSourceDrawingActions } from '../workflowPDFInputSourceReducer';
import WatermarkEditor from './WatermarkEditor';

interface WatermarkProps {
    companyId: string;
    isReadOnly: boolean;
    setAction: React.Dispatch<React.SetStateAction<WorkflowPDFInputSourceDrawingActions>>;
    setWatermark: React.Dispatch<React.SetStateAction<WatermarkUI>>;
    watermark: WatermarkUI;
}

const Watermark: React.FC<WatermarkProps> = ({ companyId, isReadOnly, setAction, setWatermark, watermark }) => {
    const { t } = useTranslation('workflow');

    const onAddWatermark = (watermark: WatermarkUI) => {
        setWatermark(watermark);
        setAction({
            type: 'insertWatermark',
            payload: watermark,
        });
    };

    const onUpdateWatermark = (watermark: WatermarkUI) => {
        setWatermark(watermark);
    };

    const onDeleteExtraInfoIdentifier = () => {
        setWatermark(undefined);
    };

    const onRedrawWatermark = () => {
        setAction({
            type: 'insertWatermark',
            payload: watermark,
        });
    };

    return (
        <TabContent title={t('watermark_title')} description={t('watermark_description')} icon={WatermarkIcon}>
            <WatermarkEditor
                companyId={companyId}
                isReadOnly={isReadOnly}
                onAddWatermark={onAddWatermark}
                onDeleteWatermark={onDeleteExtraInfoIdentifier}
                onRedrawWatermark={onRedrawWatermark}
                onUpdateWatermark={onUpdateWatermark}
                watermark={watermark}
            />
        </TabContent>
    );
};

export default Watermark;
