import { API } from '@eservices/shared/type';
import { Colors } from '@eservices/ui-constants/colors';
import { normalizeString } from '@eservices/utils/string';
import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import EmailEditor, { UnlayerOptions } from 'react-email-editor';
import styled from 'styled-components';
import FailedToLoadData from '../../../components/FailedToLoadData';
import { useTranslation } from '../../../hooks/translationHook';

const Container = styled.div`
    iframe {
        min-width: 100% !important;
    }
`;

interface TemplateEditorProps {
    tags: Array<string>;
    design?: API.EmailDesign;
    isLoading?: boolean;
    setLoading: (isLoading: boolean) => void;
    isError?: boolean;
}

const EmailTemplateEditor = React.forwardRef<EmailEditor, TemplateEditorProps>(
    ({ tags, design, isLoading, isError, setLoading }, ref) => {
        const { t, i18n } = useTranslation('emailTemplateEditor');
        const [isEditorReady, setIsEditorReady] = useState(false);

        useEffect(() => {
            if (ref && typeof ref !== 'function' && ref.current && isEditorReady) {
                const mergeTags = tags.reduce(
                    (p, tag = '') => ({
                        // TODO - Refactor after implementation of [ECON-717]
                        ...p,
                        [tag]: {
                            name: tag,
                            value: `{{${normalizeString(tag)}}}`,
                        },
                    }),
                    {},
                );
                ref.current.setMergeTags(mergeTags);
                setLoading(false);
            }
        }, [tags, isEditorReady]);

        if (isLoading) return <Skeleton active />;
        if (isError) return <FailedToLoadData />;

        const onReady = () => {
            setLoading(true);
            setIsEditorReady(true);
            if (ref && typeof ref !== 'function' && design) {
                ref.current?.editor.loadDesign(design);
            }
        };

        const onLoad = () => {
            setLoading(true);
        };

        const options: UnlayerOptions = {
            id: 'editor',
            projectId: 74387,
            locale: i18n.language,
            displayMode: 'email',
            translations: {
                [i18n.language]: { 'labels.merge_tags': t('merge_tags') },
            },
            customCSS: [
                `
                * {
                    --accent-color: ${Colors.PRIMARY} !important;
                }
                .blockbuilder-placeholder .blockbuilder-placeholder-empty {
                    color: ${Colors.TERTIARY};
                    background-color: ${Colors.QUINARY_TRANSPARENT};
                    outline: ${Colors.TERTIARY} dashed 1px;
                }
                .iRgiNB{
                    background-color: ${Colors.PRIMARY};
                }
            `,
            ],
        };

        return (
            <Container>
                <EmailEditor onLoad={onLoad} options={options} minHeight={700} ref={ref} onReady={onReady} />
            </Container>
        );
    },
);

export default EmailTemplateEditor;
