import { BankOutlined } from '@ant-design/icons';
import { ApplicationModule, UserRole } from '@eservices/shared/constant';
import { Routes } from '../../constants';
import { BasicRoute, MainRoute } from '../../types';
import CompaniesOverviewEdit from './CompaniesOverviewEdit';
import CompanyDetailCreate from './CompanyDetailCreate';
import CompanyDetailEdit from './CompanyDetailEdit';

const editCompanyOverviewPage = (): BasicRoute => ({
    exact: true,
    path: Routes.COMPANIES,
    component: <CompaniesOverviewEdit />,
});

const createCompanyPage = (): BasicRoute => ({
    path: Routes.CREATE_COMPANY,
    component: <CompanyDetailCreate />,
});

const editCompanyPage = (): BasicRoute => ({
    path: Routes.EDIT_COMPANY,
    component: <CompanyDetailEdit />,
});

export const createCompaniesRouter = (userRole: UserRole): MainRoute => {
    const companyRoutes = {
        [UserRole.SUPER_USER]: [createCompanyPage(), editCompanyPage(), editCompanyOverviewPage()],
    };

    return {
        path: Routes.COMPANIES,
        title: 'companies',
        icon: <BankOutlined />,
        subRoutes: companyRoutes[userRole],
        module: ApplicationModule.EASYPOST_CONNECT,
    };
};
