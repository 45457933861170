import { PartialRecord } from '.';
import {
    ApplicationModule,
    CACHE_GLOBAL_SECONDARY_PK,
    CACHE_GLOBAL_SECONDARY_SK,
    CompanyProcessingType,
    CompanyVolume,
    ConsumptionAnnouncementType,
    DataType,
    JobStatus,
    Language,
    OrderStatus,
    RegisteredMailStatus,
    SendingEventBusinessStatus,
    SendingEventTechnicalStatus,
    SubscriptionType,
    UserRole,
} from '../constant'; // TODO rework to new import style
import * as Cache from './cache-model';
import * as Domain from './domain-model';
import { OrderItem, PhysicalAddress, RegisteredMail, TaskDetails, WorkflowConfiguration } from './domain-model';

//TODO replace reference with the ItemsWithPagination in utils
export type ListES<T> = {
    items: Array<T>;
    pagination: {
        page: number;
        pageItems: number;
        totalItems: number;
    };
};

export type ESItem = {
    _index: string;
    _type: string;
    _id: string;
    _score: number;
    _source: any;
};

export interface BaseDataEntry {
    pk: string;
    sk: string;
    dataType: DataType;
}

export interface BaseDataEntryWithTTL extends BaseDataEntry {
    ttl: number;
}

export interface BaseCacheEntryDB {
    pk: string;
    sk: string;
    [CACHE_GLOBAL_SECONDARY_PK]?: string;
    [CACHE_GLOBAL_SECONDARY_SK]?: string;
    ttl?: number;
}

export type CacheEntryDB = BaseCacheEntryDB & Cache.CacheEntry;

export interface TrackedEntry extends BaseDataEntry {
    createdAt: string;
    lastModifiedAt: string;
    lastModifiedBy: string;
}

// TODO [ECON-625] - Refactor JobDB and SendingEventDB to include the same props as the domain model + PK & SK
export interface JobDB extends BaseDataEntry {
    departmentId: string;
    startDate: string;
    warnings: string[];
    errors: string[];
    config: WorkflowConfiguration;
    preprocessingExecutionId: string;
    orchestrationExecutionId?: string;
    name: string;
    originalFilename: string;
    location: string;
    status: JobStatus;
    isResend: boolean;
    sendingEvents: { ok: number; warning: number; total: number };
    author?: string;
    executionDate: string;
    approval?: Domain.Approval;
    ttl: number;
}

export interface SendingEventDB extends BaseDataEntry {
    jobId: string;
    jobName: string;
    departmentId: string;
    startDate: string;
    endDate?: string;
    status: SendingEventTechnicalStatus;
    businessStatus: SendingEventBusinessStatus;
    config: WorkflowConfiguration;
    taskDetails: Array<TaskDetails>;
    approval?: Domain.SendingEventApproval;
    warnings: string[];
    errors: string[];
    ttl: number;
}

export interface UserDB extends TrackedEntry {
    username: string;
    firstName: string;
    lastName: string;
    preferredLanguage: Language;
    role: UserRole;
    emailAddress: string;
    departments: string[];
    hasQERDSMandate: boolean;
    hasMFALoginEnabled: boolean;
    modulesAccess: ApplicationModule[];
}

export interface WorkflowConfigurationDB extends Omit<WorkflowConfiguration, 'id' | 'companyId'>, TrackedEntry {
    version: number; // being used?
}

export interface DepartmentDB extends TrackedEntry {
    name: string;
    erpId: string;
    hasSeparateInvoicing: boolean;
    identificationNumber?: string;
    headAddress: PhysicalAddress;
    emailAddress?: string;
    phoneNumber?: string;
    isEligibleForRPRegisteredMail: boolean;
    consumptionAnnouncementType: ConsumptionAnnouncementType;
    isActive: boolean;
}

export interface CompanyDB extends TrackedEntry {
    name: string;
    identificationNumber?: string;
    erpId: string;
    volume: CompanyVolume;
    processingType: CompanyProcessingType;
    headAddress: PhysicalAddress;
    emailAddress?: string;
    phoneNumber?: string;
    isActive: boolean;
}

export interface OrderDB extends BaseDataEntry {
    departmentId: string;
    status: OrderStatus;
    createdAt: string;
    lastModifiedAt: string;
    lastModifiedBy: string;
    items: Array<OrderItem>;
    author: string;
}

export interface RegisteredMailDB extends BaseDataEntry {
    departmentId: string;
    statusHistory: PartialRecord<RegisteredMailStatus, string>;
    primaryBarCode: string;
    scannedAt: string;
    bpostStampDate?: string;
    secondaryBarCode?: string;
    ePodLocation?: string;
}

export type RegisteredMailListES = ListES<RegisteredMail>;

export type ParcelDB = Domain.Parcel & BaseDataEntry;

export type ParcelContactDB = Domain.ParcelContact & BaseDataEntry;

export interface SystemParametersDB extends TrackedEntry {
    value: unknown;
}

export interface SubscriptionDetailsDB extends BaseDataEntry {
    usersCount?: number;
    departmentsCount?: number;
    workflowsCount?: number;
    subscriptionType?: SubscriptionType;
}

export interface PostalProductsCatalogDB {
    pk: string;
    sk: string;
    dataType: DataType.POSTAL_PRODUCT_CATALOG;
    NonPrior: string[];
    Prior: string[];
    Registered: string[];
    Parcels: string[];
}
export type DailyConsumptionAnnouncementDB = Domain.DailyConsumptionAnnouncement & BaseDataEntry;

export type QERDSApiKeyDB = Domain.QERDSApiKey & BaseDataEntry;
export type QERDSMandateHolderNotificationDB = Domain.QERDSMandateHolderNotification & BaseDataEntry;

export type PolicyDB = Domain.Policy & BaseDataEntry;

export type PoliciesInventoryDB = Domain.PoliciesInventory & BaseDataEntry;
