import DashboardOperatorFetching from './DashboardOperatorFetching';

interface DashboardOperatorViewProps {
    companyId: string;
}

const DashboardOperatorView: React.FC<DashboardOperatorViewProps> = ({ companyId }) => {
    return <DashboardOperatorFetching companyId={companyId} />;
};

export default DashboardOperatorView;
